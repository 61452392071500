<template>
  <div class="nav" ref="appNavRef">
    <!-- MAIN NAV -->
    <nav class="nav__wrap nav__wrap--main">
      <section class="nav__content nav__content--main" :class="appWidthClass">
        <div class="nav__content">
          <!-- LOGO -->
          <div class="nav__logo">
            <router-link to="/">
              <svg-icon icon="zingsoft-logomark-light"></svg-icon>
            </router-link>
          </div>
          <!-- NAV ITEMS -->
        </div>

        <!-- AVATAR -->
        <div class="nav__item-container nav__content--wide nav__content--right" v-show="!isMobile">
          <!-- Notificaions -->
          <!-- <router-link v-show="groupingDashboardView" to="/account/notifications">
            <span class="nav__alert nav__alert--no-badge"><font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon></span>
            <el-badge value="new" class="nav__alert">
              <font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon>
            </el-badge>
          </router-link> -->

          <el-dropdown @command="navigate" @visible-change="navActive" trigger="click" class="trigger trigger--relative">
            <div class="el-dropdown-link" >
              <img v-if="avatar && avatar !== '#'" class="nav__avatar" :src="avatar" /><span class="el-dropdown-link__text">{{displayName}}</span><svg-icon icon="angle" class="nav__icon--angle" fill="#fff" width="7"></svg-icon>
            </div>
            <template v-slot:dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="/account" class="nav__menu-item nav__menu-item--highlight"><img v-if="avatar && avatar !== '#'" class="nav__avatar--mobile" :src="avatar"/><span>{{displayName}}</span></el-dropdown-item>
                <el-dropdown-item command="/" class="nav__menu-item nav__menu-item--secondary"><svg-icon icon="dashboard" class="nav__menu-item__icon" width="12" height="12"></svg-icon>Dashboard</el-dropdown-item>
                <el-dropdown-item command="/account/support" class="nav__menu-item nav__menu-item--secondary"><svg-icon icon="support" class="nav__menu-item__icon" width="14" height="15"></svg-icon>Support</el-dropdown-item>
                <el-dropdown-item command="/account" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'cog']"/></span>Account</el-dropdown-item>
                <el-dropdown-item command="/account/subscriptions" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'clock']"/></span>Subscriptions</el-dropdown-item>
                <el-dropdown-item v-if="adminDashboardView" command="/admin" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'lock']"/></span>ZingNet</el-dropdown-item>
                <el-dropdown-item command="logout" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'sign-out-alt']"/></span>Logout</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </section>
      <div v-show="isMobile" class="nav__mobile-icon">
        <!-- Notificaions -->
        <!-- <router-link v-show="groupingDashboardView" to="/account/notifications">
          <span class="nav__alert nav__alert--no-badge"><font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon></span>
          <el-badge value="new" class="nav__alert">
            <font-awesome-icon :icon="['fas', 'bell']"></font-awesome-icon>
          </el-badge>
        </router-link> -->
      </div>
      <div v-show="isMobile" class="nav__mobile-icon">
        <el-dropdown @command="navigate" @visible-change="navActive" trigger="click" class="trigger trigger--relative">
          <span class="el-dropdown-link">
            <span class="nav__mobile__text">Menu <svg-icon icon="angle" class="nav__icon--angle" fill="#fff" width="9"></svg-icon></span>
          </span>
          <template v-slot:dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="/account" class="nav__menu-item nav__menu-item--highlight"><img v-if="avatar && avatar !== '#'" class="nav__avatar--mobile" :src="avatar"/><span>{{displayName}}</span></el-dropdown-item>
              <el-dropdown-item command="/account" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'cog']"/></span>Account</el-dropdown-item>
              <el-dropdown-item command="/account/subscriptions" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'clock']"/></span>Subscriptions</el-dropdown-item>
              <el-dropdown-item v-if="adminDashboardView" command="/admin" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'lock']"/></span>ZingNet</el-dropdown-item>
              <el-dropdown-item command="logout" class="nav__menu-item"><span class="nav__menu-item__icon"><font-awesome-icon :icon="['fas', 'sign-out-alt']"/></span>Logout</el-dropdown-item>
              <el-dropdown-item command="/" class="nav__menu-item nav__menu-item--secondary"><svg-icon icon="dashboard" class="nav__menu-item__icon" width="12" height="12"></svg-icon>Dashboard</el-dropdown-item>
              <el-dropdown-item command="/account/support" class="nav__menu-item nav__menu-item--secondary"><svg-icon icon="support" class="nav__menu-item__icon" width="14" height="15"></svg-icon>Support</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </nav>
  </div>
</template>

<script setup>
  import { computed, getCurrentInstance, onBeforeMount, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { computedAsync } from '@vueuse/core';
  import axios from 'axios';
  import SvgIcon from './SvgIcon.vue';
  import routes from '../routes/main.js';
  import permissionsComposable from '../mixins/permissions.js';
  const { checkPermission } = permissionsComposable();

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();

  const appNavRef = ref(null);
  const routesArr = ref(routes);
  const activeRoute = ref('/');
  const email = ref('');
  const name = ref('');
  const picture = ref(null);
  const treeProps = ref({
    children: 'children',
    label: 'description'
  });
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);
  // point URL to stage or master
  const zinggridUrl = ref(VUE_APP_ZINGGRID_ROOT_URL);

  const adminDashboardView = computedAsync(
    async () => {
      return await checkPermission('admin_dashboard_view', null, null, $store);
    }, null
  );
  const groupingDashboardView = computedAsync(
    async () => {
      return await checkPermission('grouping_dashboard_view', null, null, $store);
    }, null
  );

  const authenticated = computed(() => {
    return $store.state.user.user_id;
  });
  const isMobile = computed(() => {
      const isMobile = $store.getters['ui/isMobile'];
      if(!isMobile && mobileMenuActive.value) {
        closeMenu();
      }
      return isMobile;
  });
  const appWidthClass = computed(() => {
    return ($store.state.ui.width === 'narrow') ? 'nav__content--narrow' : 'nav__content--wide';
  });
  const accountRoutes = computed(() => {
    const routes = [];
    routesArr.value.forEach((route) => {
      if(route.path ==='/account') {
        route.children.forEach((child) => {
          routes.push({
            description: child.description,
            path: route.path + '/' + child.path,
          });
        });
      }
    });
    return routes;
  });
  const isStudio = computed(() => {
    return ($route.path.includes('/demos'));
  });
  const avatar = computed(() => {
    return ($store.state.user && $store.state.user.picture) ? picture.value : null;
  });
  const currentSection = computed(() => {
    return (isStudio.value) ? 'Studio': 'Account';
  });
  const displayName = computed(() => {
    return name.value || email.value;
  });
  const routeMap = computed(() => {
    return flattenedRoutes.value.reduce((acc, val) => {
      acc[val.description] = val.path;
      return acc;
    }, {});
  });
  const flattenedRoutes = computed(() => {
    const routes = flatten(routesArr.value);
    return routes;

    function flatten(routes, currentPath = '', result = [], levels = 0) {
      routes.forEach(({path, description, children}) => {
        if(children) {
          result.concat(flatten(children, currentPath + path + '/', result, levels + 1));
        } else {
          // Strip off any route params.
          let formattedPath = currentPath + path;
          formattedPath = formattedPath.split(':')[0]
          result.push({
            path: formattedPath,
            description,
            levels,
          });
        }
      });
      return result;
    }
  });
  const mobileMenuActive = computed(() => {
    return $store.state.ui.isMenuOpen;
  });

  onBeforeMount(() => {
    // Fetch data to prefill fields
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      name.value = userData.name;
      email.value = userData.email;
      picture.value = userData.picture;
    });
  });

  onMounted(() => {
    // Initial load
    // TODO: check for router load
    const widePaths = ['/demos/create'];

    if(window.location.pathname.includes(widePaths)) {
      $store.state.ui.width = 'wide';
    }
    // On route change
    $router.beforeEach((to, from, next) => {
      // Make request only when component is not about to be dismounted
      // AppNav not available to login views
      if (to.path !== '/login') {
        $api('user/session', {}, $global)
          .catch((error) => {
            if (error.response.status === 401 && error.response.data === 'Session Expired') {
              // Session Expired (after email change, password change/reset)
              if ($route.path !== '/logout') $router.push('/logout');
            }
          })
      };

      const appContent = document.querySelector('.app-content');
      if (appContent) appContent.scrollTop = 0;
      if(to.path.includes(widePaths)) {
        $store.state.ui.width = 'wide';
      } else {
        $store.state.ui.width = 'narrow';
      }
      next();
    });

    // After each route
    $router.afterEach((to, from) => {
      // always wrap tracking code in error catch
      try {
        if (typeof _paq !== 'undefined') {
          // not sure why I have to build the url, but it reads all routes at root for
          // piwik. So lets build that route
          _paq.push(['setDocumentTitle', `${window.location.host}${to.path}`]);
          // if piwik exists. Track a page view
          _paq.push(['trackPageView']);
        }
      } catch(e) {}
    });
    
    if($route.path === '/demos/create' || $route.path === '/builder') {
      activeRoute.value = '/demos';
    } else {
      activeRoute.value = $route.path;
    }

  });

  function updateWindowSize() {
    windowHeight.value = window.innerHeight;
    windowWidth.value = window.innerWidth;
  };

  function navigate(command) {
    if(command === 'logout') {
      logout();
    } else {
      if ($route.path !== command) $router.push(command);
      closeMenu();
    }
  };

  function navActive(active) {
    
    if (active) {
      setTimeout(() => {
        let menu = document.querySelector('.el-dropdown-menu.el-popper[id]:not([style*="display: none"])');
        if (menu) {
          let menuId = menu.getAttribute('id');
          let menuTrigger = appNavRef.value.querySelector(`[aria-controls="${menuId}"`).closest('.el-dropdown');
          menuTrigger.classList.add('active');
        }
      }, 500);
    } else {
      let menus = document.querySelectorAll('.el-dropdown.trigger');
      menus.forEach((menu) => {
        let menuTrigger = menu.closest('.el-dropdown');
        menuTrigger.classList.remove('active');
      })
    }
  };

  function openMenu() {
    $store.commit('ui/menuStatus', true);
  };

  function closeMenu() {
    $store.commit('ui/menuStatus', false);
  };

  function logout() {
    if ($route.path !== '/logout') $router.push('/logout');
  };
</script>

<style>
  .nav__logo [icon="zingsoft-logomark-light"] {
    display: flex;
  }

  .dropdown--container { display: flex; align-items: center;padding: 10px; }
  .dropdown--container svg { width:122px; height:37px; }
  /* MOBILE STYLING  */
  .el-menu-item {
    text-decoration: none;
  }

  .nav__alert,
  .nav__alert--no-badge {
    bottom: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    right: 1.5rem;
  }

  .nav__alert svg,
  .nav__alert--no-badge {
    height: 1.05rem;
  }

  .nav__alert .el-badge__content {
    border: 0 !important;
    font-size: 10px !important;
    height: 15px !important;
    line-height: 12px !important;
    padding: 1px 4px !important;
    right: 5px !important;
    top: 8px !important;
  }

  .nav__alert--no-badge a {
    color: #fff;
  }

  .nav__wrap--mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 68px;
    width: 100%;
    height: calc(100% - 68px);
    background: #FFF;
    border-top: 1px solid #EFEFEF;
    padding: 1.5rem;
    z-index: 999;
  }
  .nav__name--mobile {
    font-size: 1.5rem;
  }
  .nav__mobile-icon {
    cursor: pointer;
    font-size: 0.9375rem;
    letter-spacing: 0.03rem;
  }
  .nav__mobile-icon .nav__alert {
    position: initial;
    right: 0;
  }
  .nav__mobile__text {
    display: flex;
    padding: 0 0.25rem;
  }
  .nav__header--mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 1rem;
  }
  .nav__content--mobile {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: flex-end;
  }
  .nav__icon--angle {
    margin-left: 0.5rem;
  }
  .nav__link--mobile {
    font-size: 1.3rem;
    list-style-type: none;
    text-align: right;
    padding: 1rem 0;
    cursor: pointer;
  }
  .nav__link--mobile a {
    text-decoration: none;
  }
  .nav__link--mobile.router-link-exact-active {
    font-weight: 600;
  }
  .nav__link--mobile .fa-users.fa-w-20 { width: 2.25rem; }
  .el-dropdown-menu .fa-users {
    margin: 0 -0.25rem 0 0.25rem;
    width: 1rem;
  }

  .nav .el-icon-arrow-down.el-icon--right {
    display: none;
  }

  .nav__avatar--mobile {
    height: 1.375rem;
    width: 1.375rem;
    border-radius: 1.375rem;
    margin: 0 0.8125rem 0 0;
    font-weight: 500;
  }

  .nav {
    width: 100%;
    background: #fff;
    color: var(--color-primary-1);
    box-shadow: 1px 2px 8px rgba(49, 49, 49, 0.28);
    overflow: hidden;
    z-index: 150;
  }
  .nav__item-container {
    align-items: center;
    display: flex;
  }
  .nav__item {
    color: #fff;
    font-size: 0.8125rem;
    font-weight: 600;
    padding: 0 1.5rem;
    position: relative;
    text-decoration: none;
  }
  .nav__item--padding {
    padding: 0 1.5rem 0 1rem;
  }
  .nav__item.router-link-exact-active {
    background: var(--color-primary-7);
  }
  .nav__wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    background: #fff;
  }
  .nav__wrap--main {
    background: var(--background-dark);
    line-height: var(--nav-height);
    height: var(--nav-height);
    font-size: 1.5rem;
    color: #fff;
  }
  .nav__content {
    display: flex;
    margin: 0;
  }

  .nav__content--main {
    justify-content: space-between;
    width: 100%;
  }

  .nav__content.nav__content--wide {
    padding: 0px;
  }

  .nav__avatar {
    height: 1.375rem;
    bottom: 2px;
    border-radius: 30px;
    font-weight: 500;
    margin: 0 0.2rem;
    position: relative;
    right:10px;
  }

  .nav__avatar img {
    box-shadow: var(--box-shadow-card);
    margin-right: 0.5rem;
    top: 1px;
  }

  .nav__logo > a {
    display: flex;
  }
  .nav__logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    margin-left: var(--sidebar-primary-width);
    width: 9rem;
  }
  .nav__item__icon {
    margin: 0 0.875rem 0 0;
  }
  .nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0.3rem;
    margin-right: 3rem;
    cursor: pointer;
  }
  .nav__link a {
    color: white;
    text-decoration: none;
  }

  .nav__link.router-link-exact-active:after, .nav__link:hover:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: var(--color-tertiary-1);
  }

  .nav .el-dropdown {
    padding: 1rem;
  }
  .nav .el-dropdown.active {
    background: var(--color-primary-8);
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: normal;
  }
  .el-dropdown-link__text {
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
  }
  .el-dropdown-menu__item:hover {
    background-color: var(--color-primary-8) !important;
  }
  .el-dropdown-menu__item:first-of-type {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .el-dropdown-menu__item:last-of-type {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom-color: var(--color-primary-8) !important;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-menu {
    border: 0 !important;
  }


  /* el-dropdown overwrites */
  .el-dropdown-menu {
    padding: 0 !important;
  }
  .nav .el-dropdown-menu {
    top: 1.75rem !important;
  }

  .nav__menu-item {
    align-items: center;
    border-bottom: 1px solid #D8D8D8 !important;
    color: var(--color-primary-7) !important;
    display: flex;
    font-size: 0.9375rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.03rem !important;
    padding: 5px 20px !important;
  }
  .nav__menu-item:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .nav__menu-item:last-of-type {
    border-bottom: 0 !important;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .nav__menu-item:hover {
    color: #fff !important;
    background: var(--color-primary-8) !important;
  }
  .nav__menu-item:hover svg {
    fill: #fff;
  }
  .nav__menu-item--secondary {
    color: var(--color-tertiary-7) !important;
    background: #ECF8FF !important;
  }

  .nav__menu-item--highlight {
    background: var(--color-primary-7) !important;
    color: #fff !important;
  }
  .nav__menu-item--highlight ~ .popper__arrow::after {
    border-bottom: 5px solid var(--color-primary-7) !important;
  }
  .nav__menu-item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom: 5px solid var(--color-primary-8) !important;
  }
  .nav__menu-item__icon {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    width: 1rem;
  }
  .nav__menu-item__icon svg,
  .nav__menu-item__icon .fa-users {
    width: 1rem;
    margin: 0;
  }


  .nav__logo a {
    text-decoration: none;
  }

  @media screen and (min-width: 929px) {
    .nav .el-dropdown {
      padding: 1.5rem;
    }
  }
</style>
