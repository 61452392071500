<template>
  <secondary-layout :isAuthing="isAuthing" >
    <section ref="$el" class="content__wrap content__wrap--login">
      <div class="content content--narrow">

        <!-- Display bar if redirect from site pricing page -->
        <div class="purchaseNotification" v-if="planId && planInterval">
          Please login or sign up to continue your purchase.
        </div>

        <!-- Signup container -->
        <entry-container>
          <template v-slot:entryHeader>
            <h3>Create An Account</h3>
          </template>

          <template v-slot:entryBody>
            <div>
              <div>
                <google-entry :regenerate-session="regenerateSession">Continue with Google</google-entry>
              </div>
              <p class="margin--small--laptop">or</p>

              <el-form @submit.prevent :model="form" ref="signupForm" signup-form>
                <input-email form="form" :value="form.email" @change="updateEmail" @validate="validateForm('email')"></input-email>
                <el-popover class="tooltip_validate" effect="dark" :placement="validationPlacement">
                  <div>
                    Password must:
                    <ul class="password-validation">
                      <li class="password" v-bind:class="validClass('atLeast8Chars')"><font-awesome-icon :icon="validIcon('atLeast8Chars')"/> Be a minimum of 8 characters</li>
                      <li class="password" v-bind:class="validClass('atLeast1Lower')"><font-awesome-icon :icon="validIcon('atLeast1Lower')"/> Contain at least lower case letter</li>
                      <li class="password" v-bind:class="validClass('atLeast1Upper')"><font-awesome-icon :icon="validIcon('atLeast1Upper')"/> Contain at least 1 upper case letter</li>
                      <li class="password" v-bind:class="validClass('atLeast1Number')"><font-awesome-icon :icon="validIcon('atLeast1Number')"/> Contain at least 1 number</li>
                      <li class="password" v-bind:class="validClass('notEmail')"><font-awesome-icon :icon="validIcon('notEmail')"/> Not match email</li>
                    </ul>
                  </div> 
                  <template #reference>
                    <div :error="passwordError">
                      <el-form-item
                        prop="password"
                        :rules="[
                          { required: true, message: 'Please input password', trigger: 'blur' },
                          { validator: validateLength, trigger: 'blur' },
                          { validator: validateUpper, trigger: 'blur' },
                          { validator: validateLower, trigger: 'blur' },
                          { validator: validateNumber, trigger: 'blur' },
                          { validator: validateNotEmail, trigger: 'blur' },
                        ]">
                        <el-input @blur="validateForm('password')" v-model="form.password" tabindex=2 type="password" placeholder="Create a password">
                          <template v-slot:prepend>
                            <svg width="11" height="14" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon auth0-lock-icon-box"><path d="M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z" fill-rule="evenodd"/></svg>
                          </template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </template>
                </el-popover>
                <div :error="passwordConfirmError">
                  <el-form-item
                    prop="confirm"
                    :rules="[
                      { required: true, message: 'Please input password', trigger: 'blur' },
                      { validator: validateConfirm, trigger: 'blur' }
                    ]">
                    <el-input v-model="form.confirm" @blur="validateForm('confirm')" @keyup.enter="signup" tabindex=3 type="password" placeholder="Confirm your password">
                      <template v-slot:prepend>
                        <svg width="11" height="14" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" class="auth0-lock-icon auth0-lock-icon-box"><path d="M11 15.998H2a2 2 0 0 1-2-1.999v-6c0-1.104.896-2.001 2-2.001V4.499a4.501 4.501 0 0 1 9 0v1.5a2 2 0 0 1 2 2v6a2 2 0 0 1-2 1.999zM10 4.499a3.5 3.5 0 1 0-7 0v1.499h7V4.499zm2 3.5a1 1 0 0 0-1-1.001H2a1 1 0 0 0-1 1.001v6c0 .552.447.999 1 .999h9a1 1 0 0 0 1-.999v-6zm-5.5 4.999a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-.5.5z" fill-rule="evenodd"/></svg>
                      </template>
                    </el-input>
                  </el-form-item>
                </div>


                <el-form-item>
                  <el-button
                    @click="signup"
                    type="primary"
                    class="button button--bottom button--center"
                    :disabled="!validSignup"
                    :loading="creatingAccount">
                    Sign Up
                  </el-button>
                </el-form-item>
              </el-form>

              <p class="margin--small--laptop  p--terms">
                By creating an account you agree to the
                <a class="term_link" href="https://www.zinggrid.com/legal">Terms of Service</a>
                and 
                <a class="term_link" href="https://www.zinggrid.com/privacy">Privacy Policy</a>
              </p>

            </div>
          </template>
        </entry-container>

        <!-- Signup text -->
        <div class="signup__container">
          <p class="margin--small--laptop">
            Already have an account?
            <span @click="passQueryParam(true)">
              <span class="signup__link">Log In.</span>
            </span>
          </p>
        </div>

      </div>
    </section>
  </secondary-layout>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import EntryContainer from './components/EntryContainer.vue';
  import InputEmail from './components/InputEmail.vue';
  import GoogleEntry from './components/GoogleEntry.vue';
  import SecondaryLayout from '../layouts/Secondary.vue';
  import entryMainComposable from '../../mixins/entryMain';
 
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const { planId, planInterval, regenerateSession, passQueryParam, redirectAfterLogin }
    = entryMainComposable({$route, $router, $store});

  const props = defineProps({
    isAuthing: Boolean,
  });

  const emit = defineEmits(['keyup', 'submit']);

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const creatingAccount = ref(false);
  const form = ref({
    email: '',
    password: '',
    confirm: '',
  });
  const passwordError = ref(false);
  const passwordConfirmError = ref(false);
  // display tooltip left on desktop and top on mobile
  const validationPlacement = ref(window.innerWidth < 1024 ? 'top-start' : 'left-start');
  const validated = ref(false);
  const signupForm = ref(null);
  const $el = ref(null);
  const newPassword = ref(null);

  const atLeast8Chars = computed(() => {
    return form.value.password && form.value.password.length >= 8;
  });
  const atLeast1Upper = computed(() => {
    return /[A-Z]/.test(form.value.password);
  });
  const atLeast1Lower = computed(() => {
    return /[a-z]/.test(form.value.password);
  });
  const atLeast1Number = computed(() => {
    return /[0-9]/.test(form.value.password);
  });
  const passwordDontMatch = computed(() => {
    return form.value.password !== form.value.confirm;
  });
  const validPassword = computed(() => {
    return atLeast8Chars.value && atLeast1Upper.value && atLeast1Lower.value && atLeast1Number.value;
  });
  const notEmail = computed(() => {
    let password = form.value.password;
    let email = form.value.email;
    return password !== email && password.toLowerCase() !== email.toLowerCase();
  });
  const validSignup = computed(() => {
    return validated.value;
  });

  const validation = computed(() => {
    return {
      atLeast8Chars: atLeast8Chars.value,
      atLeast1Upper: atLeast1Upper.value,
      atLeast1Lower: atLeast1Lower.value,
      atLeast1Number: atLeast1Number.value,
      notEmail: notEmail.value,
    }
  });

  onMounted(() => {
    redirectAfterLogin();
  });
  
  function updateEmail(value) {
    if (typeof value === 'string') form.value.email = value;
  };

  /**
   * @description Signs up user who does this the normal (non-Google-Auth) process. Before account creation,
   * validates email and password fields.
   * @param {Object} e - native event object
   */
  function signup(e) {
    // Update button state
    creatingAccount.value = true;

    // stop form default and perform our own validation
    e.preventDefault();

    // Check if disabled
    if (!validSignup.value) {
      $message({
        duration: 0,
        message: 'Please fill in all fields.',
        showClose: true,
        type: 'error',
      });
      creatingAccount.value = false;
      return;
    }

    // Validate array from form
    let hasError = $el.value.querySelectorAll('.el-form-item.is-error');

    // invalid form field
    if (hasError.length > 0) {
      $message({
        duration: 0,
        message: 'Please fill in all fields correctly.',
        showClose: true,
        type: 'error',
      });
      creatingAccount.value = false;
      // stop submission
      return;
    };

    // invalid password
    if (!validPassword.value) {
      passwordError.value = true;
      $message({
        duration: 0,
        message: 'Invalid Password',
        showClose: true,
        type: 'error',
      });
      creatingAccount.value = false;
      // stop submission
      return;
    }

    // passwords don't match
    if (passwordDontMatch.value) {
      passwordConfirmError.value = true;
      $message({
        duration: 0,
        message: 'Passwords do not match!',
        showClose: true,
        type: 'error',
      });
      creatingAccount.value = false;
      // stop submission
      return;
    };

    // reset error states if we reached this far
    passwordError.value = false;
    passwordConfirmError.value = false;

    // Toggle localStorage 'auth' state
    $store.state.auth.setAuthIsAuthenticatingLocalStorage();
    $store.state.auth.setAuthIsNewSignupLocalStorage();
    
    // Signup
    $store.dispatch('auth/signup', {
      email: form.value.email,
      password: form.value.password,
    });
  };
  
  function update(type, val) {
    form.value[type] = val;
  };

  function validateConfirm(rule, value, callback) {
    if (passwordDontMatch.value) {
      callback(new Error('Passwords must match'));
    } else {
      callback();
    }
  };

  function validateLength(rule, value, callback) {
    if (atLeast8Chars.value) {
      callback();
    } else {
      callback(new Error('Password must be a minimum of 8 characters'));
    }
  };

  function validateUpper(rule, value, callback) {
    if (atLeast1Upper.value) {
      callback();
    } else {
      callback(new Error('Password must contain at least 1 upper case letter'));
    }
  };

  function validateLower(rule, value, callback) {
    if (atLeast1Lower.value) {
      callback();
    } else {
      callback(new Error('Password must contain at least 1 lower case letter'));
    }
  };

  function validateNumber(rule, value, callback) {
    if (atLeast1Number.value) {
      callback();
    } else {
      callback(new Error('Password must contain at least 1 number'));
    }
  };

  function validateNotEmail(rule, value, callback) {
    if (notEmail.value) {
      callback();
    } else {
      callback(new Error('Password must not be the same as email'));
    }
  };

  function validateForm(type) {
    if (type && (form.value.email.length === 0 || form.value.password.length === 0 || form.value.confirm.length === 0)) {
      signupForm.value.validateField(type);
      validated.value = false;
    } else {
      signupForm.value.validate((valid) => {
        validated.value = valid;
      });
    }
  };

  /**
   * @description Checks if password entered is valid and return which password validity check passes
   * @param {String} target - which type of password validity is checked
   */
  function validClass(target) {
    if (form.value.password && form.value.password.length === 0) return '';
    // else
    return (validation.value[target]) ? 'password--valid' : 'password--invalid';
  };

  /**
   * @description Returns icon representing if password entered passes validity check
   * @param {String} target - which type of password validity is checked
   */
  function validIcon(target) {
    if (newPassword.value && newPassword.value.length === 0) {
      return ['fas', 'check'];
    } else {
      return ['fas', (validation.value[target])? 'check' : 'times'];
    }
  };
</script>

<style>
  /* element ui imported globally and needs access to global CSS */
  #app-shell > section > div.background--grid_wrap > div > div.content_wrapper > section > div > div:nth-child(1) > div:nth-child(3) > section > div > form > div.el-form-item.is-required > div > div
  div[error] input { border:1px solid red; }

  @media (max-width: 800px) {
    .signup__container { margin-top: 0; width: var(--container-width--slim) !important; }
  }
</style>

<style lang="css" scoped>
  [entryContainer] .button--bottom { background: var(--color-tertiary-1); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem; }
  [entryContainer] .button--bottom.button--center { margin: 0 auto; }
  [entryContainer] .button--bottom:hover { background: var(--color-tertiary-4); }
  [entryContainer] [disabled].button--bottom { background: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed; }

  .content { text-align: center; }

  .el-form svg { position: relative; top: 2px; width: 1rem; }

  .el-form-item, [entryContainer="entry__actions"] .el-input:last-of-type { margin-bottom: 1rem; }
  .el-input-group__prepend { background: var(--color-greyscale-9); }

  [entryContainer="entry__actions"] div div:last-of-type .el-input { margin-bottom: 0.5rem; }

  .text--gray { color: var(--color-greyscale-8); }

  .link { font-size: 0.85rem; }
  .link:hover { color: var(--color-greyscale-7); }

  .text--gray { text-decoration: none; }
  .underline { text-decoration: underline; }
  /* Mobile Overwrites */

  @media screen and (max-height: 900px) {
    .signup__container { margin: 1rem auto; }
  }
</style>