<template>
  <div class="zingnetCard--column">
    <h3 class="zingnetCard--header">{{title}}</h3>
    
    <div class="progressBar" ref="$progressBar">
      <div v-if="progress" class="progressBar--completed" :style="`width: ${progress}%`"></div>
      <div v-if="progress && progress < 100" class="progressBar--divider"></div>
    </div>
    
    <div v-if="progress" class="progress">
      <span>
        <span class="progressText">{{progress}}%</span><span>completed</span>
      </span>
      <span>
        <span class="progressText progressText__left">{{left}}%</span><span>left</span>
      </span>
    </div>
    <p v-else-if="fail" class="progressText progressText__fail">Deployment failed</p>
    <p v-else-if="time == 0">Deployment is in queue</p>
    <p v-else>There are no deployments in progress right now for the {{title.split(' ').shift()}} gallery</p>
  </div>
</template>

<script setup>
  import { computed, defineProps, defineExpose, onUnmounted, ref } from 'vue';
  import { Octokit } from '@octokit/core';
  const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
  import dateComposable from '../../../../mixins/date';

  process.env.authTOKEN;
  const { dateDiff } = dateComposable();

  const props = defineProps({
    title: String,
    sitename: String,
    branch: String,
  });

  const $progressBar = ref(null);
  const estimate = ref(100);
  const fail = ref(false);
  const intervalStatus = ref(null);
  const intervalTime = ref(null);
  const lastRun = ref(null);
  const progress = ref(0);
  const increment = ref(1);
  const start = ref(false);
  const time = ref(null);
  const workflowIds = ref({
    zingchart: {
      dev: 218016,
      master: 550840,
    },
    zinggrid: {
      dev: 182535,
      master: 184183,
    },
  });

  const left = computed(() => {
    return 100 - progress.value;
  });

  onUnmounted(() => {
    if (intervalStatus.value) clearInterval(intervalStatus.value);
    if (intervalTime.value) clearInterval(intervalTime.value);
  });

  function trackProgress() {
    // Get estimation of how long deployment takes
    _getEstimate();

    // Setup progress bar
    _setupBar();

    // Check progress (set to 100% if complete, stuck at 95% if in progress, or fails)
    _checkProgress(true);
  };
  defineExpose({trackProgress});
  
  function _calculateProgress(increment) {
    time.value += increment;

    progress.value = Math.floor(time.value / estimate.value * 100);
  };

  function _checkProgress(init) {
    intervalStatus.value = setInterval(() => {
      octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${workflowIds.value[props.sitename][props.branch]}/runs?per_page=1`, {
        owner: "zingsoftinc",
        repo: `${props.sitename}-com`,
      })
        .then((result) => {
          // Check if queued, in_progress, completed (failure, success), 
          let {id, status, conclusion} = result.data.workflow_runs[0];
          if (id !== lastRun.value) {
            if (status === 'in_progress' && !start.value) {
              start.value = true;
              // Start progress bar
              _progressTime();
            } else if (status === 'completed') {
              _completeProgress(conclusion === 'success');
            }
          };
        });
    }, 5000);
  };

  function _completeProgress(succeed) {
    // Stop intervals
    if (intervalStatus.value) clearInterval(intervalStatus.value);
    if (intervalTime.value) clearInterval(intervalTime.value);

    start.value = false;

    if (succeed) {
      // Complete progress bar
      let diff = estimate.value - time.value;
      _calculateProgress(diff);     
    } else {
      // Show fail note
      fail.value = true;
    }
  };

  function _getEstimate() {
    octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${workflowIds.value[props.sitename][props.branch]}/runs?per_page=10`, {
      owner: "zingsoftinc",
      repo: `${props.sitename}-com`,
    })
      .then((result) => {
        let runs = result.data.workflow_runs

        // Get last run to not do progress tracking on wrong run
        for (let i = 0; i < runs.length; i++) {
          if (runs[i].status === 'completed') {
            lastRun.value = runs[i].id
            break;
          }
        };

        let size = 0;
        let completionTimes = runs.reduce((acc, run) => {
          if (run.status === 'completed' && run.conclusion === 'success') {
            size++;
            return acc + dateDiff(run.created_at, run.updated_at, null, 'seconds');
          } else {
            return acc;
          }
        }, 0);
        estimate.value = Math.floor(completionTimes / size);
      });
  };

  function _progressTime() {
    intervalTime.value = setInterval(() => {
      _calculateProgress(increment.value);
      
      // Stall in case progress not complete at 95%
      if (progress.value > 95) {
        _completeProgress();
        clearInterval(intervalTime.value);
      }
    }, 1000);
  };

  function _setupBar() {
    fail.value = false;
    time.value = 0;

    $progressBar.value.classList.add('progressBar--left');
  };
</script>

<style scoped>
  span, p {
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
  }
  p {
    text-align: center;
  }
  .progress {
    display: flex;
    justify-content: space-between;
  }
  .progressText {
    color: var(--color-secondary-blue);
    font-size: 2.5rem;
    font-weight: 200;
    margin: 0 0.3125rem 1rem 0;
  }
  .progressText__fail {
    color: var(--color-primary-danger);
  }
  .progressText__left {
    color: var(--color-secondary-blue-active);
  }
  .progressBar {
    background-color: var(--color-tertiary-gray);
    border-radius: var(--zingnet-border-radius);
    display: flex;
    height: 1.5rem;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
  }
  .progressBar--completed {
    background-color: var(--color-secondary-blue);
    height: 100%;
    transition: all 0.2s;
  }
  .progressBar--divider {
    background-color: #fff;
    height: 100%;
    width: 0.125rem;
  }
  .progressBar--left {
    background-color: var(--color-secondary-blue-active);
  }
</style>