<template>
  <div v-show="authenticated" ref="$el" :class="`filter_wrapper${prefix ? ` filter_prefix--${prefix}`: ''}`" :scrollable="scrollable">
    <el-select
      v-model="selected"
      :allow-create="allowCreate"
      :clearable="clearable"
      :collapse-tags="collapsible"
      :filterable="filterable"
      :multiple="multiple"
      :placeholder="placeholder">
      <el-option
        v-for="filter in options"
        :key="filter.id"
        :label="filter.name"
        :value="filter.value || filter.name">
        <div class="filter__option">
          <div class="filter__name">{{filter.name}}</div>
          <div v-if="filter.count" class="filter__count">{{filter.count}}</div>
        </div>
      </el-option>
      <template v-if="prefix" v-slot:prefix>{{prefixText}}</template>
    </el-select>
    <svg-icon icon="close--circle" height="1rem" width="1rem"></svg-icon>
    <svg-icon class="el-input-icon" icon="angle"></svg-icon>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineExpose, defineProps, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from './SvgIcon.vue';
  
  const emit = defineEmits(['authenticated', 'filterBy']);

  const props = defineProps({
    allowCreate: Boolean,
    clearable: Boolean,
    collapsible: Boolean,
    defaultOptions: Array,
    defaultValue: String,
    filterable: Boolean,
    getCount: Number,
    multiple: Boolean,
    options: Array,
    placeholder: String,
    prefix: String,
    scrollable: Boolean,
  });

  const $store = useStore();
  const $el = ref(null);
  const authSetup = ref(false);
  const clearableIcon = ref(null);
  const prefixText = ref(null);
  const selected = ref(null);

  const authenticated = computed(() => {
    if (!authSetup.value) {
      authSetup.value = true;
      const auth = $store.state.user['user_id'];
      if (auth) emit('authenticated');
      return $store.state.user['user_id'];
    }
    return true;
  });

  watch(selected, (value) => {
    emit('filterBy', value);
    // Display 'clearable' icon if value is non-null
    if (value) clearableIcon.value.classList.add('active');
    else clearableIcon.value.classList.remove('active');
  });

  onMounted(() => {
    // Save reference
    clearableIcon.value = $el.value.querySelector('[icon="close--circle"]');

    setPrefixText();
    
    setDefaultValue();
  });

  function clearFilter() {
    selected.value = null;
  };

  function setDefaultValue() {
    if (props.defaultValue) selected.value = props.defaultValue;
  };

  function setPrefixText() {
    if (props.prefix) {
      switch(props.prefix) {
        case 'metadata': prefixText.value = 'Filter By Metadata: '; break;
        case 'metadataValue': prefixText.value = 'Filter By Metadata Value: '; break;
      }
    }
  };
  
  defineExpose({clearFilter});
</script>

<style src="../assets/styles/filterSelector.css" scoped></style>

<style>
  .filter_wrapper [icon="close--circle"] svg {
    position: relative;
    top: -6px;
  }

  [class*="filter_prefix"] .el-input__prefix {
    color: #C0C4CC;
    font-size: 0.75rem;
    left: 15px;
    line-height: 30px;
  }

  .filter_wrapper .el-input input {
    height: 30px !important;
  }

  /* metadata */
  .filter_prefix--metadata .el-input input {
    padding-left: 7.75rem;
  }

  /* metadata value */
  .filter_prefix--metadataValue .el-select__tags {
    margin-left: 9.5rem;
  }

  /* Scrollable filter */
  [scrollable] .el-select__tags {
    flex-wrap: nowrap;
    max-height: 29px;
    overflow: auto;
    width: calc(100% - 12rem) !important;
  }

  .angle--right {
    transform: rotate(-90deg);
  }
</style>
