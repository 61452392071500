<template>
  <section class="content--wide" group-none>
    <p>Create your own group or request an invitation to join an existing group.</p>
    <p v-show="groupCapped" class="note  red">You currently are a part of <b>{{myGroups.length}}</b> groups. Please delete or leave a group to create another.</p>
    <p v-show="!groupingCreate" class="note  red">You do not have permission to create groups!</p>

    <el-button @click="createVisible=true" :disabled="groupCapped || !groupingCreate" type="primary">Create</el-button>
    <el-button @click="requestVisible=true" type="primary">Request</el-button>

    <!-- Create Dialog -->
    <el-dialog group-none="createDialog" class="dialog--condense dialog--padded" title="Create a Group" v-model="createVisible">
      <el-form label-width="150px" :model="createForm" ref="createFormRef" :rules="rules">
        <el-form-item label="Group Name" prop="name">
          <el-input v-model="createForm.name"></el-input>
        </el-form-item>
        
        <el-form-item
          group-none="inputRow"
          v-for="(member, index) in createForm.members"
          :label="'Member ' + parseInt(index+1)"
          :key="member.key"
          :prop="'members.' + index + '.email'"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ]">
          <el-input type="email" v-model="member.email"></el-input><el-button @click.prevent="removeMember(member)">Remove</el-button>
        </el-form-item>

        <el-form-item button-align="right">
          <el-button @click="addMember">Add Member</el-button>
          <el-button @click="validateAndSubmit('createForm')" type="primary">Create</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Request Dialog -->
    <el-dialog group-none="requestDialog" class="dialog--condense dialog--padded" title="Request Invitation" v-model="requestVisible">
      <el-form label-width="150px" :model="requestForm" ref="requestFormRef" :rules="rules">
        <el-form-item label="Group Name" prop="name">
          <el-input type="name" v-model="requestForm.name"></el-input>
        </el-form-item>
        <el-form-item button-align="right">
          <el-button @click="validateAndSubmit('requestForm')" type="primary">Request</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>

<script setup>
  import { computed, getCurrentInstance, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { computedAsync } from '@vueuse/core';
  import axios from 'axios';
  import groupsComposable from '../../../mixins/groups.js';
  import permissionsComposable from '../../../mixins/permissions.js';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $router = useRouter();
  const $store = useStore();
  const createForm = ref({
    name: '',
    members: []
  });
  const createVisible = ref(false);
  const MAX_GROUPS = ref(10);
  const requestForm = ref({
    name: '',
  });
  const requestVisible = ref(false);
  const rules = ref({
    name: [
      { required: true, message: 'Please input group name', trigger: ['blur', 'change'] },
      { min: 3, max: 50, message: 'Length should be 3 to 50', trigger: ['blur', 'change'] },
    ],
  });
  const createFormRef = ref(null);
  const requestFormRef = ref(null);

  const { myGroups } = groupsComposable({$store});
  const { checkPermission } = permissionsComposable();

  const groupCapped = computed(() => {
    if (adminDashboardView.value) return false;
    return myGroups.value.length >= MAX_GROUPS.value;
  });

  const adminDashboardView = computedAsync(
    async () => {
      return await checkPermission('admin_dashboard_view', null, null, $store);
    }, null
  );

  const groupingCreate = computedAsync(
    async () => {
      return await checkPermission('grouping_create', null, null, $store);
    }, null
  );

  /**
   * @description Adds input field for Create Group's member invite 
   */
  function addMember() {
    createForm.value.members.push({
      key: Date.now(),
      value: '',
    });
  };

  /**
   * @description Removes input field for Create Group's member invite
   * @param { String } item - input field to remove
   */
  function removeMember(item) {
    var index = createForm.value.members.indexOf(item);
    if (index !== -1) {
      createForm.value.members.splice(index, 1);
    }
  };

  /**
   * @description Create group based on form input
   */
  function submitCreate() {
    if (groupingCreate.value && !groupCapped.value) {  // Permission check
      axios({
        url: '/api/grouping',
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${$store.state.auth.idToken}`,
        },
        data: {
          'name': createForm.value.name
        }
      })
      .then((response) => {
        createVisible.value = false;
        window.location = `/?group=${response.data.groupingId}`;
        $router.replace('/');
      }).catch((err) => {
        let errMessage = 'Error creating group'
        if (err.error) errMessage = err.error;
        $message({
          message: errMessage,
          type: 'error',
        });
      });
    } else {
      $message({
        message: 'You do not have permission to create groups',
        type: 'error',
      });
    }
  };

  /**
   * @description Sends invite request
   */
  function submitRequest() {
    // TODO. Sends name and current users email
  };

  /**
   * @description Validates form, then sends for submission if valid
   * @param { String } formName - ref value of form
   */
  function validateAndSubmit(formName) {
    let form = formName === 'createForm' ? createFormRef.value : requestFormRef.value;
    form.validate((valid) => {
      if (valid) {
        if (formName === 'createForm') submitCreate();
        else submitRequest();
      } else {
        return false;
      }
    });
  };
</script>

<style>
  [group-none="buttonContainer"] {
    display: flex;
  }
  [group-none] .el-form .el-button {
    margin-left: 1rem;
  }
  [group-none="inputRow"] .el-form-item__content {
    display: flex;
  }
</style>