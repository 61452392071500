/**
 * @description Adds a custom search bar outside of ZingGrid.
 * Requires adding `[search]` attribute to targeted ZingGrid.
 */
import { onUnmounted, ref } from 'vue';

export default function searchComposable({zgRef}) {
  const search = ref('');

  onUnmounted(() => {
    if (zgRef && zgRef.value) zgRef.value = null;
  });

  function searchGrid() {
    zgRef.value.searchGrid(search.value);
  };

  return {
    search,
    zgRef,
    searchGrid
  };
};