<template>
  <div class="collapse--container">
    <div class="collapse--header">
      <h3>{{title}}</h3>
      <el-button @click="hide" v-if="buttonView">Hide {{buttonText || 'Options'}}</el-button>
      <el-button @click="view" v-else>View {{buttonText || 'Options'}}</el-button>
    </div>
    
    <div :class="`collapse--body${toCollapse ? ' collapse' : ''}`">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineProps, ref } from 'vue';

  const props = defineProps({
    title: String,
    open: Boolean,
    buttonText: String,
  });

  const button = ref(null);
  const collapse = ref(null);

  const buttonView = computed(() => {
    if (!collapse.value && collapse.value !== false) {
      return props.open;
    } else {
      return button.value;
    };
  });

  const toCollapse = computed(() => {
    if (!collapse.value && collapse.value !== false) {
      return !props.open;
    } else {
      return collapse.value;
    };
  });

  function hide() {
    collapse.value = true;
    button.value = false;
  };
  
  function view() {
    collapse.value = false;
    button.value = true;
  };
</script>

<style>
  .collapse--container {
    border: var(--border);
    border-radius: 10px;
    overflow: hidden;
  }
  .collapse--container + .collapse--container {
    margin-top: 1rem;
  }
  .collapse--header,
  .collapse--body > div {
    padding: 0.875rem 1.125rem;
  }
  .collapse--header {
    align-items: center;
    background: var(--zingnet-color-gray);
    display: flex;
    justify-content: space-between;
  }
  .collapse--header .el-button {
    margin: 0;
  }
  .collapse--body {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  }
  .collapse--body.collapse {
    border-top: 0;
    max-height: 0;
  }
  .collapse--body > div {
    border-top: var(--border);
  }
  .collapse--container h3 {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0;
  }
</style>