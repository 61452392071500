import { ref } from 'vue';
const moment = require('moment');

export default function dateComposable() {
  const SEC_IN_DAY = ref(86400);

  /**
   * @description Convert Date object to timestamp
   * @param {String} date 
   */
  function convertToTimestamp(date) {
    return Math.floor(date/1000);
  };

  /**
   * Get difference between two dates
   * @param {String} dateStart - date to diff
   * @param {String} dateEnd - date to diff
   * @param {String} inputFormat - date format of `date1` and `date2`
   * @param {String} unit - different
   */
  function dateDiff(dateStart, dateEnd, inputFormat, unit) {
    let start = moment(dateStart, inputFormat);
    let end = moment(dateEnd, inputFormat);
    return end.diff(start, unit);
  };

  function dateFromNow(date, inputFormat, removeSuffix) {
    return moment(date, inputFormat).fromNow(removeSuffix);
  };

  /**
   * @description Returns list of dates starting at `start`
   * specifed format type
   * @param {String} start - start date 
   * @param {String} step - step increment on date
   * @param {String} stepNum - number of steps to apply per increment
   * @param {String} add - number of date elements to add to array
   * @param {String} inputFormat - date format of start date input
   * @param {String} outputFormat - date format for outputted dates
   */
  function dateList({end, step, stepNum, add, inputFormat, outputFormat}) {
    let dateArr = [moment(end, inputFormat).format(outputFormat)];
    
    let cur = end;
    for (let i = 0; i < add; i++) {
      cur = moment(cur, inputFormat).subtract(stepNum, step);
      dateArr.unshift(cur.format(outputFormat));
    };
    return dateArr;
  };

  /**
   * @description Returns range of dates (start, end, prevStart, prevEnd) in
   * specied format type
   * @param {String} date - curStart
   * @param {String} type - format type
   * @param {String | Object} range - day, week, year, {start, end}
   * @param {Number} rangeNum - number of range
   */
  function dateRange(date, outputFormat='YYYY-MM-DD', range, rangeNum) {
    // Custom range
    let rangeType = typeof range === 'string' ? range : 'days';
    if (!rangeNum) {
      let end = moment(range.end);
      let start = moment(range.start);
      rangeNum = end.diff(start, 'days');
    };

    // End
    let end = date ? moment(date, 'YYYY-MM-DD') : moment(new Date());

    // Start
    let start = moment(end).subtract(rangeNum, rangeType);

    // prevEnd
    let prevEnd = moment(start).subtract(1, 'days');

    // prevStart
    let prevStart = moment(prevEnd).subtract(rangeNum, rangeType);

    return {
      end: end.format(outputFormat),
      start: start.format(outputFormat),
      prevEnd: prevEnd.format(outputFormat),
      prevStart: prevStart.format(outputFormat)
    };
  };

  function formatDate(date, inputFormat, outputFormat) {
    return date ? moment(date, inputFormat).format(outputFormat) : '';
  };

  return {
    SEC_IN_DAY, 
    convertToTimestamp,
    dateDiff,
    dateFromNow,
    dateList,
    dateRange,
    formatDate
  };
};
