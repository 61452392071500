<template>
  <el-dialog
    my-dialog
    :show-close="showClose || null"
    :type="type"
    v-model="isVisible">
    <div v-if="type=='danger'" class="dialog__icon"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="2x"/></div>
    <div v-if="type=='success'" class="dialog__icon"><font-awesome-icon :icon="['fas', 'check-circle']" size="2x"/></div>

    <h2>{{title}}</h2>

    <slot name="content"></slot>

    <section class="dialog__options">
      <slot name="options"></slot>
    </section>
  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps } from 'vue';

  const emit = defineEmits(['close']);

  const props = defineProps({
    showClose: { type: Boolean, default: true, },
    title: { type: String, default: null, },
    type: { type: String, default: null },
    visible: { type: Boolean, default: null },
  });

  const isVisible = computed({
    get() { return props.visible; },
    set() {
      emit('close');
    },
  });
</script>

<style scoped>
  .dialog__icon svg { width: 1.25rem; }
  .dialog__options { align-items: center; display: flex; flex: 1; justify-content: center; margin-top: 1.5rem; }

  h2 { font-size: 1.15rem; font-weight: 500; margin: 0; text-align: center; }

  [type="danger"] .dialog__icon, [type="danger"] h2 { color: #f56c6c; }
  [type="success"] .dialog__icon, [type="success"] h2 { color: #07a783; }
</style>

<style>
  [my-dialog] .el-button { font-size: 0.8rem; padding: 9px 20px; }
  [my-dialog] .el-button+.el-button { margin-left: 15px; }
  [my-dialog] .el-dialog { padding-right: 0 !important; max-width: 27rem; width: 90%; }
  [my-dialog] .el-dialog__body { padding: 2rem; }
  [my-dialog] .el-dialog__headerbtn { right: 15px; top: 15px; }
  [my-dialog] .el-dialog__header { padding: 0 !important; }
  [my-dialog] p { text-align: center; word-break: break-word; }
</style>
