<template>
  <zingnet-card>
    <template v-slot:header>
      <span>Deployment</span>
    </template>
    <template v-slot:body>
      <div>
        <div class="zingnetCard--row flex">
          <deploy-item
            @deploy="startDeployment"
            title="ZingChart Dev Gallery"
            sitename="zingchart"
            branch="dev">
          </deploy-item>

          <deploy-item
            @deploy="startDeployment"
            title="ZingChart Main Gallery"
            sitename="zingchart"
            branch="master">
          </deploy-item>

          <deploy-progress
            title="ZingChart Deployment Progress"
            ref="zingchartProgress"
            sitename="zingchart"
            :branch="zingchartBranch">
          </deploy-progress>
        </div>

        <div class="zingnetCard--row flex">
          <deploy-item
            @deploy="startDeployment"
            title="ZingGrid Dev Gallery"
            sitename="zinggrid"
            branch="dev">
          </deploy-item>

          <deploy-item
            @deploy="startDeployment"
            title="ZingGrid Main Gallery"
            sitename="zinggrid"
            branch="master">
          </deploy-item>

          <deploy-progress
            title="ZingGrid Deployment Progress"
            ref="zinggridProgress"
            sitename="zinggrid"
            :branch="zinggridBranch">
          </deploy-progress>
        </div>

        <portal-target name="deployment-error">
        </portal-target>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { nextTick, ref } from 'vue';
  import { Octokit } from '@octokit/core';
  const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
  import DeployItem from './DeployItem.vue';
  import DeployProgress from './DeployProgress.vue';
  import ZingnetCard from '../ZingnetCard.vue';

  process.env.authTOKEN

  const zingchartBranch = ref(null);
  const zinggridBranch = ref(null);
  const zingchartProgress = ref(null);
  const zinggridProgress = ref(null);

  function startDeployment(lib, branch) {
    // Set `branch` attribute and track progress
    if (lib === 'zingchart') {
      zingchartBranch.value = branch;
      nextTick(() => {
        zingchartProgress.value.trackProgress();
      });
    } else {
      zinggridBranch.value = branch;
      nextTick(() => {
        zinggridProgress.value.trackProgress();
      });
    };
  };
</script>