<template>
  <zingnet-card>
    <template v-slot:header>
      User Summary
      <date-range @updateDateRange="updateDateRange"></date-range>
    </template>
    
    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">New users</h3>
            <score-card-group v-if="newUsers">
              <template v-slot:card_one>
                <score-card :value="newUsers.cur" :title="`in last ${daysDiff} days`"></score-card>
              </template>
              <template v-slot:card_two>
                <score-card :value="newUsers.prev" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
              </template>
            </score-card-group>

            <div class="zingnetCard--row no-border">
              <div id="chart-newUsers"></div>
            </div>
          </section>

          <section class="zingnetCard--column">
            <div id='chart-userSubscriptions'></div>
          </section>

          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">Total users</h3>
            <score-card-group v-if="totalUsers">
              <template v-slot:card_one>
                <score-card :value="totalUsers.cur" :title="`in last ${daysDiff} days`"></score-card>
              </template>
              <template v-slot:card_two>
                <score-card :value="totalUsers.prev" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
              </template>
            </score-card-group>

            <div class="zingnetCard--row no-border">
              <div id="chart-totalUsers"></div>
            </div>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { nextTick, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import DateRange from '../DateRange.vue';
  import ScoreCard from '../ScoreCard.vue';
  import ScoreCardGroup from '../ScoreCardGroup.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import dateComposable from '../../../../mixins/date.js';
  import dateRangeComposable from '../../mixins/dateRange.js';

  const { dateDiff, dateList, dateRange } = dateComposable();
  const { start, end, prevStart, prevEnd, updateDateRange }
    = dateRangeComposable({ getReportData });

  const $store = useStore();
  const lineConfig = ref({
    type: 'line',
    plot: {
      animation: {
        effect: 'ANIMATION_EXPAND_LEFT',
        method: 'ANIMATION_LINEAR',
        sequence: 'ANIMATION_NO_SEQUENCE',
        speed: 1000,
      },
      lineWidth: '1.5px',
      marker: {
        visible: false,
      }
    },
    plotarea: {
      margin: '5px 0 25px 0',
    },
    scaleX: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '12px',
        rules: [{
          rule: '%v == 0',
          offsetX: 20,
        }, {
          rule: '%v == 7',
          offsetX: -20,
        }]
      },
      guide: {
        visible: false,
      },
      labels: [],
      lineColor: 'var(--zingnet-border-color)',
      showLabels: [],
      tick: {
        visible: false,
      },
    },
    scaleY: {
      guide: {
        visible: false,
      },
      visible: false,
    },
    series: [
      {
        values: [],
        lineColor: 'var(--color-secondary-blue)',
      },
      {
        values: [],
        lineColor: 'var(--color-primary-gray-visited)',
      }
    ]
  });
  const ringConfig = ref({
    type: 'ring',
    labels: [{
      text: 'User Subscriptions',
      fontColor: 'var(--color-tertiary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 500,
      x: 0,
      y: '0'
    }, {
      text: '90.42% subscribed',
      fontColor: 'var(--color-secondary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      x: 0,
      y: '32px'
    }, {
      text: '9.58% unsubscribed',
      fontColor: 'var(--color-secondary-blue-active)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      x: 0,
      y: '50px'
    }, {
      text: '_________________',
      fontColor: 'var(--zingnet-border-color)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 500,
      x: 0,
      y: '60px'
    }, {
      text: 'in last 7 days',
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '82px'
    }, {
      text: 'vs',
      fontColor: 'var(--color-tertiary-blue-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '115px'
    }, {
      text: '89.42% subscribed',
      fontColor: 'var(--color-primary-gray-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      x: 0,
      y: '150px'
    }, {
      text: '8.58% unsubscribed',
      fontColor: 'var(--color-primary-gray-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      x: 0,
      y: '168px'
    }, {
      text: '_________________',
      fontColor: 'var(--zingnet-border-color)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      x: 0,
      y: '177px'
    }, {
      text: 'in previous 7 days',
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '200px'
    }],
    plot: {
      offsetX: '70px',
      refAngle: 200,
      slice: '88%',
      valueBox: [{
        text: '90.42%',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 36,
        fontWeight: '100',
        offsetY: '-25px',
      }, {
        text: 'subscription rate',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 13,
        fontWeight: '200',
        offsetY: '5px',
      }, {
        text: '    _____________',
        placement: 'center',
        fontColor: 'var(--zingnet-border-color)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '15px',
        fontWeight: 600, 
        offsetY: '15px',
      }, {
        text: 'in last 7 days',
        placement: 'center',
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 13,
        fontWeight: '200',
        offsetY: '33px',
      }],
    },
    plotarea: {
      margin: '25px 0 0',
    },
    series: [
      {
        values: [9.58],
        backgroundColor: 'var(--color-secondary-blue-active)',
        text: 'subscribed',
      },
      {
        values: [90.42],
        backgroundColor: 'var(--color-secondary-blue)',
        text: 'unsubscribed',
      },
    ]
  });
  const daysDiff = ref(7);
  const labels = ref([]);
  const loading = ref(false);
  const newUsers = ref({
    cur: 0,
    prev: 0,
  });
  const showLabels = ref([]);
  const totalUsers = ref({
    cur: 0,
    prev: 0,
  });

  function calcDiff() {
    if (start.value && end.value) {
      daysDiff.value = parseInt(dateDiff(start.value, end.value, 'YYYY-MM-DD', 'days'));
    };
  };

  function generateLabel() {
    labels.value = dateList({
      end: end.value,
      step: 'days',
      stepNum: 1, 
      add: daysDiff.value, 
      inputFormat: 'YYYY-MM-DD', 
      outputFormat: 'MMM D'
    });

    showLabels.value = [labels.value[0], labels.value[labels.value.length-1]];
  };

  function instantiatePieChart(id, data, height, width) {
    zingchart.render({id, data, height, width});
  };

  function instantiateLineChart(id, series1, series2, height, width) {
    let data = Object.assign({}, lineConfig.value);

    data.scaleX.item.rules[1].rule = `%v == ${daysDiff.value}`;
    data.scaleX.labels = labels.value;
    data.scaleX.showLabels = showLabels.value;
    data.series[0].values = series1;
    data.series[1].values = series2;

    zingchart.render({id, data, height, width});

    loading.value = false;
  };

  function getReportData() {
    calcDiff();
    generateLabel();

    loading.value = true;
    _getSummaryNew();
    _getSummaryTotal();
  };

  function _formatSeries(data) {
    return Object.values(data);
  };

  function _getSummaryNew() {
    axios({
      url: `/api/user/summary/new?startDate=${start.value}&endDate=${end.value}&prevStartDate=${prevStart.value}&prevEndDate=${prevEnd.value}`,
      json: true,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
    }).then((response) => {
      _setSummaryData(response.data, 'newUsers');
    }).catch((error) => {
      console.error(error);
    });
  };

  function _getSummaryTotal() {
    axios({
      url: `/api/user/count?fields=quickbooks,email`,
      json: true,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
    }).then((response) => {
      // TODO placeholder data until add created time in from auth 0 and start recording
      let total = response.data.email;
      let data = {
        cur: {
          data: new Array(daysDiff.value+1).fill(total),
          total,
        },
        prev: {
          data: new Array(daysDiff.value+1).fill(total),
          total,
        },
      };
      _setSummaryData(data, 'totalUsers');

      _setTotalRingChart(response.data.quickbooks, response.data.email);
    }).catch((error) => {
      console.error(error);
    });
  };

  function _setSummaryData(data, type) {
    // Set scorecard data
    if (type === 'newUsers') {
      newUsers.value.cur = data.cur.total;
      newUsers.value.prev = data.prev.total;
    } else if (type === 'totalUsers') {
      totalUsers.value.cur = data.cur.total;
      totalUsers.value.prev = data.prev.total;
    };

    // Set chart data
    let series1 = _formatSeries(data.cur.data);
    let series2 = _formatSeries(data.prev.data)
    nextTick(() => {
      instantiateLineChart(`chart-${type}`, series1, series2, '80px', '316px');
    });
  };

  function _setTotalRingChart(subscribed, total) {
    let subscribedPercentage = (subscribed/total).toFixed(2);
    let text_numSubscribed = `${subscribed} subscribed`;
    let text_numUnsubscribed = `${total - subscribed} unsubscribed`;
    let text_timePeriod = `in last ${daysDiff.value} days`;

    // Set labels
    let labels = ringConfig.value.labels;
    labels[1].text = text_numSubscribed;
    labels[2].text = text_numUnsubscribed;
    labels[4].text = text_timePeriod;

    labels[6].text = text_numSubscribed;
    labels[7].text = text_numUnsubscribed;
    labels[9].text = text_timePeriod;

    // Set valueboxes
    let valueboxes = ringConfig.value.plot.valueBox;
    valueboxes[0].text = `${subscribedPercentage}%`;
    valueboxes[3].text = text_timePeriod;

    // Set series
    let series = ringConfig.value.series;
    series[0].values = [subscribed];
    series[1].values = [total-subscribed];

    instantiatePieChart('chart-userSubscriptions', ringConfig.value, '220px', '100%');
  };
</script>