<template>
  <div class="form__item">
    <label class="form__label" id="phoneNumber">Phone number</label>
    <el-input
      @input="format"
      v-model="phoneNumber_"
      class="form__field"
      for="phoneNumber"
      placeholder="(xxx) xxx - xxxx"
      type="tel"></el-input>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';

  const emit = defineEmits(['change']);

  const props = defineProps({
    value: {
      type: String
    }
  });
  
  const phoneNumber_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const phoneNumber = computed(() => {
    return props.value;
  });

  watch(phoneNumber, (value) => {
    phoneNumber_.value = value;
    dirty.value = false;
  });
  watch(phoneNumber_, () => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value)  {
    // Handles changes by the store mutating the event
      dirty.value = true;
      emit('change', {phone_number: phoneNumber_.value});
    } else if (dirty.value) {
      // If the field was modified by user entry
      emit('change', {phone_number: phoneNumber_.value});
    } 
  });

  onMounted(() => {
    phoneNumber_.value = props.value;
  });

  function format(value) {
    if (value && value.length > 0) {
      let phoneNumber = value.replace(/\s|\(|\)|\-/g, '');
      let formatted = phoneNumber.replace(/(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3, p4) => {
        let output = '';
        if (p1) output = `(${p1}`;
        if (p2) output += `${p2})`;
        if (p3) output += ` ${p3}`;
        if (p4) output += ` - ${p4}`;
        return output;
      });
      phoneNumber_.value = formatted;
    }
  };
</script>
