<template>
  <div class="form__item">
    <label class="form__label" id="company">Company</label>
    <el-input v-model="company_" class="form__field" placeholder="Your Company" for="company" maxlength="75"></el-input>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';

  const emit = defineEmits(['change']);

  const props = defineProps({
    value: {
      type: String
    }
  });
  
  const company_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const company = computed(() => {
    return props.value;
  });

  watch(company, (value) => {
    company_.value = value;
    dirty.value = false;
  });
  watch(company_, () => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value)  {
    // Handles changes by the store mutating the event
      dirty.value = true;
      emit('change', {company: company_.value});
    } else if (dirty.value) {
      // If the field was modified by user entry
      emit('change', {company: company_.value});
    } 
  });
  
  onMounted(() => {
    company_.value = props.value;
  });
</script>
