<template>
  <div :class="`scorecard ${secondary ? 'secondary' : 'primary'}`">
    <p class="value">
      <svg-icon v-if="icon" :icon="icon" :height="iconSize" :width="iconSize"></svg-icon>
      {{currency ? '$' : ''}}{{addCommas(value)}}
    </p>
    <hr>
    <p class="title">{{title}}</p>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';
  import SvgIcon from '../../../components/SvgIcon.vue';

  const props = defineProps({
    icon: String,
    iconSize: String,
    currency: Boolean,
    value: [Number, String],
    title: String,
    secondary: Boolean,
  });

  function addCommas(val) {
    return typeof val === 'number'
      ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : val;
  };
</script>

<style scoped>
  .scorecard {
    bottom: 0.625rem;
    margin-bottom: 1.25rem;
    position: relative;
    width: 100%;
  }

  .primary .value {
    color: var(--color-secondary-blue);
  }
  .secondary .value {
    color: var(--color-primary-gray-visited);
  }

  hr {
    border: 0;
    border-bottom: var(--zingnet-border);
    bottom: 0.3rem;
    margin: 0;
    position: relative;
    min-width: 7.5rem;
  }
  [icon] {
    margin-right: 0.3125rem;
  }
  p {
    margin: 0;
  }
  .value {
    font-size: 2.5rem;
    font-weight: 200;
    min-height: 60px;
  }
  .title {
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    font-weight: 400;
    white-space: nowrap;
  }
  
</style>