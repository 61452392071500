/**
 * @description Mixin used main entry screens (login and signup)
 */
import { computed, ref } from 'vue';

export default function entryMainComposable({$route, $router, $store}) {
  const acceptedRedirects = ref(['zingchart', 'zinggrid']);

  const planId = computed(() => {
    return $store.state.billing.planId;
  });
  const planInterval = computed(() => {
    return $store.state.billing.planInterval;
  });
  const lock = computed(() => {
    return $store.state.auth.lock;
  });
  const regenerateSession = computed(() => {
    // Determines if server session should be regenerated in login
    let redirectPath = localStorage.getItem('redirectPath', true);
    // Do not regenerate when logging/signing up during checkout process
    if (redirectPath) return !redirectPath.includes('/subscribe');
    return true;
  });

  /**
   * @description When redirecting off this page, pass paramters that is used to redirect to
   * studio, subscription or any other page after login/signup
   * @param {Boolean} signup - if true, redirect to login page, else redirect to sign up page
   */
  function passQueryParam(signup) {
    let query = window.location.href.split('?')[1];
    query = query ? `?${query}` : '';
    let path = `${signup ? 'login' : 'signup'}${query ? `${query}` : ''}`;
    if ($route.path !== path) $router.push(path);
  };

  /**
   * @description Called on login and signup view to save query parameter `redirectSite` into localStorage.
   * The localstorage variable will later be consumed in `AppShell.vue` after user successfully logs in.
   * The user will be redirected to an external site specified by `redirectSite`.
   * 
   * `redirectSite` is formatted `site/path`, where `site` must be one of the values in `acceptedRedirects` and
   * `path` could be any page on the site.
   * Ex. https://www.zingchart.com/docs => `zingchart/docs`
   * Ex. https://www.zingchart.com/docs/events/index.html => `zingchart/docs/events/index.html`
   */
  function redirectAfterLogin() {
    let redirectSite = $route.query.redirectSite || '';
    if (redirectSite) {
      let split = redirectSite.split('/');
      // Set redirectSite in localStorage only if it set to redirect expected sites
      if (acceptedRedirects.value.indexOf(split[0].toLowerCase()) > -1) {
        localStorage.setItem('redirectSite', `https://www.${split[0]}.com/${split[1] ? split[1] : ''}`);
      }
    }
  };

  return {
    acceptedRedirects,
    planId,
    planInterval,
    lock,
    regenerateSession,
    passQueryParam,
    redirectAfterLogin
  };
};
