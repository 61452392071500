<template>
    <div class="form__item">
      <div style="display: flex;">
        <label class="form__label" style="margin-right: 0.5rem">E-mail</label>
        <el-tooltip v-if="!email_verified" class="item" effect="dark" content="Resend Verification E-mail" placement="top">
          <span class="text-secondary text-secondary--warning" style="text-decoration: underline; cursor: pointer" @click="resendVerification()">( Unverified )</span>
        </el-tooltip>
      </div>
      <el-input v-model="email_" class="form__field" label="New E-mail"></el-input>
    </div>
    <!-- <el-button @click="updateEmail" type="primary" :disabled="!validEmail">Update</el-button> -->
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['change', 'update', 'verify']);

  const props = defineProps({
    value: {
      type: String
    }
  });

  const $store = useStore();
  const email_ = ref(null);
  const email_verified_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const validEmail = computed(() => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email_.value);
  });
  const email = computed(() => {
    return props.value
  });
  const email_verified = computed(() => {
    return $store.state.user.email_verified;
  });

  watch(email_verified, (value) => {
    email_verified_.value = value;
  });
  watch(email, (value) => {
    email_.value = value;
    dirty.value = false;
  });
  watch(email_, (value) => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value) {
      // Handles changes by the store mutating the event
      dirty.value = true;
    } else {
      // If the field was modified by user entry
      emit('change', email_.value);
    };
  });

  onMounted(() => {
    email_verified_.value = $store.state.user.email_verified;
    email_.value = props.value;
  });

  function resendVerification() {
    emit('verify');
  };

  function updateEmail() {
    emit('update', email_.value);
  };
</script>

<style scoped>
  .text-secondary {
    color: var(--color-greyscale-2);
    font-size: 0.8rem;
    position: relative;
    top: 0.2rem;
  }
  .text-secondary--warning { color: var(--color-fail); }
</style>