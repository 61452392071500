import { computed} from 'vue';
import axios from 'axios';

export default function groupsComposable({activeTab, $global, $route, $store}) {  
  const authenticated = computed(() => {
    return !!$store.state.auth.idToken;
  });
  const myGroups = computed(() => {
    updateLocalStorage($store.state.user.groups);
    return $store.state.user.groups || [];
  });

  // Call in `onBeforeMount()`
  function setupGroups() {
    $store.commit('user/initializeGroups');

    if (authenticated.value && $route.path !== '/') {
      fetchGroups();
    }
  };
  
  /**
   * @description Fetches list of groups user belongs to create tab that lists group demos.
   * If localstorage does not match, update groups object `zs_groups`.
   */
  function fetchGroups() {
    axios({
      url: '/api/grouping/list',
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      },
    })
    .then((response) => {
      let groupCounts = [];
      // For active groups, save the count of demos for each groups
      response.data.forEach((group) => {
        if (group.status === 'active') {
          if (myGroups.value.length > 0) {
            myGroups.value.filter((myGroup) => {
              if (myGroup.id === group.id) {
                group.count = myGroup.count || 0;
                group.count_zingchart = myGroup.count_zingchart || 0;
                group.count_zinggrid = myGroup.count_zinggrid || 0;
              }
            });
          } else {
            group.count = 0;
          }
          groupCounts.push(group);
        }
      });
      // Save as state
      $store.commit('user/update', {groups: groupCounts});
      // Update localstorage (is neccessary)
      updateLocalStorage(groupCounts);
      // Set active tab for Groups page
      if (activeTab) {
        let groupId = window.location.search.split('?')[1];
        if (groupId) activeTab = groupId.toString();
        else activeTab = myGroups.value ? myGroups.value[0].id.toString() : 'create';
      }
      if (myGroups.value.length === 0) return;
    }).catch((err) => {
      $global.$message({
        message: `Error grabbing your groups: ${err}`,
        type: 'error',
      });
    });
  };

  /**
   * @description Update groups object `zs_groups` in localstorage if does not match current
   * groups object `my_groups`
   * @param {Object} groups - group object to update with if differs from current object in
   * localstorage
   */
  function updateLocalStorage(groups) {
    let localStorageGroups = localStorage.getItem('zs_groups');
    let sGroups = null;
    // Compare
    if (localStorageGroups) {
      // Check if count property added to group object
      sGroups = JSON.stringify(groups);
      // Early exit if object does not need to update
      if (localStorageGroups === sGroups) return;
    }
    // Update/add
    try {
      localStorage.setItem('zs_groups', sGroups);
    } catch(e) {
      console.err('Unable to store groups: ', e);
    }
  };
  
  return {
    myGroups,
    fetchGroups,
    setupGroups,
  };
};