/**
 * @description Used with DateRange component. Tracks date range changes on all ZingNet dashboard items and
 * updates range to sync items.
 */
import { ref } from 'vue';

export default function dateRangeComposable({getReportData, getReportDataCustom}) {
  const start = ref(null);
  const end = ref(null);
  const prevStart = ref(null);
  const prevEnd = ref(null);
  const period = ref(null);
  const custom = ref(null);

  function updateDateRange(type, date) {
    switch (type) {
      case 'start': start.value = date; break;
      case 'end': end.value = date; break;
      case 'prevStart': prevStart.value = date; break;
      case 'prevEnd': prevEnd.value = date; break;
      case 'period': period.value = date; break;
    };
    
    if (type === 'prevEnd') getReportData();
    else if (type === 'custom') getReportDataCustom();
  };

  return {
    start,
    end,
    prevStart,
    prevEnd,
    period,
    custom,
    updateDateRange
  };
};