import { ref } from 'vue';
import UserModal from '../components/UserModal.vue';

export default function userModalComposable({$store}) {
  const appId = ref(VUE_APP_HELPSCOUT_BEACON_ID);
  const company = ref(null);
  const count = ref(null);
  const email = ref(null);
  const headerVal = ref({
    Authorization: `Bearer ${$store.state.auth.idToken}`
  });
  const quickbooksId = ref(null);
  const userId = ref(null);
  const userModalVisible = ref(false);

  function initUserModal(zgRef, eventType) {
    if (zgRef) {
      // Event listener domain text click
      zgRef.addEventListener(`${eventType || 'row'}:click`, (e) => {
        let target = e.detail.ZGEvent.oDOMTarget;
        let attrArr = Object.values(target.attributes).map((attr) => attr.name);
        if (attrArr.includes('open-user-modal')) {
          let author = eventType === 'card' ? e.detail.ZGData.data.source.author
            : e.detail.ZGData.data;
          openUserModal(author);
        };
      });
    };
  };
  
  function openUserModal(author) {
    company.value = author.name,
    email.value = author.email;
    userId.value = author.id;
    userModalVisible.value = true;
  };

  return {
    appId,
    company,
    count,
    email,
    headerVal,
    quickbooksId,
    userId,
    userModalVisible,
    initUserModal,
    openUserModal,
  };
};