<template>
  <div ref="metadataSelectorWrapper" class="metadataSelectorWrapper">
    <filter-selector
      ref="metadataSelector"
      @filterBy="filter"
      :allowCreate="true"
      :clearable="true"
      :defaultValue="defaultValue"
      :filterable="true"
      :multiple="multiple"
      :options="options"
      :placeholder="placeholder"
      :scrollable="scrollable">
    </filter-selector>
  </div>
</template>

<script setup>
  import { defineEmits, defineProps, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import FilterSelector from './FilterSelector.vue';
  import metadataComposable  from '../mixins/metadata.js';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const { galleryMetadata, galleryMetadataOptions, fetchAllOptions } = metadataComposable({$global});

  const emit = defineEmits(['update:metadataBy']);
  
  const props = defineProps({
    defaultValue: String,
    filterBy: Function, 
    metadata: String,
    scrollable: Boolean,
    type: String,
    demoType: String,
  });
  
  const $store = useStore();
  const collapsible  = ref(false);
  const options = ref([]);
  const placeholder = ref('Select Metadata');
  const metadataSelector = ref(null);
  const metadataSelectorWrapper = ref(null);
  const multiple = ref(false);
  
  watch(() => props.demoType, () => {
    clearResults();
    setValueOptions();
  }); 
  watch(() => props.metadata, () => {
    clearResults();
    setValueOptions();
  });

  onBeforeMount(() => {
    setData();
  });

  onMounted(() => {
    if ($store.state.user['user_id']) {
      fetchAllOptions();
    }
  });

  function clearResults() {
    metadataSelector.value.clearFilter();
  };

  function filter(value) {
    emit('update:metadataBy', value);

    setTimeout(() => {
      signalScrollable();
    }, 100);
  };

  function setData() {
    if (props.type) {
      switch(props.type) {
        case 'metadata': 
          multiple.value = false;
          options.value = galleryMetadataOptions.value.filter((type) => {
            // Allow only "select" type
            let skip = ['loadInIframe', 'vanityUrl'];
            return type.value.indexOf('is') !== 0 && !skip.includes(type.value);
          });
          break;
        default:
          collapsible.value = true;
          multiple.value = true;
          setValueOptions();
          break;
      }
    }
  };

  function setValueOptions() {
    if (props.metadata && galleryMetadata.value[props.metadata]) {
      if (props.demoType === 'all_demos') {
        options.value = galleryMetadata.value[props.metadata].options['zinggrid'].concat(galleryMetadata.value[props.metadata].options['zingchart']);
      } else {
        options.value = galleryMetadata.value[props.metadata].options[props.demoType];
      }
    }
  };

  /**
   * @description Rotate arrow at end of selector to signal whether there are more options selected
   */
  function signalScrollable() {
    if (props.scrollable) {
      // Check width to add/remove class
      let width = metadataSelectorWrapper.value.querySelector('.el-select .el-select__input-wrapper .el-select__input').getBoundingClientRect().width;
      if (width > 0 && metadataSelectorWrapper.value.querySelector('[icon="angle"]').classList.contains('angle--right')) {
        metadataSelector.value.querySelector('[icon="angle"]').classList.remove('angle--right');
      } else if (width === 0 && !metadataSelectorWrapper.value.querySelector('[icon="angle"]').classList.contains('angle--right')){
        metadataSelectorWrapper.value.querySelector('[icon="angle"]').classList.add('angle--right');
      }
    }
  };
</script>

<style scoped>
  .metadataSelectorWrapper {
    width: 100%;
  }
</style>