<template>
  <el-form
    @validate="formPricingValidation"
    pricing="formPricing"
    ref="formPricing"
    :model="formPricing"
    hide-required-asterisk
    label-position="top"
    :rules="formPricingValidationRules"
  >
    <el-form-item v-if="fieldExists([formPricing.nameFirst, formPricing.nameLast])">
      <el-col :span="formSpan">
        <el-form-item label="First Name" prop="nameFirst" :compute="userInfo_name">
          <el-input v-model="formPricing.nameFirst" placeholder="Enter first name" data-matomo-unmask></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="formSpan" :offset="formOffset">
        <el-form-item label="Last Name" prop="nameLast">
          <el-input v-model="formPricing.nameLast" placeholder="Enter last name" data-matomo-unmask></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item
      v-if="fieldExists([formPricing.company])"
      label="Company"
      prop="company"
      :compute="userInfo_company">
      <el-input v-model="formPricing.company" placeholder="Enter company name" data-matomo-unmask maxlength=75></el-input>
    </el-form-item>
    
    <el-form-item
      v-if="fieldExists([formPricing.domainOptional]) && includeDomainFields"
      label="Licensed Domain Name (optional - can be updated later)"
      prop="domainOptional">
      <el-input v-model="formPricing.domainOptional" placeholder="Enter domain (domain.com)" data-matomo-unmask></el-input>
    </el-form-item>

    <el-form-item
      v-if="fieldExists([formPricing.subdomainOptional]) && includeDomainFields"
      label="Licensed Subdomain Name (optional - can be updated later)"
      prop="subdomainOptional">
      <el-input
        @change="autofillDomainField"
        v-model="formPricing.subdomainOptional"
        placeholder="Enter subdomain (sub.domain.com)"
        data-matomo-unmask
      ></el-input>
    </el-form-item>

    <el-alert
      v-if="testing"
      title="Test Card Options"
      :closable="false"
      :compute="userInfo_card"
      type="info">
      <el-select v-model="testCard" @change="addTestCard" placeholder="Select">
        <el-option
          v-for="item in testCardOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-alert>

    <el-alert
      v-if="testing"
      title="Test Charge Options"
      :closable="false"
      type="info">
      <el-select v-model="testCharge" @change="addTestCharge" placeholder="Select">
        <el-option
          v-for="item in testChargeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-alert>

    <el-form-item
      v-if="fieldExists([formPricing.cardNumber])"
      label="Credit Card Number"
      prop="cardNumber">
      <el-input
        v-model="formPricing.cardNumber"
        @change="formatCardNumber"
        class="fs-block"
        maxlength="20"
        minlength="16"
        placeholder="Enter credit card number"
      ></el-input>
    </el-form-item>

    <el-form-item v-if="fieldExists([formPricing.cardExpiration])">
      <el-col :span="formSpan">
        <el-form-item label="Card Expiration Date" prop="cardExpiration">
          <el-date-picker
            v-model="formPricing.cardExpiration"
            class="fs-block"
            :clearable="false"
            format="MM / YYYY"
            placeholder="XX / XXXX"
            prefix-icon="false"
            type="month"
            value-format="MM / YYYY"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="formSpan" :offset="formOffset">
        <el-form-item
          v-if="fieldExists([formPricing.cardCvc])"
          label="Card CVC"
          prop="cardCvc">
          <el-input
            v-model="formPricing.cardCvc"
            class="fs-block"
            minlength="3"
            maxlength="4"
            placeholder="Enter CVC code"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-form-item>

    <el-form-item
      v-if="fieldExists([formPricing.discountOptional])"
      label="Discount Code (optional)">
      <el-input v-model="formPricing.discountOptional" placeholder="Enter discount code"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button @click="closeForm">Cancel</el-button>
      <el-button
        @click="submitAction"
        type="primary"
        :disabled="formPricingIncomplete"
        :loading="submitLoading"
      >{{submitText}}</el-button>
    </el-form-item>

  </el-form>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onBeforeMount, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import Domain from '../../../plugins/domain.js';
  import MixinAutofillDomain from '../../../mixins/autofillDomain.js';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $store = useStore();

  const emit = defineEmits(['close-dialog']);

  const props = defineProps({
    formPricing: Object,      // Form object
    includeDomainFields: Boolean,
    plan: Object,             // Plan object
    submitAction: Function,   // Action to execute on form submit
    submitLoading: Boolean,    // Flag to set button loading
    submitText: {             // Text to display on submit button
      type: String,
      default: 'Next Step',
    },
    view: Number,             // Current view in subscription form process
  });

  const REQUIRED = ref('Field required');
  const formOffset = ref(2);          // El-form el-input offset
  const formSpan = ref(11);           // El-form el-input span
  const formPricingValidated = ref(true); // Whether subscription details form passes validation
  const regexCardNumber13 = ref(new RegExp(/([3\*][0-9\*]{3}\s*)([0-9\*]{4}\s*){2}([0-9\*]{3}\s*)$/));
  const regexCardNumber14 = ref(new RegExp(/([0-24-9\*][0-9\*]{3}\s*)([0-9\*]{4}\s*){3}/));
  const testing = ref(null);
  const testCard = ref('');
  const testCardOptions = ref([
    {
      value: '4111111111111111',
      label: 'Visa'
    }, {
      value: '5555555555554444',
      label: 'MasterCard'
    }, {
      value: '378282246310005',
      label: 'American Express'
    }, {
      value: '6011111111111117',
      label: 'Discover'
    }
  ]);
  const testCharge = ref('');
  const testChargeOptions = ref([
    {
      value: 'Success',
      label: 'Success'
    }, {
      value: 'emulate=10201',
      label: 'Payment system error'
    }, {
      value: 'emulate=10301',
      label: 'Card number is invalid'
    }, {
      value: 'emulate=10401',
      label: 'General decline'
    }
  ]);

  // Prefill user data
  const name = ref(null);
  const company = ref(null);

  const hasUser = computed(() => {
    // Determines if user if logged in
    return !!$store.state.user['user_id'];
  });
  const formPricingIncomplete = computed(() => {
    // Determines if subscription details form fields non-empty and validated
    let disable = false;
    let keys = Object.keys(props.formPricing);
    // Check if field is nonempty
    keys.forEach(field => {
      if (!field.includes('Optional')) {
        if (!props.formPricing[field] || props.formPricing[field].length === 0) disable = true;
      }
    });
    // Check if form validated
    if (!formPricingValidated.value) disable = true;
    return disable;
  });
  const formPricingValidationRules = computed(() => {
    return { // Rules to validate subscription details
      nameFirst: [{required: true, trigger: 'blur', message: REQUIRED.value}],
      nameLast: [{required: true, trigger: 'blur', message: REQUIRED.value}],
      company: [{required: true, trigger: 'blur', message: REQUIRED.value}],
      domainOptional: [
        {pattern: Domain.regexDomain, trigger: 'blur', message: Domain.messageDomain},
      ],
      subdomainOptional: [
        {pattern: Domain.regexSubdomain, trigger: 'blur', message: Domain.messageSubdomain},
        {pattern: Domain.regexSubdomainCheck([props.formPricing.domainOptional]), trigger: 'blur', message: Domain.messageSubdomainCheck([props.formPricing.domainOptional])},
      ],
      cardNumber: [
        {required: true, trigger: 'blur', message: REQUIRED.value},
        {pattern: regexCardNumber.value, trigger: 'blur', message: 'Invalid card number format'},
      ],
      cardExpiration: [{required: true, trigger: 'blur', message: REQUIRED.value}],
      cardCvc: [
        {required: true, trigger: 'blur', message: REQUIRED.value},
        {pattern: regexCardCVC.value, trigger: 'blur', message: `Expecting ${messageCardCVC.value} digit number`},
      ],
    };
  });
  const regexCardNumber = computed(() => {
    return new RegExp(`(${regexCardNumber13.value.source})|(${regexCardNumber14.value.source})`);
  });
  const regexCardCVC = computed(() => {
    return isAmex() ? /^[0-9]{4}$/m : /^[0-9]{3}$/m;
  });
  const messageCardCVC = computed(() => {
    return isAmex() ? 4 : 3;
  });
  const userInfo_name = computed(() => {
    let _name = name.value ? name.value.trim().split(' ') : '';
    if (name.length > 0) {
      // If name is available, split to first and last name
      let length = _name.length;
      let nameFirst = _name.slice(0, length-1).join(' ');
      if (nameFirst) assignFormPricing('nameFirst', nameFirst);
      let nameLast = length > 1 ? _name[length-1] : null;
      if (nameLast) assignFormPricing('nameLast' , nameLast);
    }
    return _name;
  });
  const userInfo_company = computed(() => {
    // Prefill company field if user is logged in
    let _company = company.value;
    if (_company) assignFormPricing('company', _company);
  });
  const userInfo_card = computed(() => {
    // Prefill card field if user is logged in
    if (hasUser.value) {
        $api('card/retrieve', {}, $global).then((result) => {
          if( result.status !== 204) {
            let card = result.data;
            if (card) {
              if (card.last4) assignFormPricing('cardNumber' , `**** **** **** ${card.last4}`);
              if (card.expMonth && card.expYear) assignFormPricing('cardExpiration' , `${card.expMonth.toString().length === 1 ? '0' : ''}${card.expMonth} / ${card.expYear}`);
            }
          }
        });
      }
  });

  onBeforeMount(() => {
    // Fetch data to prefill fields
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      name.value = userData.name;
      company.value = userData.company;
    });
  });

  onMounted(() => {
    testing.value = VUE_APP_TESTING === 'true';
  });

  /**
   * @description Fills in test card data into form
   * @param {Number} cardNumber - card number
   */
  function addTestCard(cardNumber) {
    props.formPricing.cardNumber = cardNumber;
    props.formPricing.cardCvc = cardNumber.length === 15 ? '1234' : '123';
    let date = new Date();
    props.formPricing.cardExpiration = `${('0' + (date.getMonth()+1).toString()).slice(-2)} / ${date.getFullYear()+1}`;
  };

  /**
   * @description Fills in test card data into form to simulate charge
   * @param {Number} cardNumber - card number
   */
  function addTestCharge(chargeCode) {
    if (chargeCode) props.formPricing.chargeOptional = chargeCode;
  };

  function assignFormPricing(field, val) {
    // Assign if value given to prefill field
    if (val) {
      // Assign if field is empty (do not overwrite field value when user backtracks in checkout process)
      if (props.formPricing[field].length === 0) props.formPricing[field] = val;
    }
  };

  function autofillDomainField() {
    autofillDomain({
      checkDomainEmpty: true,
      refDomain: 'domainOptional',
      refForm: 'formPricing',
      refSubdomain: 'subdomainOptional'
    });
  };

  /**
   * @description Closes form by posting message to iframe parent. If used in dialog,
   * close dialog.
   */
  function closeForm() {
    // Close iframe
    window.top.postMessage('Close Form', '*');

    // Close dialog
    emit('close-dialog');
  };

  /**
   * @description Checks if the field(s) exist
   * @param {Array} fields - fields to check
   */
  function fieldExists(fields) {
    let exist = true;
    fields.forEach((field) => {
      if (!field && field !== '') exist = false;
    });
    return exist;
  };

  /**
   * @description On keyup, formats card number by placing space between every forth number
   * @param {String} text - current text value in input
   */
  function formatCardNumber(text) {
    if (!text.includes('*') && text) {
      // Removes all non-numbers and place spaces
      let cardNumbers = text.match(/\d+/g).join('');
      if (cardNumbers) {
        let numberGroup = cardNumbers.match(/(.{3,4})/g);
        if (numberGroup) props.formPricing.cardNumber = numberGroup.join(' ');
        else props.formPricing.cardNumber = text;
      }
    }
  };

  /**
  * @description Triggered when a form item is validated to return if validation passes
    * @param prop - prop name of form item being validated
    * @param validated - whether form item is validated
    */
  function formPricingValidation(prop, validated) {
    formValidation(validated, 'formPricing');
  };
  
  /**
   * @description Helper method that `OR` the results of form items to determine if
   * form is validated and to enable submit button
   * @param validated - whether form item is validated
   * @param form - form to determine validation
   */
   function formValidation(validated, form) {
    let formValidated = `${form}Validated`;
    // If form previously validated and form item is validated => form remains validated
    if (validated && [formValidated].value) [formValidated].value = true;
    // If form item is invalidated = > form becomes invalidated
    else if (!validated) [formValidated].value = false;
    else {
      // Check if any form item is invalidated
      setTimeout(() => {
        let invalidatedFormItem = document.querySelector(`[pricing="${form}"] .is-error`);
        [formValidated].value = !invalidatedFormItem;
      }, 500);
    }
  };

  /**
   * @description Checks if the card number entered is an American Express credit card.
   */
  function isAmex() {
    let firstChar = props.formPricing.cardNumber[0];
    if (firstChar === '*') {
      let cardNumber = props.formPricing.cardNumber.replace(/\s/g, '');
      return cardNumber.length === 15;
    } else {
      return parseInt(firstChar) === 3;
    }
  };
</script>