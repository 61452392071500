<template>
  <el-dialog
    @close="closeGalleryMetadata"
    :append-to-body="true"
    title="Add Gallery Metadata"
    v-bind="visible"
    width="50%">
    <el-form :model="galleryForm" ref="metadataForm">
      <!-- metadata -->
      <el-form-item label="Metadata" :label-width="labelWidth">
        <el-select 
          @change="clearGalleryForm(true)"
          v-model="galleryForm.metadata"
            allow-create
            filterable>
          <el-option
            v-for="item in galleryMetadataOptions"
            :disabled="metadataDisabled(item.value)"
            :key="item.index"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- value -->
      <el-form-item 
        v-if="galleryForm.metadata && galleryForm.metadata != '' && galleryForm.newOptions.length === 0"
        label="Value"
        :label-width="labelWidth">

        <!-- select -->
        <el-select
          @change="isCreateNew"
          v-if="valueType('select', true)"
          v-model="galleryForm.value"
          :allow-create="valueType('select') && galleryMetadata[galleryForm.metadata].addOption"
          filterable>
          <el-option
            v-for="item in getMetadataOptions(galleryForm.metadata)"
            :key="`${galleryForm.metadata}-${item.value}`"
            :label="getMetadataName(item)"
            :value='`{"value": "${item.value}", "metadataUid": "${item.metadata_uid}"}`'>
          </el-option>
        </el-select>

        <!-- url -->
        <el-input
          v-else-if="valueType('url')"
          v-model="galleryForm.value"
          type="url">
        </el-input>
        
        <!-- boolean -->
        <el-checkbox
          v-else-if="valueType('boolean')"
          v-model="galleryForm.value"
          checked
          true-label="1"
          false-label="0">
        </el-checkbox>
        <!-- text -->
        <el-input
          v-else
          v-model="galleryForm.value">
        </el-input>
      </el-form-item>

      <!-- additional field for url -->
      <template v-if="valueType('select-url')">
        <!-- input -->
        <el-form-item
          v-show="galleryForm.value"
          label="Pathname"
          :label-width="labelWidth">

          <el-input
            v-model="galleryForm.data"
            :name="galleryForm.data"
            type="text">
            <template v-slot:prepend>{{getRelatedDocsUrl(galleryForm.metadataUid)}}</template>
          </el-input>
        </el-form-item>
      </template>

        <!-- field for adding new option -->
        <el-form-item
          v-if="valueType('select') && galleryMetadata[galleryForm.metadata].addOption && createSelect"
          label="Type"
          :label-width="labelWidth">
          <el-checkbox-group v-model="galleryForm.demoType" size="small">
            <el-checkbox-button v-for="type in demoTypeOptions" :value="type" :key="type">{{type}}</el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>

      <!-- select-url -->
      <template v-if="valueType('select-url')">
        <el-divider></el-divider>
        <el-form-item
          v-for="(newOption) in galleryForm.newOptions"
          :label="`New Option`"
          :key="newOption.key"
          :label-width="labelWidth">
          <el-input
            v-model="newOption.value"
            placeholder="Label/Value">
          </el-input>
          <el-input
            v-model="newOption.content"
            placeholder="Url"
            type="url">
          </el-input>
          <el-input
            v-model="newOption.data"
            placeholder="Pathname"
            type="text">
          </el-input>
          <el-checkbox-group v-model="galleryForm.demoType" size="small">
            <el-checkbox-button v-for="type in demoTypeOptions" :value="type" :key="type">{{type}}</el-checkbox-button>
          </el-checkbox-group>
          <el-button @click.prevent="removeNewOption(newOption)">Delete</el-button>
        </el-form-item>
        <el-button v-if="galleryForm.newOptions < 1" @click="addFormField">Add new option</el-button>
      </template>
    </el-form>
    <template v-slot:footer>
      <span class="dialog-footer">
        <el-button @click="closeGalleryMetadata">Cancel</el-button>
        <el-button type="primary" :disabled="missingRequiredField" @click="addGalleryMetadata">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import metadataComposable from '../../../mixins/metadata';

  const emit = defineEmits(['close', 'open']);

  const props = defineProps([
    'visible',
    'zgRef',
    'demoType',
    'metadata',
  ]);

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $store = useStore();
  const createSelect = ref(false);
  const demoTypeOptions = ref(['ZingChart', 'ZingGrid']);
  const errorDuplicate = ref('New option was not added because already exists');
  const galleryForm = ref({
    metadataUid: '',
    metadata: '',
    value: '',
    newOptions: [],
    data: {},
    demoType: [],
  });
  const labelWidth = ref('100px');
  const optionsToCreate = ref(['relatedDocs']);

  const { galleryMetadata, galleryMetadataOptions, fetchAllOptions, fetchOption } = metadataComposable({$global});

  /**
   * @description Checks if any fields are empty. Enable confirm button
   * if all fields are filled.
   */
  const missingRequiredField = computed(() => {
    let missing = false;
    // Metadata type not selected
    if (galleryForm.value.metadata === '') missing = true;
    // Value selected or new option to be added
    if (galleryForm.value.value === ''
      && galleryForm.value.newOptions.length === 0) missing = true;
    // Required fields for adding new options
    if (galleryForm.value.newOptions.length > 0
      && (galleryForm.value.newOptions[0].value === ''
      || galleryForm.value.newOptions[0].content === ''
      || galleryForm.value.demoType.length === 0)) missing = true;
    // Demo type set when adding new option
    if (createSelect.value && galleryForm.value.demoType.length === 0) missing = true;
    return missing;
  });

  /**
   * @description Set galleryform.metadataUid for checkbox input
   */
  watch(galleryForm.value, (val) => {
    if (val == 1) {
      galleryForm.value.metadataUid = galleryMetadata.value[galleryForm.value.metadata].options.metadata_uid
    } else if (val == 0) {
      galleryForm.value.metadataUid = '';
    } else if (galleryForm.value.metadata === 'relatedDocs') {
      galleryForm.value.content = getRelatedDocsUrl(galleryForm.value.metadataUid);
    };
  });

  onMounted(() => {
    if ($store.state.user['user_id']) {
      fetchAllOptions();
    }
  });

  /**
   * @description Add metadata related to site gallery demos
   */
  function addGalleryMetadata() {
    // If add new options, include to add as attribute value
    includeNewOptions();
    // Insert body
    let body = {
      metadataUid: galleryForm.value.metadataUid,
      metadata: galleryForm.value.metadata,
      value: galleryForm.value.value,
      data: galleryForm.value.data,
      content: galleryForm.value.content,
    };
    // Close gallery metadata dialog
    emit('close');
    // Add new metadata option (if created)
    addMetadataOption(galleryForm.value, body);
    // Clear dialog form
    clearGalleryForm();
  };

  function addFormField() {
    galleryForm.value.newOptions.push({
      key: Date.now(),
      value: '',
      content: '',
    });
  };

  /**
   * @description If new option is added, update database to include new option
   * @param {Object} galleryForm - metadata value object
   * @param {String} galleryForm.metadata - type of metadata
   * @param {Array} value - value to assign metadata (option)
   */
  function addMetadataOption(galleryForm, body) {
    // Add new options to database
    if (galleryForm.value.metadata === 'relatedDocs' && galleryForm.value.value && galleryForm.value.newOptions.length > 0) {
      // Handle for select-url
      _addMetadataOption(body, galleryForm.value.value, galleryForm.value.demoType, galleryForm.value.newOptions[0].content);
    } else {
      // Handle for select(single)
      _addMetadataOption(body, galleryForm.value.value, galleryForm.value.demoType)
    }
  };

  /**
   * @description Check if metadata option already exists. If not, add new option
   */
  function _addMetadataOption(body, option, demoType, data) {
    if (galleryMetadata.value[galleryForm.value.metadata] && galleryMetadata.value[galleryForm.value.metadata].type !== 'boolean') {
      // If metadata has options, check if options already exists
      let existingOption = [];
      let curOptions = galleryMetadata.value[galleryForm.value.metadata].options;
      for (let options in curOptions) {
        existingOption = existingOption.concat(curOptions[options].filter((curOption) => option === curOption.value));
      }
      let optionToAdd = valueType('select-url') ?
        galleryForm.value.newOptions.filter((opt) => opt.value === option)
        : false;
      // Determine if option exists and allowed to be added
      if (existingOption.length === 0 && optionsToCreate.value.indexOf(body.metadata) > -1) {
        // Construct new option to add
        let newOption = {
          metadata: galleryForm.value.metadata,
          value: option,
          content: optionToAdd && optionToAdd.length > 0 ? optionToAdd[0].content : '',
          metadataData: data || '', 
          demo_type: (galleryForm.value.demoType || demoType).map((type) => type.toLowerCase()),
          metadata_uid: galleryForm.value.metadataUid
        }

        // Add to database
        $api('metaoptions/create', newOption, $global)
          .then((result) => {
            // Add to metadata table
            body.metadataUid = result.data.id;
            props.zgRef.insertRow(body);
            // Include new option
            if (newOption.metadata === 'relatedDocs') {
              fetchOption(newOption.metadata);
            };
          }).catch((err) => {
            if (err && err.response && err.response.status === 400) {
              $message({
                message: errorDuplicate.value,
                type: 'error',
              })
            }
          });
      } else if (optionToAdd.length > 0) {
        // Add to metadata table
        props.zgRef.insertRow(body);
        // Error when user attempts to add option that already exists
        $message({
          message: errorDuplicate.value,
          type: 'error',
        })
      } else {
        // Add to metadata table (non-select metadata)
        props.zgRef.insertRow(body);
      }
    } else {
      // Add to metadata table (custom metadata)
      props.zgRef.insertRow(body);
    }
  };

  /**
   * @description Clears form item that is inserted into ZingGrid
   * @param {Boolean} onlyValue - clears only value
   */
  function clearGalleryForm(onlyValue) {
    if (onlyValue) {
      // Clear only value
      galleryForm.value.value = '';
      galleryForm.value.newOptions = [];
      galleryForm.value.data = '';
      galleryForm.value.demoType = [];
    } else {
      // Clear all
      galleryForm.value = {
        metadata: '',
        value: '',
        data: '',
        newOptions: [],
        content: '',
        demoType: [],
      }
    }
    createSelect.value = false;
  };

  /**
   * @description Clears form and sets visibility of dialog to false
   */
  function closeGalleryMetadata() {
    emit('close');
    clearGalleryForm();
  };

  /**
   * @description Get metadata option name. Append details determining if option
   * is limited to option
   */
  function getMetadataName(item) {
    let name = item.name;
    if (item.demo_type) {
      if (item.demo_type === 'zinggrid') {
        name += ' - ZG';
      } else if (item.demo_type === 'zingchart') {
        name += ' - ZC';
      }
    }
    return name;
  };

  /**
   * @description Displays options based on type and demo type
   * @param {String} type - metadata type
   */
  function getMetadataOptions(type) {
    let options = galleryMetadata.value[type].options;
    let retval = [];
    // Retrieve options (single demo type)
    if (options[props.demoType]) retval = retval.concat(options[props.demoType]);
    // Retrieve options (multiple demo types)
    if (props.demoType.includes(',')) {
      let demoType = props.demoType.split(',');
      demoType.forEach((dt) => {
        let trim = dt.trim();
        if (options[trim]) retval = retval.concat(options[trim]);
      });
    }
    return retval;
  };

  /**
   * @description Returns related docs url associated to label
   */
  function getRelatedDocsUrl(metadataUid) {
    if (metadataUid) {
      let relatedDocsOptions = galleryMetadata.value.relatedDocs.options;
      // For each option type (ZC, ZG, both)
      for (let option in relatedDocsOptions) {
        if (props.demoType.includes(option)) {
          // Return related docs url
          let target = relatedDocsOptions[option].filter((option) => {
            if (option.metadata_uid == metadataUid) return option;
          });
          return target[0].content;
        }
      }
    }
    return;
  };

  /**
   * @description In the case of adding new option that requires an additional fields,
   * include new options to current metadata value
   */
  function includeNewOptions() {
    galleryForm.value.newOptions.forEach((option) => {
      if (galleryForm.value.metadata === 'relatedDocs') {
        galleryForm.value.value = option.value;
        galleryForm.value.data = option.data;
        galleryForm.value.content = option.content;
      } else {
        option.demo_type = galleryForm.value.demoType;
      }
    });
  };

  /**
   * @description Determine if new option is being creating by checking if
   * no matching text is displayed on blur. If an option is being created,
   * display additional field to associate type.
   * @param {String} val - value of selected option in {value, metadataUid} format
   */
  function isCreateNew(val) {
    val = JSON.parse(val);
    let exists = false;
    let galleryMetadataOptions = galleryMetadata.value[galleryForm.value.metadata].options;

    // Clear previous input
    galleryForm.value.demoType = [];

    // Determine options to check
    let options = [];
    if (props.demoType.includes(',')) {
      for (let option in galleryMetadataOptions) {
        options.push(option);
      };
    } else {
      for (let option in galleryMetadataOptions) {
        if (option.includes(props.demoType)) options.push(option);
      };
    };

    // Check if option exists
    options.forEach((option) => {
      let target = galleryMetadataOptions[option].filter((opt) => opt.value === val.value);
      exists = exists || target.length > 0;
    });
    createSelect.value = !exists;

    if (createSelect.value) {
      // Add new option to option list
      galleryForm.value.newOptions.push({
        value: galleryForm.value.value,
      });
    } else {
      // Set UID
      galleryForm.value.metadataUid = val.metadataUid;
      galleryForm.value.value = val.value;
    };
  };

  /**
   * @description Determines if metadata option should be disabled because max
   * number allowed already reached for demo.
   * `gallaryMetadata` contains data indicating if metadata limited to one input.
   * `metadata` contains the metadata data to demo
   * @param {String} option - metadata option to check if disabled
   * @return {Object} `galleryMetadata` with `disabled` field added
   */
  function metadataDisabled(option) {
    let metadata = galleryMetadata.value[option];
    // Determine value to set `disabled`
    if (metadata.selectMultiple) {
      // If `selectMultiple` is true, set `disabled` to false
      metadata.disabled = false;
    } else {
      if (props.metadata && typeof props.metadata === 'object' && Array.isArray(props.metadata)) {
        // Check if metadata already added
        let gridMetadata = props.metadata.filter((meta) => meta.metadata === option);
        metadata.disabled = gridMetadata.length > 0;
      } else {
        // Assume no metadata
        metadata.disabled = false;
      }
    };

    return galleryMetadata.value[option].disabled;
  };
  /**
   * @description Emits event to set dialog visibility to true
   */
  function openGalleryMetadata() {
    emit('open');
  };

  /**
   * @description Remove new option from being added to database
   * @param {Object} newOption - new option to not add
   */
  function removeNewOption(newOption) {
    let index = galleryForm.value.newOptions.indexOf(newOption);
    if (index !== -1) {
      galleryForm.value.newOptions.splice(index, 1);
    }
  };

  /**
   * @description Determines which form-item to create depending on
   * metadata value type
   * @param {String} type - metadata value type
   * @param {Boolean} includes - includes if metadata value type includes type. 
   * Else checks for equal
   */
  function valueType(type, includes) {
    let retVal = galleryMetadata.value[galleryForm.value.metadata];
    if (retVal && type) {
      let checkType = includes
        ? galleryMetadata.value[galleryForm.value.metadata].type.includes(type)
        : galleryMetadata.value[galleryForm.value.metadata].type === type;
      retVal = retVal && checkType;
    }

    return retVal;
  };
</script>