<template>
  <div ref="$el" studio-editor="container">
    <div studio-editor="controls">
      <!-- Copy option -->
      <el-popover
        placement="bottom"
        trigger="click"
        content="Copied!"
        popper-class="studioEditor_popoverCopy">
        <template v-slot:reference>
          <el-button @click="codeCopy" link size="small">Copy</el-button>
        </template>
      </el-popover>
      <!-- Fold option -->
      <el-button @click="codeFold" link size="small">{{toggleFold}}</el-button>
    </div>
    <!-- Temporary copy -->
    <textarea studio-editor="clipboard"></textarea>
    <!-- Monaco editor container -->
    <div ref="editor" class="editor"></div>
    <input type="hidden" :customAttribute="options"/>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineExpose, defineProps, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import * as monaco from 'monaco-editor';
  import beautify from 'js-beautify';

  const emit = defineEmits(['update']);

  const props = defineProps({
    id: String,
    autocomplete: Boolean,
    code: [String, Boolean],
    language: String,
    fontFamily: String,
    fontSize: String,
    theme: String,
  });

  // Non-reactive data
  let monacoRef = null; // Reference to monaco editor
  const instance = getCurrentInstance();
  const $store = useStore();

  // REFERENCES
  const $el = ref(null);
  const clipboard = ref(null);
  const proxy = ref(null);

  // MAPPINGS
  // Maps theme label to theme file name
  const assetMap = ref({
    cobalt: 'Cobalt',
    monokai: 'Monokai',
    github: 'GitHub',
  });
  // Maps theme label to theme name
  const themeMap = ref({
    default: 'vs',
    dark: 'vs-dark',
  });

  // THEMES
  const themes = ref({
    default: 'default',
    dark: 'default',
    monokai: null,
    github: null,
  });

  // CURRENT
  const curFold = ref(false);
  const toggleFold = ref('Collapse');

  // FLAGS
  const loadCode = ref(false);

  /**
   * @description Monaco editor options
   */
  const options = computed(() => {
    let editorOptions = {
      automaticLayout: true,
      contenteditable: true,
      fontSize: `${props.fontSize}px`,
      fontFamily: props.fontFamily,
      fixedOverflowWidgets: true,
      language: props.language,
      lineNumbers: 'on',
      lineNumbersMinChars: 4,
      minimap: {
        enabled: false
      },
      selectOnLineNumbers: true,
      tabSize: 2,
      theme: themeMap.value[props.theme] || props.theme,
      value: typeof props.code === 'boolean' ? '' : props.code,
      wordWrap: 'off',

      // autocomplete-related
      acceptSuggestionOnEnter: props.autocomplete,
      acceptSuggestionOnCommitCharacter: props.autocomplete,
      quickSuggestions: props.autocomplete,
      roundedSelection: props.autocomplete,
      snippetSuggestions: props.autocomplete,
      suggestOnTriggerCharacters: props.autocomplete,
      wordBasedSuggestions: props.autocomplete,
    };

    // Requires delay because `monacoRef` changes alot on render and triggers infinite loop
    nextTick(() => {
      if (monacoRef) monacoRef.updateOptions(editorOptions);
    });
    return editorOptions;
  });
  
  /**
   * @description Update editor content on first time load. Set flag to prevent from triggerring again.
   * This only triggers if `code !== ''`. In this case, this code is manually triggered in the setup.
   * @param {String} val - code
   */
  watch(() => props.code, (val) => {
    if (!loadCode.value && typeof val === 'string') {
      loadCode.value = true;
      monacoRef.setValue(val);
    };
  });
  /**
   * @description Fetch themes and define in editor.
   */
  watch(() => props.theme, (val) => {
    if (!themes.value[val]) {
      // Fetch themes
      axios(`/assets/themes/${assetMap.value[val]}.json`)
        .then((data) => {
          // Define and set theme in editor
          themes.value[val] = data.data;
          monaco.editor.defineTheme(val, themes.value[val]);
          monaco.editor.setTheme(val);
        })
    } else if (themes.value[val] !== 'default') {
      // Sets defined theme. For default themes, 'setTheme()' does not need to be called
      monaco.editor.setTheme(val);
    }
  });

  onMounted(() => {
    // References
    clipboard.value = $el.value.querySelector('[studio-editor="clipboard"]');
    
    // Add library TDF
    addTDFs();

    // Add autocomplete
    addAutocomplete();

    // Create and setup monaco editor
    monacoRef = monaco.editor.create(instance.refs.editor, options.value);

    // Get reference to proxy (JavaScript only)
    getProxy();
    
    // Setup the rest of the editor
    setup();
  });

  onUnmounted(() => {
    monaco.editor.getModels().forEach(model => model.dispose());
  });

  /**
   * @description Helper function to add autocomplete data for ZingGrid HTML and CSS
   */
  function _addAutocomplete(language) {
    // Fetch docs
    axios({
      url: language === 'css' ? 'https://cdn.zinggrid.com/docs/elements/css/css.json' : 'https://cdn.zinggrid.com/docs/elements/members/members.json',
      method: 'GET'
    }).then((res) => {
      // Format data provider
      let dataProvider = language === 'css' ? formatCssDataProvider(res.data) : formatHtmlDataProvider(res.data);

      // Add data provider
      monaco.languages[language][`${language}Defaults`].setOptions({
        data: {
          useDefaultDataProvider: true,
          dataProviders: [dataProvider]
        },
      });
    });
  };

  /**
   * @description Add autocomplete data for ZingGrid HTML and CSS
   */
  function addAutocomplete() {
    let language = options.value.language;
    if (language === 'css' || language === 'html') {
      _addAutocomplete(language);
    };
  };

  /**
   * @description Add TDFs for ZingChart and ZingGrid library
   */
  function addTDFs() {
    let isJavascript = options.value.language === 'javascript';
    let tdfAdded = Object.keys(monaco.languages.typescript.javascriptDefaults._extraLibs).length;

    // Add TDF once
    if (isJavascript && tdfAdded === 0) {
      // Enable validation
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
      });

      // Set compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        allowNonTsExtensions: true,
        allowJS: true,
        checkJS: true
      });
      
      // Fetch TDFs
      let zingchartTDF = null;
      axios({
        url: '/api/asset/tdf',
        method: 'GET',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
      }).then((res) => {
        zingchartTDF = res.data;
        axios({
          url: 'https://cdn.zinggrid.com/index.d.ts',
          method: 'GET'
        }).then((response) => {
          // Add TDFs
          let libSource = response.data + zingchartTDF;
          let libUri = 'ts:filename/tdf.d.ts';
          monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);

          // Create model
          monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri));
        });
      });
    }
  };

  /**
   * @description Copies code editor content
   */
  function codeCopy() {
    clipboard.value.value = monacoRef.getValue();
    clipboard.value.select();
    document.execCommand('copy');
  };

  /**
   * @description Toggles code folding
   */
  function codeFold() {
    // Toggle code fold
    if (curFold.value) monacoRef.trigger('fold', 'editor.unfoldAll');
    else monacoRef.trigger('fold', 'editor.foldAll');
    // Update state
    curFold.value = !curFold.value;
    // Update text
    toggleFold.value = curFold.value ? 'Expand' : 'Collapse';
  };

  /**
   * @description Format API docs to CSS data provider
   */
  function formatCssDataProvider(docs) {
    let dataProvider = { version: '1.1', properties: [] };
    for (let i = 0; i < docs.length; i++) {
      dataProvider.properties.push({
        name: docs[i].name,
        description: docs[i].description,
        references: [{
          name: 'Demo Reference',
          url: docs[i].demoLink,
        }, {
          name: 'CSS Reference',
          url: docs[i].cssRefLink,
        }]
      });
    };
    return dataProvider;
  };

  /**
   * @description Converts ZingGrid name to kebab-case
   */
  function convertToZGKebab(tagName) {
    // edge case for main zing-grid tag
    if (tagName === 'ZingGrid') return 'zing-grid';
    let tmpStr = tagName.split('ZG'); // split after zg e.g) ZGColum
    tmpStr = tmpStr[1].replace(/([A-Z])/g, '-$1').toLowerCase();
    return `zg${tmpStr}`;
  };

  /**
   * @description Format API docs to HTML data provider
   */
  function formatHtmlDataProvider(docs) {
    let dataProvider = { version: '1.1', tags: [] };
    for (let el in docs) {
      let kebabFormat = convertToZGKebab(el);
      let tag = {
        name: kebabFormat,
        description: docs[el].elementDescription,
        references: [{
          name: 'Docs Reference',
          url: `https://www.zinggrid.com/docs/api/tags/${kebabFormat}`,
        }],
        attributes: [],
      };

      for (let i = 0; i < docs[el].members.length; i++) {
        let _docs = docs[el].members[i];
        tag.attributes.push({
          name: _docs.name,
          description: _docs.description,
          values: (_docs.displayValues || []).map((val) => {
            return {
              name: val.replace(/\"/g, ''),
            }
          }),
          references: [{
            name: 'Docs Reference',
            url: `https://www.zinggrid.com/docs/api/tags/${kebabFormat}#${_docs.name}`,
          }, {
            name: 'Demo Reference',
            url: _docs.demoLink,
          }],
        });
      };

      dataProvider.tags.push(tag);
    };
    return dataProvider;
  };

  /**
   * @description Get reference to editor proxy.
   * This is for the case where a user uses Typescript and the code needs to be
   * compiled down to JavaScript to preview the demo.
   */
  function getProxy() {
    if (monacoRef && monacoRef.getModel() && options.value.language === 'javascript') {
      monaco.languages.typescript.getJavaScriptWorker()
        .then((worker) => {
          // Check again b/c Studio reloads with user is logged in
          // The original load will attempt to get `uri`, but `monacoRef` would be null
          // The second load will properly get reference to the proxy
          if (monacoRef && monacoRef.getModel()) {
            worker(monacoRef.getModel().uri)
              .then((proxy) => {
                proxy.value = proxy;
              });
          };
        });
    }
  };

  /**
   * @description Setup editor by listening for "change" event to update "code" value
   */
  function setup() {
    monacoRef.onDidChangeModelContent((e) => {
      updateCode();
    });
  };

  /**
   * @description Triggered on editor code change to emit event to allow parent component to
   * get updated editor value (content)
   */
  function updateCode() {
    // Get code from editor
    let changes = monacoRef.getValue();
    nextTick(() => {
      // For JavaScript (make sure TS compiled to JS)
      if (proxy.value && monacoRef && monacoRef.getModel()) {
        proxy.value.getEmitOutput(monacoRef.getModel().uri.toString())
          .then((r) => { 
            changes = beautify(r.outputFiles[0].text, { indent_size: 2 });
            emit('update', props.language, changes);
          });
      } else {
        // Update code
        emit('update', props.language, changes);
      };
    });
  };

  /**
   * @description Update code in editor pane. Call this method when code change occurs
   * programatically (ex. library asset update) and not by a user typing in editor.
   * @param {String} val - code
   */
  function updateEditorCode(val) {
    monacoRef.setValue(val);
  };

  defineExpose({updateEditorCode});
</script>

<style>
.editor,
[studio-editor="container"] {
  height: 100%;
  width: 100%;
}

[studio-editor="clipboard"] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
[studio-editor="container"] {
  position: relative;
}
[studio-editor="controls"] {
  opacity: 0.5;
  position: absolute;
  top: -3px;
  right: 15px;
  z-index: 10;
}
[studio-editor="controls"]:hover {
  opacity: 1;
}
[studio-editor="controls"] > * {
  margin-left: 5px;
}
[studio-editor="controls"] .el-button {
  padding: 2px 7px;
}

.studioEditor_popoverCopy {
  min-width: 90px;
  padding: 10px 0;
  text-align: center;
}
</style>
