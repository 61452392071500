<template>
  <div class="form__item">
    <label class="form__label" id="billing">Additional Billing Information</label>
    <el-input
      v-model="billing_"
      class="form__field"
      for="billing"
      maxlength="200"
      placeholder="Example: accountsToPayable@zingsoft.com"
      :rows="2"
      :show-word-limit="true"
      type="textarea"></el-input>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';

  const emit = defineEmits(['change']);

  const props = defineProps({
    value: {
      type: String
    }
  });
  
  const billing_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const billing = computed(() => {
    return props.value;
  });

  watch(billing, (value) => {
    billing_.value = value;
    dirty.value = false;
  });
  watch(billing_, () => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value)  {
    // Handles changes by the store mutating the event
      dirty.value = true;
      emit('change', {billing: billing_.value});
    } else if (dirty.value) {
      // If the field was modified by user entry
      emit('change', {billing: billing_.value});
    } 
  });

  onMounted(() => {
    billing_.value = props.value;
  });
</script>
