<template>
  <secondary-layout slim="true" unsuccessful :isAuthing="isAuthing">
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow" unsuccessful="container">

        <entry-container slim=true unsuccessful="center">
          <template v-slot:entryHeader>
            <h3>Unsuccessful login</h3>
          </template>

          <template v-slot:entryBody>
            <div unsuccessful="container__body">
            <h1>Unable to log into<br>ZingSoft studio</h1>
              <p>An authentication error has occurred<br>during the process.</p>
              <br>
              <p>Please contact <a href="https://www.zinggrid.com/contact" target="_blank" unsuccessful="link">support</a> or try to log in again.</p>

              <div @click="goHome" class="button  button--bottom  button--center">
                <p><span>Retry</span></p>
              </div>
            </div>
          </template>
        </entry-container>

      </div>
    </section>
  </secondary-layout>
</template>

<script setup>
  import { defineProps } from 'vue';
  import EntryContainer from './components/EntryContainer.vue';
  import SecondaryLayout from '../layouts/Secondary.vue';
  
  const props = defineProps({
    isAuthing: Boolean,
  });

  function goHome() {
    window.location = '/';
  };
</script>

<style lang="css" scoped>
  [unsuccessful] .button--bottom.button--center { background-color: #06A3F5; margin: 2rem auto 0 auto; }
  [unsuccessful] .button--bottom p { color: var(--color-reset); font-size: 0.9375rem; line-height: inherit; }
  [unsuccessful] .entry__actions > div { position: relative; }
  [unsuccessful] h1 { color: var(--header-color); font-size: 1.375rem; margin: 0 auto 1.5rem; max-width: var(--container-width);text-align: center; }
  [unsuccessful] p { color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; margin: auto; }
  [unsuccessful="center"] { align-self: center; }
  [unsuccessful="center"].center--content-inline { display: flex; margin: auto; }
  [unsuccessful="container"] { text-align:center; }
  [unsuccessful="container__body"] { flex: 1; padding: 0.5rem 0 1.5rem; }
  [unsuccessful="link"] { color: var(--color-greyscale-8); font-weight: 900; text-decoration: underline; }
  [unsuccessful="link--unsuccessful" ] a { text-decoration: none; }
  [unsuccessful="link--unsuccessful" ] p { color: var(--color-reset); font-size: 0.9rem; font-weight: 500; letter-spacing: 1px; margin-right: 0.5rem; text-transform: uppercase; }
</style>