import dateComposable from '../../../mixins/date';

export default function rendererComposable({intercomAppId}) {
  const moment = require('moment');
  const { dateFromNow, formatDate } = dateComposable();

  /* Renderers */
  /**
   * @description Renders boolean value as an X or checkmark inside a pill.
   * @param {Boolean} val - boolean value 
   */
  function renderBoolean(val) {
    let className = val ? 'pos' : 'neg';
    let icon = val
      ? `<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.81353 7.21774L0.968732 4.37294L0 5.33485L3.81353 9.14838L12 0.96191L11.0381 0L3.81353 7.21774Z" fill="#1E6F2B"/>
        </svg>` 
      : `<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z" fill="#912541"/>
        </svg>`;
    return `<div class="pill pill__${className}">${icon}</div>`;
  };

  /**
   * @description Render conversation message to display sent user email, date message sent,
   * and message body
   * @param {Number} id - conversation message id
   * @param {String} email - sent user email
   * @param {String} updated - conversation message update date/time
   * @param {String} body - conversation message body
   */
  function renderConversationMessage(id, email, updated, body) {
    return `<div class="messages">
      <div class="messages--header">
        <div class="messages--header--left">

        <div open-user-modal class="text--link">${email}</div>
          <div>${moment(parseInt(updated)*1000).fromNow()}</div>
        </div>
        <div class="messages--header--right">
          <a href="https://app.intercom.com/a/apps/${intercomAppId.value}/inbox/inbox/conversation/${id}]" target="_blank" rel="noreferrer noopener">
            <button type="button" class="el-button el-button--default"><span>View Conversation</span></button>
          </a>
        </div>
      </div>
      <div class="messages--body">
        ${body}
      </div>
    </div>`;
  };

  /**
   * @description Renders date to display date from now and formatted date.
   * Ex. 12 hours ago  9/18/20
   * @param {String} val - date
   */
  function renderDate(val) {
    return `${dateFromNow(val)} <span class="date">${formatDate(val, null, 'M/D/YY')}</span>`;
  };

  /**
   * @description Renders cell to display license domain name and open modal to display domain referer info.
   * @param {String} domain - license domain
   * @param {Number} record - domain record id 
   */
  function renderDomain(domain, record) {
    if (!domain) return null;
    return `<span class="text--link" [open-domain-modal]>${domain}</span>
      <a @click="openModal(${record})" href="https://${domain}" target="_blank" rel="noreferrer noopener">
        <span icon="open" class="container" domain-link="">
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" style="height: 9px; width: 9px;">
            <path d="M6.22222 6.22222H0.777778V0.777778H3.5V0H0.777778C0.346111 0 0 0.35 0 0.777778V6.22222C0 6.65 0.346111 7 0.777778 7H6.22222C6.65 7 7 6.65 7 6.22222V3.5H6.22222V6.22222ZM4.27778 0V0.777778H5.67389L1.85111 4.60056L2.39944 5.14889L6.22222 1.32611V2.72222H7V0H4.27778Z"></path>
          </svg>
        </span>
      </a>`;
  };

  /**
   * @description Used with [cellClass] to render undefined values as NA
   * Ex. NA
   * @param {String} val - value 
   */
  function renderNA(val) {
    return typeof val === 'undefined'   // undefined
      || val === ''   // empty string
      || (typeof val === 'object' && val && val.length === 0)   // empty array
      || (!val && (val !== 0 || val !== false))   // null
      ? 'zg-cell__na' 
      : false;
  };

  /**
   * @description Renders product type inside a pill
   * @param {String} val - ZingGrid or ZingChart 
   */
  function renderProductType(val) {
    function productPill(val) {
      let v = val.toLowerCase();
      let text = v === 'zinggrid' ? 'ZingGrid' : 'ZingChart';
      return `<div class="pill pill__${v}">${text}</div>`;
    };

    if (typeof val === 'object') {
      val = [... new Set(val)];
      let retVal = val.map((v) => productPill(v));
      return `<div class="pill--container">${retVal.join('')}</div>`;
    } else {
      return productPill(val);
    };
  };

  /**
   * @description Renders user cells to display name that links to Studio profile link
   * @param {String} email - User email
   * @param {String} name - User display name
   * @param {Number} id - Studio id
   */
  function renderUser(email, name, id) {
    let displayName = name ? `${email} (${name})` : email;
    return `<a href="/profile/${id}" target="_blank">${displayName}</a>`;
  };

  return {
    renderBoolean,
    renderConversationMessage,
    renderDate,
    renderDomain,
    renderNA,
    renderProductType,
    renderUser,
  };
};