<template>
  <el-dialog
    @close="triggerClose"
    domain-modal
    license-modal
    v-bind="visible"
    width="1200px">

    <template v-slot:header>
      <p class="title">Create License</p>
    </template>

    <el-form v-model="licenseForm">
      <div class="flex">
        <div class="column__left">
          <section>
            <p class="zingnetCard--header required">Type</p>
            <el-select v-model="licenseForm.type">
              <el-option
                v-for="type in licenseTypes"
                :key="type"
                :label="type"
                :value="type"></el-option>
            </el-select>
          </section>
          <section>
            <p class="zingnetCard--header required">Plan</p>
            <el-select v-model="licenseForm.plan" filterable large>
              <el-option
                v-for="license in licenseOptions"
                :key="license"
                :label="license"
                :value="license"></el-option>
            </el-select>
          </section>
          <section v-if="isAnnualLicense">
            <p class="zingnetCard--header required">Expires</p>
            <el-date-picker v-model="licenseForm.expires" type="date"></el-date-picker>
          </section>
          <section>
            <p class="zingnetCard--header required">User</p>
            <p>For "Studio Email/ID" option, provide user's email or studio ID to associate domain to.</p>
            <p>Or select "Admin Account" option to just associate domain to admin account.</p>
            <p>Or select "Create Account" and input customer's email address. This will associate domain to new studio account.</p>
            <el-input placeholder="Please input" v-model="licenseForm.user" class="input-with-select">
              <template v-slot:prepend>
                <el-select @change="updateUserInput" v-model="userInput" placeholder="Select">
                  <el-option key="user" label="Studio Email / ID" value="user"></el-option>
                  <el-option key="admin" label="Admin Account" value="admin"></el-option>
                  <el-option key="create" label="Create Account" value="create"></el-option>
                </el-select>
              </template>
            </el-input>
            <el-button 
              v-if="accountCreateButtonDisplay"
              @click="createAccount"
              :disabled="accountCreateButtonDisabled">
              {{accountCreateButtonText || 'Create Account'}}
            </el-button>
            <el-tag :type="tagType" v-if="accountCreateMessage">{{accountCreateMessage}}</el-tag>
          </section>
          <section v-if="isAdminAccount">
            <p class="zingnetCard--header required">Customer ID</p>
            <p>Provide user's
              <el-popover
                placement="top-start"
                trigger="hover">
                <p>Can be found in Quickbooks customer url: <br/> 
                <code>/customerdetail?nameId=CUSTOMER_ID</code></p>
                <template v-slot:reference>
                  <el-button link>Quickbooks Customer ID</el-button>
                </template>
              </el-popover>.
            </p>
            <el-input v-model="licenseForm.id_quickbooks" placeholder="Customer ID"></el-input>
          </section>
          <section v-if="!isAdminAccount">
            <p class="zingnetCard--header">Quickbooks ID</p>
            <p>Link Studio account by providing 
              <el-popover
                placement="top-start"
                trigger="hover">
                <p>Can be found in Quickbooks customer url: <br/> 
                <code>/customerdetail?nameId=CUSTOMER_ID</code></p>
                <template v-slot:reference>
                  <el-button link>Quickbooks Customer ID</el-button>
                </template>
              </el-popover>.
            </p>
            <el-input v-model="licenseForm.id_quickbooks"></el-input>
          </section>
          <section>
            <p class="zingnetCard--header required">Invoice ID</p>
            <p>Provide  
             <el-popover
              placement="top-start"
              trigger="hover">
              <p>Can be found in Quickbooks invoice url: <br/> 
              <code>/invoice?txnId=INVOICE_ID</code></p>
              <template v-slot:reference>
                <el-button link>Quickbooks invoice ID</el-button>
              </template>
            </el-popover>.
            <el-input v-model="licenseForm.quickbooks_invoice_id"></el-input>
          </p>
          </section>
        </div>

        <div class="column__right">
          <section v-if="isOemLicense">
            <p class="zingnetCard--header required">Company Name</p>
            <el-input v-model="licenseForm.companyName"></el-input>
          </section>
          <section v-if="!isOemLicense">
            <p class="zingnetCard--header required">Register Domains</p>
            <el-form-item
              v-for="(domain, index) in licenseForm.domains"
              class="flex"
              :key="domain.key"
              :prop="'domains.' + index + '.value'">
              <el-input v-model="domain.domain" class="input-with-append">
                <template v-slot:append>
                  <el-checkbox v-model="domain.sub" label="Subdomain"  value="Subdomain"></el-checkbox>
                </template>
              </el-input>
              <el-button @click.prevent="removeDomain(domain)" type="danger" :icon="Delete"></el-button>
            </el-form-item>
            <div>
              <el-button @click="addDomain">Add domain</el-button>
              <el-button @click="resetDomain">Reset domain</el-button>
            </div>
          </section>
          <section>
            <p class="zingnetCard--header">Notes</p>
            <el-input type="textarea" :rows="4" :cols="50" v-model="licenseForm.notes"></el-input>
          </section>
        </div>
      </div>
    </el-form>

    <template v-slot:footer>
      <el-button @click="createLicense" :disabled="disable || !fieldsCompleted">Create License</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, nextTick, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Delete } from '@element-plus/icons-vue'
  import axios from 'axios';
  import adminCreateAccountComposable from '../../mixins/adminCreateAccount';

  const emit = defineEmits(['close']);
  const props = defineProps({
    visible: Boolean,
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const accountCreated = ref(false);
  const accountCreateOption = ref(false);
  const accountCreateButtonDisplay = ref(false);
  const accountCreateButtonText = ref(null);
  const accountCreateMessage = ref(null);
  const tagType = ref(null);

  const { adminCreateAccount } = adminCreateAccountComposable({$global});

  const disable = ref(false);
  const userInput = ref('user');
  const licenseForm = ref({
    type: null,
    id_quickbooks: null,
    companyName: null,
    domains: [{ key: 1, domain: '', sub: false }],
    expires: null,
    quickbooks_invoice_id: null,
    plan: null,
    user: null,
    update: 0,
    notes: null,
  });
  const licenses = ref(['New/Annual:ZC-Enterprise-A', 'New/Annual:ZC-Internal-A', 'New/Annual:ZC-OEM-A', 'New/Annual:ZC-SaaS-A', 'New/Annual:ZC-Website-A', 'New/Annual:ZG-Multi-Domain-A', 'New/Annual:ZG-OEM-A', 'New/Annual:ZG-Single-Domain-A', 'Perpetual:ZC-Enterprise-P', 'Perpetual:ZC-Internal-P', 'Perpetual:ZC-Saas-P', 'Perpetual:ZC-Website-P', 'Perpetual:ZG-Multi-Domain-P', , 'Perpetual:ZG-Single-Domain-P']);
  const licenseTypes = ref(['ZingChart', 'ZingGrid']);

  const accountCreateButtonDisabled = computed(() => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return licenseForm.value.user && !re.test(licenseForm.value.user);                // Check if email format
  });
  const fieldsCompleted = computed(() => {
    return licenseForm.value.type != null
      && licenseForm.value.plan != null
      && licenseForm.value.user != null
      && licenseForm.value.quickbooks_invoice_id != null
      && (!accountCreateOption.value || (accountCreateOption.value && accountCreated.value));
  });
  const isAdminAccount = computed(() => {
    return licenseForm.value.user == 2 || licenseForm.value.user === 'admin@zingsoft.com' || userInput.value === 'admin';
  });
  const isAnnualLicense = computed(() => {
    if (licenseForm.value.plan) {
      return licenseForm.value.plan.includes('-A');
    } else {
      licenseForm.value.expires = null;
    };
    return false;
  });
  const isOemLicense = computed(() => {
    return licenseForm.value.plan && licenseForm.value.plan.includes('OEM');
  });
  const licenseOptions = computed(() => {
    if (licenseForm.value.type) {
      let zingchartLicenses = licenses.value.filter((license) => license.includes(':ZC-'));
      let zinggridLicenses = licenses.value.filter((license) => license.includes(':ZG-'));
      return licenseForm.value.type.toLowerCase() === 'zingchart' ? zingchartLicenses : zinggridLicenses;
    };
    return [];
  });
  const showLicenseField = computed(() => {
    if (licenseForm.value.type) {
      let isZingChartLicense = licenseForm.value.type.toLowerCase() === 'zingchart';
      let isNotOEM = !licenseForm.value.type.includes('-OEM-');
      return isZingChartLicense && isNotOEM;
    } else {
      licenseForm.value.license = null;
    };
    return false;
  });

  
  function clearUserInput() {
    disable.value = false;
    userInput.value = 'user';

    accountCreated.value = false;
    accountCreateOption.value = false;
    accountCreateButtonDisplay.value = false;
    accountCreateButtonText.value = null;
    accountCreateMessage.value = null;
    tagType.value = null;

    licenseForm.value.type = null;
    licenseForm.value.id_quickbooks = null;
    licenseForm.value.companyName = null;
    licenseForm.value.domains = [{ key: 1, domain: '', sub: false }],
    licenseForm.value.expires = null;
    licenseForm.value.quickbooks_invoice_id = null;
    licenseForm.value.plan = null;
    licenseForm.value.user = null;
    licenseForm.value.update = 0;
    licenseForm.value.notes = null;
  };

  function createLicense() {
    disable.value = true;
    // Validate
    if (validateForm()) {
      // Process license request
      processRequest();
    };
  };

  function processRequest() {
    // Format date into form that database can process
    if (licenseForm.value.expires) {
      // Format date as YYYY-MM-DD hh:mm:ss
      let date = new Date(licenseForm.value.expires);
      licenseForm.value.expires =  date.toISOString().replace('T', ' ').replace('.000Z', '');
    };

    let promises = licenseForm.value.companyName
      ? [licenseForm.value.companyName]
      : licenseForm.value.domains;

    Promise.all(
      promises.map(async (domain) => {
        if (domain && domain.domain !== '') {
          let body = JSON.parse(JSON.stringify(licenseForm.value));

          // Associate to user
          body[body.user.includes('@') ? 'email' : 'id_user'] = body.user;
          if (body.id_user) body.id_user = parseInt(body.id_user);

          if (body.domains) {
            // Registered domain/subdomains
            body.domain = domain.domain;
            body.sub = domain.sub;
          };

          // Removed unused fields
          if (body.domains) delete body.domains;
          delete body.user;
          
          return await createLicenseRequest(body);
        };
      })
    ).then(() => {
      // Success message
      $message({
        message: 'License Created!',
        type: 'success',
      });
      nextTick(() => {
        disable.value = false;
        triggerClose();
      });
    });
  };

  function createAccount() {
    adminCreateAccount(licenseForm.value.user)
      .then(() => {

        // Success message
        $message({
          message: 'Account Created!',
          type: 'success',
        });

        // Set flags to continue process
        accountCreated.value = true;
        accountCreateMessage.value = 'Account successfully created';
        tagType.value = 'success';
      }).catch((err) => {
        // Error message
        let errMessage = `Error occurred while creating account: ${err}`;
        if (err && err.response && err.response.data) errMessage = err.response.data;
        $message({
          message: errMessage,
          type: 'error',
        });

        accountCreated.value = false;
        accountCreateMessage.value = errMessage;
        tagType.value = 'danger';
      });
  };

  function createLicenseRequest(request) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/api/license',
        method: 'POST',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
        data: request,
      }).then(() => {
        resolve(true);
      }).catch((err) => {
        disable.value = false;
        // Error message
        let errMessage = `Error occurred while creating license: ${err}`;
        $message({
          message: errMessage,
          type: 'error',
        });
        reject(false);
      });
    });
  };

  function triggerClose() {
    clearUserInput();
    emit('close');
  };

  function updateUserInput(val) {
    // Option "admin": Autofill admin email
    licenseForm.value.user = val === 'admin' ? 'admin@zingsoft.com' : '';
    // Option "create": Display create account button
    accountCreateButtonDisplay.value = val === 'create' ? true : false;
    accountCreateOption.value = val === 'create' ? true : false;
  };

  function validateForm() {
    let err = null;
    let nonEmptyDomain = licenseForm.value.domains.filter((domain) => domain.domain !== '');

    if (!licenseForm.value.type) {
      err = 'Missing license type';
    } else if (!licenseForm.value.plan) {
      err = 'Missing license plan';
    } else if (!licenseForm.value.user) {
      err = 'Missing user email or ID'
    } else if (!licenseForm.value.id_quickbooks && isAdminAccount.value) {
      err = 'Missing user\'s Quickbooks ID'
    } else if (!licenseForm.value.quickbooks_invoice_id) {
      err = 'Missing Quickbooks invoice ID';
    } else if (isAnnualLicense.value && !licenseForm.value.expires) {
      err = 'Missing license expiration date';
    } else if (nonEmptyDomain.length === 0 && !licenseForm.value.companyName) {
      err = 'Missing domain or company name to regsiter';
    };

    if (err) {
      disable.value = false;
      $message({
        message: err,
        type: 'error',
      });
      return false;
    };
    return true;
  };

  function removeDomain(item) {
    let index = licenseForm.value.domains.indexOf(item);
    if (index !== -1) {
      licenseForm.value.domains.splice(index, 1);
    }
  };

  function addDomain() {
    licenseForm.value.domains.push({
      key: Date.now(),
      domain: '',
      sub: false,
    });
  };

  function resetDomain() {
    licenseForm.value.domains = [{ key: 1, domain: '', sub: false }];
  };
</script>

<style scoped>
  p {
    word-break: break-word;
  }
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>

<style>
  :root {
    --domain-modal-padding: 1.75rem 1.875rem;
  }

  [domain-modal] .el-dialog {
    border-radius: 10px;
    padding-right: 0 !important;
  }

  [domain-modal] .el-dialog__header {
    border-bottom: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }
  [domain-modal] .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  [domain-modal] .el-dialog__body {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    padding: 0;
  }

  [domain-modal] .el-dialog__footer {
    border-top: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }

  [domain-modal] .zingnetCard--header {
    display: block;
  }

  [domain-modal] .el-input + .el-input {
    margin-top: 1rem;
  }
  [domain-modal] .el-select {
    min-width: 50px;
    width: 100%;
  }
  [license-modal] .el-button {
    margin-top: 0;
  }

  [license-modal] .el-select ~ .el-input {
    margin-top: 1rem;
  }

  .zingnetCard [license-modal] .el-date-editor {
    padding: 0;
  }

  .zingnetCard [license-modal] .el-date-editor input {
    padding: 1.375rem 2.063rem;
  }

  .zingnetCard [license-modal] .el-input__prefix {
    top: 3px;
  }
  
  .zingnetCard [license-modal] .el-input-group--prepend .el-input-group__prepend {
    min-width: 150px;
    padding: 0;
  }

  .zingnetCard [license-modal] .el-input-group--append .el-input-group__append {
    border: 0;
    min-width: 150px;
  }

  .zingnetCard [license-modal] .el-form-item.flex .el-form-item__content {
    flex-flow: row;
  }

  .zingnetCard [license-modal] .el-form-item__content .el-input-group {
    display: flex;
  }

  .zingnetCard [license-modal] .el-form-item.flex .el-form-item__content .el-button {
    height: 100%;
  }
</style>