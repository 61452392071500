<template>
  <aside
    :create-sidebar="show"
    :open="sidebarOpen">
    <app-sidebar
      :active-primary="activePrimary"
      :open="sidebarOpen"
      :sidebars="sidebarPrimary">
    </app-sidebar>
  </aside>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, nextTick, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import AppSidebar from '../../../components/AppSidebar.vue';

  const emit = defineEmits(['resize', 'show']);

  const props = defineProps(['show', 'uid']);

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $store = useStore();
  const activePrimary = ref('docs');
  const sidebarPrimary = ref({
    'docs': {
      id: 'docs',
      sidebar: [
        {
          id: 'docs',
          name: 'Related Docs'
        }
      ],
    },
  });

  const sidebarOpen = computed(() => {
    let count = 0;
    let interval = setInterval(() => {
      emit('resize');
      count++;
      if (count === 10) clearInterval(interval);
    }, 100);
    return $store.state.ui['isSidebarOpen'];
  });

  watch(() => props.uid, () => {
    fetchRelatedDocs(props.uid);
  });

  /**
   * @description Format text to capitalize first letter of each word
   * @param {String} text - text to format
   */
  function capitalize(text) {
    let retval = '';
    let words = text.split(' ');
    words.forEach((word, index) => {
      if (index !== 0) retval += ' ';
      retval += `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
    });
    return retval;
  };

  /**
   * @description Add class to enable sidebar transition
   */
  function enableTransition() {
    nextTick(() => {
      let el = document.querySelector('.content--hasSidebar--wide');
      if (el) el.classList.add('ready');
    });
  };
  
  /**
   * @description Fetch relatedDocs metadata to populate sidebar
   */
  function fetchRelatedDocs(uid) {
    $api('metadata/read', {
      uid,
      format: 'sidebar',
    }, $global).then((result) => {
      let relatedDocs = result.data.relatedDocs;
      let sidebar = sidebarPrimary.value.docs.sidebar;

      if (relatedDocs && relatedDocs.length > 0) {
        // Add related docs sidebar items
        relatedDocs.forEach((doc) => {
          sidebar.push({
            id: doc.name,
            name: capitalize(doc.name),
            url: doc.url,
          })
        });

        emit('show');
        enableTransition();
      } else {
        // Placeholder for no docs
        sidebar.push({
          id: `na_${(new Date).valueOf()}`,
          name: 'N/A',
        });
      }
    });
  };
</script>

<style>
  /* Hide Sidebar */
  [create-sidebar="hidden"] {
    max-width: 0;
    overflow: hidden;
    transform: translate3d(0,0,0);
  }
</style>