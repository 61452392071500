<template>
  <secondary-layout slim=true>
    <section class="content__wrap content__wrap--login">
      <div class="content content--narrow">
        <entry-container form=false slim=true verify>
          <template v-slot:entryHeader>
            <h3>Email Verified</h3>
          </template>

          <template v-slot:entryBody>
            <div entryContainer="entry__content__inner">
              <h1>Welcome to ZingSoft!</h1>
              <p>Thank you for creating an account.</p>
              <p>To continue, please verify your email.</p>
              <div @click="goHome" class="button button--bottom">
                <p>Verify Email</p>
              </div>
            </div>
          </template>
        </entry-container>
      </div>
    </section>
  </secondary-layout>
</template>

<script setup>
  import EntryContainer from './components/EntryContainer.vue';
  import SecondaryLayout from '../layouts/Secondary.vue';
  
  function goHome() {
    window.location = '/';
  };
</script>

<style>
  [entryContainer][verify] .button--bottom { margin: 2rem auto 1.5rem; width: 11rem; }
  [verify] .button--bottom p { color: var(--color-reset); font-size: 0.8125rem; margin: auto; }
  [verify="container__body"] { flex: 1; padding: 0.5rem 0; }
  [verify] h1 { color: var(--header-color); font-size: 1.375rem; margin: 2rem auto 0.5rem; max-width: var(--container-width); text-align: center; }
</style>
