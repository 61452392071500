<template>
  <el-button lockButton class="button" :size="size" :type="type" v-on:click="login">
    <slot>Login</slot>
  </el-button>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['beforeLogin']);
  
  const props = defineProps({
    'size': String,
    'type': String,
  });

  const $store = useStore();

  const lock = computed(() => {
    return $store.state.auth.lock;
  });
  const tenantName = computed(() => {
    return $store.state.tenant.name;
  });
  const tenantLogo = computed(() => {
    return $store.state.tenant.logo;
  });
  
  onMounted(() => {
    $store.commit('auth/init_lock');
  });
  
  function login(event) {
    emit('beforeLogin');

    if (lock.value) {
      // Open auth0 widget if initialize
      lock.value.show({
        initialScreen: 'signUp'
      });
    } else {
      // Retry initialization and open
      $store.commit('auth/init_lock', true);
    }
  };
</script>

<style lang="css" >
</style>
