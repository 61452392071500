import { computed, ref } from 'vue';

export default function purchaseStepsComposable({$global, activePlan, activePlanIndex, cardBrand, planDialogVisible,
  pricingInterval, productSubscriptions, productType, subscriptions, userId, view}) {
  
  const disableCompany = computed(() => {
    return company.value === '';
  });
  const plans = computed(() => {
    let products = [];
    productSubscriptions.value.forEach(product => {
      product.plans.forEach(plan => {
        if(plan.interval === 'perpetual' && pricingInterval.value) {
          // Set plan as a top level for that interval
          product.plan = plan;
          products.push(product);
        } else if(plan.interval === 'year' && !pricingInterval.value){
          product.plan = plan;
          products.push(product);
        }
      });
    });
    // Sort by price.
    let sorted = products.sort((a, b) => {
      if (a && b) return a.UnitPrice > b.UnitPrice ? 1 : -1;
    });
    sorted.some((product, index) => {
      if (product.metadata.display_on_website ) {
        return sorted
      }
    });
    return sorted;
  });
  const intervalDisplay = computed(() => {
    if (activePlan.value) {
      if (activePlan.value.plan.interval === 'year') return '/ year';
      return 'one time';
    }
  });

  const planSteps = ref(0);
  const activeName = ref(0);
  const domain = ref('');
  const domainField = ref([]);
  const domainObj = ref({
    newDomain: '',
  });
  const selectedPlan = ref(null);
  const selectedSubscription = ref(null);
  const dialogDomainForm = ref({
    dialogNewDomain: '',
    dialogNewSubdomain: '',
  });
  const dialogNewDomains = ref([]);
  const dialogNewSubdomains = ref([]);
  const company = ref('');
  const coupon = ref(null);
  const updateDomainSubscription = ref(null);
  const subscribeLoading = ref(false);
  const licensePurchasedDialogVisible = ref(false);

  function getUserSubscriptions() {
    // Obtain customer's subscriptions
    $global.$api('subscription/list', {}, $global)
      .then((result) => {
        if (result.data) {
          // Add fields in `domainField` form for each doamin
          domainField.value = [];
          for(let i = 0; i < result.data.length; i++) {
            domainField.value.push(Object.assign({}, domainObj.value));
          };
          subscriptions.value = result.data;
        }
      })
      .catch((err) => {
        console.log(err)
        $global.$message({
          duration: 0,
          message: 'Unable to locate your subscription data. Please contact us.',
          showClose: true,
          type: 'error',
        });
      });
  };

  function addSubscription(planSku, domains, subdomains, datalayerObj) {
    subscribeLoading.value = true;
    // Add subscription
    $global.$api('subscription/add', {
      planSku,
      domains,
      subdomains,
      coupon: coupon.value,
    }, $global)
    .then((result) => {
      if (result.status === 202) {
        $global.$message({
          message: 'Subscription added, but some domains/subdomains were not registered.',
          showClose: true,
          type: 'warning',
        });
      } else {
        $global.$message({
          message: 'Subscription added!',
          showClose: true,
          type: 'success',
        });
      }

      // Push data to datalayer
      datalayerObj['purchase-status'] = 'success';
      datalayerObj['transaction-id'] = result.data.Id;
      window.dataLayer.push(datalayerObj);

      // End process by displaying confirmation page
      endSubscriptionProcess(datalayerObj);
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 400) {
        $global.$message({
          message: `Subscription added. ${error.response.data}`,
          showClose: true,
          type: 'warning',
        });

        // Push data to datalayer
        datalayerObj['purchase-status'] = 'fail';
        window.dataLayer.push(datalayerObj);

        endSubscriptionProcess(datalayerObj);
      } else {
        $global.$message({
          duration: 0,
          message: `Cannot subscribe. ${error.response.data}`,
          showClose: true,
          type: 'error',
        });

        // Push data to datalayer
        datalayerObj['purchase-status'] = 'fail';
        window.dataLayer.push(datalayerObj);
      }
      subscribeLoading.value = false;
      // planDialogVisible.value = false;
    });
  };

  function renewSubscription(renewalName, renewalSku, sku, datalayerObj, noDataObject) {
    return new Promise((resolve, reject) => {
      subscribeLoading.value = true;
      // Add subscription
      $global.$api('subscription/renew', {
        renewalName,
        renewalSku,
        sku,
      }, $global)
      .then((result) => {
        $global.$message({
          message: 'License renewed!',
          showClose: true,
          type: 'success',
        });

        // Push data to datalayer
        if (datalayerObj && !noDataObject) {
          datalayerObj['purchase-status'] = 'success';
          datalayerObj['transaction-id'] = result.data.Id;
          window.dataLayer.push(datalayerObj);

          // End process by displaying confirmation page
          endSubscriptionProcess(datalayerObj, noDataObject);
        };
        resolve(true);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          $global.$message({
            message: `License renewed. ${error.response.data}`,
            showClose: true,
            type: 'warning',
          });

          // Push data to datalayer
          if (datalayerObj) {
            datalayerObj['purchase-status'] = 'fail';
            window.dataLayer.push(datalayerObj);

            endSubscriptionProcess(datalayerObj, noDataObject);
          } else {
            placingOrder.value = false;
          };
          reject(error);
        } else {
          $global.$message({
            duration: 0,
            message: `Cannot renew. ${error.response.data}`,
            showClose: true,
            type: 'error',
          });

          // Push data to datalayer
          if (datalayerObj) {
            datalayerObj['purchase-status'] = 'fail';
            window.dataLayer.push(datalayerObj);
          } else {
            placingOrder.value = false;
          };
        }
        subscribeLoading.value = false;
        reject(error);
      });
    });
  };

  function nextStep1() {
    // Advance to next form
    planSteps.value++;

    // Push data to datalayer
    window.dataLayer.push({
      'event': 'subscriptionCheckout',
      'checkout-step': '1',
      'checkout-name': 'plan',
      'product-name': expandProductType(),
      'license-id': activePlan.value.Sku.toUpperCase(),
      'license-interval':  pricingInterval.value ? 'perpetual' : 'yearly',
      'license-type': activePlan.value.name.toLowerCase(),
      'license-price': activePlan.value.plan.UnitPrice,
      'user-id': userId.value,
    });
  };

  function nextStep2() {
    // Update user company info (if available) and 
    updateCompany();
    
    // Advance to next step
    planSteps.value++;

    // Push data to datalayer
    window.dataLayer.push({
      'event': 'subscriptionCheckout',
      'checkout-step': '2',
      'checkout-name': 'subscription-info',
      'product-name': expandProductType(),
      'license-id': activePlan.value.Sku.toUpperCase(),
      'license-interval': pricingInterval.value ? 'perpetual' : 'yearly',
      'license-type': activePlan.value.name.toLowerCase(),
      'license-price': activePlan.value.plan.UnitPrice,
      'license-domains-count': dialogNewDomains.value.length,
      'license-domains-name': JSON.parse(JSON.stringify(dialogNewDomains.value)),
      'license-subdomains-count': dialogNewSubdomains.value.length,
      'license-subdomains-name': JSON.parse(JSON.stringify(dialogNewSubdomains.value)),
      'license-company': company.value,
      'user-id': userId.value,
    });
  };

  function nextStep3(sku, planName, domains, subdomains, forcePurchase) {
    let datalayerObj = {
      'event': 'subscriptionCheckout',
      'checkout-step': '3',
      'checkout-name': 'subscribe',
      'product-name': expandProductType(),
      'license-id': activePlan.value.Sku.toUpperCase(),
      'license-interval': pricingInterval.value ? 'perpetual' : 'yearly',
      'license-type': activePlan.value.name.toLowerCase(),
      'license-price': activePlan.value.plan.UnitPrice,
      'license-domains-count': dialogNewDomains.value.length,
      'license-domains-name': JSON.parse(JSON.stringify(dialogNewDomains.value)),
      'license-subdomains-count': dialogNewSubdomains.value.length,
      'license-subdomains-name': JSON.parse(JSON.stringify(dialogNewSubdomains.value)),
      'license-company': company.value,
      'card_type': cardBrand.value,
      'purchase-status': null,
      'transaction-id': null,
      'user-id': userId.value,
    };

    if (forcePurchase) {
      // Already did check in "else" case and customer confirm purchase
      addSubscription(sku, domains, subdomains, datalayerObj);
    } else {
      // Check if user already subscribed to annual license
      $global.$api('subscription/list', {}, $global)
        .then((result) => {
          let subscriptions = (result.data || []).map((subscription) => subscription.planName.split(':').pop());
          let duplicate = (subscriptions.indexOf(planName)) !== -1;
          let isAnnual = planName.endsWith('-A');

          if (duplicate && isAnnual) {
            // Prompt user if they want to purchase or pay renewal
            licensePurchasedDialogVisible.value = true;
          } else {
            // Create new subscription and push data to datalayer
            addSubscription(sku, domains, subdomains, datalayerObj);
          };
        });
    };
  };

  function renewalStep(sku, planName, noDataObject) {
    return new Promise((resolve, reject) => {
      // Get renewal version of annual license
      let renewalSku = sku.replace('-S-', '-').replace(/-A$/g, '-R');
      let renewalName = planName.replace(/-A$/g, '-R');

      let datalayerObj = noDataObject
        ? null 
        : {
          'event': 'subscriptionCheckout',
          'checkout-step': '3',
          'checkout-name': 'subscribe',
          'product-name': expandProductType(),
          'license-id': renewalSku.toUpperCase(),
          'license-interval': 'yearly',
          'license-type': renewalName.toLowerCase(),
          'license-price': activePlan.value.plan.UnitPrice,
          'license-domains-count': dialogNewDomains.value.length,
          'license-domains-name': JSON.parse(JSON.stringify(dialogNewDomains.value)),
          'license-subdomains-count': dialogNewSubdomains.value.length,
          'license-subdomains-name': JSON.parse(JSON.stringify(dialogNewSubdomains.value)),
          'license-company': company.value,
          'card_type': cardBrand.value,
          'purchase-status': null,
          'transaction-id': null,
          'user-id': userId.value,
        };

      renewSubscription(renewalName, renewalSku, sku, datalayerObj, noDataObject)
        .then((response) => {
          resolve(response);

        }).catch((error) => {
          reject(error)
        });
    });
  };

  /**
  * @description Ends subscription process by updating user subscription view, increment
  * subscription step, and reset variables
  */
  function endSubscriptionProcess(datalayerObj, noDataObject) {
    // Update subscriptions (to display newly purchased)
    getUserSubscriptions();
    // Display confirmation page
    if (planSteps && planSteps.value) planSteps.value++;
    if (view && view.value) view.value++;
    // Clear flags
    coupon.value = null;
    subscribeLoading.value = false;

    if (!noDataObject) {
      // Push data to datalayer
      let newDatalaterObj = Object.assign({}, datalayerObj, {
        'event': 'subscriptionCheckout',
        'checkout-step': '4',
        'checkout-name': 'finish',
      });
      window.dataLayer.push(newDatalaterObj);
    };
  };

  function expandProductType() {
    return productType.value === 'ZC' ? 'zingchart' : 'zinggrid';
  };

  function openPlanDialog(type) {
    // Open dialog
    planDialogVisible.value = true;
    // Determine which products to display
    productType.value = type;
    // Set active plan index to highlight first product
    activePlanIndex.value = type === 'ZC' ? 0 : 1;
    // Push data to dataLayer
    window.dataLayer.push({
      'event': 'clickSubscription',
      'checkout-step': '0',
      'product-name': expandProductType(),
      'user-id': userId.value,
    });
  };

  function updateCard(val) {
    cardBrand.value = val;
  };

  function updateCompany() {
    // Update company info (if given)
    if (company.value) {
      $global.$api('user/update', {company: company.value}, $global)
        .then(() => {
          $global.$store.dispatch('refresh_state');
        })
        .catch((err) => {
          $global.$message({
            duration: 0,
            message: 'Could not update company',
            showClose: true,
            type: 'error',
          });
        });
    };
  };

  return {
    disableCompany,
    plans,
    intervalDisplay,
    planSteps,
    activeName,
    domain,
    domainField,
    domainObj,
    selectedPlan,
    selectedSubscription,
    dialogDomainForm,
    dialogNewDomains,
    dialogNewSubdomains,
    company,
    coupon,
    updateDomainSubscription,
    subscribeLoading,
    licensePurchasedDialogVisible,
    getUserSubscriptions,
    addSubscription,
    renewSubscription,
    nextStep1,
    nextStep2,
    nextStep3,
    renewalStep,
    endSubscriptionProcess,
    expandProductType,
    openPlanDialog,
    updateCard,
    updateCompany
  };
};
