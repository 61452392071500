<template>
  <zingnet-card :gridBody="true">
    <template v-slot:header>
      <span>
        CDN Tracker for Library Usage
        <date-range @updateDateRange="updateDateRange" :additional-items="dateRangeItems" :group-name="dateRangeGroupName"></date-range>
      </span>
    </template>
    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">ZingChart CDN Top Referer Domains</h3>
            <ol v-if="cdnData" class="zingnetCard--list">
              <li v-for="li in cdnData.zingchartDomains" :key="li.id">
                <p @click="openModal(li)" class="zingnetCard--list--item">{{li.domain}}</p>
                <bubble-count :count="li.hits"></bubble-count>
                <a :href="`https://${li.domain}`" target="_blank" rel="noreferrer noopener">
                  <svg-icon domain-link icon="open" :height="outLinkSize" :width="outLinkSize"></svg-icon>
                </a>
              </li>
            </ol>
          </section>

          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">ZingGrid CDN Top Referer Domains</h3>
            <ol v-if="cdnData" class="zingnetCard--list">
              <li v-for="li in cdnData.zinggridDomains" :key="li.id">
                <p @click="openModal(li)" class="zingnetCard--list--item">{{li.domain}}</p>
                <bubble-count :count="li.hits"></bubble-count>
                <a :href="`https://${li.domain}`" target="_blank" rel="noreferrer noopener">
                  <svg-icon domain-link icon="open" :height="outLinkSize" :width="outLinkSize"></svg-icon>
                </a>
              </li>
            </ol>
          </section>

          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">CDN Usage <bubble-count v-if="cdnData" :comparison-cur="cdnData.weekHits" :comparison-prev="cdnData.prevWeekHits"></bubble-count></h3>
            <div class="zingnetCard--row">
              <score-card-group v-if="cdnData">
                <template v-slot:card_one>
                  <score-card :value="cdnData.weekHits" :title="`hits in last ${daysDiff} days`"></score-card>
                </template>
                <template v-slot:card_two>
                  <score-card :value="cdnData.prevWeekHits" :title="`hits in previous ${daysDiff} days`" :secondary="true"></score-card>
                </template>
              </score-card-group>
            </div>
            <div class="zingnetCard--row no-border">
              <div id="chart-cdnUsage"></div>
            </div>
          </section>
        </section>

        <section class="zingnetCard--row no-border">
          <zing-grid
            id="zingNet_cdnTracker"
            data='[{"hits": null, "domain": null, "uris": null}]'
            filter="true"
            height=650
            pager="true"
            page-size=10
            selector="true"
            sort="true">
            <zg-colgroup>
              <zg-column index="hits" filter="disabled" type="number" width="10%"></zg-column>
              <zg-column index="domain" renderer="renderDomain" width="30%"></zg-column>
              <zg-column index="uris" header="URI" width="60%" :hidden="hiddenUri"></zg-column>
              <zg-column index="first_seen" type="date" width="60%" :hidden="hiddenSeen"></zg-column>
            </zg-colgroup>
          </zing-grid>
        </section>

        <domain-modal
          @openUserModal="showUserDetails"
          @close="visible=false"
          :domain-obj="domainObj"
          v-model="visible">
        </domain-modal>

        <user-modal
          @close="userModalVisible=false"
          :company="company"
          :email="email"
          :id="userId"
          :quickbooks-id="quickbooksId"
          v-model="userModalVisible">
        </user-modal>

      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { onMounted, nextTick, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import BubbleCount from '../BubbleCount.vue';
  import DateRange from '../DateRange.vue';
  import DomainModal from './DomainModal.vue';
  import ScoreCard from '../ScoreCard.vue';
  import ScoreCardGroup from '../ScoreCardGroup.vue';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import UserModal from '../UserModal.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import dateComposable from '../../../../mixins/date.js';
  import dateRangeComposable from '../../mixins/dateRange.js';
  import rendererComposable from '../../mixins/renderer.js';
  import userModalComposable from '../../mixins/userModal.js';

  const $store = useStore();
  const { dateDiff, dateList, dateRange } = dateComposable();
  const { start, end, prevStart, prevEnd, custom, updateDateRange }
    = dateRangeComposable({getReportData, getReportDataCustom});
  const { renderDomain } = rendererComposable({});
  const { company, email, quickbooksId, userId, userModalVisible, openUserModal }
    = userModalComposable({$store});

  const chartConfig = ref({
    type: 'line',
    plot: {
      animation: {
        effect: 'ANIMATION_EXPAND_LEFT',
        method: 'ANIMATION_LINEAR',
        sequence: 'ANIMATION_NO_SEQUENCE',
        speed: 1000,
      },
      lineWidth: '1.5px',
      marker: {
        visible: false,
      },
    },
    plotarea: {
      margin: '5px 0 25px 0',
    },
    scaleX: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '12px',
        rules: [{
          rule: '%v == 0',
          offsetX: 20,
        }, {
          rule: '%v == 7',
          offsetX: -20,
        }]
      },
      guide: {
        visible: false,
      },
      labels: [],
      lineColor: 'var(--zingnet-border-color)',
      showLabels: [],
      tick: {
        visible: false,
      },
    },
    scaleY: {
      guide: {
        visible: false,
      },
      visible: false,
    },
    series: [
      {
        values: [],
        lineColor: 'var(--color-secondary-blue)',
      },
      {
        values: [],
        lineColor: 'var(--color-primary-gray-visited)',
      }
    ]
  });
  const cdnData = ref(null);
  const dateRangeItems = ref([{label: 'Newest', value: 'first_seen-desc'}, {label: 'Oldest', value: 'first_seen-asc'}, {label: 'High Usage', value: 'hits_total-desc'}]);
  const dateRangeGroupName = ref('All Time');
  const daysDiff = ref(null);
  const domainObj = ref(null);
  const hiddenSeen = ref(true);
  const hiddenUri = ref(false);
  const loading = ref(false);
  const outLinkSize = ref('9');
  const visible = ref(false);
  
  onMounted(() => {
    ZingGrid.registerMethod(renderDomain, 'renderDomain');
  });

  function getReportData() {
      nextTick(() => {
      // Get number of days
      daysDiff.value = dateDiff(start.value, end.value, 'YYYY-MM-DD', 'days');

      loading.value = true;

      // Get report
      axios({
        url: `/api/cdn_log?start=${start.value}&end=${end.value}&prevStart=${prevStart.value}&prevEnd=${prevEnd.value}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${$store.state.auth.idToken}`,
        }
      })
      .then((response) => {
        hiddenSeen.value = true;
        hiddenUri.value = false;

        // Set report data
        cdnData.value = response.data;
        let series = chartConfig.value.series;
        series[0].values = [...new Array(daysDiff.value + 1 - cdnData.value.weekSeries.length).fill(0), ...cdnData.value.weekSeries];
        series[1].values = [...new Array(daysDiff.value + 1 - cdnData.value.prevWeekSeries.length).fill(0), ...cdnData.value.prevWeekSeries];
        chartConfig.value.scaleX.item.rules[1].rule = `%v == ${daysDiff.value}`;

        nextTick(() => {
          initChart();
          initGrid(cdnData.value.logs);
        });
      });
    });
  };

  function getReportDataCustom() {
    nextTick(() => {
      loading.value = true;

      let [sortBy, sortDir] = custom.value.split('-');

      // Get report
      axios({
        url: `/api/cdn_domain/list?sortBy=${sortBy}&sortDir=${sortDir}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${$store.state.auth.idToken}`,
        }
      })
      .then((response) => {
        hiddenSeen.value = false;
        hiddenUri.value = true;

        function mapHitsProp(arr) {
          return arr.map((entry) => {
            entry.hits = entry.hits_total;
            return entry;
          });
        };

        // Set report data
        cdnData.value.zingchartDomains = mapHitsProp(response.data.topZingChart.entries);
        cdnData.value.zinggridDomains = mapHitsProp(response.data.topZingGrid.entries);

        // Format grid data
        let gridData = mapHitsProp(response.data.records.entries);

        nextTick(() => {
          initGrid(gridData);

          loading.value = false;
        });
      });
    });
  };

  function getRecentLogs() {
    axios({
      url: '/api/admin/cdn/report',
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      }
    })
    .then((response) => {
      // Set data
      let zgRef = document.getElementById('zingNet_cdnTracker');
      zgRef.setData(response.data.records);
    });
  };

  function initChart() {
    // Determine scale labels
    let labels = dateList({
      end: end.value,
      step: 'days',
      stepNum: 1, 
      add: daysDiff.value, 
      inputFormat: 'YYYY-MM-DD', 
      outputFormat: 'MMM D'
    });
    chartConfig.value.scaleX.labels = labels;
    chartConfig.value.scaleX.showLabels = [labels[0], labels[labels.length-1]];

    // Render ZingChart
    zingchart.render({
      id: 'chart-cdnUsage',
      data: chartConfig.value,
      height: '80px',
      width: '414px',
    });

    loading.value = false;
  };

  function initGrid(data) {
    let zgRef = document.getElementById('zingNet_cdnTracker');

    if (zgRef) {
      // Event listener domain text click
      zgRef.addEventListener('row:click', (e) => {
        let target = e.detail.ZGEvent.oDOMTarget;
        let attrArr = Object.values(target.attributes).map((attr) => attr.name);
        if (attrArr.includes('[open-domain-modal]')) {
          openModal(e.detail.ZGData.data);
        };
      });

      // Set data
      zgRef.setData(data);
    };
  };

  function openModal(domainObj) {
    domainObj.value = domainObj;
    visible.value = true;
  };

  function showUserDetails(obj) {
    quickbooksId.value = obj.quickbooksId;
    openUserModal(obj);
  };
</script>

<style scoped>
  [domain-link] {
    fill: var(--color-primary-gray);
    margin: 0 0.3125rem;
  }
  [domain-link]:hover {
    cursor: pointer;
    fill: var(--color-tertiary-blue-focus);
  }
  li {
    display: flex;
  }
  li > p {
    margin: 0;
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .zingnetCard--column {
    min-height: 265px;
    min-width: 330px;
  }
  .zingnetCard--column:nth-child(3) {
    min-width: 476px;
  }
</style>