<template>
  <div ref="$el">
    <!-- Scroll Checkbox -->
    <label v-if="type === 'scroll'" id="toggleCheck" data-field="checkbox" zing-theme-builder="toggle-check">
      <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
      <span zing-theme-builder="toggle-label">{{checkVal ? "Scroll Enabled" : "Scroll Disabled"}}</span>
    </label>
    <!-- Guide Checkbox -->
    <label v-else-if="type === 'guide'" id="guideCheck" data-field="checkbox" zing-theme-builder="toggle-check">
      <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
      <span zing-theme-builder="toggle-label">{{checkVal ? "Guide Enabled" : "Guide Disabled"}}</span>
    </label>
    <!-- Feature Checkall Checkbox -->
    <label v-else-if="type === 'checkall'" id="featureCheck" data-field="checkbox" zing-theme-builder="toggle-check">
      <el-checkbox @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
      <span zing-theme-builder="toggle-label">{{checkVal ? "Check All" : "Uncheck All"}}</span>
    </label>
    <!-- Control Checkbox -->
    <el-checkbox v-else @change="_inputChecked" v-model="checkVal" zing-theme-builder="check"></el-checkbox>
  </div>
</template>

<script setup>
  // TODO replace $parent
  import { defineProps, onMounted, ref } from 'vue';

  const props = defineProps({
    checked: Boolean,
    type: String
  });

  const classCheck = ref('is-checked');
  const classSelected = ref('selected');
  const $el = ref(null);
  const $builder = ref(null);
  const $checkbox = ref(null);
  const checkVal = ref(null);
  const $input = ref(null);
  
  onMounted(() => {
    // Set $checkbox to checkbox
    switch (props.type) {
      case 'scroll': $checkbox.value = document.querySelector('#toggleCheck [role="checkbox"]'); break;
      case 'guide': $checkbox.value = document.querySelector('#guideCheck [role="checkbox"]'); break;
      default: $checkbox.value = this; $input.value = $checkbox.value._vnode.elm; break;
    }
    // Set data variable to prop
    checkVal.value = props.checked;
    $builder .value = this.$parent.$parent.$parent;
    init();
  });
  
  /**
   * @description Initializes input by setting default value and checking if type is checkbox
   */
   function init() {
    if (!$input.value) return;
    const checkbox = $input.value.querySelector('[aria-checked="mixed"]');
    if (checkVal.value) {
      checkbox.classList.add(classCheck.value);
      checkbox.closest('[data-field="checkbox"]').classList.add(classSelected.value);
    } else {
      checkbox.classList.remove(classCheck.value);
      checkbox.closest('[data-field="checkbox"]').classList.remove(classSelected.value);
    }
  };
  
  /**
   * @description Checkbox change event that adds/removes class based on if checkmark checked.
   *     For type 'guide', hides/shows guide
   * @private
   */
  function _inputChecked() {
    const checkbox = $el.value;
    const checkboxInput = checkbox.querySelector('input');
    const label = checkbox.closest('[data-field="checkbox"]');
    const controlItem = checkbox.closest('[zing-theme-builder="item"]');
    if (props.type === 'guide') {
      if (checkVal.value) this.$parent.$clicked.classList.remove('hidden');
      else this.$parent.$clicked.classList.add('hidden');
    } else if (props.type === 'checkall') {
      $builder .value.features.forEach(function(feature) {
        feature.value = checkVal.value;
      }.bind(this));
      $builder .value._updateHTML();
    }
    if (checkVal.value) label.classList.add(classSelected.value);
    else label.classList.remove(classSelected.value);
    if(controlItem && controlItem.getAttribute('zing-theme-builder') === 'item') this.$parent._controlCheckboxClick(checkboxInput);
  };
</script>