<template>
  <!-- WINDOW OPTIONS (MOBILE) -->
  <el-dropdown
    v-if="isMobile"
    @command="handleUpdate"
    size="small"
    trigger="click">
    <el-button
      v-if="isMobile"
      type="primary"
      size="small">
      <font-awesome-icon :icon="['fas', 'window-maximize']" size="sm"></font-awesome-icon><i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <template v-slot:dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="option in showControlsOptions"
          :key="option"
          :command="option"
          :disabled="option === disabled"
          >{{option.toUpperCase()}}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <!-- WINDOW OPTIONS (DESKTOP) -->
  <el-checkbox-group
    v-else
    @change="handleUpdate"
    v-model="showControls"
    :min="1"
    size="small">
    <el-checkbox-button v-for="(toggle, index) in showControlsOptions" :value="toggle" :key="index" size="small">
      {{toggle.toUpperCase()}}&nbsp;
    </el-checkbox-button>
  </el-checkbox-group>
</template>

<script setup>
  import { computed, defineEmits, getCurrentInstance, ref } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['update']);

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $store = useStore();
  const disabled = ref('html');
  const showControls = ref(['html', 'css', 'js']);
  const showControlsOptions = ref(['html', 'css', 'js']);

  const isMobile = computed(() => {
    return $store.getters['ui/isMobile'];
  });
  const stateHtmlTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsHtml) {
      return settings.tabsHtml === 'true' ? 'html' : null;
    } else {
      return 'html';
    };
  });
  const stateCssTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsCss) {
      return settings.tabsCss === 'true' ? 'css' : null;
    } else {
      return 'css';
    };
  });
  const stateJsTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsJs) {
      return settings.tabsJs === 'true' ? 'js' : null;
    } else {
      return 'js';
    };
  });
  
  /**
   * @description Triggered on radio change event. Emits update event to parent element.
   * Disables current option.
   * Also updates the tab state.
   * @param {Array} value - label value of chosen radio(s)
   */
  function handleUpdate(value) {
    // Disables current tab option
    disabled.value = value;

    // Emits event to parent
    emit('update', value);

    // Update state
    let state = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    state.tabsHtml = value.includes('html').toString();
    state.tabsCss = value.includes('css').toString();;
    state.tabsJs = value.includes('js').toString();;
    $store.state.user['settings_editor'] = JSON.stringify(state);

    // Null check user and settings before updating
    if ($store.state.user.user_id && $store.state.user['settings_editor']) {
      // Update database
      $api('user/update', {
        'settings_editor': state,
      }, $global)
      .then((response) => {
        $store.dispatch('user/refresh', $global);
      })
      .catch((result) => {
        $message({
          duration: 0,
          message: 'Unable to update autoupdate settings',
          showClose: true,
          type: 'error',
        });
      })
    };
  };
</script>

<style>
</style>