<template>
  <div class="zingnetCard--column">
    <h3 class="zingnetCard--header">{{title}}</h3>
    <el-button 
      @click="_triggerSiteDeploy"
      :disabled="uiState">
      Click to Deploy<svg-icon icon="run"></svg-icon>
    </el-button>
    <hr>
    <p>Last deployed {{formatDate(lastDeploymentDate, null, 'MMMM D, YYYY')}}</p>
    <p>at {{formatDate(lastDeploymentDate, null, 'hh:mm A')}}.</p>

    <portal to="deployment-error">
      <el-alert v-if="userFeedackDisplay"
        title="Status of Github Action Request"
        :type="userFeedbackStatus"
        :description="userFeedback"
        :closable="false">
      </el-alert>
    </portal>
  </div>
</template>

<script setup>
  import { defineEmits, defineProps, onMounted, onBeforeUnmount, ref } from 'vue';
  import { Octokit } from '@octokit/core';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import dateComposable from '../../../../mixins/date';

  const { formatDate } = dateComposable();
  const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
  process.env.authTOKEN;
  const ACTION_TIMEOUT_TIME = 2200;

  const emit = defineEmits(['deploy']);

  const props = defineProps({
    branch: String,
    sitename: String,
    title: String,
  });

  const lastDeploymentDate = ref(null);
  const userFeedackDisplay = ref(false);
  const userFeedbackStatus = ref('info');
  const userFeedback = ref(''); 
  const actionTimeout = ref(null);
  const uiState = ref(false);
  const workflowIds = ref({
    zingchart: {
      dev: 218016,
      master: 550840,
    },
    zinggrid: {
      dev: 182535,
      master: 184183,
    },
  });

  onMounted(() => {
    getLastDeployment();
  });

  onBeforeUnmount(() => {
    window.clearTimeout(actionTimeout.value);
  });

  function getLastDeployment() {
    octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/${workflowIds.value[props.sitename][props.branch]}/runs?per_page=1`, {
      owner: "zingsoftinc",
      repo: `${props.sitename}-com`,
    })
    .then((result) => {
      lastDeploymentDate.value = result.data.workflow_runs[0].updated_at;
    });
  };

  /**
   * @description To fire off github dispatch event to the site repository
   */
  function _triggerSiteDeploy() {
    uiState.value = true;
    userFeedbackStatus.value = 'info';
    userFeedackDisplay.value = true;
    userFeedback.value = 'Sending request to dispatch action...';
    // https://github.com/octokit/core.js#readme
    octokit.request('POST https://api.github.com/repos/{owner}/{repo}/dispatches', {
      owner: "zingsoftinc",
      repo: `${props.sitename}-com`,
      event_type: `gallery-updated-${props.branch}`
    })
    .then((result) => {
      if (result.status != 204) throw new Error('Error Requesting dispatch from repository. Check network tab...');
      
      // Show success message
      userFeedbackStatus.value = 'success';
      userFeedackDisplay.value = true;
      uiState.value = true;
      userFeedback.value = 'Successfully sent action to repository! Opening action tab...';
      actionTimeout.value = setTimeout(() => {
        // Clear message
        userFeedackDisplay.value = false;
        uiState.value = false;
      }, ACTION_TIMEOUT_TIME);

      // Start progress tracking
      emit('deploy', props.sitename, props.branch)
    })
    .catch((error) => {
      console.log(error);
      userFeedbackStatus.value = 'error';
      userFeedackDisplay.value = true;
      userFeedback.value = error;
      uiState.value = false;
    });
  };
</script>

<style scoped>
  .el-button {
    margin-top: 0;
  }
  hr {
    border: 0;
    border-top: var(--zingnet-border);
    margin: 0.9375rem 0 0.625rem;
  }
  [icon="run"] {
    margin-left: 0.3125rem;
  }
  p {
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
  }
  .zingnetCard--column {
    max-width: 24%;
  }
</style>