const html = `<html>
<head>
  <script src="https://cdn.zingchart.com/zingchart.min.js"><\/script>
</head>
  <body>
    <div id="myChart"></div>
  </body>
</html>`;

const css = `body {
      margin:0;
color: red;
} #myChart {height: 320px; width: 600px;}`;

const js = `zingchart.render({
id: 'myChart',
data: {
  type:'line',
  series: [{
    values: [1,2,3,4,5,6]
  }]
}
})`;

export default {html, css, js};
