<template>
  <section class="secondary--section" :authing="isAuthing || undefined" :noJustify="noJustify || undefined" :slim="slim || undefined">
    <div class="background--grid_wrap">
      <div class="background--grid content__wrap">
        <secondary-nav v-if="!slim || undefined"></secondary-nav>
        <div class="content_wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
    <app-footer v-if="!slim || undefined" footer="secondary"></app-footer>
  </section>
</template>

<script setup>
  import { computed, defineProps, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import AppFooter from '../../views/layouts/Footer.vue';
  import SecondaryNav from '../../components/SecondaryNav.vue';

  const $store = useStore();

  const authenticated = computed(() => {
    return !!$store.state.auth.idToken;
  });

  const props = defineProps({
    isAuthing: Boolean,
    slim: false,  // if slim, do not display navbar and footer
    noJustify: Boolean,
  });
  
  onMounted(() => {
    // Hide current view if user is currently authed
    // NOTE: This is need because when Auth0 redirects you back to the app
    // this view (Secondary.vue) briefly shows before the `authenticated()`
    // method in `appShell.vue` is run and the variable set, which then
    // shows the `<template v-else-if="authenticated">` view
    // Since the auth token is set in `localStorage`, we'll read that immediately
    // on mounted to hide this view if they are logged in
    isAuthedOnLoad();
  });

  /**
   * @description Read from localStorage if the user is logged in (authed),
   * to hide this view so it doesn't momentarily flicker
   */
  function isAuthedOnLoad() {
    const isAuthed = localStorage.getItem('id_token');
  };
</script>

<style>
  [authing] { display:none; }
  
  .app-footer--narrow, .content--narrow { max-width: var(--max-width); padding: 0 1rem 1rem 1rem; width: 100%; }
  
  .background--grid { background-image: url('../../assets/images/bg_grid.png'); background-repeat: no-repeat; background-size: cover; }
  .background--grid_wrap { background: linear-gradient(45deg, #004559, #006379); display: flex; flex-direction: column; min-height: calc(100% - var(--footer-height)); }

  .content,
  .content_wrap,
  .secondary--section { height: 100%; }
  .content_wrapper { min-width: 30rem; }

  [slim] .background--grid_wrap { min-height: 100%; }

  @media screen and (max-width: 472px) {
    .content_wrapper { min-width: 100% !important; }
    .app-footer--narrow { padding: 0 0.25rem; }
  }
</style>