<template>
  <section zingnet-date-range>
    <div class="el-select-wrapper">
      <el-select v-model="selectVal" placeholder="Select" date-range>
        <!-- Default Items -->
        <template v-if="!groupName">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </template>

        <!-- Additional Items -->
        <template v-if="additionalItems && !groupName">
          <el-option
            v-for="item in additionalItems"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </template>

        <!-- Additional Items (Grouped) -->
        <template v-if="additionalItems && groupName">
          <el-option-group
            key="default"
            label="Default">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
          <el-option-group
            :key="groupName"
            :label="groupName">
            <el-option
              v-for="item in additionalItems"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
        </template>

      </el-select>
      <svg-icon class="el-select-icon" icon="angle"></svg-icon>
    </div>

    <el-date-picker
      @change="updateCustomRange"
      v-if="selectVal === 'range' || typeof selectVal === 'object'"
      v-model="customDateRange"
      type="daterange"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
      value-format="yyyy-MM-dd">
    </el-date-picker>
    <p v-else>{{dateLabel}}</p>

    <p hidden>{{range}}</p>
  </section>
</template>

<script setup>
  import { computed, defineEmits, defineProps, nextTick, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from '../../../components/SvgIcon.vue';
  import dateComposable from '../../../mixins/date';
  
  const { dateRange, formatDate } = dateComposable();
  
  const emit = defineEmits(['updateDateRange']);

  const props = defineProps({
    additionalItems: Array,
    groupName: String,
  });

  const $store = useStore();
  const customDateRange = ref([]);
  const dateRangeObj = ref(null);
  const label = ref(null);
  const options = ref([
    {
      label: 'Past day',
      value: 'day',
    },
    {
      label: 'Past week',
      value: 'week',
    },
    {
      label: 'Past month',
      value: 'month',
    },
    {
      label: 'Past year',
      value: 'year',
    },
    {
      label: 'Custom',
      value: 'range',
    },
  ]);
  const optionsVal = ref(null);
  const prevRange = ref('week');
  const selectVal = ref('week');

  const dateLabel = computed(() => {
    // Trigger update on select drop update on current or another <date-range>
    if (selectVal.value) {
      nextTick(() => updateRange());
    };
    return label.value;
  });
  const range = computed(() => {
    nextTick(() => updateRange());
    return $store.state.user.adminDateRange || 'week';
  });
  
  onMounted(() => {
    setRange();
  });

  function updateCustomRange() {
    if (customDateRange.value && customDateRange.value.length == 2) {
      selectVal.value = {start: customDateRange.value[0], end: customDateRange.value[1]};

      prevRange.value = selectVal.value;
      $store.state.user.adminDateRange = selectVal.value;

      setRange();
    };
  };

  function updateRange() {
    if (!selectVal.value !== 'range') {
      if (prevRange.value !== selectVal.value) {
        // Triggered by current <date-range>
        prevRange.value = selectVal.value;
        if (_isDefaultOption(selectVal.value)) {
          $store.state.user.adminDateRange = selectVal.value;
        };
        setRange();
      } else if (prevRange.value === selectVal.value && prevRange.value !== range.value) {
        // Triggered by another <date-range>
        prevRange.value = range.value;
        selectVal.value = range.value;

        if (typeof selectVal.value === 'object') {
          customDateRange.value = [selectVal.value.start, selectVal.value.end];
        };

        setRange();
      };
    };
  };

  function setRange() {
    if (_isDefaultOption(selectVal.value)) {
      // Update value
      dateRangeObj.value = dateRange(null, 'YYYY-MM-DD', selectVal.value, 1);

      // Emit events to notify of date range update
      emit('updateDateRange', 'period', selectVal.value)
      emit('updateDateRange', 'start', dateRangeObj.value.start);
      emit('updateDateRange', 'end', dateRangeObj.value.end);
      emit('updateDateRange', 'prevStart', dateRangeObj.value.prevStart);
      emit('updateDateRange', 'prevEnd', dateRangeObj.value.prevEnd);

      // Update label
      let start = formatDate(dateRangeObj.value.start, 'YYYY-MM-DD', 'MMMM D');
      let end = formatDate(dateRangeObj.value.end, 'YYYY-MM-DD', 'MMMM D');
      label.value = `Currently viewing ${start} - ${end}`;
    } else if (typeof selectVal.value === 'object') {
      // Update value
      dateRangeObj.value = dateRange(selectVal.value.end, 'YYYY-MM-DD', selectVal.value);

      // Emit events to notify of date range update
      emit('updateDateRange', 'start', dateRangeObj.value.start);
      emit('updateDateRange', 'end', dateRangeObj.value.end);
      emit('updateDateRange', 'prevStart', dateRangeObj.value.prevStart);
      emit('updateDateRange', 'prevEnd', dateRangeObj.value.prevEnd);
    } else {
      emit('updateDateRange', 'custom', selectVal.value);
    };
  };

  function _isDefaultOption(opt) {
    if (!optionsVal.value) optionsVal.value = options.value.map((o) => o.value);
    return optionsVal.value.indexOf(opt) > -1;
  };
</script>

<style>
  [zingnet-date-range] p {
    color: var(--color-primary-gray-visited);
    font-size: 0.9375rem;
    font-style: italic;
    margin: 0 0 0 1.125rem;
  }
  [zingnet-date-range] {
    align-items: center;
    display: flex;
    margin-top: 1.1875rem;
  }

  [zingnet-date-range] .el-date-editor {
    margin-left: 1.5rem;
  }
</style>