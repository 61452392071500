<template>
  <zingnet-card :grid-body="true">
    <template v-slot:header>
      <span>
        Recent Sales
        <date-range @updateDateRange="updateDateRange"></date-range>
      </span>
    </template>
    <template v-slot:header__right>
      <span>
        <el-button @click="openQuickbooks">View in Quickbooks</el-button>
      </span>
    </template>
    <template v-slot:body>
      <div>
        <zing-grid
          cell-class="renderNA"
          default-display="N/A"
          layout="row"
          layout-controls="disabled"
          no-border
          pager="true"
          page-size=10
          selector="true">
          <zg-colgroup>
            <zg-column index="transactionDate, type, id" header="Transaction Date" renderer="renderTransactionDate"></zg-column>
            <zg-column index="amount" type="currency"></zg-column>
            <zg-column index="client, clientId" header="Client" renderer="renderClient"></zg-column>
            <zg-column index="license"></zg-column>
            <zg-column index="zingBrand" renderer="renderProductType"></zg-column>
          </zg-colgroup>
          <zg-data>
            <zg-param name="headers" :value="JSON.stringify(headerVal)"></zg-param>
            <zg-param ref="srcParamRef" name="src" :value="srcValue"></zg-param>
          </zg-data>
        </zing-grid>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { defineEmits, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import DateRange from '../DateRange.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import dateComposable from '../../../../mixins/date';
  import dateRangeComposable from '../../mixins/dateRange';
  import rendererComposable from '../../mixins/renderer';

  const { dateFromNow, dateRange, formatDate } = dateComposable();
  const { start, end, updateDateRange } = dateRangeComposable({getReportData});
  const { renderNA, renderProductType } = rendererComposable({});


  const emit = defineEmits(['openQuickbooks']);

  const $store = useStore();
  const srcParamRef = ref(null);

  const headerVal = ref({
    Authorization: `Bearer ${$store.state.auth.idToken}`
  });
  const srcValue = ref('');

  onMounted(() => {
    ZingGrid.registerMethod(renderClient, 'renderClient');
    ZingGrid.registerMethod(renderNA, 'renderNA');
    ZingGrid.registerMethod(renderProductType, 'renderProductType');
    ZingGrid.registerMethod(renderTransactionDate, 'renderTransactionDate');
  });


  function openQuickbooks() {
    emit('openQuickbooks');
  };

  function renderClient(client, id) {
    return `<a href="https://c11.qbo.intuit.com/app/customerdetail?nameId=${id}" target="_blank">${client}</a>`;
  };

  function renderTransactionDate(val, type, id) {
    let objType = type === 'Payment' ? 'recvpayment' : 'salesreceipt';
    let link = `https://c11.qbo.intuit.com/app/${objType}?txnId=${id}`;
    return `<a href="${link}" target="_blank">${dateFromNow(val)}</a> <span class="date">${formatDate(val, null, 'M/D/YY')}</span>`;
  };

  function getReportData() {
    srcValue.value = `${window.location.origin}/api/admin/quickbooks/sales/latest?startDate=${start.value}&endDate=${end.value}`;
  };
</script>