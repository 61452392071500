<template>
  <div class="nav" secondary-nav>
    <nav class="nav__wrap nav__wrap--main">
      <div class="nav__logo nav__logo--secondary">
        <svg-icon icon="zingsoft-logomark"></svg-icon>
      </div>

      <div class="nav__sign-in">
        <span class="mobile--hide">
          <template v-if="isSignup">Already have an account?</template>
          <template v-else>Don't have an account?</template>
        </span>
        <div class="nav__sign-in__link" >
          <span v-if="isSignup" @click="launch('/')">
            <span class="text--underline">Log in</span>
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z"/><path d="M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z"/></g></svg>
          </span>
          <span v-else @click="launch('/signup')">
            <span class="text--underline">Create account</span>
            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M8.778 0a9.336 9.336 0 0 0-5.284 1.623A9.033 9.033 0 0 0 .164 5.78L0 6.23h2.026l.084-.217c.364-.77.86-1.466 1.486-2.077a7.372 7.372 0 0 1 5.182-2.094c1.96 0 3.8.744 5.183 2.095a7.02 7.02 0 0 1 2.146 5.058 7.027 7.027 0 0 1-2.146 5.058 7.372 7.372 0 0 1-5.183 2.094 7.388 7.388 0 0 1-5.182-2.094A7.167 7.167 0 0 1 2.11 11.98l-.084-.216H0l.16.45a9.033 9.033 0 0 0 3.33 4.158A9.288 9.288 0 0 0 8.777 18C13.864 18 18 13.963 18 9s-4.136-9-9.222-9z"/><path d="M0 9.892h7.672l-1.764 1.862L7.118 13 11 9 7.118 5 5.864 6.246l1.808 1.862H0v1.784z"/></g></svg>
          </span>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import axios from 'axios';
  import Lock from '../components/Lock.vue';
  import routes from '../routes/main.js';
  import { isRegExp } from 'util';
  import { mapState } from '@vuex';
  import SvgIcon from '../components/SvgIcon.vue';

  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const activeRoute = ref('/');
  const email = ref('');
  const name = ref('');
  const picture = ref(null);
  const treeProps = ref({
    children: 'children',
    label: 'description'
  });
  const isSignup = ref(null);
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);
  // point URL to stage or master
  const zinggridUrl = ref(VUE_APP_ZINGGRID_ROOT_URL);
    
  const planId = computed(() => {
    return $store.state.billing.planId;
  });
  const planInterval = computed(() => {
    return $store.state.billing.planInterval;
  });
  const lock = computed(() => {
    return $store.state.auth.lock;
  });
  const tenantName = computed(() => {
    return $store.state.tenant.name;
  });
  const tenantLogo = computed(() => {
    return $store.state.tenant.logo;
  });
  const isMobile = computed(() => {
    const isMobile = $store.getters['ui/isMobile'];
    if(!isMobile && mobileMenuActive.value) {
      closeMenu();
    }
    return isMobile;
  });
  const appWidthClass = computed(() => {
    return ($store.state.ui.width === 'narrow') ? 'nav__content--narrow' : 'nav__content--wide';
  });
  const accountRoutes = computed(() => {
    const temp = [];
    routes.forEach((route) => {
      if(route.path ==='/account') {
        route.children.forEach((child) => {
          temp.push({
            description: child.description,
            path: route.path + '/' + child.path,
          });
        });
      }
    });
    return temp;
  });
  const isStudio = computed(() => {
    return ($route.path.includes('/demos'));
  });
  const avatar = computed(() => {
    return ($store.state.user && $store.state.user.picture) ? picture.value : null;
  });
  const currentSection = computed(() => {
    return (isStudio.value) ? 'Studio': 'Account';
  });
  const displayName = computed(() => {
    return name.value || email.value;
  });
  const routeMap = computed(() => {
    return flattenedRoutes.value.reduce((acc, val) => {
      acc[val.description] = val.path;
      return acc;
    }, {});
  });
  const flattenedRoutes = computed(() => {
    return flatten(routes);

    function flatten(routes, currentPath = '', result = [], levels = 0) {
      routes.forEach(({path, description, children}) => {
        if(children) {
          result.concat(flatten(children, currentPath + path + '/', result, levels + 1));
        } else {
          // Strip off any route params.
          let formattedPath = currentPath + path;
          formattedPath = formattedPath.split(':')[0]
          result.push({
            path: formattedPath,
            description,
            levels,
          });
        }
      });
      return result;
    }
  });
  const mobileMenuActive = computed(() => {
    return $store.state.ui.isMenuOpen;
  });

  onMounted(() => {
    $store.commit('auth/init_lock');

    // Initial load
    const widePaths = ['/demos/create'];

    if(widePaths.includes(window.location.pathname)) {
      $store.state.ui.width = 'wide';
    }
    // On route change
    $router.beforeEach((to, from, next) => {
      if(widePaths.includes(to.path)) {
        $store.state.ui.width = 'wide';
      } else {
        $store.state.ui.width = 'narrow';
      }
      next();
    });
    if ($route.path === '/demos/create' || $route.path === '/builder') {
      activeRoute.value = '/demos';
    } else if ($route.path === '/signup') {
      isSignup.value = true;
    } else {
      activeRoute.value = $route.path;
      isSignup.value = false;
    }
  });

  function launch(path) {
    let query = window.location.href.split('?')[1];
    query = query ? `?${query}` : '';
    let _path = `${path}${query}`;
    if ($route.path !== _path) $router.push(_path);
  };

  function updateWindowSize() {
    windowHeight.value = window.innerHeight;
    windowWidth.value = window.innerWidth;
  };

  function navigate(command) {
    if(command === 'logout') {
      logout();
    } else {
      if ($route.path !== command) $router.push(command);
      closeMenu();
    }
  };

  function openMenu() {
    $store.commit('ui/menuStatus', true);
  };

  function closeMenu() {
    $store.commit('ui/menuStatus', false);
  };

  function logout() {
    $store.commit('auth/logout');
    // Don't run window.location since it refreshes the app
    if ($route.path !== '/') $router.push('/');
  };
</script>

<style>
  [secondary-nav] .nav__sign-in__link svg {
    fill: var(--color-tertiary-6);
    margin-right: 0.25rem;
    position: relative;
    top: 3px;
    width: 0.8rem;
  }

  /* MOBILE STYLING  */
  [secondary-nav] .nav {
    box-shadow: var(--box-shadow-card);
  }

  [secondary-nav] .nav__content {
    display: flex;
    margin: 0;
    padding: 0 20px;
    width: 100%;
  }

  [secondary-nav] .nav__content--main {
    justify-content: space-between;
  }

  [secondary-nav] .nav__content.nav__content--wide {
    padding: 0px;
  }

  [secondary-nav] .nav__logo--secondary {
    margin-left: 0;
  }

  [secondary-nav] .nav__logo--secondary [icon] {
    display: flex;
  }

  [secondary-nav] .nav__sign-in {
    align-items: center;
    color: var(--color-greyscale-8);
    display: flex;
    font-size: 0.85rem;
    padding: 0;
    text-decoration: none;
  }

  [secondary-nav] .nav__sign-in svg {
    margin-right: 0.25rem;
  }

  [secondary-nav] .nav__sign-in__link {
    color: var(--color-primary-1);
    cursor: pointer;
    font-weight: 600;
    margin: 0 0 0 0.5rem;
  }

  [secondary-nav] .nav__sign-in__link:hover {
    color: var(--color-tertiary-1);
  }

  [secondary-nav] .nav__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 875px; 
    margin: 0 auto;
    padding: 0.75rem;
    background: #fff;
    color: var(--color-greyscale-8);
  }

  [secondary-nav] .text--underline { text-decoration: underline; }

  [secondary-nav] .nav__wrap--main .el-popover.el-popper { padding: 0; }
  [secondary-nav] .nav__wrap--main .el-popover.el-popper a:first-of-type { border-bottom: 1px solid #80808026; }
  [secondary-nav] .nav__wrap--main .el-popover.el-popper a { align-items: center; display: flex; height: 65px; justify-content: center; }
  [secondary-nav] .nav__wrap--main .el-popover.el-popper a:hover { background: #f8f8f8; }
  [secondary-nav] .nav__wrap--main .el-popover.el-popper div:first-of-type { margin: 0.5rem 0; } 
</style>