<template>
  <section class="zingnetCardSection">
    <div class="zingnetCardRow">
      <!-- CDN Tracker -->
      <cdn-tracker></cdn-tracker>

      <!-- Lighthouse Score -->
      <page-speed></page-speed>
      
      <!-- Studio Visitors -->
      <page-visitors type="Studio"></page-visitors>

      <!-- ZingChart Visitors -->
      <page-visitors type="ZingChart"></page-visitors>
      
    </div>
  </section>
</template>

<script setup>
  import CdnTracker from './CdnTracker.vue';
  import PageSpeed from './PageSpeed.vue';
  import PageVisitors from './PageVisitors.vue';
</script>