import { ref } from 'vue';

export default function addCardComposable({$global, hasCard}) {
  const addLoading = ref(false);
  const cardDialogVisible = ref(false);
  const formCard = ref({
    cardNumber: '',
    cardExpiration: '',
    cardCvc: '',
    chargeOptional: '',
  });

  function addCard() {
    addLoading.value = true;
    let cardExpiration = formCard.value.cardExpiration.split('/');
    let card = {
      number: formCard.value.cardNumber,
      exp_month: parseInt(cardExpiration[0]),
      exp_year: parseInt(cardExpiration[1]),
      cvc: formCard.value.cardCvc,
    }
    if (formCard.value.chargeOptional) card.name = formCard.value.chargeOptional;


    $global.$api('card/add', {
      data: {card},
    }, $global).then((response) => {
      if (response.data.cardType) {
        cardDialogVisible.value = false;
        hasCard.value = true;
      } else {
        $global.$message({
          duration: 0,
          message: `Could not add card. ${response.data}`,
          showClose: true,
          type: 'error',
        });
      }
      addLoading.value = false;
    }).catch((err) => {
      $global.$message({
        duration: 0,
        message: `Could not add card. ${err.response.data}`,
        showClose: true,
        type: 'error',
      });
      addLoading.value = false;
    });
  };

  return {
    addCard,
    addLoading,
    cardDialogVisible,
    formCard
  };
};