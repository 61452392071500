<template>
  <zingnet-card :gridBody="true">
    <template v-slot:header>
      <span>
        Gallery Metadata
        <metadata-selector
          type="metadata"
          v-model:metadataBy="metadataType"
          default-value="chartType"
        ></metadata-selector>
      </span>
    </template>

    <template v-slot:header__right>
      <div>
        <span class="input-wrapper">
          <el-input
            @change="searchGrid"
            v-model="search"
            placeholder="Search"
            search>
          </el-input>
          <svg-icon class="el-input-icon" icon="search2" fill="var(--color-primary-gray)"></svg-icon>
        </span>
      </div>
    </template>

    <template v-slot:body>
      <div>
        <section class="zingnetCard--row no-border">
          <zing-grid
            ref="zgRef"
            :caption="gridCaption"
            :data="gridData"
            editor-controls
            cell-editor="disabled"
            filter="true"
            height="650"
            metadata-list="grid"
            selector="true"
            search="true"
            sort="true">
            <zg-colgroup>
              <zg-column index="metadata_uid" hidden="true" editor="disabled"></zg-column>
              <zg-column index="name"></zg-column>
              <zg-column :hidden="noContent" index="content"></zg-column>
              <zg-column index="demo_type" type="select" type-select-options="zingchart,zinggrid" type-select-multiple="true"></zg-column>
              <zg-column type="editor"></zg-column>
              <zg-column type="remover"></zg-column>
            </zg-colgroup>
          </zing-grid>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, getCurrentInstance, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import MetadataSelector from '../../../../components/MetadataSelector.vue';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import ZingnetCard from '../../components/ZingnetCard.vue';
  import metdataCompasable from '../../../../mixins/metadata.js'
  import searchComposable from '../../mixins/search.js';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const { galleryMetadata, fetchOption, fetchAllOptions } = metdataCompasable({$global});
  const { search, searchGrid } = searchComposable({zgRef});
  
  const $api = $global.$api;
  const $store = useStore();
  const metadataType = ref('chartType');
  const zgRef = ref(null);

  const checkMetadata = computed(() => {
    return galleryMetadata.value && metadataType.value && galleryMetadata.value[metadataType.value];
  });
  const id_user = computed(() => {
    return $store.state.user['user_id'];
  });
  const gridCaption = computed(() => {
    let retval = '';
    if (metadataType.value) {
      let split = metadataType.value.split('_');
      split.forEach((s) => {
        retval += `${s[0].toUpperCase()}${s.slice(1)} `;
      });
    }
    return retval;
  });
  const gridData = computed(() => {
    let options = [];
    if (checkMetadata.value) {
      let metadata = galleryMetadata.value[metadataType.value].options;
      for (let opt in metadata) {
        options = options.concat(metadata[opt]);
      }
    }
    return JSON.stringify(options);
  });
  const noContent = computed(() => {
    return checkMetadata.value
      ? galleryMetadata.value[metadataType.value].type !== 'select-url'
      : true;
  });
  
  onMounted(() => {
    setupGrid();

    if ($store.state.user['user_id']) {
      fetchAllOptions();
    }
  });

  function addMetadataRecord(data) {
    let {name, content, demo_type} = data;
    let body = {
      metadata: metadataType.value,
      value: name,
      demo_type
    };
    // Add content for specified metadata type
    if (content && galleryMetadata.value[metadataType.value].type === 'select-url')  {
      body.content = content;
    }
    // Add to database
    $api('metaoptions/create', body, $global)
      .then(() => {
        fetchOption(metadataType.value);
      });
  };

  function deleteMetadataRecord(data) {
    $api('metaoptions/delete', {
      id: data.metadata_uid,
    }, $global).then(() => {
        fetchOption(metadataType.value);
      });
  };

  function editMetadataRecord(oldData, newData) {
    $api('metaoptions/update', {
      id: newData.metadata_uid,
      type: metadataType.value,
      value: newData.name,
      content: newData.content,
      demo_type: newData.demo_type,
    }, $global).then(() => {
        fetchOption(metadataType.value);
      });
  };

  function setupGrid() {
    zgRef.value.addEventListener('data:record:change', (e) => {
      editMetadataRecord(e.detail.ZGData.oldData, e.detail.ZGData.data);
    });
    zgRef.value.addEventListener('data:record:delete', (e) => {
      deleteMetadataRecord(e.detail.ZGData.data);
    });
    zgRef.value.addEventListener('data:record:insert', (e) => {
      addMetadataRecord(e.detail.ZGData.data);
    });
  };
</script>

<style scoped>
  .filter_wrapper {
    margin-top: 1rem;
  }
</style>

<style>
  .zingnetCard--header .el-input__wrapper {
    padding: 0 !important;
  }
</style>