import { computed, ref } from 'vue';

export default function renewSubscriptionComposable({$global, getUserSubscriptions, hasCard, products}) {
  const toggleSubscriptionDialogVisible = ref(false);
  const toggleRenewSubscriptionDialogVisible = ref(false);
  const toggleSubscriptionText = ref('');
  const renewalAmount = ref('');
  const renewalPlan = ref(null);
  const renewalInvoiceId = ref('');
  const renewLoading = ref(false);
  const startRenew = ref(true);

  const disableRenewButton = computed(() => {
    return !hasCard.value;
  });

  function closeRenewalDialog() {
    renewalAmount.value = '';
    renewalPlan.value = null;
    renewalInvoiceId.value = '';

    startRenew.value = true;
    renewLoading.value = false;
    toggleRenewSubscriptionDialogVisible.value = false;
};

  function renewSubscriptionById() {
    let planName = renewalPlan.value.planName.split(':').pop();
    let sku = products.value.filter(p => p.Name === planName)[0].Sku;
    let renewalName = planName.replace('-A', '-R');
    let renewalSku = sku.replace('-A', '-R').replace('-S', '');

    // Renew button set to "loading"
    renewLoading.value = true;

    $global.$api('subscription/renew', {
      invoiceId: renewalInvoiceId.value,
      renewalName,
      renewalSku,
      sku,
    }, $global)
      .then(() => {
        renewLoading.value = false;
        startRenew.value = false;
        $global.$message({
          message: 'Subscription renewed!',
          showClose: true,
          type: 'success',
        });
      getUserSubscriptions();
    })
    .catch(() => {
      renewLoading.value = false;
      $global.$message({
        duration: 0,
        message: 'Cannot renew',
        showClose: true,
        type: 'error',
      });
    });
  };

  return {
    toggleSubscriptionDialogVisible,
    toggleRenewSubscriptionDialogVisible,
    toggleSubscriptionText,
    renewalAmount,
    renewalPlan,
    renewalInvoiceId,
    renewLoading,
    startRenew,
    disableRenewButton,
    closeRenewalDialog,
    renewSubscriptionById
  };
};