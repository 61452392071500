<template>
  <div class="form__item">
    <label class="form__label" id="full-name">Full Name</label>
     <el-input v-model="name_" class="form__field" placeholder="John" for="full-name" maxlength="75"></el-input>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';

  const emit = defineEmits(['change']);

  const props = defineProps({
    value: {
      type: String
    }
  });
  
  const name_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const name = computed(() => {
    return props.value;
  });

  watch(name, (value) => {
    name_.value = value;
    dirty.value = false;
  });
  watch(name_, () => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value)  {
    // Handles changes by the store mutating the event
      dirty.value = true;
      emit('change', {name: name_.value});
    } else if (dirty.value) {
      // If the field was modified by user entry
      emit('change', {name: name_.value});
    } 
  });

  onMounted(() => {
    name_.value = props.value;
  });
</script>
