<template>
  <default-layout>
    <!-- Profile -->
    <section v-show="adminAccess"  class="content--narrow">
      <header class="content__header content__header--expanded">
        <img v-if="profile.avatar != '#'" class="profile__avatar" :src="profile.avatar" alt="Avatar">
        <font-awesome-icon v-else class="profile__avatar" :icon="['fas', 'user-circle']" size="10x"/>
        <div class="profile__info">
          <h2>{{profile.email}}</h2>
          <h3 v-if="profile.name">{{profile.name}}</h3>
          <p v-if="profile.company"><font-awesome-icon :icon="['fas', 'building']" size="1x"/>{{profile.company}}</p>
          <p v-if="profile.location"><font-awesome-icon :icon="['fas', 'map-marker-alt']" size="1x"/>{{profile.location}}</p>
        </div>
      </header>

      <!-- Demos -->
      <demo-viewer mode="grid" create=false :user-id="id" :search="true" :pagination="true"></demo-viewer>
    </section>
  </default-layout>
</template>

<script setup>
  import { getCurrentInstance, onMounted, ref } from 'vue';
  import { computedAsync } from '@vueuse/core';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import DefaultLayout from '../layouts/Default.vue';
  import DemoViewer from '../../components/DemoViewer.vue';
  import permissionsComposable from '../../mixins/permissions';

  const { checkPermission } = permissionsComposable();
  
  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const id = ref($route.params.id);
  const profile = ref({
    avatar: null,
    email: null,
    name: null,
    userId: null,
    company: null,
    location: null,
  });

  const adminAccess = computedAsync(
    async () => {
      return new Promise((resolve, reject) => {
        checkPermission('admin_dashboard_view', null, null, $store)
          .then((response) => {
            if (!response) $router.replace('/401');
            resolve(response);
          }).catch(() => {
            reject(false);
          })
      });
  });

  onMounted(() => {
    getProfile();
  });

  /**
   * @Desciption Gets user profile data
   */
  function getProfile() {
    $api('user/profile', {
      slug: id.value,
    }, $global)
    .then((response) => {
      ['avatar', 'email', 'name', 'userID', 'company', 'location'].forEach(prop => {
        if(response.data) profile.value[prop] = response.data[prop];
      });
    });
  };
</script>

<style>
  .content__header--expanded {
    align-items: center;
    justify-content: center;
    margin: 2rem;
  }
  .profile__avatar {
    border-radius: 50%;
    height: 7rem;
    margin: 2rem;
  }
  .profile__info h2 {
    font-size: 1.25rem;
  }
  .profile__info h3 {
    color: var(--color-primary-2);
    font-size: 1rem;
    font-weight: 500;
  }
  .profile__info p {
    color: var(--color-greyscale-2);
  }
  .profile__info h2,
  .profile__info h3,
  .profile__info p {
    margin: 0 0 0.25rem 0;
  }
  .profile__info svg {
    margin-right: 0.5rem;
  }
</style>
