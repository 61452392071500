<template>
  <zingnet-card>
    <template v-slot:header>
      <span>
        Site Error Tracker
        <date-range @updateDateRange="updateDateRange"></date-range>
      </span>
    </template>
    <template v-slot:header__right>
      <span>
        <el-button @click="openTrackJS">View in TrackJS</el-button>
      </span>
    </template>
    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">Highest Impact</h3>
            <ol v-if="errors" class="zingnetCard--list">
              <li v-for="li in errors.highestImpact" :key="li.message">
                <a :href="`https://${li.url}`" target="_blank" rel="noreferrer noopener"><span class="zingnetCard--list--item">{{li.message}}</span></a>
                <bubble-count :count="li.count"></bubble-count>
              </li>
            </ol>
          </section>
          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">Top Errors</h3>
            <ol v-if="errors" class="zingnetCard--list">
              <li v-for="li in errors.top" :key="li.message">
                <a :href="`https://${li.url}`" target="_blank" rel="noreferrer noopener"><span class="zingnetCard--list--item">{{li.message}}</span></a>
                <bubble-count :count="li.count"></bubble-count>
              </li>
            </ol>
          </section>
        </section>

        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <div id="chart-errorTracking"></div>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import BubbleCount from '../BubbleCount.vue';
  import DateRange from '../DateRange.vue';
  import moment from 'moment';
  import ZingnetCard from '../ZingnetCard.vue';
  import dateRangeComposable from '../../mixins/dateRange';

  const { start,
    end, updateDateRange } = dateRangeComposable({getReportData});

  const $store = useStore();
  const config = ref({
    type: 'mixed',
    title: {
      text: 'Errors per Page View',
      fontColor: 'var(--color-tertiary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      textAlign: 'left',
      x: '-5px',
      y: '-5px',
    },
    crosshairX: {
      plotLabel: {
        backgroundColor: '#fff',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '15px',
      },
      scaleLabel: {
        backgroundColor: 'var(--color-primary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
      },
    },
    legend: {
      borderWidth: 0,
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        offsetX: '-5px',
      },
      layout: 'horizontal',
      offsetX: '-5px',
      offsetY: '-16px',
      width: '150px',
    },
    scaleX: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
      },
      fontSize: '12px',
      lineColor: 'var(--zingnet-chart-color)',
      minValue: null,
      step: 3600000,
      transform: {
        type: 'date',
        all: '%M %d %H:%m'
      },
      tick: {
        visible: false,
      }
    },
    scaleY: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
      },
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      guide: {
        lineColor: 'var(--zingnet-chart-color)',
      },
      lineColor: 'var(--zingnet-chart-color)',
      step: 20,
      tick: {
        fontSize: '12px',
        fontFamily: 'Nunito Sans, sans-serif',
        lineColor: 'var(--zingnet-chart-color)',
      },
    },
    plotarea: {
      margin: '45px 20px 25px 40px',
    },
    series: [
      {
        type: 'area',
        values: [],
        text: 'Errors',
        backgroundColor: 'var(--color-fail)',
        lineColor: 'var(--color-fail)',
        marker: {
          backgroundColor: 'var(--color-fail)',
        },
      },
      {
        type: 'line',
        values: [],
        text: 'Page Views',
        lineColor: 'var(--color-secondary-blue)',
        marker: {
          backgroundColor: 'var(--color-secondary-blue)',
        },
      },
    ]
  });
  const errors = ref({
    highestImpact: [],
    top: [],
  });
  const loading = ref(false);

  function dateToMillisecondas(sDate) {
    return moment(sDate, 'YYYY-MM-DD').valueOf();
  };

  function getReportData() {
    loading.value = true;

    axios({
      url: `/api/admin/trackjs/report?startDate=${start.value}&endDate=${end.value}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      }
    }).then((response) => {
      // Set "Top 5" data
      errors.value.highestImpact = response.data.impactErrors.map((data) => {return {message: data.key, count: data.count, url: data.trackJsUrl}});
      errors.value.top = response.data.topErrors.map((data) => {return {message: data.key, count: data.count, url: data.trackJsUrl}});

      // Set chart data
      config.value.series[0].values = response.data.errorSeries;
      config.value.series[1].values = response.data.viewSeries;
      config.value.scaleX.minValue = dateToMillisecondas(start.value);
      zingchart.render({
        id: 'chart-errorTracking',
        data: config.value,
        height: '400px',
        width: '1074px',
      });

      loading.value = false;
    });
  };

  function openTrackJS() {
    window.open('https://my.trackjs.com/');
  };
</script>