<template>
  <div class="plan__list">
    
    <h4 v-if="updating">Update your domains to the subscription. You have <span v-html="countHTML"></span> free update(s) left.</h4>
    <h4 v-else>Add your domains to the subscription. This can also be modified at a later date.</h4>

    <!-- Domains -->
    <div class="plan-domains">
      <header class="plan-domains__header">
        <h4>Domains ({{domains.length}} / {{activePlanMaxDomains && activePlanMaxDomains === 'unlimited' ? 'unlimited' : `${activePlanMaxDomains} used`}}) - {{ optional ? 'optional' : 'requires at least one'}}</h4>
      </header>

      <div class="plan-domains__container" ref="dataDomainScroll" data-domain-scroll>
        <section class="plan-domains__list" v-for="(domain, index) in domains" :key="`domain${index}`">
          <div class="plan-domains__count">{{index + 1}}</div>
          <div class="plan-domains__url">{{getDomain(domain)}}</div>
          <div @click="removeNewDomain(domain)" class="plan-domains__delete"><font-awesome-icon :icon="['fas', 'trash-alt']" size="1x"/></div>
        </section>
      </div>

      <el-form @submit.prevent :model="form" ref="dialogDomainFormRef">
        <div class="plan-domains__add" v-show="activePlanMaxDomains && (domains.length < activePlanMaxDomains || activePlanMaxDomains === 'unlimited')">
          <el-form-item prop="dialogNewDomain" :rules="validateDomain()">
            <el-input type="text" v-model="form.dialogNewDomain" v-on:keyup.enter="handleDialogAddDomain(false)" autofocus></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleDialogAddDomain(false)" type="primary" size="small">Add</el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- Subdomains -->
      <header class="plan-domains__header">
        <h4>Subdomains ({{subdomains.length}} / {{activePlanMaxSubdomains && activePlanMaxSubdomains === 'unlimited' ? 'unlimited' : `${activePlanMaxSubdomains} used`}}) - {{ optional ? 'optional' : 'requires at least one'}}</h4>
      </header>
      
      <div class="plan-domains__container" ref="dataSubdomainScroll" data-subdomain-scroll>
        <section class="plan-domains__list" v-for="(subdomain, index) in subdomains" :key="`subdomain${index}`">
          <div class="plan-domains__count">{{index + 1}}</div>
          <div class="plan-domains__url">{{getDomain(subdomain)}}</div>
          <div @click="removeNewDomain(subdomain, true)" class="plan-domains__delete"><font-awesome-icon :icon="['fas', 'trash-alt']" size="1x"/></div>
        </section>
      </div>

      <el-form @submit.prevent :model="form" ref="dialogSubdomainFormRef">
        <div class="plan-domains__add" v-show="activePlanMaxSubdomains && (subdomains.length < activePlanMaxSubdomains || activePlanMaxSubdomains === 'unlimited')">
          <el-form-item prop="dialogNewSubdomain" :rules="validateSubdomain()">
            <el-input type="text" v-model="form.dialogNewSubdomain" v-on:keyup.enter="handleDialogAddDomain(true)" autofocus></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="handleDialogAddDomain(true)" type="primary" size="small">Add</el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- Warning Alerts -->
      <section class="plan-alerts">
        <el-alert
          v-if="exceedDomainLimit"
          title="Number of domains exceeded"
          description="Please remove domain(s) or upgrade license."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="exceedSubdomainLimit"
          title="Number of subdomains exceeded"
          description="Please remove subdomain(s) or upgrade license."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="exceedDomainUpdate"
          title="Number of updated exceeded"
          description="Please contact support@zingsoft.com to request further domain updates."
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="notSubdomain"
          title="Not a subdomain"
          :description="`${subdomain} is not a subdomain of given domain(s).`"
          :closable="false"
          type="error">
        </el-alert>

        <el-alert
          v-if="missingDomains"
          title="Please register a domain"
          description="It is required to register at least one domain."
          :closable="false"
          type="warning">
        </el-alert>
      </section>
    </div>

    <footer class="plan-dialog__footer">
      <el-button @click="cancelUpdateDomains" type="success"  plain>{{cancelText}}</el-button>
      <el-button @click="updateDomains"
        type="success"
        :disabled="disable || exceedDomainLimit || exceedSubdomainLimit || exceedDomainUpdate || missingDomains || notSubdomain"
        :loading="loading">
        {{submitText}}</el-button>
    </footer>

  </div>
</template>

<script setup>
  import { computed, defineEmits, defineExpose, defineProps, getCurrentInstance, nextTick, ref } from 'vue';
  import { useStore } from 'vuex';

  import axios from 'axios';
  import Domain from '../../../plugins/domain.js';
  import autofillDomainComposable from '../../../mixins/autofillDomain.js';
  const { autofillDomain } = autofillDomainComposable();

  const emit = defineEmits(['keyup', 'submit', 'cancel-action', 'remove-domain', 'submit-action']);

  const props = defineProps({
    cancelText: String,
    disable: Boolean,
    domains: Array,
    form: Object,
    activePlan: Object,
    optional: Boolean,
    subdomains: Array,
    subscription: Object,
    submitText: String,
    updating: Boolean,
    visible: Boolean,
  });


  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const loading = ref(false);
  const subdomain = ref(null);
  const toAdd = ref([]);
  const toRemove = ref([]);
  const dialogDomainFormRef = ref(null);
  const dialogSubdomainFormRef = ref(null);
  const dataDomainScroll = ref(null);
  const dataSubdomainScroll = ref(null);

  const activePlanMaxDomains = computed(() => {
    return props.activePlan ? props.activePlan.metadata.max_domains
      : (props.subscription ? props.subscription.maxDomains : 0);
  });
  const activePlanMaxSubdomains = computed(() => {
    return props.activePlan ? props.activePlan.metadata.max_subdomains
      : (props.subscription ? props.subscription.maxSubdomains : 0);
  });
  const clearAfterClose = computed(() => {
    clearState();
    return props.visible;
  });
  const exceedDomainLimit = computed(() => {
    return props.domains.length > parseInt(props.activePlanMaxDomains);
  });
  const exceedSubdomainLimit = computed(() => {
    return props.subdomains.length > parseInt(props.activePlanMaxSubdomains);
  });
  const exceedDomainUpdate = computed(() => {
    if (props.subscription && props.subscription.plan.type === 'ZG' && props.subscription.plan.product === 'Multi-Domain') {
      // ZingGrid allowed unlimited updates
      return false;
    } else {
      // ZingChart license capped to at most 2 updates
      return updateCount.value >= 2;
    }
  });
  const missingDomains = computed(() => {
    // Skip check if optional
    if (props.optional) return false;
    return props.domains.length === 0;
  });
  const notSubdomain = computed(() => {
    // Early exit if given subdomain with no domains or not given any subdomain
    if ((props.subdomains.length === 0)  // not given any subdomain
      || (props.domains.length === 0 && props.subdomains.length > 0)) { // given subdomain with no domains
      subdomain.value = typeof props.subdomains[0] === 'object' ? props.subdomains[0].domain : props.subdomains[0];
      return false;
    }

    // Check if each subdomain is a subdomain to at least one given domain
    for (let i = 0; i < props.subdomains.length; i++) {
      let _subdomain = typeof props.subdomains[i] === 'object' ? props.subdomains[i].domain : props.subdomains[i];
      for (let j = 0; j < props.domains.length; j++) {
        let _domain = typeof props.domains[j] === 'object' ? props.domains[j].domain : props.domains[j];
        if (_subdomain.endsWith(`.${_domain}`)) {
          subdomain.value = _subdomain;
          return false;
        }
      }
    }
    return true;
  });
  const updateCount = computed(() => {
    if (props.subscription) {
      let updateCounts = props.subscription.domains.map((domain) => domain.update ? parseInt(domain.update) : 0);
      let max = updateCounts && updateCounts.length > 0 ? Math.max(...updateCounts) : 0;
      return max;
    }
  });
  const countHTML = computed(() => {
    // ZingGrid license has unlimited updates while ZingChart is capped
    let count = props.subscription && props.subscription.plan.type === 'ZG' && props.subscription.plan.product === 'Multi-Domain' ? 'unlimited' : 2 - updateCount.value;
    let color = count === 0 ? 'var(--color-secondary-1)' : 'green';
    return `<span style="color: ${color}">${count}</span>`;
  });

  /**
   * @description Run when "cancel" button clicked. Emit cancel event, clears data,
   * and clear validation errors.
   */
  function cancelUpdateDomains() {
    clearData();
    clearValidationErrors();
    dialogSubdomainFormRef.value.clearValidate();
    emit('cancel-action');
  };

  /**
   * @description Cleans form data
   */
  function clearData() {
    loading.value = false;
    toAdd.value = [];
    toRemove.value = [];
  };
  
  function clearState() {
    clearData();
    clearValidationErrors();
  };

  function clearValidationErrors() {
    dialogDomainFormRef.value.clearValidate();
    dialogSubdomainFormRef.value.clearValidate();
  };

  /**
   * @description Displays domain. `domain` can either be an object or string type. If it is type string,
   * then the domain is about to add added to the license. Else if type object, the domain is already
   * associated to license.
   * @param {Object|String} domain - domain object or domain
   */
  function getDomain(domain) {
    return typeof domain === 'object' ? domain.domain : domain;
  };

  function _validateAndAddDomain(sub) {
    // Determine if adding domain or subdomain
    let form = sub ? dialogSubdomainFormRef : dialogDomainFormRef;

    form.value.validate((valid) => {
      // Validate domain/subdomain
      let formField = sub ? 'dialogNewSubdomain' : 'dialogNewDomain';
      if (valid && props.form[formField] !== '') {
        // Add domain/subdomain
        if (sub) {
          props.subdomains.push(props.form[formField]);
        } else {
          props.domains.push(props.form[formField]);
        };
        toAdd.value.push({
          domain: props.form[formField], 
          sub,
        });
        // Clear field
        props.form[formField] = '';
        // Scroll to bottom of container to display added domain/subdomain
        setTimeout(() => {
          let target = sub === 'subdomain' ? dataSubdomainScroll : dataDomainScroll;
          target.value.scrollTop = target.scrollHeight;
        }, 0);
      }
    });
  };

  /**
   * @description Add domain in display after verify url is valid. Does not add to database yet.
   * After form submission and `update` is true, domains will be added to database.
   * Always scroll to the bottom of the container to display newly added domain/subdomain.
   * @param {Boolean} sub - adding subdomain
   */
  function handleDialogAddDomain(sub) {
    // For subdomain, check if domain field filled
    let domainFieldEmpty = props.domains.length === 0;

    if (sub && domainFieldEmpty) {
      // When adding subdomain and domain is empty, automatically add domain
      autofillDomain({
        refDomain: 'domains',
        refForm: 'form',
        refSubdomain: 'dialogNewSubdomain'
      });
      nextTick(() => {
        _validateAndAddDomain(sub);
      });
    } else {
      // Add subdomain/domain regularly
      _validateAndAddDomain(sub);
    } 
  };

  /**
   * @description Remove domain from display. Does not remove from database yet.
   * After form submission and `update` is true, domains will be removed from database.
   * @param {Object|String} domainToRemove - domain to remove from view
   * @param {Boolean} sub - flag indicating that domain is a subdomain
   */
  function removeNewDomain(domainToRemove, sub) {
    // Save reference to remove from `license` table
    if (domainToRemove.id) {
      toRemove.value.push(domainToRemove);
    } else {
      // Remove newly added domain from `toAdd`
      toAdd.value = toAdd.value.filter((domain) => domain.domain !== domainToRemove);
    }
    // Remove from display
    emit('remove-domain', domainToRemove, sub);
  };

  /**
   * @description After form submission, if `update` is true, adds and/or remove domains
   * from database. Then emits event to perform action after formm submit.
   */
  function updateDomains() {
    // Updating license table
    if (props.updating) {
      // Set `loading` attribute
      loading.value = true;
      // Add to database
      axios({
        url: '/api/subscription/domain/update',
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + $store.state.auth.idToken },
        data: {
          toAdd: toAdd.value,
          toRemove: toRemove.value,
          quickbooks_invoice_id: props.subscription.id,
        },
        json: true,
      }).then((response) => {
        toAdd.value = [];
        toRemove.value = [];
        $message({
          message: 'Domains in license updated!',
          showClose: true,
          type: 'success',
        });

        // Execute action after submit
        emit('submit-action');
      }).catch((error) => {
        loading.value = false;
        $message({
          duration: 0,
          message: error.response.data,
          showClose: true,
          type: 'error',
        });
      });
    } else {
      emit('submit-action');
    }
  };

  /**
   * @description Validates domain to check if it is a valid url or contains accepted format (*.domain.com).
   */
  function validateDomain() {
    return [
      {pattern: Domain.regexDomain, trigger: 'blur', message: Domain.messageDomain},
    ];
  };

  /**
   * @description Validates subdomain to check if it is a valid url or contains accepted format (*.domain.com), and is a subdomain of provided domain.
   */
  function validateSubdomain() {
    let domains = props.domains.concat(toAdd.value.filter((domain) => !domain.sub));
    return [
      {pattern: Domain.regexSubdomain, trigger: 'blur', message: Domain.messageSubdomain},
      {pattern: Domain.regexSubdomainCheck(domains), trigger: 'blur', message: Domain.messageSubdomainCheck(domains)}
    ];
  };

  defineExpose({clearState});
</script>

<style>
  .plan-alerts {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
  }

  .plan-alerts .el-alert {
    margin-bottom: 0.5rem;
  }

  .plan-domains {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .plan-domains__container {
    max-height: 165px;
    overflow: auto;
  }

  .plan-domains__list {
    align-items: center;
    background: white;
    border-radius: 5px;
    display: flex;
    font-size: 0.9rem;
    font-weight: 500;
    height: auto;
    justify-content: start;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
  }

  .plan-domains__count {
    background: var(--color-primary-1);
    border-radius: 5px 0 0 5px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: relative;
    padding: 0.5rem;
  }
  .plan-domains__delete {
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    color: var(--color-secondary-1);
    cursor: pointer;
  }

  .plan-domains__add {
    display: flex;
  }
  .plan-domains__add .el-form-item {
    flex: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: 2.5rem;
  }
  .plan-domains__add .el-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 1.9rem;;
    left: -56px;
    position: absolute;
    width: 55px;
  }

  .plan-domains__header,
  .plan-dialog__footer {
    display: flex;
  }

  .plan-domains__url {
    flex: 1;
    padding: 0.5rem 0 0.5rem 1rem;
  }

  .plan__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    padding: 1rem 0;
    width: 100%;
    word-break: break-word;
  }

  @media screen and (min-width: 512px) {
    .plan-domains {
      width: 60%;
    }
  }

  .plan-domains__add .el-form-item .el-input__inner {
    padding-right: 4rem;
  }
</style>