/**
 * @description Demo state is used to handle the situation of `Create.vue` component being
 * loaded twice when refreshed.
 * `Create.vue` is first mounted because url path contains "/demos/create".
 * Is it mounted again in the case where a user is logged in. 
 */

const demo = {
  namespaced: true,
  mutations: {
    cacheDemo(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    cacheDemo(context, payload) {
      context.commit('cacheDemo', payload);
    },
    consumeDemo(context) {
      context.commit('cacheDemo', '');
    },
  },
  state: {
    data: '',
  },
};

export default demo;
