<template>
  <zingnet-card>
    <template v-slot:header>
      <span>Page Speed Test</span>
    </template>
    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row">
          <section v-for="page in pages" :key="`pageSpeed-${page.site}`" class="zingnetCard--column" v-loading="page.loading">
            <div :id="`chart-pageSpeed-${page.site}`"></div>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>
<script setup>
  import { onMounted, ref } from 'vue';
  import axios from 'axios';
  import ZingnetCard from '../ZingnetCard.vue';

  const loading = ref(false);
  const pages = ref([{url: 'www.zingchart.com', site: 'ZingChart', loading: true}, {url: 'www.zinggrid.com', site: 'ZingGrid', loading: true}]);
  const chartConfig = ref({
    type: 'ring',
    labels: [{
      text: '',
      fontColor: 'var(--color-tertiary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      x: 0,
      y: '0',
    }],
    plot: {
      offsetY: '-20px',
      refAngle: 220,
      slice: '88%',
      valueBox: [{
        text: '90.42%',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 32,
        fontWeight: '100',
        offsetY: '-10px',
      }, {
        text: 'Performance',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 13,
        fontWeight: '200',
        offsetY: '17px',
      }],
    },
    plotarea: {
      marginLeft: '50%',
    },
    series: [
      {
        values: [],
        backgroundColor: 'var(--color-secondary-blue-active)',
        text: 'blank',
      },
      {
        values: [],
        backgroundColor: 'var(--color-secondary-blue)',
        text: 'Performance',
      },
    ]
  });

  onMounted(() => {
    getReport();
  });

  function getReport(page) {
    pages.value.forEach((page, i) => {
      axios({
        url: `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=https://${page.url}&key=${VUE_APP_GOOGLE_API}`,
      }).then((response) => {
        initChart(response.data, page.site, i);
      });
    });
  };

  function initChart(data, site, index) {
    let config = JSON.parse(JSON.stringify(chartConfig.value));
    let props = ['first-contentful-paint', 'speed-index', 'largest-contentful-paint', 'interactive', 'total-blocking-time', 'cumulative-layout-shift'];
    let pos = 20;

    let lighthousePerformance = parseInt(data.lighthouseResult.categories.performance.score*100);

    config.labels[0].text = `${site} Performance`;
    props.forEach((prop) => { pos = _lighthouseData(prop, data, config, pos); });

    config.plot.valueBox[0].text = lighthousePerformance;
    config.series[0].values = [100-lighthousePerformance];
    config.series[1].values = [lighthousePerformance];

    zingchart.render({
      id: `chart-pageSpeed-${site}`,
      data: config,
      height: '250px',
      width: '100%'
    });

    pages.value[index].loading = false;
  };

  function _lighthouseData(prop, data, config, pos) {
    let title = data.lighthouseResult.audits[prop].title;
    let displayValue = data.lighthouseResult.audits[prop].displayValue;

    let template = function(text, pos, isTitle) {
      return {
        text: isTitle ? `${text}:` : text,
        fontColor: isTitle ? 'var(--color-primary-gray)' : 'var(--color-secondary-blue-active)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        x: `${isTitle ? 0 : 35}%`,
        y: `${pos}%`,
      };
    };

    config.labels.push(template(title, pos, true));
    config.labels.push(template(displayValue, pos, false));

    return pos += 12;
  };
</script>

<style scoped>
  .zingnetCard--row {
    min-height: 296px;
  }
</style>