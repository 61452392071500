<template>
  <zingnet-card>
    <template v-slot:header>Licenses</template>
    <template v-slot:header__right>
      <span class="input-wrapper">
        <el-input
          @change="searchGrid"
          v-model="search"
          placeholder="Search"
          search>
        </el-input>
        <svg-icon class="el-input-icon" icon="search2" fill="var(--color-primary-gray)"></svg-icon>
      </span>
    </template>
    <template v-slot:subtitle>
      <p>
        A brief breakdown of the licenses currently in our system.<br/>
        The account, admin@zingsoft.com, carries licenses of clients not currently registered to ZingSoft Studio.<br/>
        NOTE: Do not open items on Quickbooks when making edits to this table!!!<br/>
      </p>
    </template>
    <template v-slot:body>
      <div zg-custom-creator @click="licenseModalVisible=true"><svg-icon icon="create" fill="#fff"></svg-icon></div>
      <zing-grid
        ref="licensingGrid"
        cell-class="_renderNA"
        default-display="N/A"
        editor="modal"
        editor-controls="remover"
        filter="true"
        layout="row"
        layout-controls="disabled"
        no-border
        pager="true"
        page-size="5"
        page-size-options="5,15,50"
        sort="true"
        search="true"
        selector="true">
        <zg-colgroup>
          <zg-column index="type" type="select" type-select-options="zingchart,zinggrid" renderer="renderProductType"></zg-column>
          <zg-column index="created" header="Created" _type="date" _type-date-format="MM-DD-YYYY hh:mm"></zg-column>
          <zg-column index="last_updated" header="Last Updated" _type="date" _type-date-format="MM-DD-YYYY HH:mm"></zg-column>
          <zg-column index="id_user" header="ID User" sort="disabled" hidden="true"></zg-column>
          <zg-column index="email" header="User"></zg-column>
          <zg-column index="quickbooks_invoice_id" header="Invoice ID" sort="disabled"
            type="url" type-url-src="https://c11.qbo.intuit.com/app/invoice?txnId=[[index.quickbooks_invoice_id]]" validation="string"></zg-column>
          <zg-column index="plan" sort="disabled" editor="disabled" renderer="_renderPlan" width="300px" type="select" :type-select-options="licenses"></zg-column>
          <zg-column index="domain" sort="disabled"></zg-column>
          <zg-column index="sub" header="Is Subdomain?" type="boolean" sort="disabled" renderer="renderBoolean"></zg-column>
          <zg-column index="update" type="number" sort="disabled"></zg-column>
          <zg-column index="expires" type="date" sort="disabled"></zg-column>
          <zg-column index="unsubscribed" header="Is Unsubscribed?" type="boolean" sort="disabled" renderer="renderBoolean"></zg-column>
          <zg-column index="license" sort="disabled"></zg-column>
          <zg-column index="notes" editor="textareaEditor"></zg-column>
        </zg-colgroup>
        <zg-data>
          <!-- set up the initial CRUD src -->
          <zg-param name="src" :value="srcValue"></zg-param>
          <!-- set up the authorization token -->
          <zg-param name="headers" :value="token"></zg-param>
          <!-- create options -->
          <zg-param name="idKey" value="id"></zg-param>
          <zg-param name="newIndexPath" value="id"></zg-param>
          <!-- default user id assignment is two -->
          <zg-param name="createCustomFunction" value='customCreate'></zg-param>
          <!-- modify update calls to POST -->
          <zg-param name="updateCellMethod" value='POST'></zg-param>
          <zg-param name="updateCellCustomFunction" value='customUpdate'></zg-param>
          <zg-param name="updateRowCustomFunction" value='customUpdate'></zg-param>
        </zg-data>
      </zing-grid>

      <license-modal @close="licenseModalVisible=false" v-model="licenseModalVisible"></license-modal>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, defineProps, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import LicenseModal from './LicenseModal.vue';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import rendererComposable from '../../mixins/renderer.js';
  import searchComposable from '../../mixins/search.js';

  const licensingGrid = ref(null);
  const { renderBoolean, renderNA, renderProductType } = rendererComposable({});
  const { search, searchGrid } = searchComposable({zgRef: licensingGrid});

  const props = defineProps({
    licenseType: {
      type: String
    },
  });

  const $store = useStore();
  const licenses = ref('New/Annual:ZC-Enterprise-A,New/Annual:ZC-Internal-A,New/Annual:ZC-OEM-A,New/Annual:ZC-SaaS-A,New/Annual:ZC-Website-A,New/Annual:ZG-Multi-Domain-A,New/Annual:ZG-OEM-A,New/Annual:ZG-Single-Domain-A,Perpetual:ZC-Enterprise-P,Perpetual:ZC-Internal-P,Perpetual:ZC-Saas-P,Perpetual:ZC-Website-P,Perpetual:ZG-Multi-Domain-P,Perpetual:ZG-Single-Domain-P');
  const licenseModalVisible = ref(false);
  const textareaEditor = ref({
    init($cell, editorField) {
      let textareaEl = document.createElement('textarea');
      textareaEl.autoComplete = 'off';
      textareaEl.ariaInvalid = false;
      textareaEl.name = 'notes';
      textareaEl.rows = 4;
      textareaEl.cols = 50;
      editorField.appendChild(textareaEl);
    },
    onOpen($cell, editorField, mData) {
      let textareaEl = editorField.querySelector('textarea');
      if (!mData) {
        mData = editorField.value || '';
      }
      textareaEl.value = String(mData);
    },
    onClose(editorField) {
      return editorField.querySelector('textarea').value;
    },
  });
 
  const token = computed(() => {
    return `{"Authorization": "Bearer ${$store.state.auth.idToken}", "X-CSRF-TOKEN": "${axios.defaults.headers.delete['X-CSRF-Token'] }"}`;
  });
  const srcValue = computed(() => {
    return window ? `${window.location.origin}/api/license` : null;
  });

  onMounted(() => {
    // getAllLicenses();
    ZingGrid.registerEditor(textareaEditor.value, 'textareaEditor');
    ZingGrid.registerMethod(customCreate, 'customCreate');
    ZingGrid.registerMethod(_renderPlan, '_renderPlan');
    ZingGrid.registerMethod(renderBoolean, 'renderBoolean');
    ZingGrid.registerMethod(renderNA, 'renderNA');
    ZingGrid.registerMethod(renderProductType, 'renderProductType');
  });
  
  function _renderPlan(val) {
    // Remove before colon
    let split = val.split(':').pop().split('-');
    let planProduct = split.shift() === 'ZG' ? 'ZingGrid' : 'ZingChart';
    let planInterval = split.pop() === 'A' ? 'Annual' : 'Perpetual';
    let planType = split.join('-')
    return [planInterval, planProduct, planType].join(' ');
  };

  /**
   * @description Grabs and format sales data of all three products
   */
  function getAllLicenses() {
    return axios({
      url: 'api/license',
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      }
    })
    .then((result) => {
      licensingGrid.value.setData(result.data);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  function _customUpdate(record) {
    let { id, expires, domain, id_user, email, quickbooks_invoice_id, type, notes } = record;

    // default to updated record or a year from now
    const YEAR_IN_MILLISECONDS = 31450000000;
    if (!expires) {
      expires = new Date( Date.now() + YEAR_IN_MILLISECONDS ).toISOString();
    };

    return {
      id,
      domain,
      id_user,
      email,
      quickbooks_invoice_id,
      type,
      notes
    }
  };

  function customCreate(recordId, rawData, options) {
    let {domain, quickbooks_invoice_id, notes } = rawData;

    axios({
      url: `/api/license`,
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
      data: {
        domain,
        id_user: '2', // default is ZingGrid user
        email: 'admin@zingsoft.com',
        quickbooks_invoice_id,
        type: licenseType.value,
        notes
      }
    }).then(() => {
      options.callback();
    });
  };

  function customUpdate(recordId, rawData, options) {
    let data = _customUpdate(rawData);
    
    axios({
      url: `/api/license/${rawData.id}`,
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
      data
    }).then(() => {
      options.callback();
    });
  };
</script>

<style scoped>
  [zg-custom-creator] {
    background: var(--color-primary-gray);
    border-bottom: var(--zingnet-border);
    height: 2rem;
  }
  [icon="create"] {
    left: 31px;
    position: relative;
    top: 5px;
  }
  [icon="create"]:hover {
    cursor: pointer;
  }
</style>

<style>
  [zg-custom-creator] [icon="create"]:hover svg {
    fill: var(--color-secondary-blue) !important;
  }
  .zingnetCard zg-filter input[type="checkbox"][aria-label="Filter Is Unsubscribed? Column"]:before {
    left: 54px;
  }
</style>