import { ref } from 'vue';

export default function caching() {
  const MILLISECONDS = ref(1000);
  const SECONDS = ref(60);
  const MINUTES = ref(60);
  const HOURS = ref(24);
  
  /**
   * @description Determine whether or not to fetch for data. Returns data if not expired and item exists in localstorage.
   * Else if not expired and item not in localstorage, or is expired then return false.
   * @param {String} item - localstorage item to check expiration for.
   * Format for localstorage item expected to be: `zs_ITEM`.
   * Format for expiration time for item expected to be: `zs_ITEM_expire`.
   */
  function cacheFetch(item) {
    let itemName = `zs_${item}`;
    let data = localStorage.getItem(itemName);
    let expire = _cacheGetExpire(item);
    let now = new Date().getTime();
    let isExpired = expire > now;
    
    // Check if localstorage item exists or is expired
    if (data && isExpired) {
      // Fetches from localstorage if exists and not expired
      return data;
    } else {
      // Generate new expiration dat
      if (isExpired) _cacheGenerateExpire(item);

      // False if data not exists and/or expired
      return false;
    }
  };

  /**
   * @description Saves data in as localstorage item and generates expiration time for item
   * @param {String} item - localstorage item to check expiration for.
   * Format for localstorage item expected to be: `zs_ITEM`.
   * Format for expiration time for item expected to be: `zs_ITEM_expire`.
   * @param {String|Object} data - data to set in localstorage item
   */
  function cacheSaveData(item, data) {
    // Save data
    // Make sure value can be saved in localstorage
    let itemData = typeof data === 'object' ? JSON.stringify(data) : data;
    localStorage.setItem(`zs_${item}`, itemData);

    // Save expiration time
    _cacheGenerateExpire(item);
  };

  /**
   * @description Fetch expiration time from localstorage when `data` is null. If does not exist, generate new expiration date.
   * @param {String} item - localstorage item to check expiration for.
   * Format for localstorage item expected to be: `zs_ITEM`.
   * Format for expiration time for item expected to be: `zs_ITEM_expire`.
   */
  function _cacheGetExpire(item) {
    let itemName = `zs_${item}_expire`;

    // Use existing expiration time if exists in localstorage or generate new one
    let zsExpire = localStorage.getItem(itemName);
    return zsExpire ? parseInt(zsExpire) : _cacheGenerateExpire(item);
  };

  /**
   * @description Generate and set new expiration time. Expiration time is a day from generated time in milliseconds.
   * @param {String} item - localstorage item to check expiration for.
   * Format for localstorage item expected to be: `zs_ITEM`.
   * Format for expiration time for item expected to be: `zs_ITEM_expire`.
   */
  function _cacheGenerateExpire(item) {
    // Calculate new expiration time (one day from current time)
    let newExpire = new Date().getTime() + (MILLISECONDS.value * SECONDS.value * MINUTES.value * HOURS.value);
    
    // Set expiration time in local storage (given localstorage item name)
    if (item) localStorage.setItem(`zs_${item}_expire`, newExpire);
    
    return newExpire;
  };

  return {
    MILLISECONDS,
    SECONDS,
    MINUTES,
    HOURS,
    cacheFetch,
    cacheSaveData,
    _cacheGetExpire,
    _cacheGenerateExpire,
  };
};
