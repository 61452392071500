<template>
  <zingnet-card :gridBody="true">
    <template v-slot:header>
      <span>
        {{type}} Site Analytics
        <date-range @updateDateRange="updateDateRange" value-format="yyyy-MM-dd"></date-range>

        <div class="el-select-wrapper" page-visitor-select>
          <el-select v-model="selected" @change="updateTable">
            <el-option-group
              v-for="group in selectOptions"
              :key="group.label"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>

          <svg-icon class="el-select-icon" icon="angle"></svg-icon>
        </div>
      </span>
    </template>

    <template v-slot:header__right>
      <el-button @click="openMatomo">Open Matomo</el-button>
    </template>

    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row no-border">
          <el-button @click="back" subtable-back :disabled="disableBack">Back</el-button>
          <!-- Main grid -->
          <zing-grid
            ref="zgRef"
            cell-break="ellipsis"
            cell-class="renderNA"
            column-controls="true"
            filter="true"
            height=725
            main
            no-border
            pager="true"
            page-size=10
            selector="true"
            sort="true">
            <zg-colgroup>
              <zg-column index="subtable" sort="disabled" filter="disabled" renderer="_renderSubtable" width="118px"></zg-column>
              <zg-column index="label,url" header="Label" width="200px" type="custom" renderer="_renderUrl"></zg-column>
              <zg-column index="nb_hits" header="Pageviews"></zg-column>
              <zg-column index="bounce_rate"></zg-column>
              <zg-column index="avg_time_on_page"></zg-column>
              <zg-column index="exit_rate"></zg-column>
              <zg-column index="avg_time_generation"></zg-column>
            </zg-colgroup>
          </zing-grid>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, defineProps, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import DateRange from '../DateRange.vue';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import ZingnetCard from '../ZingnetCard.vue';

  import dateRangeComposable from '../../mixins/dateRange.js';
  import rendererComposable from '../../mixins/renderer.js';

  const { period, updateDateRange } = dateRangeComposable({getReportData});
  const { renderNA } = rendererComposable({});

  const props = defineProps({
    type: String,
  });

  const $store = useStore();
  const baseData = ref(null);
  const loading = ref(false);
  const dataHistory = ref([]);
  const pageHistory = ref([]);
  const columnsMap = ref({
    'Actions': ['exit_rate', 'avg_time_generation', 'avg_time_on_page', 'bounce_rate', 'nb_hits'],
    'DevicesDetection': ['bounce_count', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
    'Referrers': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
    'UserCountry': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
    'UserLanguage': ['bounce_count', 'max_actions', 'nb_actions', 'nb_conversions', 'nb_visits', 'nb_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length'],
    'VisitorInterest': ['nb_visits'],
    'VisitorInterest.getNumberOfVisitsByVisitCount': ['nb_visits', 'nb_visits_percentage'],
    'VisitsSummary': ['avg_time_on_site', 'bounce_count', 'bounce_rate', 'max_actions', 'nb_actions', 'nb_actions_per_visit', 'nb_uniq_visitors', 'nb_users', 'nb_visits', 'nv_visits_converted', 'sum_visit_length'],
    'VisitTime': ['bounce_count', 'max_actions', 'nb_actions', 'nb_visit', 'nv_visits_converted', 'sum_daily_nb_uniq_visitors', 'sum_daily_nb_users', 'sum_visit_length']
  });
  const selectOptions = ref([{
    label: 'Devices',
    options: [{
      label: 'Brand',
      value: 'DevicesDetection.getBrand',
    }, {
      label: 'Browsers',
      value: 'DevicesDetection.getBrowsers',
    }, {
      label: 'Browser Engines',
      value: 'DevicesDetection.getBrowserEngines',
    }, {
      label: 'Browser Versions',
      value: 'DevicesDetection.getBrowserVersions',
    }, {
      label: 'Model',
      value: 'DevicesDetection.getModel',
    }, {
      label: 'OS Families',
      value: 'DevicesDetection.getOsFamilies',
    }, {
      label: 'OS Versions',
      value: 'DevicesDetection.getOsVersions',
    }, {
      label: 'Type',
      value: 'DevicesDetection.getType',
    }],
  }, {
    label: 'Referrers',
    options: [{
      label: 'All',
      value: 'Referrers.getAll',
    }, {
      label: 'Search Engines',
      value: 'Referrers.getSearchEngines',
    }],
  }, {
    label: 'Users',
    options: [{
      label: 'Countries',
      value: 'UserCountry.getCountry',
    }, {
      label: 'Languages',
      value: 'UserLanguage.getLanguage',
    }],
  }, {
    label: 'Visitor Actions',
    options: [{
      label: 'Downloads',
      value: 'Actions.getDownloads',
    }, {
      label: 'Entry Page Titles',
      value: 'Actions.getEntryPageTitles',
    }, {
      label: 'Entry Page Urls',
      value: 'Actions.getEntryPageUrls',
    }, {
      label: 'Exit Page Titles',
      value: 'Actions.getExitPageTitles',
    }, {
      label: 'Exit Page Urls',
      value: 'Actions.getExitPageUrls',
    }, {
      label: 'Outlinks',
      value: 'Actions.getOutlinks',
    }, {
      label: 'Page Titles',
      value: 'Actions.getPageTitles',
    }, {
      label: 'Page Titles Following Site Search',
      value: 'Actions.getPageTitlesFollowingSiteSearch',
    }, {
      label: 'Page Urls',
      value: 'Actions.getPageUrls'
    }, {
      label: 'Page Urls Following Site Search',
      value: 'Actions.getPageUrlsFollowingSiteSearch',
    }, {
      label: 'Site Search Categories',
      value: 'Actions.getSiteSearchCategories',
    }, {
      label: 'Site Search Keywords',
      value: 'Actions.getSiteSearchKeywords',
    }, {
      label: 'Site Search No Result Keywords',
      value: 'Actions.getSiteSearchNoResultKeywords',
    }],
  }, {
    label: 'Visitor Interest',
    options: [{
      label: 'Visits by Days Since Last',
      value: 'VisitorInterest.getNumberOfVisitsByDaysSinceLast',
    }, {
      label: 'Visits by Visit Count',
      value: 'VisitorInterest.getNumberOfVisitsByVisitCount',
    }, {
      label: 'Visits per Page',
      value: 'VisitorInterest.getNumberOfVisitsPerPage',
    }, {
      label: 'Visits per Visit Duration',
      value: 'VisitorInterest.getNumberOfVisitsPerVisitDuration',
    }],
  }, {
    label: 'Visits Summary',
    options: [{
      label: 'Summary',
      value: 'VisitsSummary.get',
    }],
  }, {
    label: 'Visit Time',
    options: [{
      label: 'Local',
      value: 'VisitTime.getVisitInformationPerLocalTime',
    }],
  }]);
  const selected = ref('Actions.getPageUrls');
  const zgRef = ref(null);

  const disableBack = computed(() => {
    return dataHistory.value.length === 0; 
  });

  onMounted(() => {
    setupDrilldown();

    ZingGrid.registerMethod(renderNA, 'renderNA');
    ZingGrid.registerMethod(_renderSubtable, '_renderSubtable');
    ZingGrid.registerMethod(_renderUrl, '_renderUrl');
  });

  function generateOptionValue(type, val) {
    return `${type}.get${val.replace(/\s/g, '')}`;
  };

  function getReportData() {
    loading.value = true;

    axios({
      url: `/api/admin/matomo/visitors?method=${selected.value}&site=${props.type}&period=${period.value}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      }
    }).then((response) => {
      baseData.value = response.data;
      zgRef.value.setData(baseData.value);
      loading.value = false;
    });
  };

  function openMatomo() {
    window.open('https://tracking.zingchart.com/piwik/');
  };

  function updateColumns() {
    // Display selected columns
    let cols = columnsMap.value[selected.value];
    if (!cols) {
      let split = selected.value.split('.').shift();
      cols = columnsMap.value[split];
    };

    // Default columns
    let colObj = [{
      index: 'subtable', sort: 'disabled', filter: 'disabled', renderer: '_renderSubtable', width: '118px',
    }, {
      index:'label,url', header: 'label', width: '200px', type: 'custom', renderer: '_renderUrl',
    }];

    // Add columns for specified data
    cols.forEach((col) => {
      colObj.push({index: col});
    });

    // Set data
    zgRef.value.columns = JSON.stringify(colObj);
  };

  function updateTable() {
    // Clear history
    dataHistory.value = [];
    pageHistory.value = [];

    // Get and update data
    getReportData();

    // Update columns
    updateColumns();
  };

  function setupDrilldown() {
    zgRef.value.addEventListener('cell:click', bindExpandEvent);
  
    function bindExpandEvent(e) {
      // Check if subtable available for row
      let attributes = e.detail.ZGTarget.attributes;
      let dataFieldIndex = attributes.getNamedItem('data-field-index').value;
      let subtable = e.detail.ZGData.value;
      if (dataFieldIndex === 'subtable' && subtable) {
        // Update table and add to history
        viewSubtable(subtable);
      };
    };
  };

  function back() {
    // Remove from history
    let data = dataHistory.value.pop();
    let page = pageHistory.value.pop();

    // Setup grid
    zgRef.value.setData(data);
    zgRef.value.setPageIndex(page);
  };

  function viewSubtable(data) {
    // Save history
    dataHistory.value.push(zgRef.value.getData());
    pageHistory.value.push(zgRef.value.getPageIndex())

    // Set data
    zgRef.value.setData(data);
  };

  function _renderSubtable(subtable) {
    return subtable
      ? `<button type="button" subtable-expand class="el-button el-button--default" data-v-2e79777e=""><span>+</span></button>`
      : '';
  };

  function _renderUrl(label, url) {
    return url
      ? `<a href="${url}" target="_blank">${label}</a>`
      : label;
  };
</script>

<style>
  .zingnetCard .el-button[subtable-back] {
    margin-left: 2rem;
    position: absolute;
    top: 2.3rem;
    z-index: 100;
  }
  .zingnetCard zg-cell .el-button[subtable-expand] {
    margin: 0;
  }
  .zingnetCard [data-field-index="subtable"] {
    display: flex;
    justify-content: center;
  }

  .zingnetCard [page-visitor-select] {
    margin-top: 1rem;
  }
  .zingnetCard [page-visitor-select],
  .zingnetCard [page-visitor-select] input {
    width: 275px
  }
</style>