/**
 * @description Mixin used for `Create.vue` to handle the UI of the studio.
 * This deals basically with resetting UI, handling toggle and resize of the code
 * and preview handles.
 */
import { computed, nextTick, ref, watch } from 'vue';

export default function editorPaneComposable({$store, instance, $resizerPreview, editorLayout, isMobile }) {
  const currentlyResizing = ref(null);
  const cssSize = ref({
    height: 0,
    width: 0,
  });
  const htmlSize = ref({
    height: 0,
    width: 0,
  });
  const jsSize = ref({
    height: 0,
    width: 0,
  });
  const previewSize = ref({
    height: 400,
    width: 0,
  });
  const lastX = ref(0);
  const lastY = ref(0);
  const resize = ref({
    height: 10,
    width: 10,
  });
  const showControls = ref(['html', 'css', 'js']);
  const windowSize = ref(document.body ? document.body.getBoundingClientRect().width : 0);

  const codeActive = computed(() => {
    return showHTML.value + showCSS.value + showJS.value;
  });
  const cssStyle = computed(() => {
    let style = {
      display: (showCSS.value) ? 'initial' : 'none',
      height: isMobile.value || layoutRow.value ? '100%' : cssSize.value.height + 'px',
      width: isMobile.value || layoutCol.value ? '100%' : cssSize.value.width + 'px',
    };
    return style;
  });
  const htmlStyle = computed(() => {
    let style = {
      display: (showHTML.value) ? 'initial' : 'none',
      height: isMobile.value || layoutRow.value ? '100%' : htmlSize.value.height + 'px',
      width: isMobile.value || layoutCol.value ? '100%' : htmlSize.value.width + 'px',
    };
    return style;
  });
  const jsStyle = computed(() => {
    let style = {
      display: (showJS.value) ? 'initial' : 'none',
      height: isMobile.value || layoutRow.value ? '100%' : jsSize.value.height + 'px',
      width: isMobile.value || layoutCol.value ? '100%' : jsSize.value.width + 'px',
    };
    return style;
  });
  const layoutCol = computed(() => {
    return editorLayout.value.indexOf('col') > -1;
  });
  const layoutRow = computed(() => {
    return editorLayout.value.indexOf('row') > -1;
  });
  const previewStyle = computed(() => {
    return {
      'min-height': `${previewSize.value.height}px`,
      'min-width': `${previewSize.value.width}px`,
    };
  });
  const showHTML = computed(() => {
    return showControls.value.includes('html');
  });
  const showCSS = computed(() => {
    return showControls.value.includes('css');
  });
  const showJS = computed(() => {
    return showControls.value.includes('js');
  });
  const stateHtmlTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsHtml) {
      return settings.tabsHtml === 'true' ? 'html' : null;
    } else {
      return 'html';
    };
  });
  const stateCssTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsCss) {
      return settings.tabsCss === 'true' ? 'css' : null;
    } else {
      return 'css';
    };
  });
  const stateJsTab = computed(() => {
    let settings = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    if (settings.tabsJs) {
      return settings.tabsJs === 'true' ? 'js' : null;
    } else {
      return 'js';
    };
  });

  watch(showControls, () => {
    resetPanes();
  });

  // Call in `onMounted()`
  function setupEditorPanes() {
    nextTick(() => {
      // UI Reset
      resetPanes();

      $resizerPreview.value.addEventListener('touchstart', (e) => setActiveResizer('preview', e));
      window.addEventListener('touchend', untrackEvent);
      window.addEventListener('touchmove', calculateDragHandle);
      window.addEventListener('mouseup', untrackEvent);
      window.addEventListener('mousemove', calculateDragHandle);
      window.addEventListener('resize', handleResize);
      setTimeout(() => {
        const templateList = document.querySelector('.template__list');
        if (templateList) {
          templateListBottom.value = templateList ? templateList.getBoundingClientRect().bottom : 0;
          templateList.addEventListener('scroll', updateMoreTemplates);
        }
      }, 3000);

      setupCodePanes();
    });
  };

  // Call in `onUnmounted()`
  function cleanupEditorPanes() {
    $resizerPreview.value.removeEventListener('touchstart', (e) => setActiveResizer('preview', e));
    window.removeEventListener('touchend', untrackEvent);
    window.removeEventListener('touchmove', calculateDragHandle);
    window.removeEventListener('mouseup', untrackEvent);
    window.removeEventListener('mousemove', calculateDragHandle);
    window.removeEventListener('resize', handleResize);
  };
  
  function calculateDragHandle(event) {
    if (currentlyResizing.value) {
      switch(currentlyResizing.value) {
        case 'html':
          resizeCodepane(htmlSize.value, cssSize.value, jsSize.value, event);
        break;
        case 'css':
          resizeCodepane(cssSize.value, null, jsSize.value, event);
        break;
        case 'preview':
          resizePreviewpane(event);
        break;
      }
      lastX.value = setClientPos(event, 'x');
      lastY.value = setClientPos(event, 'y');
    }
    // Needs a preventDefault, otherwise mouseup events aren't always reliable.
    // Do not apply preventDefault to inputs and textarea because it disables highlighting
    // Do not apply to pre to not block code dragging
    if (event.type !== 'touchmove' && !(event.srcElement.tagName === 'INPUT'
      || event.srcElement.tagName === 'TEXTAREA'
      || event.srcElement.tagName === 'PRE')) event.preventDefault();
  };

  /**
   * @description Handle resize by triggering resize event to preview in preview pane,
   * reseting pane size, and getting current window size.
   */
  function handleResize() {
    resizePreview();
    resetPanes();
    trackWindowSize();
  };

  /**
   * @description Maximize/show a code pane
   * @param {String} type - code pane
   */
  function maximizeWindow(type) {
    showControls.value = [type];
  };

  /**
   * @description Setups the studio to display only HTML code
   */
  function setupCodePanes() {
    if (isMobile.value) {
      showControls.value = 'html';
    } else {
      let currentSettings = [stateHtmlTab.value, stateCssTab.value, stateJsTab.value];
      currentSettings = currentSettings.filter((el) => el);
      showControls.value = currentSettings;
    };
  };

  /**
   * @description Display code pane
   * @param {String} type - code pane to display
   * @return {Object} opacity to show/hide code pane
   */
  function showControlStyling(type) {
    return (showControls.value.includes(type)) ? {opacity: 1} : {opacity: 0};
  };

  /**
   * @description Reset size of code and preview panes
   */
  function resetPanes() {
    if(instance.refs.editor) {
      // Caclulate code panel size
      const editorHeight = window.getComputedStyle(instance.refs.editor).height.slice(0, -2);
      const editorWidth = window.getComputedStyle(instance.refs.editor).width.slice(0, -2);
      const resizeActive = codeActive.value - 1;
      const resizeSize = resizeActive * (layoutCol.value ? resize.value.height : resize.value.width);
      const codeHeight = (editorHeight - resizeSize)/(showControls.value.length);
      const codeWidth = (editorWidth - resizeSize)/(showControls.value.length);

      // Reset code panel height
      htmlSize.value.height = codeHeight;
      cssSize.value.height = codeHeight;
      jsSize.value.height = codeHeight;

      // Reset code panel width
      htmlSize.value.width = codeWidth;
      cssSize.value.width = codeWidth;
      jsSize.value.width = codeWidth;
    }
  };

  /**
   * @description Resize pane width
   * @param {Object} paneSizeObj - pane size object
   * @param {Object} ps1 - paneSize object of first affected code pane
   * @param {Object} ps2 - paneSize object of second affected code pane
   * @param {Object} event - native drag event object
   */
  function resizeCodepane(paneSizeObj, ps1, ps2, event) {
    const MIN_SIZE = 50;
    let last = layoutCol.value ? lastY.value : lastX.value;
    let client = layoutCol.value ?
      setClientPos(event, 'y') : setClientPos(event, 'x');
    let size = layoutCol.value ? 'height' : 'width';
    if (client < last) {
      // Calc html
      let delta = (last - client);
      let paneSize = (paneSizeObj[size] - delta);
      if (paneSize < MIN_SIZE) {
        return;
      }
      // Remove sizing from target
      paneSizeObj[size] = paneSize;
      // Add sizing to the right neighbor
      if (showCSS.value && ps1) {
        ps1[size] = ps1[size] + delta;
      } else {
        ps2[size] = ps2[size] + delta;
      }
    } else {
      const delta = (client - last);
      // Remove sizing from neighbors
      if (showCSS.value && ps1) {
        ps1[size] = parseInt(ps1[size]) - delta;
      } else {
        ps2[size] = parseInt(ps2[size]) - delta;
      }
      // Add sizing from neighbors
      paneSizeObj[size] = parseInt(paneSizeObj[size]) + delta;
    }
  };

  /**
   * @description Resize preview inside preview pane
   */
  function resizePreview() {
    let previewIframe = document.getElementById('preview');
    if (previewIframe) {
      previewIframe.contentWindow.postMessage('resize', '*');
    };
  };

  /**
   * @description Resize pane height
   * @param {Object} event - navtive drav event object
   */
  function resizePreviewpane(event) {
    if (event) {
      let previewSizeTemp = parseInt(layoutCol.value ? previewSize.value.width : previewSize.value.height);
      let last = layoutCol.value ? lastX.value : lastY.value;
      let client = layoutCol.value ?
        setClientPos(event, 'x') : setClientPos(event, 'y');
      let size = layoutCol.value ? 'width' : 'height';
      if (client < last) {
        let delta = parseInt(last - client);
        let newSize = editorLayout.value === 'col-left'
          || editorLayout.value === 'row-top' ? previewSizeTemp - delta : previewSizeTemp + delta;
        previewSize.value[size] = newSize;
      } else {
        let delta = parseInt(client - last);
        let newSize = editorLayout.value === 'col-left'
          || editorLayout.value === 'row-top' ? previewSizeTemp + delta : previewSizeTemp - delta;
        previewSize.value[size] = newSize;
      }
    }
  };

  /**
   * @description Gets the clientX and clientY based off of the type of event. For touch events,
   * the x and y coordinates are located in 'touch[0]' property
   * @param {Object} event - native event object
   * @param {String} pos - position to get ('x', 'y')
   */
  function setClientPos(event, pos) {
    let mouseType = ['mousedown', 'mousemove'];

    if (pos === 'x') {
      return mouseType.includes(event.type) ? event.clientX : event.touches[0].clientX;
    } else {
      return mouseType.includes(event.type) ? event.clientY : event.touches[0].clientY;
    }
  };

  /**
   * @description Triggered on 'mousedown' or 'touchstart' event to start resizing pane.
   * `currentlyResizing.value` is set to start resize event. The current mouse/touch location
   * is saved to later determine how much to resize pane.
   * @param {String} target - pane being resized
   * @param {Object} event - navtive event object
   */
  function setActiveResizer(target, event) {
    currentlyResizing.value = target;
    lastX.value = setClientPos(event, 'x');
    lastY.value = setClientPos(event, 'y');
    if(target === 'html') {
      if (layoutRow.value)
        htmlSize.value.width = window.getComputedStyle(instance.refs.codeHTML.$el).width.slice(0, -2);
    } else if (target === 'css') {
      if (layoutRow.value)
        cssSize.value.width = window.getComputedStyle(instance.refs.codeCSS.$el).width.slice(0, -2);
    } else if (target === 'preview') {
      if (layoutCol.value)
        previewSize.value.width = window.getComputedStyle(instance.refs.previewWrap).width.slice(0, -2);
      else
        previewSize.value.height = window.getComputedStyle(instance.refs.previewWrap).height.slice(0, -2);
    }
  };

  /**
   * @description Get the size of the window
   */
  function trackWindowSize() {
    windowSize.value = document.body ? document.body.getBoundingClientRect().width : 0;
  };

  /**
   * @description On control change, update `showControls.value`
   * @param {Array} option - option(s) selected
   */
  function updateControls(option) {
    showControls.value = option;
  };

  /**
   * @description Triggered on 'mouseup' or 'touchend' event to track when 'mousemove' or
   * 'touchmove' event ends. This determines when resize is done.
   */
  function untrackEvent() {
    currentlyResizing.value = null;
  };

  return {
    currentlyResizing,
    previewSize,
    cssStyle,
    htmlStyle,
    jsStyle,
    previewStyle,
    showHTML,
    showCSS,
    showJS,
    setupEditorPanes,
    cleanupEditorPanes,
    handleResize,
    maximizeWindow,
    resizePreview,
    setActiveResizer,
    updateControls
  }
};
