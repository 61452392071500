<template>
  <zingnet-card :grid-body=true>
    <template v-slot:header>Latest Active Users</template>
    <template v-slot:header__right>
      <div>
        <span class="input-wrapper">
          <el-input
            @change="searchGrid"
            v-model="search"
            placeholder="Search"
            search>
          </el-input>
          <svg-icon class="el-input-icon" icon="search2" fill="var(--color-primary-gray)"></svg-icon>
        </span>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <zing-grid
          ref="zgRef"
          id="latestUsers"
          cell-class="renderNA"
          default-display="N/A"
          height=650
          layout="row"
          layout-controls="disabled"
          no-border
          pager="true"
          page-size=10
          search="true"
          selector="true"
          sort="true">
          <zg-data>
            <!-- REST PARAMS -->
            <zg-param name="headers" :value="headerVal"></zg-param>
            <zg-param name="src" :value="srcValue"></zg-param>
            <!-- LOADBYPAGE PARAMS -->
            <zg-param name="countPath" value="total"></zg-param>
            <zg-param name="idKey" value="id"></zg-param>
            <zg-param name="loadByPage" value="true"></zg-param>
            <zg-param name="limitToKey" value="limit"></zg-param>
            <zg-param name="limitToValue" value="10"></zg-param>
            <zg-param name="pageKey" value="page"></zg-param>
            <zg-param name="recordPath" value="result"></zg-param>
            <zg-param name="searchKey" value="search"></zg-param>
            <zg-param name="sortBy" value='email'></zg-param>
            <zg-param name="sortByKey" value='sort'></zg-param>
            <zg-param name="sortDirKey" value='dir'></zg-param>
            <zg-param name="queryString" value='?exclude=zingsoft+pint'></zg-param>
          </zg-data>
          <!-- ZGColgroup -->
          <zg-colgroup>
            <zg-column index="email, name, id" header="User" renderer="renderUser"></zg-column>
            <zg-column index="last_login" header="Last Seen" type="date" renderer="renderDate"></zg-column>
            <zg-column index="last_ip" header="Last IP"></zg-column>
            <zg-column index="logins_count" header="# of Logins"></zg-column>
            <zg-column index="demo_count" header="# of Demos"></zg-column>
          </zg-colgroup>
        </zing-grid>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import searchComposable from '../../mixins/search.js';
  import rendererComposable from '../../mixins/renderer.js';

  const $store = useStore();
  const zgRef = ref(null);
  const { renderDate, renderNA, renderUser } = rendererComposable({});
  const { search, searchGrid } = searchComposable({ zgRef });
  
  const headerVal = computed(() => {
    return `{"Authorization": "Bearer ${$store.state.auth.idToken}"}`;
  });
  
  const srcValue = computed(() => {
    return window ? `${window.location.origin}/api/user/list` : null;
  });

  onMounted(() => {
    ZingGrid.registerMethod(renderDate, 'renderDate');
    ZingGrid.registerMethod(renderNA, 'renderrenderNADate');
    ZingGrid.registerMethod(renderUser, 'renderUser');
  });
</script>