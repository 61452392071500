<template>
  <div zing-theme-builder="item" :name="name" :value="value">
    <div class="form-field">
      <label data-field="checkbox">
        <form-checkbox :checked="selected"></form-checkbox> 
        <span zing-theme-builder="control-cell" v-html="tooltip"></span>
        <span zing-theme-builder="control-cell">
          <span v-if="resolvedVal" :defaultVal="defaultVal" zing-theme-builder="default" v-html="resolvedVal"></span>
          <span v-else zing-theme-builder="default" :defaultVal="defaultVal"><em>{{defaultVal}}</em></span>
          <input @keyup="_controlTextChange" type="text" zing-theme-builder="value" :value="value">
        </span>
      </label>
    </div>
  </div>
</template>

<script setup>
  // TODO not fully converted, still using $children and $parent
  import { getCurrentInstance, onMounted, ref } from'vue';
  import FormCheckbox from './FormCheckbox.vue';
  import DEFAULT_VAR from './assets/json/themeDefault.json';
  import THEME_VAR from './assets/json/theme.json';
  
  const props = defineProps({
    defaultVal: String,
    name: String,
    value: String,
    selected: Boolean
  });

  const instance = getCurrentInstance();
  // json files
  const androidJson = ref(null);
  const defaultJson = ref(DEFAULT_VAR);
  const iosJson = ref(null);
  const genThemeJson = ref(THEME_VAR);
  //misc
  const resolvedVal = ref(false);
  const $builder = ref(null);
  const regex_var = ref(/var\(.*\)/g);
  const tooltip = ref(null);
  const tooltipSelector = ref('[zing-theme-builder="tooltip"]');

  onMounted(() => {
    $builder.value = this.$parent.$parent.$parent;
    androidJson.value = $builder.value.themeAndroid;
    iosJson.value = $builder.value.themeIos;
    _addValue();
    _addTooltip();
  });

  /**
   * @description Display value of variable
   * @private
   */
  function _addValue() {
    // Resolve value of css variable
    let cssValue = _replaceVar(props.defaultVal);
    const template = `<em zing-theme-builder="resolved">${cssValue}</em>`;
    resolvedVal.value = template;
  };

  /**
   * @description Add tooltip containing inheritance info over css variables in Variable Column
   * @private
   */
  function _addTooltip() {
    // Get list of inherited
    const inherited = _splitArgs(props.defaultVal);
    // Remove values
    inherited.forEach(function(item, index) { if(!item.includes('var(')) { inherited.splice(index, 1); } });
    let tooltipTemplate = `<em zing-theme-builder="var"><em zing-theme-builder="name">${props.name}</em>`
    // Add tooltip if css variables inherit from another
    if(inherited.length !== 0) {
      tooltipTemplate += `<em zing-theme-builder="tooltip">Inheriting from:<ol>`;
      inherited.forEach(function(item) { tooltipTemplate += `<li>${item}</li>`; });
      tooltipTemplate += `</ol></em>`
    } else tooltipTemplate += `</em>`;
    tooltip.value = tooltipTemplate;
  };

  /**
   * @description When checkbox clicked, calls parent function
   * @private
   */
  function _controlCheckboxClick(e) { 
    $builder.value._controlCheckboxClick(e); 
    // Check on close if default value change
    if (!e.closest('[data-field="checkbox"]').classList.contains('selected')) _addValue();
    _updateDependents();
  };

  /**
   * @description When test inputted, calls parent function and update dependent variables
   * @private
   */
  function _controlTextChange(e) { 
    $builder.value._controlTextChange(e);
    _updateDependents();
  };

  /**
   * @description Returns the value extracted from a css variable
   * @param {String} cssVar - css variable to extract value from
   * @private
   */
  function _extractValue(cssVar) {
    // Regex that extracts component name (ex. zg-pager, zing-grid) 
    // LIMITATIONS: misses components consisting of two '-' (ex. zg-head-cell, zg-option-list)
    const regex_component = /--[a-z]+-[a-z]+/g;
    const output = $builder.value.outputCss;
    let componentName = null;
    let cssValue = null;
    // Find component to search for css variable
    if (cssVar.includes('--zg-head-cell')) { componentName = 'ZGHeadCell'; }
    else if (cssVar.includes('--zg-option-list')) { componentName = 'ZGOptionList'; }
    else {
      const component = cssVar.match(regex_component)[0];
      componentName = component.toLowerCase().split('-').map(n => n = `${n.charAt(0).toUpperCase()}${n.substring(1)}`).join('').replace(/zg/i, 'ZG').replace(/zing-grid/i, 'ZingGrid');
    }
    // Determine value by searching in first in output
    if (output && Object.keys(output).length && output[componentName]) { output[componentName].forEach(item => { if(item.name === cssVar) cssValue = item.value; }); }
    // Then general theme
    if (!cssValue && cssVar.includes('--theme')) { genThemeJson.value.forEach(item => { if(item.name === cssVar) cssValue = item.defaultvalue; }); }
    // Then components
    if (!cssValue) {
      const cssJson = $builder.value.cssJson.filter(n => n.memberof === componentName);
      cssJson.forEach(item => { if(item.name === cssVar) { cssValue = item.defaultvalue; } });
    }
    // Value to display when css value is empty string
    if (!cssValue) cssValue = '--- no value ---';
    return cssValue;
  };

  /**
   * @description Given a default value, replaces all css variables with its values
   * @param {String} defaultVal - string to replace all var(*) with actual values
   * @private
   */
  function _replaceVar(defaultVal) {
    const regex_numVar = /var\([^/)]*\)/g;
    let retVal = defaultVal;
    // Check if contains 1+ css variable within one argument
    const numVars = defaultVal.match(regex_numVar);
    if(numVars && numVars.length > 1) {
      numVars.forEach(item => { 
        const resolved = _resolveValue(item);
        if (resolved === '--- no value ---') return resolved;
        retVal = retVal.replace(item, resolved); 
      });
    } else if (numVars && numVars.length === 1) {
      const varMatch = defaultVal.match(regex_var.value);
      const resolved = _resolveValue(varMatch[0]);
      if (resolved === '--- no value ---') return resolved;
      retVal = retVal.replace(varMatch[0], resolved);
    } else {
      retVal = _resolveValue(retVal);
    }
    return retVal;
  };

  /**
   * @description Returns a value that a css variable resolves to
   * @param {String} cssValue - string to replace all var(*) with actual values
   * @private
   */
  function _resolveValue(cssValue) {
    // Regex that extracts 'var(*)'
    let content = null;
    let wholeValue = null;
    let toReplace = null;
    // If does not contain css variables, return given value
    if (!cssValue.match(regex_var.value) || !cssValue.includes('--')) { return cssValue; }
    // If starts with var(), grab inner content
    if (cssValue.indexOf('var(') === 0) content = cssValue.slice(4, -1);
    else {
      wholeValue = cssValue;
      content = toReplace = cssValue.match(regex_var.value)[0];
    }
    let cssValues = _splitArgs(content);
    // For each item, search for value and replace 'var(*)' until no more 'var(*)' in that item
    let resolved = null;
    let resolvedArr = [];
    cssValues.forEach(item => {
      // if resolve return first item that doesn't return '--- no value ---'
      const matches = item.match(regex_var.value);
      if (matches) {
        // (Case 1) Contains var(...)
        const resolve = _resolveValue(item);
        resolvedArr.push(resolve);
      } else if (item.includes('--')) {
        // (Case 2) Contains css variable to resolve
        const extracted = _extractValue(item);
        if (extracted === '--- no value ---') { 
          resolvedArr.push(extracted);
        } else {
          const resolve = _resolveValue(extracted);
          resolvedArr.push(resolve);
        }
      } else {
        // (Case 3) Returns '--- no value ---'
        resolvedArr.push(item);
      }
    });
    resolvedArr.forEach(item => { if (item && item !== '--- no value ---') { if (!resolved) resolved = item; } });
    if (resolved) {
      if (wholeValue) { return wholeValue.replace(toReplace, resolved); };
      return resolved;
    }
    return '';
  };

  /**
   * @description Splits string by css variable arguments
   * @param {String} content - string to replace all var(*) with actual values
   * @returns Array of css variable arguments
   * @private
   */
  function _splitArgs(content) {
    // Split by comma
    let cssValues = content.split(', ');
    // Combine indices that make up on css var argument
    for(var i = 0; i < cssValues.length; i++) {
      // Check that number of '(' matches number of ')'
      const numLeftParam = cssValues[i].match(/\(/g);
      const numRightParam = cssValues[i].match(/\)/g);
      if ((numLeftParam !== numRightParam) || (numLeftParam && numRightParam && numLeftParam.length !== numRightParam.length)) {
        if (cssValues[i+1]) {
          cssValues[i] += `, ${cssValues[i+1]}`;
          cssValues.splice(i+1, 1); 
        }
      }
    }
    return cssValues;
  };

  /** 
   * @description Updates the dependent css variables' default value
   * @param
   * @private
   */
  function _updateDependents() {
    const controls = this.$parent.$children;
    controls.forEach(function(control) {
      // Change only if not opened, has tooltip, is dependent to changing variable
      const tooltip = control.$el.querySelector('[zing-theme-builder="tooltip"]');
      if (!control.$el.querySelector('[data-field="checkbox"].selected') && tooltip
        && tooltip.textContent.includes(props.name)) {
          control._addValue()
        };
    }.bind(instance));
  };
</script>