<template>
  <zingnet-card :gridBody="true">
    <template v-slot:header>Status Badges</template>
    <template v-slot:body>
      <div class="zingBadges">
        <div class="zingnetCard--row no-border">
          <zing-grid
            id="zingNet_badge"
            cell-class="renderNA"
            data='[{"repo": null, "branch": null, "src": null}]'
            filter="true"
            height=1515
            no-border
            selector="true"
            sort>
            <zg-colgroup>
              <zg-column
                index="repo"
                sort-asc="true"
                type="url"
                type-url-src="https://github.com/zingsoftinc/[[index.repo]]"
                width="30%">
              </zg-column>
              <zg-column
                index="branch"
                default-display="N/A"
                width="25%">
              </zg-column>
              <zg-column
                index="src"
                header="Badge Status"
                type="image"
                width="45%">
              </zg-column>
            </zg-colgroup>
          </zing-grid>
        </div>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { Octokit } from '@octokit/core';
  const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
  import ZingnetCard from '../ZingnetCard.vue';
  import rendererComposable from '../../mixins/renderer';

  const { renderNA } = rendererComposable({});

  const gridData = ref([
    {
      repo: 'zingchart-lib',
      type: 'build',
      logo: 'github',
    },
    {
      repo: 'zingchart-cdn',
      type: 'Build_and_Deploy_Firebase',
      logo: 'github',
    },
    {
      repo: 'zingchart-lib',
      type: 'Deploy_Dev_CDN',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      type: 'Deploy_to_GAE',
      branch: 'stage',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      type: 'Run_Unit_Tests',
      branch: 'stage',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      type: 'Code_Coverage',
      branch: 'stage',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      src: 'https://camo.githubusercontent.com/d4e91911041c865d2c88a5514ef9ba53aa772cce/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d7a732d73747564696f2d756e69742d74657374732d7374616765',
      alt: 'Stage: Tests Passing'
    },
    {
      repo: 'zingsoft-studio',
      type: 'Deploy_to_GAE',
      branch: 'master',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      type: 'Run_Unit_Tests',
      branch: 'master',
      logo: 'github', 
    },
    {
      repo: 'zingsoft-studio',
      src: 'https://camo.githubusercontent.com/21f94993f1117a3528b580ec181540af84435590/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d7a732d73747564696f2d756e69742d74657374732d6d6173746572',
      alt: 'Master: Tests Passing'
    },
    {
      repo: 'zinggrid-cdn',
      type: 'Build_and_Deploy_Firebase',
      logo: 'github', 
    },
    {
      repo: 'zinggrid-lib',
      type: 'Run_Unit_Tests',
      branch: 'github-ci',
      logo: 'github', 
    },
    {
      repo: 'zinggrid-lib',
      type: 'Run_Unit_Tests',
      branch: 'dev',
      logo: 'github', 
    },
    {
      repo: 'zinggrid-lib',
      type: 'Run_Visual_Tests',
      branch: 'dev',
      logo: 'github', 
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/edd5eb069122dce2ac58b90e2691f2763316933e/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d7a672d76697375616c2d686561646c6573732d6368726f6d652d74657374696e67',
      alt: 'Visual Testing Headless Chrome',
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/9a9861e7292e3dcf99a6b4bd47ba92565d9b63dc/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d6465762d6d61632d6368726f6d652d6465736b746f70',
      alt: 'dev mac chrome desktop'
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/97ea094664e3fc82a2c79e3ee1c15e9a8f9df53b/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d6465762d6d61632d66697265666f782d6465736b746f70',
      alt: 'dev mac firefox desktop'
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/25bc925da36d5070e85ceeff9aa33b2d6e1f6742/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d6465762d77696e31302d6368726f6d652d6465736b746f70',
      alt: 'dev win10 chrome desktop'
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/543d327602a119a3506de74f96c874a6a15882c0/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d6465762d77696e31302d66697265666f782d6465736b746f70',
      alt: 'dev win10 firefox desktop'
    },
    {
      repo: 'zinggrid-lib',
      src: 'https://camo.githubusercontent.com/e357b309e519df52adb34689b0fbc3f722ef77c4/68747470733a2f2f7a696e67736f66742d6261646765732e676c697463682e6d652f62616467653f69643d6465762d77696e31302d656467652d6465736b746f70',
      alt: 'dev win10 edge desktop'
    },
    {
      repo: 'zinggrid-com',
      type: 'Build_and_Deploy_Firebase',
      branch: 'master',
      logo: 'github', 
    },
    {
      repo: 'zinggrid-com',
      type: 'Build_and_Deploy_Firebase',
      branch: 'dev',
      logo: 'github', 
    }
  ]);
  const mapping = ref({
    'zingchart-cdn': {
      Build_and_Deploy_Firebase: 391157,
    },
    'zingchart-lib': {
      build: 368382,
      Deploy_Dev_CDN: 395832,
    },
    'zinggrid-cdn': {
      Build_and_Deploy_Firebase: 157961,
    },
    'zinggrid-com': {
      Build_and_Deploy_Firebase: 114343,
    },
    'zinggrid-lib': {
      Run_Unit_Tests: 149927,
      Run_Visual_Tests: 390162,
    },
    'zingsoft-studio': {
      Deploy_to_GAE: 359907,
      Run_Unit_Tests: 416396,
      Code_Coverage: 416968,
    },
  });

  onMounted(() => {
    ZingGrid.registerMethod(renderNA, 'renderNA');
    
    getBadgeData();
  });

  function getBadgeData() {
    // Add src and alt of badge (if don't have)
    let data = gridData.value.filter((badgeData) => typeof badgeData.src === 'undefined');
    let promises = data.map((badgeData) => {
      return _badgeRequest(badgeData);
    });

    // Add badge data to grid
    Promise.all(promises).then(() => {
      let zgRef = document.getElementById('zingNet_badge');
      zgRef.setData(JSON.stringify(gridData.value));
    });
  };

  function _badgeRequest(badgeData) {
    return new Promise((resolve, reject) => {
      octokit.request(`GET https://api.github.com/repos/{owner}/{repo}/actions/workflows/{workflow_id}/runs?${badgeData.branch ? `branch=${badgeData.branch}&` : ''}per_page=1`, {
        owner: 'zingsoftinc',
        repo: badgeData.repo,
        workflow_id: mapping.value[badgeData.repo][badgeData.type],
      })
      .then((result) => {
        if (result && result.data && result.data.workflow_runs && result.data.workflow_runs[0]) {
          _buildShield(result.data.workflow_runs[0].conclusion, badgeData);
        };
        resolve();
      })
      .catch((error) => {
        console.log(error);
      });
    });
  };

  function _buildShield(result, badgeData) {
    let encodedText = _urlEncode(badgeData.type);

    let isSuccess = result === 'success';
    let resultText = isSuccess ? 'passing' : 'failing';
    let resultColor = isSuccess ? 'success' : 'critical';

    badgeData.src = `https://img.shields.io/badge/${encodedText}-${resultText}-${resultColor}?logo=${badgeData.logo}`;
    badgeData.alt = encodedText;
  };

  function _urlEncode(text) {
    return text.replace(' ', '%20');
  };
</script>

<style scoped>
  section {
    margin-top: 25px;
  }

  .zingBadge-section {
    border: 3px solid white;
    border-radius: 4px;
    flex: 1 0 45%;
    padding: 25px 30px;
    position: relative;
    margin: 40px 10px 10px;
    text-align: center;
  }

  .zingBadge-section h2 {
    background: #e0e0e0;
    left: 0;
    margin: auto;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: -19px;
    width: fit-content;
  }

  .zingBadges {
    display: flex;
    flex-wrap: wrap;
  }
</style>