<template>
  <section api-key v-if="adminAccess">
    <!-- Settings Section -->
    <header class="content__header">
      <h2 class="header__title">Profile</h2>
    </header>

    <zing-grid
      ref="zgRef"
      caption="Existing keys"
      :data="existingKeysData"
      layout="row"
      layout-controls="disabled"
      pager="true"
      page-size="5">
      <zg-colgroup>
        <zg-column index="key" header="Key Prefix"></zg-column>
      </zg-colgroup>
    </zing-grid>
    <el-button @click="generateKey" type="primary">Generate Key</el-button>

    <!-- Generated Key -->
    <el-dialog title="Create New API Key" v-model="dialogVisible">
      <el-alert
        title="API key will only be displayed now."
        type="info"
        :closable="false"
        show-icon>
      </el-alert>

      <p>
        Please store somewhere safe because this key cannot be generated or
        retrieved again.
      </p>

      <div api-key="key-container">
        <el-input v-model="apiKey" ref="apiKeyRef" readonly></el-input>
        <el-popover content="Copied!" placement="right" effect="light">
          <template v-slot:reference>
            <el-button @click="copyKey" size="small" trigger="click" type="primary">Copy</el-button>
          </template>
        </el-popover>
      </div>
    </el-dialog>
  </section>
</template>

<script setup>
  import { computed, defineProps, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';

  const props = defineProps({
    adminAccess: {
      type: Boolean
    }
  });

  const $store = useStore();
  const apiKey = ref('');
  const apiKeyRef = ref(null);
  const dialogVisible = ref(false);
  const existingKeys = ref([]);
  const zgRef = ref(null);

  const existingKeysData = computed(() => {
    return JSON.stringify(
      existingKeys.value.map((key) => {
        return { key: key };
      })
    );
  });

  onMounted(() => {
    if (props.adminAccess) {
      fetchExistingKeys();
    }
  });

  /**
   * @description Copies input field content container api key
   */
  function copyKey() {
    if (apiKeyRef.value) {
      apiKeyRef.value.select();
      document.execCommand("copy");
    }
  };

  /**
   * @description Fetch existing keys
   */
  function fetchExistingKeys() {
    axios({
      url: "/api/user/apikey",
      method: "GET",
      headers: { Authorization: `Bearer ${$store.state.auth.idToken}` },
    }).then((result) => {
      existingKeys.value = result.data || [];
    });
  };

  /**
   * @description Opens dialog and requests server to generate key. When received, displays API key to
   * user, which will only be available once.
   */
  function generateKey() {
    dialogVisible.value = true;

    axios({
      url: "/api/user/apikey",
      method: "POST",
      headers: { Authorization: `Bearer ${$store.state.auth.idToken}` },
    }).then((result) => {
      apiKey.value = result.data.rawKey;
      existingKeys.value.push(result.data.prefix);
      zgRef.value.refresh();
    });
  };
</script>

<style>
[api-key="key-container"] {
  display: flex;
  margin-top: 2rem;
}

[api-key="key-container"] .el-button {
  margin-left: 1rem;
}

[api-key] zing-grid {
  margin-bottom: 1rem;
}

[api-key] .el-dialog__header {
  padding: 20px 20px 10px !important;
}
</style>