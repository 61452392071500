<template>
  <section class="content--narrow warning" :light="light ? true : false">
    <template v-if="is401">
      <h1>401</h1>
      <h2>Unauthorized</h2>
    </template>
    <template v-else-if="is429">
      <h1>429</h1>
      <h2>Too Many Requests</h2>
    </template>
    <template v-else>
      <h1>404</h1>
      <h2>Page Not Found</h2>
    </template>
    <div id="sadSVG">
        <svg width="100%" height="333px" viewBox="0 0 546 333" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient x1="57721%" y1="45304%" x2="57721%" y2="40671%">
            <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
            <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
            <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="66897%" y1="45304%" x2="66897%" y2="40671%">
            <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
            <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
            <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
          </linearGradient>
          <linearGradient x1="76073%" y1="45304%" x2="76073%" y2="40671%">
            <stop stop-color="#808080" stop-opacity="0.25" offset="0%"></stop>
            <stop stop-color="#808080" stop-opacity="0.12" offset="54%"></stop>
            <stop stop-color="#808080" stop-opacity="0.1" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.479506341">
          <g transform="translate(-1025.000000, -453.000000)" fill-rule="nonzero">
            <g transform="translate(1025.000000, 453.000000)">
              <rect fill="#FFFFFF" x="5" y="5" width="536" height="323" rx="8"></rect>
              <rect fill="#00384A" x="37" y="44" width="473" height="30"></rect>
              <rect fill="#D0F1FC" x="37" y="84" width="473" height="30"></rect>
              <rect fill="#C4DDEB" x="37" y="204" width="473" height="30"></rect>
              <rect fill="#C4DDEB" x="37" y="124" width="473" height="30"></rect>
              <rect fill="#50C6E3" x="107" y="124" width="90" height="30"></rect>
              <rect fill="#50C6E3" x="350" y="124" width="90" height="30"></rect>
              <rect fill="#D0F1FC" x="37" y="244" width="473" height="30"></rect>
              <rect fill="#D0F1FC" x="37" y="164" width="473" height="30"></rect>
              <rect fill="url(#linearGradient-1)" opacity="0.5" x="342" y="51" width="44" height="30"></rect>
              <rect fill="url(#linearGradient-2)" opacity="0.5" x="401" y="51" width="44" height="30"></rect>
              <rect fill="url(#linearGradient-3)" opacity="0.5" x="460" y="51" width="44" height="30"></rect>
              <polygon fill="#FFB1D6" points="167 154 167 276.832031 137 276.832031 137 154"></polygon>
              <polygon fill="#FFB1D6" points="410 154 410 276.832031 380 276.832031 380 154"></polygon>
              <polygon fill="#00384A" points="226 204 275.029877 204 323 204 323 244 274.5 244 226 244"></polygon>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </section>
</template>

<script setup>
  import { computed, defineProps } from 'vue';
  import { useRoute } from 'vue-router';

  const $route = useRoute();

  const props = defineProps({
    light: Boolean,
  });

  const is401 = computed(() => {
    return $route.path === '/401';
  });
  const is429 = computed(() => {
    return $route.path === '/429';
  });
  const is404 = computed(() => {
    return $route.path === '/404';
  });
</script>

<style>
  .warning h1, .warning h2 { text-align: center; }
  .warning h1 { font-size: 2.5rem; margin: 3rem 0 0; }
  .warning h2 { color: var(--color-secondary-3); font-size: 1.25rem; margin: 0; }
  .warning [light] h1 { color: var(--color-reset); }
  .warning #sadSVG { width:100%; }
</style>
