import { ref, watch } from 'vue';

export default function pricingComposable(renewalProcess) {
  const pricingInterval = ref(false);
  const activePlanIndex = ref(0);
  const productSubscriptions = ref([]);
  const subscriptions = ref([]);
  const products = ref([]);
  const productTypes = ref(['ZC', 'ZG']);
  const productType = ref(null);
  const subscriptionNames = ref(null);
  const skuQty = ref([]);
  const subscriptionDialogVisible = ref(false);
  const subscriptionUpdateDialogVisible = ref(false);

  watch(pricingInterval, () => {
    renewalProcess.value = false;
  });
  watch(activePlanIndex, () => {
    renewalProcess.value = false;
  });

  function computeAmount(subscription){
    if (subscription.plan.amount) {
      if (subscription.discount && subscription.discount.coupon && subscription.discount.coupon.percent_off) {
        return ((subscription.plan.amount - (subscription.plan.amount * subscription.discount.coupon.percent_off * 0.01))).toFixed(2);
      } else if (subscription.discount && subscription.discount.coupon && subscription.discount.coupon.amount_off) {
        return ((subscription.plan.amount - subscription.discount.coupon.amount_off)).toFixed(2);
      } else {
        return (subscription.plan.amount).toFixed(2);
      }
    }
  };

  return {
    pricingInterval,
    activePlanIndex,
    productSubscriptions,
    subscriptions,
    products,
    productTypes,
    productType,
    subscriptionNames,
    skuQty,
    subscriptionDialogVisible,
    subscriptionUpdateDialogVisible,
    computeAmount
  };
};
