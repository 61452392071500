import { ref } from 'vue';

export default function updateDomainComposable({$global, activePlanIndex, dialogDomainForm, domainFormCreate,
  dialogNewDomains, dialogNewSubdomains, domainFormUpdate, getUserSubscriptions, planSteps, renewalProcess}) {
  const domainToDelete = ref(null);

  function handlePlanDialogClose() {
    // Clear validation errors in domain forms
    if (domainFormCreate.value) domainFormCreate.value.clearState();
    if (domainFormUpdate.value) domainFormUpdate.value.clearState();

    // Clear variables
    dialogNewDomains.value = [];
    dialogNewSubdomains.value = [];
    dialogDomainForm.value.dialogNewDomain = '';
    dialogDomainForm.value.dialogNewSubdomain = '';
    activePlanIndex.value = 0;
    planSteps.value = 0;
    renewalProcess.value = false;
  };

  function removeNewDomain(_domain, sub) {
    if (sub) {
      dialogNewSubdomains.value = dialogNewSubdomains.value.filter(domain => domain !== _domain);
    } else {
      dialogNewDomains.value = dialogNewDomains.value.filter(domain => domain !== _domain);
    };
  };

  function updateSubscription() {
    $global.$api('subscription/update', {
      slug: selectedSubscription.value,
      domains: [domain.value],
      subdomains: [subdomain.value],
    }, $global)
    .then(() => {
      $global.$message({
        message: 'Subscription updated!',
        showClose: true,
        type: 'success',
      });
      getUserSubscriptions();
      subscriptionUpdateDialogVisible.value = false;
    })
    .catch((error) => {
      $global.$message({
        duration: 0,
        message: `Cannot subscribe. ${error.response.data.message}.`,
        showClose: true,
        type: 'error',
      });
      subscriptionUpdateDialogVisible.value = false;
    });
  };

  return {
    domainToDelete,
    handlePlanDialogClose,
    removeNewDomain,
    updateSubscription,
  };
};