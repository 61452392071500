<template>
  <section class="settings__content">
    <h2>Delete Account</h2>
    <p>
    Please enter your username to confirm account deletion:
    </p>
    <div class="form__item">
      <label class="form__label" id="username">Username</label>
      <el-input class="form__field" placeholder="Username" for="username"></el-input>
    </div>
    <el-button type="danger">Delete Account</el-button>
  </section>
</template>