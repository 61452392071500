<template>
  <default-layout v-if="authenticated && hasUser" :isAuthing="localStorageAuthState">
    <warning></warning>
  </default-layout>

  <secondary-layout v-else :isAuthing="localStorageAuthState" :noJustify="true">
    <warning light></warning>
  </secondary-layout>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import DefaultLayout from './layouts/Default.vue';
  import SecondaryLayout from './layouts/Secondary.vue';
  import Warning from '../components/Warning.vue';

  const $store = useStore();
  const currentLocalStorageAuthState = ref(false);

  const localStorageAuthState = computed(() => {
    return currentLocalStorageAuthState.value;
  });
  // Determines is authenticated through auth0 is successful
  const authenticated = computed(() => {
    return !!$store.state.auth.idToken;
  });
  // After successful authentication, checks if user in database to complete login/signup process
  const hasUser = computed(() => {
    return !!$store.state.user['user_id'];
  });

  onMounted(() => {
    let isAuth = localStorage.getItem('isAuthenticating') == 'true';
    localStorage.setItem('isAuthenticating', false);
    currentLocalStorageAuthState.value = isAuth;
    setTimeout(() => {
      if (isAuth) currentLocalStorageAuthState.value = false;
    }, 1000);
  });
</script>
