<template>
  <zingnet-card>
    <template v-slot:header>
      Quickbooks Linking
    </template>
    <template v-slot:subtitle>
      <p>
        Lookup, update, or link customer's Studio account to Quickbooks client. <br/>
        This allows customers to view licenses on their Studio account.
      </p>
    </template>
    <template v-slot:body>
      <section class="zingnetCard--row" quickbooks-link>
        <section class="zingnetCard--column">

          <!-- Form -->
          <el-form v-model="quickbooksLinkForm">
            <section>
              <h3 class="zingnetCard--header">Lookup</h3>
              <p>Select way to lookup customer information.</p>
              <el-select v-model="userInput" @change="clearUserInput" class="margin">
                <el-option key="user" label="Studio User ID" value="id"></el-option>
                <el-option key="email" label="Studio Email" value="email"></el-option>
                <el-option key="quickbooks" label="Quickbooks ID" value="quickbooks"></el-option>
              </el-select>
              <el-input v-show="userInput === 'id'" v-model="quickbooksLinkForm.id" placeholder="Studio User ID"></el-input>
              <el-input v-show="userInput === 'email'" v-model="quickbooksLinkForm.email" placeholder="Studio Email"></el-input>
              <el-input v-show="userInput === 'quickbooks'" v-model="quickbooksLinkForm.quickbooks" placeholder="QuickBooks ID"></el-input>
            </section>
            <section>
              <el-button @click="getLinkInfo" :disabled="disable || fetching">Get Information</el-button>
            </section>
            <section>
            </section>
          </el-form>
        </section>
        <section class="zingnetCard--column">
          <h3 class="zingnetCard--header">User Information</h3>
          <!-- Results -->
          <section>
            <zing-grid ref="zgRef"
              default-display="N/A"
              editor="modal"
              :editor-controls="displayResults ? 'editor' : 'disabled'"
              layout="card"
              layout-controls="disabled">
              <zg-colgroup>
                <zg-column index="id" header="Studio User ID" editor="disabled" type="number" type-number-formatting="disabled"></zg-column>
                <zg-column index="email" header="Studio Email" editor="disabled"></zg-column>
                <zg-column index="quickbooks" header="Quickbooks ID" type="url" validator="text" type-url-src="https://app.qbo.intuit.com/app/customerdetail?nameId=[[index.quickbooks]]"></zg-column>
              </zg-colgroup>
            </zing-grid>
          </section>
        </section>
      </section>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, getCurrentInstance, nextTick, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import ZingnetCard from '../ZingnetCard.vue';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const displayResults = ref(false);
  const fetching = ref(false);
  const userInput = ref('email');
  const quickbooksLinkForm = ref({
    email: null,
    quickbooks: null,
    id: null,
  });
  const response = ref([{
    email: null,
    quickbooks: null,
    id: null,
  }]);
  const zgRef = ref(null);

  const disable = computed(() => {
    return !quickbooksLinkForm.value.email && !quickbooksLinkForm.value.quickbooks && !quickbooksLinkForm.value.id;
  });
  const headerVal = computed(() => {
    return `{"Authorization": "Bearer ${$store.state.auth.idToken}"}`;
  });
  
  onMounted(() => {
    zgRef.value.addEventListener('data:cell:change', (e) => {
      linkAccount(e);
    });

    zgRef.value.addEventListener('data:record:change', (e) => {
      linkAccount(e);
    });
  });

  function clearUserInput() {
    quickbooksLinkForm.value.email = null;
    quickbooksLinkForm.value.quickbooks = null;
    quickbooksLinkForm.value.id = null;
  };

  function getLinkInfo() {
    fetching.value = true;

    axios({
      url: '/api/admin/user/linked',
      method: 'POST',
      headers: JSON.parse(headerVal.value),
      data: quickbooksLinkForm.value
    })
    .then((response) => {
      // Success message
      $message({
        message: 'Information Retrieved!',
        type: 'success',
      });

      nextTick(() => {
        fetching.value = false;
        displayResults.value = true;
        clearUserInput();
        zgRef.value.setData([response.data]);
      });
    }).catch((err) => {
      fetching.value = false;

      // Error message
      let errMessage = `Error occurred while getting information: ${err}`;
      $message({
        message: errMessage,
        type: 'error',
      });
    });
  };

  function linkAccount(e) {
    axios({
      url: '/api/admin/user/linked',
      method: 'PATCH',
      headers: JSON.parse(headerVal.value),
      data: e.detail.ZGData.data
    })
    .then((response) => {
      // Success message
      $message({
        message: 'Updated!',
        type: 'success',
      });
    }).catch((err) => {
      // Error message
      let errMessage = `Error occurred while updating: ${err}`;
      $message({
        message: errMessage,
        type: 'error',
      });
    });
  };
</script>

<style scoped>
  [quickbooks-link] .el-select ~ .el-input {
    margin-top: 1rem;
  }
</style>
