import { ref } from 'vue';

export default function subscriptionInfoComposable() {
  const subscriptionInfoDialogVisible = ref(false);
  const subscriptionInfoTitle = ref(null);
  const subscriptionInfoItems = ref([]);
  
  function handleSubscriptionInfo(product) {
    subscriptionInfoTitle.value = product.Name;
    subscriptionInfoItems.value = product.features;
    subscriptionInfoDialogVisible.value = true;
  };

  return {
    subscriptionInfoDialogVisible,
    subscriptionInfoTitle,
    subscriptionInfoItems,
    handleSubscriptionInfo,
  }
};