<template>
    <section class="form__collection" style="width:100%">
      <div class="form__col">
        <div class="form__row">
          <el-form class="form__item">
            <label class="form__label" for="newPass">New password</label>
            <el-input class="form__field" :type="isPassword" v-model="newPassword" placeholder="New password" for="new-password"></el-input>
          </el-form>
        </div>
        <div class="form__row" v-show="newPassword !== ''">
          <el-form class="form__item">
            <label class="form__label" for="confirmPass">Confirm new password</label>
            <el-input class="form__field" :type="isPassword" v-model="confirmPassword" placeholder="Confirm new password" for="new-password"></el-input>
          </el-form>
        </div>
        <div class="form__row">
          <el-checkbox v-model="showPassword">show password</el-checkbox>
        </div>
        <div class="form__row" v-if="resetPassword">
          <el-button :disabled="!validPassword" type="primary" @click="updatePassword">Reset Password</el-button>
        </div>
      </div>
      <div class="form__col" style="min-width: 175px;">
        <div v-if="success" class="status success">We've sent a verification email to the new e-mail address provided.</div>
        <div v-if="failure" class="status failure">An error occurred: {{message}}</div>
        <span v-if="showMismatch" style="color:#f44336;">Passwords do not match</span>
        <div v-show="newPassword !== ''">
          Password must:
          <ul class="password-validation">
            <li class="password" v-bind:class="validClass('atLeast8Chars')"><font-awesome-icon :icon="validIcon('atLeast8Chars')"/> Be a minimum of 8 characters</li>
            <li class="password" v-bind:class="validClass('atLeast1Lower')"><font-awesome-icon :icon="validIcon('atLeast1Lower')"/> Contain at least lower case letter</li>
            <li class="password" v-bind:class="validClass('atLeast1Upper')"><font-awesome-icon :icon="validIcon('atLeast1Upper')"/> Contain at least 1 upper case letter</li>
            <li class="password" v-bind:class="validClass('atLeast1Number')"><font-awesome-icon :icon="validIcon('atLeast1Number')"/> Contain at least 1 number</li>
            <li class="password" v-bind:class="validClass('notEmail')"><font-awesome-icon :icon="validIcon('notEmail')"/> Not match email</li>
          </ul>
        </div>
      </div>
    </section>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, ref, watch } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['input', 'change', 'update']);

  const props = defineProps({
    value: {},
    resetPassword: {
      default: true,
    },
    email: String,
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $store = useStore();
  const newPassword = ref('');
  const confirmPassword = ref('');
  const success = ref(false);
  const failure = ref(false);
  const message = ref('');
  const showPassword = ref(false);
  
  const isPassword = computed(() => {
    return (showPassword.value) ? 'text' : 'password';
  });
  const showMismatch = computed(() => {
    if (newPassword.value === '' || confirmPassword.value === '') {
      return false;
    } else {
      return !match.value;
    }
  });
  const match = computed(() => {
    return newPassword.value === confirmPassword.value
  });
  const atLeast8Chars = computed(() => {
    return newPassword.value && newPassword.value.length >= 8;
  });
  const atLeast1Upper = computed(() => {
    return /[A-Z]/.test(newPassword.value);
  });
  const atLeast1Lower = computed(() => {
    return /[a-z]/.test(newPassword.value);
  });
  const atLeast1Number = computed(() => {
    return /[0-9]/.test(newPassword.value);
  });
  const notEmail = computed(() => {
    return newPassword.value && props.email && newPassword.value !== props.email && newPassword.value.toLowerCase() !== props.email.toLowerCase();
  });
  const validPassword = computed(() => {
    return match.value && atLeast8Chars.value && atLeast1Upper.value && atLeast1Lower.value && atLeast1Number.value && notEmail.value;
  });
  const validation = computed(() => {
    return {
      atLeast8Chars: atLeast8Chars.value,
      atLeast1Upper: atLeast1Upper.value,
      atLeast1Lower: atLeast1Lower.value,
      atLeast1Number: atLeast1Number.value,
      notEmail: notEmail.value,
    }
  });
 
  watch(validPassword, (value) => {
    if(value) {
      emit('input', newPassword.value);
    } else {
      emit('input', null);
    }
  });
  
  function updatePassword() {
    // Update password
    emit('change', newPassword.value);
    // Add timestamp to invalidate sessions before password change
    $api('user/update', {session_expiration: new Date().getTime()}, $global);
    // Refresh token to updating auth init
    $store.dispatch('refresh_tokens');
  };

  function validClass(target) {
    if (newPassword.value && newPassword.value.length === 0) {
      return '';
    } else {
      return (validation.value[target]) ? 'password--valid' : 'password--invalid';
    }
  };

  function validIcon(target) {
    if (newPassword.value && newPassword.value.length === 0) {
      return ['fas', 'check'];
    } else {
      return ['fas', (validation.value[target])? 'check' : 'times'];
    }
  };

  function update() {
    failure.value = success.value = false;
    if (match.value && newPassword.value !== '' && confirmPassword.value !== '') {
      emit('update', newPassword.value);
    }
  };
</script>

<style>
.password-validation {
  margin: 0;
  padding: 0;
}
.password-validation li {
  list-style-type: none;
}

.password {
  color: #a2a2a2;
}

.password--valid {
  position: relative;
  list-style-type: none;
  color: #4caf50;
}

.password--invalid {
  color: #f44336;
  list-style-type: none;
  position: relative;
}

.layout--row {
  display: flex;
}
</style>
