<template>
  <el-dialog
    my-dialog
    licensePurchaseDialog
    v-model="isVisible">
    <div v-if="phase1">
      <h2>License Already Purchased</h2>
      <p>It looks like you already own a {{ planName }} license.</p>
      <p>Are you trying to renew your existing license or purchase another one?</p>

      <div class="dialog__controls">
        <el-button @click="buySubscription()">Continue Purchase</el-button>
        <el-button type="primary" @click="continueRenewSubscription()">Renew</el-button>
      </div>
    </div>
    <div v-else>
      <h2>Renew License</h2>
      <p>Your purchase has been updated to a renewal.</p>
      <p>In the next view, review the updated information and click the "Renew" button.</p>

      <div class="dialog__controls">
        <el-button type="primary" @click="continueRenewal()">Continue Renewal</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps, ref } from 'vue';
  import MixinPurchaseSteps from '../subscriptionMixins/PurchaseSteps.js';
  import MixinSubscriptions from '../subscriptionMixins/Subscriptions.js';

  const emit = defineEmits(['close', 'purchase', 'renew']);

  const props = defineProps({
    showClose: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Object,
      default: null
    },
    domains: {
      type: Array,
      default: null
    },
    subdomains: {
      type: Array,
      default: null
    },
    userId: {
      type: Number,
      default: null
    },
  });
   
  // If true, prompts user if they want to purchase or renew
  // else informs user that purchase converted to a renewal
  const phase1 = ref(true);

  const isVisible = computed({
    get() {
      phase1.value = true;
      return props.visible;
    },
    set() {
      phase1.value = true;
      emit('close');
    },
  });
  const planName = computed(() => {
    return props.plan ? props.plan.Name : null;
  });
  const planSku = computed(() => {
    return props.plan ? props.plan.Sku : null;
  });
  const planPrice = computed(() => {
    return props.plan ? props.plan.UnitPrice : null;
  });
      
  function buySubscription() {
    // Close dialog
    isVisible.value = false;

    // Continue subscription process
    emit('purchase');
  };

  function continueRenewSubscription() {
    // Update text to inform user information updated
    phase1.value = false;
  };

  function continueRenewal() {
    // Close dialog
    isVisible.value = false;

    // Continue subscription process
    emit('renew');
  };
</script>

<style scoped>
  [licensePurchaseDialog] h2 { text-align: center; }
  [licensePurchaseDialog] .el-button.el-button--default {
    border-color: #DCDFE6 !important;
    color: rgb(96, 98, 102) !important;
  }
</style>