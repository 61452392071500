<template>
  <section class="zingnetCardSection">
    <div class="zingnetCardRow">
      <!-- TrackJS -->
      <error-tracking></error-tracking>
      
      <!-- Pingdom -->
      <uptime-monitoring></uptime-monitoring>
      
    </div>
  </section>
</template>

<script setup>
  import UptimeMonitoring from './UptimeMonitoring.vue';
  import ErrorTracking from './ErrorTracking.vue';
</script>