<template>
  <section ref="$el" class="demo-cards" :mode="mode" :template="template ? template : undefined">

    <!-- Header / Demo Controls -->
    <header class="demo-controls" >
      <div class="demo-controls__lvl1">
        <div class="demo-controls__left">
          <!-- Group Filter -->
          <div v-if="adminDashboardView && !templateType" class="demo-control__item">
            <!-- @update-group="updateGroup" -->
            <group-selector
              ref="groupSelector"
              v-model:groupBy="group"
              :template="templateType"
              :type="zingType ? demoType : null">
            </group-selector>
          </div>
          <!-- Tag Filter -->
          <div class="demo-control__item">
            <tag-selector 
              ref="tagSelector"
              v-model:filterBy="tag"
              :group="group"
              :template="templateType"
              :type="zingType ? demoType : null">
            </tag-selector>
          </div>
          <!-- Sort -->
          <div class="demo-control__item">
            <el-select v-model="sortBy" placeholder="Select" :disabled="template && zingType">
              <el-option
                v-for="item in sortOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <svg-icon class="el-input-icon" icon="angle"></svg-icon>
          </div>
        </div>
        <!-- Search -->
        <div class='demo-controls__right'>
          <div class="demo-control__item demo-control__item--search">
            <el-input @keyup.enter="checkSearchStr" search placeholder="Search By..." v-model="watchSearch" maxlength="75"></el-input>
            <svg-icon class="el-input-icon" icon="search"></svg-icon>
          </div>
        </div>
      </div>
      <div v-if="adminDashboardView" class="demo-controls__lvl2">
        <div class="demo-control__item">
          <metadata-selector
            type="metadata"
            v-model:metadataBy="metadataType"
          ></metadata-selector>
        </div>
        <div class="demo-control__item">
          <metadata-selector
            type="value"
            :metadata="metadataType"
            v-model:metadataBy="metadataValue"
            :scrollable="true"
            :demoType="zingType ? demoType : null"
          ></metadata-selector>
        </div>
      </div>
    </header>

    <!-- Demos -->
    <div class="zing-grid-wrapper">
      <div class="custom-load"></div>
      <input type="hidden" :value="preDemoSrc">
      <zing-grid
        demo-viewer
        layout="card"
        layout-controls="disabled"
        pager="true"
        :page-size="updatePageSize('size')"
        :page-size-options="pageSizeOptions"
        loading="true">
        <!-- ZGData -->
        <zg-data>
          <!-- REST PARAMS -->
          <zg-param name="headers" :value="headerVal"></zg-param>
          <zg-param name="src" :value="demoSrc"></zg-param>
          <zg-param name="recordPath" value="results"></zg-param>
          <zg-param name="createOptions" value='{"exclude": true}'></zg-param>
          <zg-param name="deleteOptions" value='{"exclude": true}'></zg-param>
          <!-- LOADBYPAGE PARAMS -->
          <zg-param name="loadByPage" value="true"></zg-param>
          <zg-param name="countPath" value="size"></zg-param>
          <zg-param name="limitToKey" value="limit"></zg-param>
          <zg-param name="limitToValue" :value="pageSize"></zg-param>
          <zg-param name="startAtKey" value="start"></zg-param>
          <zg-param name="nextPath" value="_links.next"></zg-param>
          <zg-param name="prevPath" value="_links.prev"></zg-param>
        </zg-data>
        <!-- ZGColgroup -->
        <zg-colgroup>
          <zg-column index="uid, created, title, image, tags, last_updated, description, template_type, thumbnail_image" header=" " renderer="renderCard"></zg-column>
        </zg-colgroup>
      </zing-grid>
    </div>

    <!-- Demo Settings -->
    <demo-settings
      @save="saveDemo"
      @toggle-visibility="settingsVisible = !settingsVisible"
      @update-demo="updateDemoData"
      :demo="demo"
      :settings="['general', 'share', 'template']"
      :settingsVisible="settingsVisible"
    ></demo-settings>
  </section>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { computedAsync } from '@vueuse/core';
  import DemoCard from './DemoCard.vue';
  import DemoSettings from '../views/demos/DemoSettings.vue';
  import GroupSelector from './GroupSelector.vue';
  import MetadataSelector from './MetadataSelector.vue';
  import TagSelector from './TagSelector.vue';
  import Autocomplete from 'vuejs-auto-complete';
  import debounce from 'lodash.debounce';
  import SvgIcon from './SvgIcon.vue';
  import cachingComposable from '../mixins/caching.js';
  import chartTypeComposable from '../mixins/chartTypes.js';
  import permissionComposable from '../mixins/permissions.js';

  const { cacheFetch, cacheSaveData } = cachingComposable();
  const { chartTypes } = chartTypeComposable();
  const { checkPermission } = permissionComposable();

  const emit = defineEmits(['update-view']);

  const props = defineProps({
    create: {default: true},      // Determines if Create dummy card is displayed
    demoType: String,             // Display specified demo type: zinggrid, zingchart
    mode: String,                 // Display mode: grid, list
    search: {default: false},     // Determines if search bar should be displayed
    admin: Boolean,               // Admin-only feature that enables viewing/searching all demos
    template: {default: false},   // Displays user template
    userId: {default: null},      // Fetch demos for specified user
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $confirm = $global.$confirm;
  const $message = $global.$message;
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const $el = ref(null);
  const tagSelector = ref(null);
  const data = ref(null);
  const demoSrc = ref(null);
  const customLoad = ref(null);                                        // Reference to custom load mask
  const curDemoSrc = ref(null);
  const group = ref('all_demos');
  const metadataType = ref(null);
  const metadataValue = ref(null);
  const pageSize = ref(null);
  const pageSizeOptions = ref(null);
  const tag = ref(null);                                               // Filter value to filter demos by
  const sortBy = ref('last_updated');                                  // Determines how to sort demos by
  const sortByManage = ref('last_updated');
  const searchQuery = ref(null);                                       // Search term to search demos by title
  const watchSearch = ref('');
  const settingsVisible = ref(false);
  const sortOptions = ref([                                            // Sort options to sort demos by
    {value: 'last_updated', label: 'Last Modified'},
    {value: 'title', label: 'Alphabetical'},
  ]);
  const newQuery = ref(false);                                         // Determines if demos have filter, search, or sort applied
  const zgRef = ref(null);                                             // Reference to ZingGrid, which displays demos
  const zgRefMainFrame = ref(null);                                    // Reference to ZingGrid#frame-main-body
  const viewport = ref(null);
  const gridHeight = ref(845);

  // Demo Data
  const uid = ref(null);
  const demoTemplate = ref(null);
  const title = ref(null);
  const description = ref(null);
  const tags = ref(null);
  const image = ref(null);
  const isPremium = ref(null);
  const isTemplate = ref(null);
  const isPublic = ref(null);

  const adminDashboardView = computedAsync(
    async () => {
      return await checkPermission('admin_dashboard_view', null, null, $store);
    }, null
  );
  const adminTemplateCreate = computedAsync(
    async () => {
      return await checkPermission('admin_template_create', null, null, $store);
    }, null
  );
  
  /**
   *  @description Header Object to insert to ZingGrid REST API call
   */
  const headerVal = computed(() => {
    return `{"Authorization": "Bearer ${$store.state.auth.idToken}"}`;
  });
  /**
   * @description Returns true if demo type is zingchart or zinggrid (not group)
   */
  const zingType = computed(() => {
    return props.demoType === 'zingchart' || props.demoType === 'zinggrid' || props.demoType === 'all_demos' ? true : false;
  });
  /**
   * @description Based on sort value, returns sort direction
   */
  const sortDir = computed(() => {
    return (sortBy.value === 'last_updated') ? 'DESC' : 'ASC';
  });
  /**
   * Returns grid to first page.
   * Returns demo source to use in ZingGrid `src` based on filter terms and query params
   * - filter: search, tag, template, demo type
   * - query params: id_grouping, sort_by, sort_direction
   */
  const preDemoSrc = computed(() => {
    // Determine how to sort demos
    sortBy.value = props.template && zingType.value ? 'title' : sortByManage.value;
    // Returns demo source
    let url = '';
    // Demos specific to user
    if (group.value === 'personal_demos') url = `/api/demo/list/${props.userId}?`;
    // Public template demos
    else if (props.template) url = `/api/demo?`;
    // Demos specific to user including group demos
    else if (group.value === 'all_demos') url = `/api/demo?${group.value ? '&id_grouping='+group.value : '&id_grouping=all_demos'}&id=${props.userId}`;
    // Demos specific to group
    else url = `/api/demo?${group.value ? '&id_grouping='+group.value : ''}`;

    // Determine how to sort demos
    url += `&sort_by=${sortBy.value}&sort_direction=${sortDir.value}`;

    // Determine if to enable admin-level feature (read/search all demos)
    if (props.admin && adminDashboardView.value) url += `&admin_level=true`;

    // Filter demos by:
    let filter = [];
    // search query
    if (searchQuery.value) filter.push({by: 'title', value: searchQuery.value, type: 'demo'});
    // tag
    if (tag.value) filter.push({by: 'name', value: tag.value, type: 'tag'});
    // demo type (chart or grid) - both if null
    if (props.demoType) filter.push({by: 'type', value: props.demoType, type: 'demo'});
    // template (personal and public)
    if (props.template) filter.push({by: 'is_template', value: 1, type: 'demo'});
    // constructs filter string
    if (filter && filter.length > 0) url += `&filter=${JSON.stringify(filter)}`;
    // constructs metadata string
    if (metadataValue.value && metadataValue.value.length > 0) {
      let metadata = [];
      metadata.push({by: 'type', value: metadataType.value, type: 'metadata'});
      metadataValue.value.forEach((value) => {
        metadata.push({by: 'value', value: encodeURIComponent(value), type: 'metadata'})
      });
      url += `&metadata=${JSON.stringify(metadata)}`;
    }
    // Properties to retrieve
    let propsToRetrieve = ['uid', 'description', 'image', 'is_template', 'last_updated', 'premium_template', 'public', 'tags', 'template_type', 'thumbnail_image', 'title'];
    url += `&props_only=${JSON.stringify(propsToRetrieve)}`;
      
    // Display only public demos (admin-created for all users)
    if (props.template && props.demoType) url += '&template_default&template_card';

    // Set to first page and update size
    if (zgRef.value && curDemoSrc.value !== url) {
      zgRef.value.setPageIndex(1);
      curDemoSrc.value = url;
      demoSrc.value = url;
    };
  });
  const demo = computed(() => {
    return {
      uid: uid.value,
      demoTemplate: demoTemplate.value,
      title: title.value,
      description: description.value,
      tags: tags.value,
      image: image.value,
      isPremium: isPremium.value,
      isTemplate: isTemplate.value,
      isPublic: isPublic.value,
      id_user: props.userId,
    };
  });
  /**
   * @description Determine template type based on template and demo type. Used to set query param for tag selector
   */
  const templateType = computed(() => {
    if (props.template) {
      return props.demoType ? 'public' : 'personal';
    } else {
      return undefined;
    }
  });

  // When filter, search, or sort applied, set `newQuery` to true to return ZingGrid to first page when displaying results
  watch(watchSearch, debounce(function(newVal){
    checkSearchStr(newVal);
  }, 1000));
  watch(sortBy, () => {
    // Save sort state
    if (!props.template) sortByManage.value = sortBy.value;
    // Trigger new query
    newQuery.value = true
  });
  watch(tag, () => {
    newQuery.value = true;
  });
  /**
   * @description Updates the grid layout mode
   */
  watch(() => props.mode, () => {
    nextTick(() => {
      let layout = props.mode === 'list' ? 'row' : 'card';
      if (zgRef.value) {
        zgRef.value.executeOnLoad(() => {
          zgRef.value.setLayout(layout);
          zgRef.value.updateSize();
        });
      }
    });
  });

  onMounted(() => {
    customLoad.value = $el.value.querySelector('.custom-load');
    zgRef.value = $el.value.querySelector('zing-grid[demo-viewer]');
    if (localStorage.getItem('setupDemos') === 'true' && zingType.value) addDefaultDemos();

    setupZG();
    registerMethods();
    checkQuery();

    // After each route
    $router.afterEach((to, from) => {
      // remove ZingGrid reference and event listeners
      if (zgRef.value) {
        zgRef.value.removeEventListener('record:click', handleRecordClick);
        zgRef.value.removeEventListener('data:record:delete', handleRecordDelete);
        zgRef.value.removeEventListener('grid:ready', handleGridReady_create);
        zgRef.value.removeEventListener('data:load', handleDataLoad_create);
        zgRef.value.removeEventListener('cell:render', handleCellRender_create);
        zgRef.value.removeEventListener('grid:ready', handleGridReady);
        zgRef.value.removeEventListener('data:load', handleDataLoad);
        zgRef.value = null;
      }
    });
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
    if (zgRef.value) {
      zgRef.value.removeEventListener('record:click', handleRecordClick);
      zgRef.value.removeEventListener('data:record:delete', handleRecordDelete);
      zgRef.value.removeEventListener('grid:ready', handleGridReady_create);
      zgRef.value.removeEventListener('data:load', handleDataLoad_create);
      zgRef.value.removeEventListener('cell:render', handleCellRender_create);
      zgRef.value.removeEventListener('grid:ready', handleGridReady);
      zgRef.value.removeEventListener('data:load', handleDataLoad);
      zgRef.value = null;
    }
  });

  const checkSearchStr = debounce(function(string) {
    newQuery.value = true;
    searchQuery.value = string;
  }, 500);
  
  /**
   * @description For new accounts, create default chart and grid demos
   */
  async function addDefaultDemos() {
    // Remove signup trigger
    localStorage.removeItem('setupDemos');
    localStorage.removeItem('signupReferrer');

    // Default demos to add
    const defaultDemos = [VUE_APP_GRID_ONE, VUE_APP_GRID_TWO, VUE_APP_CHART_ONE, VUE_APP_CHART_TWO, VUE_APP_CHART_THREE]
    
    // After creating default demos, add to demolist
    defaultDemos.forEach(async(demo, index) => {
      const data = await retrieveDemo(demo);
      await createDemo(data); 
      if (index === defaultDemos.length-1) {
        zgRef.value.refresh();
      };
    });
  };

  /**
   * @description On mounted, checks for query parameters to display a specific dashboard.
   * Possible queries:
   * - group: expects grouping_id => displays Group dash for specificed group's page/tab
   * - groupfilter: expects grouping_id => displays Manage dash that filters for specified group (all demos)
   */
   function checkQuery() {
    let query = $route.query;
    if (query.group) {
      // group
      emit('update-view', 'groups', null, parseInt(query.group));
    } else if (query.groupfilter) {
      // groupfilter
      nextTick(() => {
        emit('update-view', 'manage', null, 'all_demos');
        group.value = query.groupfilter;
      });
    }
    if ($router.path === '/') $router.replace('/')
  };

  /**
   * @description Called to append 'No Demos' when ZingGrid does not display any demos
   */
  function appendNoDemos() {
    // Append only if no demos displayed
    let pageIndex = zgRef.value.getPageIndex();
    let rows = zgRef.value.querySelectorAll('zg-body zg-row');
    if (pageIndex === 1 && rows && rows.length === 0) {
      zgRef.value.insertRow({title: 'demo__card--create'});
    }
  };

  /**
   * @description Create default demo to add to new account
   * @param { Object } demoData - data to create new demos from
   */
  function createDemo(demoData) {
    return new Promise((resolve) => {
      $api('demo/add', {
        data: demoData
      }, $global).then((response) => {
        resolve(true);
      }).catch((err) => {
        $message({
          duration: 0,
          message: 'Could not create default demos',
          showClose: true,
          type: 'error',
        });
      });
    });
  };

  /**
   * @description Returns url to image given the image id. Custom loaded image is chosen if it
   * was uploaded as demo thumbnail (contains 'demo_image' in name).
   * @param {String} imageId - id of image to retrieve url for
   * @param {String} customId - id of uploaded image to retrieve url for
   */
  function demoImage(imageId, customId) {
    let image = customId && /demo(_|-)thumbnail/.test(customId.toLowerCase()) ? customId : imageId;
    return `https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${image}`;
  };

  /**
   * @description Returns url to image given the template type
   * @param {String} imageId - id of image to retrieve url for
   */
  function demoTemplateType(template_type) {
    return `../assets/images/demoTemplates/${template_type}.png`;
  };

  /**
   * @description Clears filtered tag and search results
   */
  function clearFilterResults() {
    watchSearch.value = null;
    tagSelector.value.clearTagResults();
  };

  /**
   * @description Returns formated date: Month Day, Year
   * @param {String} timestamp - timestamp to format
   */
  function formatDate(timestamp) {
    return new Date(timestamp).toLocaleString('en-us', { month: "numeric", year: 'numeric', day: 'numeric' } );
  };

  /**
   * @description Returns demolink to demo provided the uid
   * @param {String} uid - unique id of demo
   */
  function formatDemoLink(uid) {
    return `/demos/create/${uid}`;
  };

  /**
   * @description Sets the grid height such that the grid fits the screen
   * @param {Boolean} resize - set true if triggerred by resize event. Othersize, setting on component mount
   */
  function getGridHeight(resize) {
    setTimeout(() => {
      // Start with viewport
      let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      // Subtract page contents (nav, header, header tools, footer)
      function getHeight(selector) {
        let el = document.querySelector(selector);
        return el ? el.getBoundingClientRect().height : 0;
      }
      let navHeight = getHeight('.nav');
      let headerHeight = getHeight('.content__header');
      let tabsHeight = getHeight('.header-container');
      let toolsHeight = getHeight('.demo-controls');
      let footerHeight = getHeight('.footer__wrap');
      let pagerHeight = 275;
      let offsetHeight = navHeight + headerHeight + tabsHeight + toolsHeight + footerHeight + pagerHeight;
      let offsetResize = 90;
      gridHeight.value = height - offsetHeight;
      
      // Window inner height and grid height
      let winHeight = window.innerHeight - offsetHeight;
      let curHeight = zgRef.value ? parseInt(zgRef.value.getHeight()) : null;

      if (curHeight) {
        // Determine height to set
        if (curHeight > winHeight && !resize) {
          zgRef.value.height = winHeight + pagerHeight;
        } else {
          if (resize) gridHeight.value += offsetResize;
          zgRef.value.height = gridHeight.value;

          if (resize) {
            setTimeout(() => {
              // Update page size and page size options based on viewport
              zgRef.value.updateSize();
              updatePageSize();
            }, 500);
          }
        }
      };
    }, 0);
  };

  /**
   * @description Get text only from description. There are cases where description contains html.
   * @param {String} description - description to extract text from
   */
  function getText(description) {
    let tempEl = document.createElement('html');
    tempEl.innerHTML = description;
    return tempEl.innerText;
  };

  /**
   * @description Handle 'cell:render' event by setting demo image height
   */
  function handleCellRender_create() {
    setTimeout(() => {
      // Check if preview given, give height of 100% just in case images displays default behind
      let images = document.querySelectorAll('[demo-viewer] .demo__image__wrapper');
      images.forEach(image => {
        let demoImage = image.querySelector('.demo__image');
        if (demoImage && demoImage.getBoundingClientRect().height > 25) image.style.height = '100%';
      })
    }, 0);
  };

  /**
   * @description Handle 'data:load' event by setting grid data and appending dummy card
   * when there are no demos
   * @param {Object} e - native event object
   */
  function handleDataLoad(e) {
    data.value = e.srcElement.data;
    appendNoDemos();

    // Handles cut off bug
    if (!zgRefMainFrame.value) {
      let shadow = zgRef.value.shadowRoot;
      zgRefMainFrame.value = shadow.querySelector('#frame-main-body');
    };
    nextTick(() => {
      zgRefMainFrame.value.removeAttribute('style');
    });
  };

  /**
   * @description Handle 'data:load' event by returning to new page on new query and
   * appending dummy card for non-template demos
   * @param {Object} e - native event object
   */
  function handleDataLoad_create(e) {
    // Return to first page on new queries
    if (newQuery.value) {
      zgRef.value.setPageIndex(1);
      newQuery.value = false;
    }
    if (!props.template) {
      if (zgRef.value.getPageIndex() === 1 && e.srcElement.data && e.srcElement.data.length === 0) zgRef.value.insertRow({'title': 'demo__card--start'});
    }
  };

  /**
   * @description Handle 'grid:ready' event by removing load mask and set grid
   * @param {Object} e - native event object
   */
  function handleGridReady(e) {
    appendNoDemos();
    // Remove load mask;
    zgRef.value.loadmask = 'disabled';
    data.value = e.srcElement.data;
    // Set grid height
    getGridHeight();
    nextTick(() => {
      zgRef.value.updateSize();
    });
  };

  /**
   * @description Handle 'grid:ready' event by appending dummy card when there are no demos
   * @param {Object} e - native event object
   */
  function handleGridReady_create(e) {
    if (!props.template) {
      if (zgRef.value.getPageIndex() === 1 && e.srcElement.data && e.srcElement.data.length === 0) zgRef.value.insertRow({'title': 'demo__card--start'});
    }
  };

  /**
   * @description Handle 'record:click' event on grid. On click event, execute action
   * based on target's `[js-trigger]` value
   * @param {Object} e - native event object
   */
  function handleRecordClick(e) {
    const target = e.detail.ZGEvent ? e.detail.ZGEvent.oDOMTarget : null;
    const targetClass = target ? target.classList : null;
    const demoUid = e.detail.ZGData.data.uid ? e.detail.ZGData.data.uid : null;
    const demoTitle = e.detail.ZGData.data.title ? e.detail.ZGData.data.title : uid;

    const toggleMenu = (target) => {
      let dropdownTrigger = target.getAttribute('js-trigger') === 'toggle' ? target : target.closest('[js-trigger="toggle"]');
      if (dropdownTrigger) {
        if (dropdownTrigger.classList.contains('active')) dropdownTrigger.classList.remove('active');
        else dropdownTrigger.classList.add('active');
      }
    }

    if (target) {
      // `js-trigger-*` attributes added to elements to trigger the following interactions
      if (target.getAttribute('js-trigger') === 'open') {
        // Open demo
        if (demoUid && targetClass) {
          // Open new tab if ctrl pressed
          let url = `/demos/create/${demoUid}`;
          if (e.detail.ZGEvent.bIsCTRL) window.open(url,'_blank');
          else if ($route.path !== url) $router.push(url);
        }
      } else if (target.getAttribute('js-trigger') === 'create') {
        // Create demo
        emit('update-view', 'create', props.demoType ? props.demoType.toLowerCase() : null);
      } else if (target.getAttribute('js-trigger') === 'fork') {
        // Fork demo
        toggleMenu(target);
        let path = `/demos/create/${demoUid}?fork`;
        if (demoUid && $route.path !== path) $router.push(path);
      } else if (target.getAttribute('js-trigger') === 'delete') {
        // Delete demo
        toggleMenu(target);
        $confirm(`Are you sure you want to delete demo "${demoTitle || demoUid}?"`, 'Delete', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error',
        })
        .then(() => {
          // After confirming custom prompt, trigger ZG delete and confirm in ZG delete dialog
          customLoad.value.style.opacity = 1;
          target.nextElementSibling.click();
          const dialog = zgRef.value.querySelector('zg-dialog');
          dialog.shadowRoot.querySelector('.zg-dialog-confirm').click();
          // Save current page index and turn on load mask
          const curPage = zgRef.value.getPageIndex();
          // Update grid data, navigate back to user last was and turn off load mask
          zgRef.value.refresh();
          zgRef.value.setPageIndex(curPage);
          setTimeout(() => { customLoad.value.style.opacity = 0 }, 1500);
        })
      } else if (target.getAttribute('js-trigger') === 'edit') {
        // Edit demo
        if (demoUid) {
          toggleMenu(target);
          $api(`demo/retrieve`, {
            slug: demoUid
          }, $global)
            .then(response => {
              uid.value = demoUid;
              demoTemplate.value = response.data.template_type || '';
              title.value = response.data.title;
              description.value = response.data.description;
              tags.value = response.data.tags;
              image.value = response.data.image;
              isPremium.value = response.data.premium_template === 'true' ? true : false;
              isTemplate.value = (response.data.is_template === 1);
              isPublic.value = (response.data.public === 1);
              settingsVisible.value = true;
            })
            .catch(error => {
              switch(error && error.response && error.response.status) {
                case 401:
                  if (localStorage.getItem('startup_status')) location.reload();
                  else if ($route.path !== '/401') $router.push('/401');
                  break;
                default:
                  if ($route.path !== '/404')$router.push('/404');
                  break;
              }
            });
        }
      } else if (target.getAttribute('js-trigger') === 'started') {
        // Fork demo
        let path = `/demos/create/${props.demoType === 'zingchart' ? VUE_APP_GET_STARTED_CHART : VUE_APP_GET_STARTED_GRID}?fork`;
        if ($route.path !== path) $router.push(path);
      } else if (target.getAttribute('js-trigger') === 'toggle' || target.closest('[js-trigger="toggle"]')) {
        // Toggle dropdown menu
        toggleMenu(target);
      }
    }
  };

  /**
   * @description Handle 'record:delete' event on grid by making DELETE request and
   * displaying success message on competion
   * @param {Object} e - native event object
   */
  function handleRecordDelete(e) {
    const demoUid = e.detail.ZGData.data.uid ? e.detail.ZGData.data.uid : null;
    $api('demo/delete', {
      slug: demoUid,
    }, $global)
    .then(() => {
      $message({
        duration: 0,
        message: 'Demo successfully deleted!',
        showClose: true,
        type: 'success',
      });
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Demo could not be deleted.',
        showClose: true,
        type: 'warning',
      });
    })
  };

  /**
   * @description Register methods for ZingGrid renderers
   */
  function registerMethods() {
    window.renderCard = (uid, created, title, image, tags, last_updated, description, template_type, thumbnail_image) => {
      const CREATE = 'demo__card--create';
      const START = 'demo__card--start';
      const TITLE_CREATE = 'You don\'t have any more demos.' 
      const TITLE_START = `Not sure where to start? Try out this ${props.demoType === 'zingchart' ? 'chart' : 'grid'} with preset data.`;
      const TEXT_CREATE = 'Create a demo?';
      const TEXT_START = 'Get Started!';
      const BUTTON_TEXT = 'Get Started';

      let template = '';
      let descriptionText = getText(description);
      let trigger = title === CREATE ? 'create' : 'started';

      if (title === CREATE || title === START) {
        // Demo Create/Dummy
        template += `<div class="demo__card--dummy ${title}" js-trigger="${trigger}">
          <div class="demo__header__left demo__header--dummy demo__header__left--dummy hover-hide list-hide">
            <p class="demo__title--dummy">${title === CREATE ? TITLE_CREATE : TITLE_START}</p>
            <p class="demo__text--dummy">${title === CREATE ? TEXT_CREATE : TEXT_START}</p>
          </div>
          <div class="demo__header__right demo__header--dummy hover-hide list-hide">
            <img class="demo__image--dummy" src="/assets/images/create-demo.svg" alt="Create Demo">
          </div>
          <button class="button demo__button demo__button--dummy not-hover-hide list-hide" js-trigger="${trigger}">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z" fill="#2BBEEE"/></svg>
              ${BUTTON_TEXT}
            </div>
          </button>
          <div class="demo__card demo__header grid-hide" js-trigger="${trigger}">
            <div class="demo__header__right demo__header__right--dummy" js-trigger="${trigger}">
              <h3 class="demo__title demo__title--dummy" js-trigger="${trigger}">`;
                // Grid Mode
                template += `<span class="demo__icon list-hide" js-trigger="${trigger}"><svg width="13" height="13" fill="#fff" xmlns="http://www.w3.org/2000/svg" style="fill: var(--color-primary-4);"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z"></path></svg></span>
                <span class="demo__title__text demo__title__text--dummy list-hide" js-trigger="${trigger}">${BUTTON_TEXT}</span>`;
                // List Mode
                template += `<span class="demo__icon  demo__icon--open grid-hide" js-trigger="${props.template ? 'fork' : 'open'}"><svg class="demo__icon__svg boxed" js-trigger="${props.template ? 'fork' : 'open'}" fill="var(--color-primary-4)" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z"></path></svg></span>
                <span class="demo__title__text grid-hide mobile--hide tablet--hide" js-trigger="${trigger}">${title === CREATE ? TITLE_CREATE : TITLE_START}&nbsp;</span>
                <span class="demo__title__text--emphasize grid-hide" js-trigger="${trigger}">${title === CREATE ? TEXT_CREATE : `${BUTTON_TEXT}.`}</span>
              </h3>
            </div>
            <div class="demo__header__left--list">
              <button class="button demo__button demo__button--dummy demo__button--dummy--list grid-hide" js-trigger="${trigger}">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#fff"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z"/></svg>
                ${BUTTON_TEXT}
              </button>
            </div>
          </div>
        </div>`;
      } else {
        // Demo Card
        template += `<section class="demo__card">
          <div class="demo__header" js-trigger="${props.template ? 'fork' : 'open'}">
            <div class="demo__header__right" js-trigger="${props.template ? 'fork' : 'open'}">
              <h3 class="demo__title" js-trigger="${props.template ? 'fork' : 'open'}">
                <span class="demo__icon  demo__icon--open" js-trigger="${props.template ? 'fork' : 'open'}">
                  ${props.template ? 
                    `<svg class="demo__icon__svg boxed" js-trigger="fork" width="13" height="13" fill="#fff" xmlns="http://www.w3.org/2000/svg" style="fill: var(--color-primary-4);"><path d="M6.5 0A6.502 6.502 0 0 0 0 6.5C0 10.088 2.912 13 6.5 13S13 10.088 13 6.5 10.088 0 6.5 0zm3.25 7.15h-2.6v2.6h-1.3v-2.6h-2.6v-1.3h2.6v-2.6h1.3v2.6h2.6v1.3z"></path></svg>`
                    :
                    `<svg class="demo__icon__svg boxed" js-trigger="open" fill="var(--color-primary-4)" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M10.667 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334C0 11.4.593 12 1.333 12h9.334C11.4 12 12 11.4 12 10.667V6h-1.333v4.667zM7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0H7.333z"></path></svg>`
                  }
                </span>
                <span class="demo__title__text" js-trigger="${props.template ? 'fork' : 'open'}">${title ? title : 'Untitled Demo'}</span>
              </h3>
              ${props.template ? 
                `<h4 class="demo__description list-hide" js-trigger="fork">${descriptionText}</h4>` : 
                `<h4 class="demo__date mobile--hide" js-trigger="fork"><span class="tablet--hide">Last edited </span>${formatDate(last_updated)}</h4>`}
            </div>`;
        
        // Grid Mode
        if (!props.template) {
          template += `<div class="demo__header__left">
              <div class="el-dropdown" js-trigger="toggle">
                <span class="el-dropdown-link el-dropdown-selfdefine" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">
                  <svg class="demo__icon" fill="var(--color-primary-4)" width="14" height="4" xmlns="http://www.w3.org/2000/svg"><path d="M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z"/></svg>
                </span>

                <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; width: 110px;">
                  <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="edit">
                    <svg js-trigger="edit" xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 8 9" fill="#fff" style="position: relative; top: 2px;"><path d="M4 .5c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm.4 6h-.8V4.1h.8v2.4zm0-3.2h-.8v-.8h.8v.8z" /></svg>
                    Edit Info
                  </li>
                  <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="fork">
                    <svg js-trigger="fork" aria-hidden="true" data-prefix="fas" data-icon="code-branch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-code-branch fa-w-12 fa-1x" style="color: rgb(175, 175, 175);"><path js-trigger="fork" d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z" class=""></path></svg>
                    Fork
                  </li>
                  <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="delete">
                    <svg js-trigger="delete" aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path js-trigger="delete" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg>
                    Delete
                  </li>
                  <zg-button action="removerecord" class="el-dropdown-menu__item" js-trigger="delete">
                    <template v-slot:icon>
                      <zg-icon name=""><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg></zg-icon>
                      Delete
                    </template>
                  </zg-button>
                  <div x-arrow="" class="popper__arrow"></div>
                </ul>
              </div>
            </div>`;
        }
        
        // List Mode
        template += `<div class="demo__header__left--list" js-trigger="open">`;
        
        // List Mode: Tags
        if (!props.template && tags) {
          // Inline
          template += `<div class="tag-container desktop-sm--hide tablet--hide mobile--hide">`;
          if (typeof tags === 'string') tags = tags.split(',');
          tags.slice(0, 4).forEach((tag, index) => {
            if (index < 3) template += `<span class="tag">${tag.name}</span>`;
            else {
              template += `<span>
                <button type="button" class="tag-button el-button el-button--text el-popover__reference" tabindex="0" style="position: relative">
                  <span>${tags.length - 3} more...</span>
                  <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; text-align: center; width: fit-content;">`
              tags.slice(3).forEach(tag => {
                template += `<li tabindex="-1" class="el-dropdown-menu__item">
                    <span class="tag tag--menu" style="padding: 0.2rem 0.5rem; width: fit-content">${tag.name}</span>
                  </li>`;
              }) 
              template += `<div x-arrow="" class="popper__arrow"></div>
                </ul>
                </button>
              </span>`;
            }
          });
          template += `</div>`;
          // Menu
          if (tags.length > 0) {
            template += `<div class="el-dropdown desktop-lg--hide" js-trigger="toggle">
              <span class="el-dropdown-link el-dropdown-selfdefine focusing" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">                
                <svg class="demo__icon" fill="#ABB2B9" width="1rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tags" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-tags fa-w-20"><path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z" class=""></path></svg>
              </span>
              <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; text-align: center; width: fit-content;">`;
            tags.forEach(tag => {
              template += `<li tabindex="-1" class="el-dropdown-menu__item">
                  <span class="tag">${tag.name}</span>
                </li>`;
            });
            template += `<div x-arrow="" class="popper__arrow"></div>`;
            template += `</ul>
              </div>`;
          }
        } else {
          // Template Description
          template += `<h4 class="demo__description mobile--hide tablet--hide" js-trigger="create">${descriptionText}</h4>`;
        }

        // List Mode: Buttons
        if (props.template) {
          // do nothing
        } else {
          template += `
            <div class="button-container desktop-sm--hide tablet--hide mobile--hide">
              <button class="button" js-trigger="edit">
                <svg js-trigger="edit" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 8 9" fill="#fff"><path d="M4 .5c-2.208 0-4 1.792-4 4s1.792 4 4 4 4-1.792 4-4-1.792-4-4-4zm.4 6h-.8V4.1h.8v2.4zm0-3.2h-.8v-.8h.8v.8z" /></svg>
                Edit Info
              </button>
              <button class="button" js-trigger="fork">
                <svg js-trigger="fork" width="12" height="16" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M12 4.5a2.5 2.5 0 1 0-3.203 2.4c-.019.503-.131.89-.344 1.153-.481.6-1.54.7-2.662.803-.882.082-1.794.169-2.541.528v-4.5A2.499 2.499 0 0 0 2.5 0a2.5 2.5 0 0 0-.75 4.884v6.229A2.506 2.506 0 0 0 0 13.5a2.5 2.5 0 1 0 3.4-2.331 1.1 1.1 0 0 1 .466-.419c.506-.256 1.262-.325 2.065-.4 1.319-.122 2.813-.262 3.694-1.356.438-.544.66-1.244.675-2.122A2.51 2.51 0 0 0 12 4.5zM2.5 2c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm0 12a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5-.225.5-.5.5zm7-10c.275 0 .5.225.5.5s-.225.5-.5.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"/></svg>  
                Fork
              </button>
              <button class="button" js-trigger="delete">
                <svg js-trigger="delete" width="12" height="15" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M0 2.362v-.787C0 1.201.287.9.643.9h3l.252-.526A.633.633 0 0 1 4.468 0h3.061a.64.64 0 0 1 .576.374L8.357.9h3c.356 0 .643.3.643.675v.787a.33.33 0 0 1-.321.338H.32A.33.33 0 0 1 0 2.362zm11.143 1.575v9.113c0 .745-.576 1.35-1.286 1.35H2.143c-.71 0-1.286-.605-1.286-1.35V3.937A.33.33 0 0 1 1.18 3.6h9.642a.33.33 0 0 1 .322.337zM3.857 5.85a.441.441 0 0 0-.428-.45.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .428-.45v-6.3zm2.572 0A.441.441 0 0 0 6 5.4a.441.441 0 0 0-.429.45v6.3c0 .248.193.45.429.45a.441.441 0 0 0 .429-.45v-6.3zM9 5.85a.441.441 0 0 0-.429-.45.441.441 0 0 0-.428.45v6.3c0 .248.193.45.428.45A.441.441 0 0 0 9 12.15v-6.3z"/></svg>
                Delete
              </button>
            </div>
            
            <div class="el-dropdown desktop-lg--hide" js-trigger="toggle">
              <span class="el-dropdown-link el-dropdown-selfdefine focusing" aria-haspopup="list" aria-controls="dropdown-menu" role="button" tabindex="0">
                <svg class="demo__icon" fill="#ABB2B9" width="14" height="4" xmlns="http://www.w3.org/2000/svg"><path d="M1.75 0C.787 0 0 .787 0 1.75 0 2.712.787 3.5 1.75 3.5c.962 0 1.75-.788 1.75-1.75C3.5.787 2.712 0 1.75 0zm10.5 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75S14 2.712 14 1.75C14 .787 13.213 0 12.25 0zM7 0c-.963 0-1.75.787-1.75 1.75 0 .962.787 1.75 1.75 1.75s1.75-.788 1.75-1.75C8.75.787 7.963 0 7 0z"/></svg>
              </span>

              <ul class="el-dropdown-menu el-popper" style="transform-origin: center top; z-index: 2008; width: 110px;">
                <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="edit">
                  <svg js-trigger="edit" width="15" height="15" fill="#fff" style="position:relative; right:2px; top:3px;" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36 7.5-7.5C15 3.36 11.64 0 7.5 0zm.75 11.25h-1.5v-4.5h1.5v4.5zm0-6h-1.5v-1.5h1.5v1.5z"/></svg>
                  Edit Info
                </li>
                <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="fork">
                  <svg js-trigger="fork" aria-hidden="true" data-prefix="fas" data-icon="code-branch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-code-branch fa-w-12 fa-1x" style="color: rgb(175, 175, 175);"><path js-trigger="fork" d="M384 144c0-44.2-35.8-80-80-80s-80 35.8-80 80c0 36.4 24.3 67.1 57.5 76.8-.6 16.1-4.2 28.5-11 36.9-15.4 19.2-49.3 22.4-85.2 25.7-28.2 2.6-57.4 5.4-81.3 16.9v-144c32.5-10.2 56-40.5 56-76.3 0-44.2-35.8-80-80-80S0 35.8 0 80c0 35.8 23.5 66.1 56 76.3v199.3C23.5 365.9 0 396.2 0 432c0 44.2 35.8 80 80 80s80-35.8 80-80c0-34-21.2-63.1-51.2-74.6 3.1-5.2 7.8-9.8 14.9-13.4 16.2-8.2 40.4-10.4 66.1-12.8 42.2-3.9 90-8.4 118.2-43.4 14-17.4 21.1-39.8 21.6-67.9 31.6-10.8 54.4-40.7 54.4-75.9zM80 64c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm0 384c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm224-320c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16z" class=""></path></svg>
                  Fork
                </li>
                <li tabindex="-1" class="el-dropdown-menu__item" js-trigger="delete">
                  <svg js-trigger="delete"" aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path js-trigger="delete"" d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg>
                  Delete
                </li>
                <zg-button action="removerecord" class="el-dropdown-menu__item" js-trigger="delete">
                  <template v-slot:icon>
                    <zg-icon name=""><svg aria-hidden="true" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-1x" style="color: rgb(175, 175, 175);"><path d="M0 84V56c0-13.3 10.7-24 24-24h112l9.4-18.7c4-8.2 12.3-13.3 21.4-13.3h114.3c9.1 0 17.4 5.1 21.5 13.3L312 32h112c13.3 0 24 10.7 24 24v28c0 6.6-5.4 12-12 12H12C5.4 96 0 90.6 0 84zm416 56v324c0 26.5-21.5 48-48 48H80c-26.5 0-48-21.5-48-48V140c0-6.6 5.4-12 12-12h360c6.6 0 12 5.4 12 12zm-272 68c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208zm96 0c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208z" class=""></path></svg></zg-icon>
                    Delete
                  </template>
                </zg-button>
                <div x-arrow="" class="popper__arrow"></div>
              </ul>
            </div>
          </div>`;
        }

        // Grid Mode: Preview
        let chartTypeTemplateImage = props.template && props.demoType === 'zingchart'
          ? chartTypes.value[title.trim().toLowerCase().replace(/\s/g, '-')]
          : `<img class="demo__image" js-trigger="${props.template ? 'fork' : 'open'}" src="${props.template && props.demoType === 'zinggrid'? demoTemplateType(template_type) : demoImage(image, thumbnail_image)}" alt="Demo Preview" onerror="this.parentNode.style.display='none'; this.parentNode.classList.add('error-default');"/>`;

        template += `</div>
          </div>
          <div class="demo__body" js-trigger="${props.template ? 'fork' : 'open'}">
            <div class="demo__image__wrapper list-hide" ${typeof chartTypeTemplateImage === 'undefined' ? 'style="display: none"' : ''}js-trigger="${props.template ? 'fork' : 'open'}">
              ${chartTypeTemplateImage}
            </div>
            <div class="demo__image demo__image__default ${props.template && props.demoType === 'zinggrid' ? ' demo__image__default--template' : ''}" js-trigger="${props.template ? 'fork' : 'open'}">
              <span class="demo__image__default__text" js-trigger="${props.template ? 'fork' : 'open'}">${props.template ? '' : 'Generating Screenshot'}</span>
            </div>
          </div>
        </section>`;
      }

      return template;
    }
  };

  /**
   * @description Methods to trigger on resize to update ZingGrid
   */
  function resizeHandler() {
    if (zgRef.value) {
      // Update size of zinggrid
      getGridHeight(true);
    }
  };

  /**
   * @description Retieve default demos to add to new account
   * @param { String } demoUID - unique id of default demo
   */
  function retrieveDemo(demoUID) {
    return new Promise((resolve) => {
      $api('demo/retrieve', {
          slug: demoUID
      }, $global).then((response) => {
        resolve({
          html: response.data.html,
          js: response.data.js,
          css: response.data.css,
          title: response.data.title,
          description: response.data.description,
          public: response.data.public,
          is_template: response.data.is_template,
          premium_template: response.data.premium_template,
          image: response.data.image,
          image_small: response.data.image_small,
          mobile_grid: response.data.mobile_grid,
          mobile_height: response.data.mobile_height,
          mobile_image: response.data.mobile_image,
          mobile_image_small: response.data.mobile_image_small,
          type: response.data.type,
        });
      }).catch((err) => {
        $message({
          duration: 0,
          message: 'Could not retieve default demos',
          showClose: true,
          type: 'error',
        });
        resolve();
      });
    })
  };

  /**
   * @description Saves the demo
   * @param { String } type - determines if demo is saved as personal or specified group. If none provided, saved as last specified
   * @param { Boolean } displayMessage - determines which success message to display on save
   */
  function saveDemo(type, displayMessage = true) {
    // Determine type of grid
    const data = {
      title: title.value,
      description: description.value,
      public: isPublic.value,
      id_user: props.userId,
      is_template: isTemplate.value,
      template_type: demoTemplate.value,
      premium_template: isPremium.value,
    };
    if (adminTemplateCreate.value) data['default_template'] = isPublic.value ? 'true' : 'false';
    // Expect type to be group id (not event object triggered by saving from settings)
    if (type && typeof(type) !== 'object') data['id_grouping'] = type === 'personal' ? null : type;
    $api('demo/update', {
      slug: uid.value,
      data,
    }, $global)
    .then(response => {
      let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
      let messageText = messageStatus === 'success' ? 'Demo updated!' : response.data.warning;
      $message({
        message: messageText,
        showClose: true,
        type: messageStatus,
      });

      saveTags();
      zgRef.value.refresh();
      nextTick(() => settingsVisible.value = false);
    })
  };

  function saveTags() {
    //Sort through all of the tags. If any are null, then we need to save them.
    //TODO: Batch save tags
    let tagsToSave = tags.value.filter((tag) => tag.id === null);
    tagsToSave.forEach((tag) => {
      $api('tag/add', {
        uid: uid.value,
        name: tag.name,
      }, $global)
      .catch((error) => {
        $message({
          duration: 0,
          message: 'Could not create tag',
          showClose: true,
          type: 'error',
        })
      });
    });
  };

  /**
   * @description Sets up [demo-viewer] ZingGrid by adding event listeners and set layout mode
   */
  function setupZG() {
    // Add resize event listener
    window.addEventListener('resize', resizeHandler);
    if (zgRef.value) {
      // Add listeners
      zgRef.value.executeOnLoad(() => {
        if (zgRef.value) {
          // Set layout mode
          zgRef.value.setLayout(props.mode === 'list' ? 'row' : 'card');

          // Add event listeners for interations with grid
          zgRef.value.addEventListener('record:click', handleRecordClick);

          // After delete confirmation, delete demo from database
          zgRef.value.addEventListener('data:record:delete', handleRecordDelete);

          // Append dummy 'Create' card
          if (props.create !== 'false') {
            // After grid data loads, append one more record for 'Create Demo'
            zgRef.value.addEventListener('grid:ready', handleGridReady_create);
            zgRef.value.addEventListener('data:load', handleDataLoad_create);
            zgRef.value.addEventListener('cell:render', handleCellRender_create);
          }

          // Append dummy 'No Demos' card if no entries returned and handle error images
          // `data:load` also fixes cutoff bug
          zgRef.value.addEventListener('grid:ready', handleGridReady);
          zgRef.value.addEventListener('data:load', handleDataLoad);
        };
      });
    
      // Overwrite shadowroot style (ZGBody cuts off ZGCard)
      let style = document.createElement('style');
      zgRef.value.shadowRoot.appendChild(style);
    };
  };

  /**
   * @description Update demo information to save
   */
  function updateDemoData(prop, val) {
    switch (prop) {
      case 'demoTemplate': demoTemplate.value = val; break;
      case 'description': description.value = val; break;
      case 'isPremium': isPremium.value = val; break;
      case 'isPublic': isPublic.value = val; break;
      case 'isTemplate': isTemplate.value = val; break;
      case 'tags': tags.value = val; break;
      case 'title': title.value = val; break;
    }
  };

  /**
   * @description Update page size and page size options based on viewport
   * @param {Enum} ret - to return page size or page size options ('size', 'options')
   */
  function updatePageSize(ret) {
    // Get viewport
    if (zgRef.value) {
      zgRef.value.executeOnLoad(() => {
        // Get if accessible
        viewport.value = zgRef.value.getViewport();

        // Update localstorage if does not exist or different
        let viewportItem = cacheFetch('viewport');
        if (viewport.value !== viewportItem) cacheSaveData('viewport', viewport.value);
      });
    } else {
      // Get from localstorage
      viewport.value = cacheFetch('viewport');
    }
    // Determine page size and page size options values
    if (viewport.value && viewport.value.includes('desktop')) {
      pageSize.value = 12;
      pageSizeOptions.value = '12, 24, 48, 96';
    } else if (viewport.value && viewport.value === 'tablet-portrait') {
      pageSize.value = 10;
      pageSizeOptions.value = '10, 20, 40, 80';
    } else {  // 'tablet-landscape
      pageSize.value = 9;
      pageSizeOptions.value = '9, 18, 36, 72';
    }

    // Return if requested
    if (ret === 'size') {
      return pageSize.value;
    } else if (ret === 'options') {
      return pageSizeOptions.value;
    } else {
      // Set page size and page size options
      if (zgRef.value) {
        zgRef.value.executeOnLoad(() => {
          zgRef.value.setPageSize(pageSize.value);
          zgRef.value.setPageSizeOptions(pageSizeOptions.value);
        });
      };
    }
  };
</script>

<style>
  .demo-control {
    display: flex;
    justify-content: flex-end;
  }
  .demo-control__item {
    align-items: center;
    display: flex;
    line-height: var(--input-height);
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
  }
  .demo-control__item .el-input__inner {
    line-height: var(--input-height);
  }
  .demo-control__item--search {
    width: 100%;
  }
  .demo-controls {
    background: var(--color-primary-7);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 1rem 0.9375rem 0.875rem;
  }
  [class^="demo-controls__lvl"] {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 250px;
  }
  [class^="demo-controls__lvl"] + [class^="demo-controls__lvl"] {
    padding-top: 0.75rem;
  }
  .demo-controls__left {
    display: flex;
    flex-direction: column;
  }
  .demo-controls__right {
    width: 100%;
  }
  .demo-controls__right [icon="search"] {
    top: 0.25rem;
  }
  .demo-controls__row {
    display: flex;
    justify-content: space-between;
  }
  .demo-controls__row + .demo-controls__row {
    padding-top: var(--row-padding);
  }

  [demo-viewer] .button {
    background: transparent;
    color: var(--color-primary-1);
  }

  [chartTypeThumbnail],
  [charttypethumbnail] {
    fill: transparent;
    filter: grayscale(100%) sepia(10%) hue-rotate(149deg) saturate(160%) drop-shadow(2px 4px 4px rgba(87, 87, 87, 0.32157));
    height: 8rem;
    margin: 1rem 0 0 0;
    opacity: 0.85;
    width: 100%;
  }

  /* Element Overwrites */
  [demo-viewer] {
    --color-text: #858585;
    --zg-pager-padding: 1.25rem 0;
    --zg-row-card-grid-gap: 2.3125rem;
    --zg-select-arrow-color: var(--color-text);
  }
  [demo-viewer] .el-dropdown-link {
    height: 1rem;
  }
  [demo-viewer] .el-dropdown.active .el-dropdown-menu {
    opacity: 1 !important;
    pointer-events: all;
  }
  [demo-viewer] .el-dropdown-menu {
    border: 1px solid #fff;
    left: -5.5rem;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 2.5rem;
  }
  [demo-viewer] .el-dropdown-menu svg {
    fill: #6A848F;
    margin-right: 0.25rem;
  }
  [mode="list"] [demo-viewer] .el-dropdown-menu::after {
    top: -2rem;
    content: "";
    height: 2.1rem;
    left: 0;
    position: relative;
    width: 110px;
  }
  [mode="list"] [demo-viewer] .el-dropdown-menu::after {
    bottom: 3rem;
  }
  [demo-viewer] .el-popover__reference .el-dropdown-menu {
    top: 1rem;
  }
  [demo-viewer] .el-dropdown-menu__item {
    border-bottom: 1px solid #EBEEF5;
    color: var(--color-primary-7);
    cursor: pointer !important;
    font-size: 0.7rem;
    line-height: 30px;
    padding: 0.15rem 1.25rem;
  }
  [demo-viewer] .el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom-color: transparent !important;
  }
  [demo-viewer] .el-dropdown-menu__item:last-of-type {
    border-bottom: 0;
  }
  [demo-viewer] .el-dropdown-menu__item:hover {
    background: var(--color-primary-8) !important;
    border-color: var(--color-primary-8);
    color: #fff !important;
  }
  [demo-viewer] .el-dropdown-menu__item:hover svg {
    fill: #fff;
    cursor: pointer;
  }
  [demo-viewer] .el-dropdown-menu__item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  [demo-viewer] .el-dropdown-menu__item:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .demo-controls .el-select {
    width: 100%;
  }
  [demo-viewer] .popper__arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-bottom: 5px solid transparent !important;
  }
  [demo-viewer] .el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow {
    border-bottom: transparent !important;
  }
  [mode="list"] [demo-viewer] .el-dropdown {
    top: 0;
  }
  [mode="list"] [demo-viewer] .popper__arrow {
    left: 86px !important;
  }

  /* ZingGrid Overwrites */

  /* load mask styling */
  zing-grid[loading] zg-body { padding:0; }
  zing-grid[loading] zg-load-mask { 
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    height: 600px;
    z-index:999;
    display:flex; 
    justify-content:center; 
    align-items:center; 
    background: var(--background-light);
  }

  .loading--image { position:relative; display: inline-block; }
  .loading--image img {
    height: 50px;
    width: 50px; 
    margin-top: -50px;
    border-radius: 50%;
    -webkit-animation: rock 1.5s cubic-bezier(.71,-.01,.23,.98) infinite;
    animation: rock 1.5s cubic-bezier(.71,-.01,.23,.98) infinite;
  }
  /* end load mask styling */

  [demo-viewer] {
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 0.5rem;
  }
  [demo-viewer],
  [demo-viewer] zg-pager {
    background: transparent;
    border: 0;
  }
  [demo-viewer] zg-body {
    background: transparent;
    margin-top: 0.8125rem;
    padding: 0;
  }
  [demo-viewer] zg-body zg-row {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 0;
    box-shadow: var(--box-shadow-card);
    transition: box-shadow 0.25s ease-out;
  }
  [demo-viewer] zg-body zg-row:hover {
    box-shadow: var(--box-shadow-card-hover);
  }
  [demo-viewer] zg-cell {
    height: 250px;
    max-height: 250px;
    padding: 0;
  }
  [demo-viewer] zg-cell > div {
    height: 100%;
    padding: 0;
    width: 100%;
  }
  [demo-viewer] zg-pager input,
  [demo-viewer] zg-select,
  [demo-viewer] .zg-select-selected {
    border-color: var(--color-greyscale-11);
    color: var(--color-text);
    font-size: 0.75rem;
    height: 1.875rem;
    line-height: 0.75rem;
  }
  [demo-viewer] zg-pager zg-text {
    color: var(--color-primary-2);
  }
  [demo-viewer] zg-pager zg-button svg {
    fill: var(--color-tertiary-7);
  }
  [demo-viewer] zg-pager zg-button[action="reload"],
  /* [demo-viewer] zg-pager zg-select, */
  [demo-viewer] zg-pager zg-text[slot="right"] {
    opacity: 0;
    pointer-events: none;
  }
  [demo-viewer] .zg-select-trigger {
    background: #fff;
  }
  [demo-viewer] zg-cell > label,
  [demo-viewer] zg-head,
  [demo-viewer] zg-status {
    display: none;
  }

  /* ZingGrid .el-dropown menu */
  [demo-viewer] [js-trigger],
  [demo-viewer] .el-dropdown,
  [demo-viewer] .el-dropdown-menu__item {
    cursor: pointer;
  }
  [demo-viewer] .el-dropdown {
    padding: 0.5rem 0.25rem;
    left: 0.75rem;
    border-radius: 4px;
    top: 0.25rem;
  }
  [demo-viewer] .el-dropdown .demo__icon {
    max-height: 100%;
    transform: rotate(90deg);
  }
  [demo-viewer] .el-dropdown zg-button {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .custom-load {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(245, 247, 250);
    pointer-events: none;
    opacity: 0;
  }

  .zing-grid-wrapper {
    position: relative;
  }

  .zing-grid-wrapper zing-grid[loading] { height:550px; }
  /* List: ZingGrid Overwrites */
  [mode="list"] [demo-viewer] {
    margin-top: 1.3rem;
  }
  [mode="list"] [demo-viewer] zg-body zg-row {
    flex-basis: 100%;
    padding: 0;
    margin: 0 0 0.375rem 0;
  }
  [mode="list"] [demo-viewer] zg-cell {
    height: 5.75rem;
  }

  /* Grid Mode */
  .button {
    line-height: 0.725rem;
    padding: 0 0.625rem;
    transition: background 0.25s ease-in-out;
  }
  .button svg { fill: var(--color-primary-7); }
  [demo-viewer] zg-row:hover .button:hover {
    background: #fafdff;
  }
  .demo__body {
    position: relative;
  }
  .demo__button {
    height: var(--btn-height);
    margin: 2rem auto;
  }
  .demo__button--dummy {
    max-width: fit-content;
  }
  .demo__button--dummy--list {
    margin: 0;
  }
  .demo__card {
    padding: 1rem 1.4375rem;
    width: 100%;
  }
  .demo__card,
  .demo__body,
  .demo__image__container {
    height: 100%;
    max-height: calc(100%);
  }
  .demo__date,
  .demo__description {
    color: #AEADAD;
    font-size: 0.6875rem;
    font-weight: normal;
    margin: 0.2rem 0 0;
  }
  .demo__card--dummy {
    border-radius: var(--border-radius);
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .demo__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.02rem;
    min-height: 2.563rem;
  }
  .demo__header--dummy {
    flex: 1;
  }
  .demo__header__right.demo__header--dummy {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
  .demo__header__left {
    align-self: flex-start;
    bottom: 9px;
    position: relative;
  }
  .demo__header__left--dummy {
    bottom: 0;
    flex: 1;
    font-size: 0.875rem;
    margin: auto 0 auto 31px;
  }
  .demo__icon--open {
    margin-right: 0.5rem;
  }
  .demo__image {
    background-color: #ffffff52;
    background-size: contain;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 10px #57575752;
    margin-top: 1rem;
    max-height: 9.5rem;
    position: relative;
    width: 100%;
  }
  [template="true"] .demo__image {
    height: 8rem;
  }
  
  .demo__image--dummy {
    border-radius: var(--border-radius);
    float: right;
    height: 45%;
  }
  .demo__image__wrapper[style="display: none;"] + .demo__image__default {
    height: 8rem;
  }
  .demo__image__default {
    background-color: #fff;
    color: var(--color-primary-2);
    font-size: 1.15rem;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
  .demo__image__default--template {
    background-color: transparent;
  }
  .demo__image__wrapper:not(.error-default) ~ .demo__image__default,
  .demo__image__wrapper:not(.error-default) ~ .demo__image__default:after {
    display: none;
  }
  .demo__image__default::after {
    content: "";
    background-color: #ffffff52;
    background-image: url('../assets/images/demoTemplates/default.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .demo__image__default__text {
    left: 0;
    position: absolute;
    top: 2.5rem;
    width: 100%;
    z-index: 2;
  }
  .demo__image__wrapper {
    background-color: #fff;
    height:100%;
  }
  .demo__text--dummy {
    color: var(--color-tertiary-7);
  }
  .demo__title {
    align-items: center;
    color: var(--color-primary-7);
    display: flex;
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0;
    max-width: 15.63rem;
    width: 100%;
  }
  .demo__title__text {
    bottom: 2px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  [demo-viewer] .button svg {
    margin-right: 0.4375rem;
  }
  [mode="grid"] .demo__card {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
  [mode="grid"] .demo__title--dummy {
    color: var(--background-dark);
    font-weight: 600;
  }

  /* Grid Mode : Hover */
  [demo-viewer] zg-body zg-row:hover,
  [template] [demo-viewer] zg-body zg-row:hover {
    background: var(--sidebar-item-hover);
  }
  [demo-viewer] zg-body zg-row:hover .demo__card--dummy {
    align-items: center;
    justify-content: center;
  }
  [demo-viewer] zg-row:hover .demo__image__wrapper {
    background-color: transparent;
  }

  [mode="grid"] .grid-hide,
  [mode="list"] .list-hide,
  [demo-viewer] zg-body zg-row:hover .hover-hide,
  [demo-viewer] zg-body zg-row:not(:hover) .not-hover-hide {
    height: 0 !important;
    margin: 0;
    opacity: 0 !important;
    padding: 0;
    width: 0;
    max-width: 0;
  }
  [demo-viewer] zg-body zg-row:hover .hover-hide {
    flex: 0 !important;
  }
  [demo-viewer] .el-dropdown:hover,
  [demo-viewer] .el-dropdown.active {
    background: var(--color-primary-8);
  }
  [demo-viewer] .el-dropdown:hover svg.demo__icon,
  [demo-viewer] .el-dropdown.active svg.demo__icon {
    fill: #fff !important;
  }

  /* List Mode */
  .button-container,
  .demo__header__left--list,
  .tag-container {
    display: flex;
  }
  .button-container {
    align-items: center;
    min-width: fit-content;
  }
  .tag-container {
    margin-right: 2.375rem;
  }
  .tag-button.is-link {
    margin-left: 0.875rem;
    padding: 0;
    position: relative;
    color: var(--color-primary-3);
    top: 3px;
  }
  /* override parent styling for hoverstate */
  .tag-button.is-link:hover { color: var(--color-primary-3); }

  [demo-viewer] .tag {
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--color-greyscale-4);
    color: var(--color-greyscale-2);
  }
  [mode="list"] .demo__body,
  [mode="list"] .demo__header__left,
  [mode="grid"] .demo__header__left--list {
    height: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
  }
  [mode="list"] .demo__card {
    padding: 0 1rem;
    width: 100%;
  }
  [mode="list"] .demo__date {
    font-size: 0.75rem;
    white-space: nowrap;
    width: 100%
  }
  [mode="list"] .demo__header {
    height: 100%;
  }
  [mode="grid"] .demo__header__left--list {
    flex: 1;
    justify-content: flex-end;
  }
  [mode="grid"] .demo__header__right {
    max-width: calc(100% - 2rem);
  }
  [mode="list"] .demo__header__left--list {
    max-width: 40%;
  }
  [mode="list"] .demo__header__right {
    display: flex;
    max-width: 65%;
  }
  [mode="list"] .demo__header__right--dummy {
    max-width: 100%;
  }
  [mode="list"] .demo__header__right h3,
  [mode="list"] .demo__header__right h4 {
    margin-right: 1.125rem;
  }
  [mode="list"] .demo__icon--open {
    fill: var(--color-primary-4);
  }
  [mode="list"] .demo__icon__svg {
    fill: var(--color-grayscale-10);
  }
  [mode="list"] [demo-viewer] zg-cell {
    height: 100%;
  }
  [mode="list"] [demo-viewer] zg-cell > div {
    display: flex;
  }
  [mode="list"] .demo__title--dummy {
    max-width: inherit;
  }
  [mode="list"] .demo__title__text--dummy {
    overflow: visible;
  }
  [mode="list"]  .demo__title__text--emphasize {
    font-weight: bolder;
    text-decoration: underline;
  }
  /* List Mode : Hover */
  [mode="list"] [demo-viewer] zg-row:hover .demo__button {
    opacity: 1;
    pointer-events: all;
  }
  [mode="list"] [demo-viewer] zg-row:hover .demo__title__text {
    text-decoration: none;
  }

  /* Template */
  [demo-viewer] .demo__description {
    color: var(--color-text);
    font-size: 0.75rem;
    font-weight: 400;
    height: 2.25rem;
    letter-spacing: 0.02rem;
    overflow: hidden;
    position: relative;
  }
  [mode="grid"] .demo__description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.13rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light) 50%);
  }
  [mode="grid"] zg-cell {
    left: 0;
    overflow: visible;
    position: relative;
  }
  [mode="grid"] zg-row:hover .demo__description:after {
    background: linear-gradient(to right, #2bbeee00, var(--sidebar-item-hover) 50%);
  }
  [template] .demo__header {
    min-height: 3.813rem;
  }
  [template][mode="list"] .demo__header {
    height: 2.938rem;
    min-height: 2.938rem;
  }
  [template] .demo__image__default::after {
    background-image: url('../assets/images/demoTemplates/basic.png');
  }
  [template] .demo__image__wrapper {
    background-color: transparent;
  }
  [mode="list"] [demo-viewer] .demo__description {
    flex: 1;
    height: 100%;
    margin-right: 3.25rem;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  [template] [demo-viewer] zg-body zg-row {
    background: var(--background-light);
    border: 1px solid #d8d8d8;
    border-image: url('../assets/images/dashed-border.png') 1 round;
  }

  /* animations at the bottom */
  @-webkit-keyframes rock {
    25% {
      -webkit-transform: rotate(-65deg);
      transform: rotate(-65deg)
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
    75% {
      -webkit-transform: rotate(65deg);
      transform: rotate(65deg)
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
  }

  @keyframes rock {    
    25% {
      -webkit-transform: rotate(-65deg);
      transform: rotate(-65deg)
    }
    50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
    75% {
      -webkit-transform: rotate(65deg);
      transform: rotate(65deg)
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
    }
  }

  @media screen and (min-width:400px) {}
    .demo__image--dummy {
      height: 85%;
    }
  @media screen and (min-width:800px) {
    [class^="demo-controls__lvl"] {
      flex-direction: row;
      justify-content: space-between;
    }
    .demo-controls__left {
      flex-direction: row;
      width: 100%;
    }
    .demo-controls__right {
      width: 50%;
    }
    .demo-control__item {
      margin-bottom: 0;
    }
    .demo-control__item--search {
      margin-left: auto;
      max-width: 20rem;
    }
    .demo-control__item + .demo-control__item,
    .demo-controls__left + .demo-controls__right {
      margin-left: 1rem;
    }
    [demo-viewer] .el-input input[search] {
      float: right;
      max-width: 23.44rem !important;
      padding-right: 2.5rem;
      width: 100% !important;
    }
    .demo-controls .el-select {
      width: inherit;
    }
    [template] [mode="list"] .demo__header__right {
      max-width: 30%;
    }
  }
  @media screen and (max-width: 1000px) {
    .tag-container {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    [mode="list"] [demo-viewer] .demo__description {
      margin-top: 0.5rem;
    }
  }
</style>