<template>
  <zingnet-card>
    <template v-slot:header>
      <span>Test Coverage</span>
    </template>
    <template v-slot:header__right>
      <el-button @click="openTestCoverage">View in CodeClimate</el-button>
    </template>
    <template v-slot:body>
      <div>
        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <div id='chart-testCoverage'></div>
          </section>

          <section class="zingnetCard--column">
            <zing-grid
              rounded-borders
              :data='JSON.stringify(grid_codeClimate)'
              layout='row'
              layout-controls='disabled'
              selector="true"
              sort="true">
            </zing-grid>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import axios from 'axios';
  import ZingnetCard from '../ZingnetCard.vue';

  // Test Coverage
  const chart_testCoverage = ref({
    type: 'ring',
    labels: [{
      text: 'Proportion of Code Tested',
      fontColor: 'var(--color-tertiary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      x: 0,
      y: '0'
    }, {
      text: '90.42% tested',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      x: 0,
      y: '12%'
    }, {
      text: '9.58% tested',
      fontColor: 'var(--color-secondary-blue-active)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      x: 0,
      y: '18%'
    }, {
      text: '_________________',
      fontColor: 'var(--zingnet-border-color)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 500,
      x: 0,
      y: '21%'
    }, {
      text: 'in last 7 days',
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '28%'
    }, {
      text: 'vs',
      fontColor: 'var(--color-tertiary-blue-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '40%'
    }, {
      text: '89.42% tested',
      fontColor: 'var(--color-primary-gray-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      x: 0,
      y: '52%'
    }, {
      text: '8.58% untested',
      fontColor: 'var(--color-primary-gray-visited)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 600,
      x: 0,
      y: '58%'
    }, {
      text: '_________________',
      fontColor: 'var(--zingnet-border-color)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      x: 0,
      y: '61%'
    }, {
      text: 'in previous 7 days',
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      fontWeight: 400,
      x: 0,
      y: '68%'
    }],
    plot: {
      offsetY: '-20px',
      refAngle: 50,
      slice: '88%',
      valueBox: [{
        text: '90.42%',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 28,
        fontWeight: '100',
        offsetY: '-5px',
      }, {
        text: 'code tested',
        placement: 'center',
        fontColor: 'var(--color-secondary-blue)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: 13,
        fontWeight: '200',
        offsetY: '17px',
      }],
    },
    plotarea: {
      marginLeft: '30%',
    },
    series: [
      {
        values: [9.58],
        backgroundColor: 'var(--color-secondary-blue-active)',
        text: 'untested',
      },
      {
        values: [90.42],
        backgroundColor: 'var(--color-secondary-blue)',
        text: 'tested',
      },
    ]
  });
  const grid_codeClimate = ref([{
    metric: 'Lines of Code',
    value: 'n/a'
  }, {
    metric: 'Tested Files',
    value: 'n/a'
  }, {
    metric: 'Complexity',
    value: 'n/a'
  }, {
    metric: 'Duplication',
    value: 'n/a'
  }]);

  onMounted(() => {
    instantiateChart('chart-testCoverage', chart_testCoverage.value, '300px', '100%');
    getCodeClimateData();
  });

  async function getCodeClimateData() {
    // Get test report id and lines of code
    // TODO Code climate for library removed => no data returned from endpoint
    let testReportId = null // await _getTestReportId();

    if (testReportId) {
      // Get screenshot id
      let snapshotId = await _getSnapshotId(testReportId);

      // Number of tested files
      await _getNumTestFiles(testReportId);

      // Complexity
      await _getComplexity(snapshotId);

      // Duplication
      await _getDuplication(snapshotId);
    };
  };

  function instantiateChart(id, data, height, width) {
    zingchart.render({id, data, height, width});
  };

  function openTestCoverage() {
    window.open('https://codeclimate.com/dashboard');
  };

  function setSidebar(id, val) {
    document.getElementById(id).textContent = val;
  };

  function _codeClimateRequest(url, cb) {
    return new Promise((resolve, reject) => {
      axios({
        url: `https://api.codeclimate.com/v1/repos/${VUE_APP_CODE_CLIMATE_REPO_ID}${url}`,
        method: 'GET',
        headers: {
          'Authorization': `Token token=${VUE_APP_CODE_CLIMATE_API}`,
          'Accept': 'application/vnd.api+json',
        },
      }).then((response) => {
        cb(response, resolve, reject);
      }).catch((err) => {
        reject(err);
      });
    });
  };

  function _getComplexity(snapshotId) {
    let url = `/snapshots/${snapshotId}/issues?filter[categories]=Complexity&page[size]=1`;
    let fn = (response, resolve) => {
      let complexity = response.data.meta.total_count;
      grid_codeClimate.value[2].value = complexity;
      setSidebar('zingNet_codeClimate_complexity', complexity);
      resolve();
    };
    return _codeClimateRequest(url, fn);
  };

  function _getDuplication(snapshotId) {
    let url = `/snapshots/${snapshotId}/issues?filter[categories]=Duplication&page[size]=1`;
    let fn = (response, resolve) => {
      let duplication = response.data.meta.total_count;
      grid_codeClimate.value[3].value = duplication;
      setSidebar('zingNet_codeClimate_duplication', duplication);
      resolve();
    };
    return _codeClimateRequest(url, fn);
  };

  function _getSnapshotId(testReportId) {
    let url = '';
    let fn = (response, resolve) => {
      resolve(response.data.data.relationships.latest_default_branch_snapshot.data.id);
    };
    return _codeClimateRequest(url, fn);
  };

  function _getNumTestFiles(testReportId) {
    let url = `/test_reports/${testReportId}/test_file_reports?page[size]=1`;
    let fn = (response, resolve) => {
      // Get number of files
      let lastLink = response.data.links.last;
      let numFiles = lastLink.split('&')[0].split('=')[1];
      // Set to data object
      grid_codeClimate.value[1].value = numFiles;
      setSidebar('zingNet_codeClimate_files', numFiles);
      resolve(numFiles);
    };
    return _codeClimateRequest(url, fn);
  };

  function _getTestReportId() {
    let url = `/test_reports?page[size]=1`;
    let fn = (response, resolve, reject) => {
      // Lines of code
      if (response.data && response.data.data && response.data.data[0]) {
        let linesOfCode = response.data.data[0].attributes.lines_of_code;
        grid_codeClimate.value[0].value = linesOfCode;
        setSidebar('zingNet_codeClimate_linesOfCode', linesOfCode);
        // Test report id
        resolve(response.data.data[0].id);
      } else {
        reject('Unable to to retrieve test coverage report');
      }
    };
    return _codeClimateRequest(url, fn);
  };
</script>