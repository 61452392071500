const ui = {
  namespaced: true,
  mutations: {
    changeWidth(state, type) {
      state.width = type;
    },
    initializeSidebarSecondary(state, type) {
      if (localStorage.getItem('sidebarSecondary')) {
        state.sidebarSecondary = localStorage.getItem('sidebarSecondary');
      }
    },
    updateAlignment(state) {
      const CONTENT_WIDTH = 1200;
      const PADDING = 32;
      const SIDEBAR_WIDTH = 215;
      let alignment = null;
      if (state.isSidebarOpen) {
        alignment = parseInt(state.windowWidth) - CONTENT_WIDTH - PADDING - SIDEBAR_WIDTH;
      } else {
        alignment = parseInt(state.windowWidth) - CONTENT_WIDTH - PADDING;
      }
    },
    updateDimensions(state) {
      state.windowHeight = window.innerHeight;
      state.windowWidth = window.innerWidth;
      this.commit('ui/updateAlignment');
    },
    updateSidebar(state, data) {
      let keys = Object.keys(this.state.ui);
      keys.forEach((key) => {
        if (data[key] != null) state[key] = data[key];
      });
      this.commit('ui/updateAlignment');
    },
    menuStatus(state, value) {
      state.isMenuOpen = value;
    },
    sendMessage(state, value) {
      state.message = value;
    },
  },
  getters: {
    isMobile: (state) => {
      return state.windowWidth < 930;
    },
  },
  actions: {
  },
  state: {
    headerBar: true,
    message: '',
    alignRight: 'color: red;',
    width: 'narrow',
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    isMenuOpen: false,
    isSidebarOpen: true,
    sidebarPrimary: 'manage',
    sidebarSecondary: 'zinggrid',
  },
};

export default ui;
