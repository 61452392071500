<template>
  <el-dialog
    @open="getData"
    @close="triggerClose"
    domain-modal
    v-bind="visible"
    width="1200px">

    <template v-slot:header>
      <div>
        <p class="title">Referrer info for
          <a v-if="domainObj" class="title--link" :href="`https://${domainObj.domain}`" target="_blank" rel="noreferrer noopener">{{domainObj.domain}}</a>
        </p>
        <!-- <date-range></date-range> -->
      </div>
    </template>

    <div class="flex">
      <div>
        <section>
          <p class="zingnetCard--header">Hit Frequency</p>
          <p>CDN received <b>{{domainObj ? domainObj.hits : ''}} hits</b> from this referrer in this time period.</p>
        </section>
        <section>
          <p class="zingnetCard--header">Licensing</p>
          <div v-loading="loading">
            <template v-if="licenses && licenses.size > 0">
              <ul>
                <template v-for="license in licenses" :key="license">
                  <a :href="`https://c11.qbo.intuit.com/app/customerdetail?nameId=${quickbooksId}`" target="_blank"><li>{{license}}</li></a>
                </template>
              </ul>
            </template>
            <template v-else>
              <callout type="danger" icon="info">
                <template v-slot:content>
                  <p>This domain is not associated with a ZingChart or ZingGrid license</p>
                </template>
              </callout>
            </template>
          </div>
        </section>
        <section v-if="userId || quickbooksId">
          <p class="zingnetCard--header">Associated User</p>
          <p @click="openUserModal(userId, quickbooksId)" class="text--link">More details</p>
        </section>
        <section>
          <p class="zingnetCard--header">Add a Note about this referrer</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="Add your note here."
            v-model="newNote">
          </el-input>
          <el-button @click="addNote" span>Add Note</el-button>

        </section>
      </div>

      <div>
        <section>
          <p class="zingnetCard--header">
            Notes about this referrer
            <bubble-count :count="notesCount" :after="true"></bubble-count>
          </p>
          <section class="domainNote--container">
            <div v-for="note in notes" :key="note.id" class="domainNote">
              <div class="domainNote--header">Written {{dateFromNow(note.created_at, '')}}</div>
              <div class="domainNote--body">{{note.note}}</div>
            </div>
          </section>
        </section>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
  import { computed, defineProps, getCurrentInstance, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import BubbleCount from '../BubbleCount.vue';
  import Callout from '../Callout.vue';
  import dateComposable from '../../../../mixins/date';

  const { dateFromNow } = dateComposable();

  const emit = defineEmits(['close', 'openUserModal']);
  
  const props = defineProps({
    domainObj: Object,
    visible: Boolean,
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const notes = ref(null);
  const newNote = ref(null);
  const licenses = ref({});
  const loading = ref(false);
  const quickbooksId = ref(null);
  const userId = ref(null);

  const notesCount = computed(() => {
    return notes.value ? notes.value.length : 0;
  });

  onMounted(() => {
    getData();
  });

  function addNote() {
    let newNoteData = {
      domain_id: props.domainObj.domain_id,
      note: newNote.value,
      created_at: new Date().toISOString().split('T')[0],
    };

    axios({
      url: '/api/cdn_note',
      method: 'POST',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
      data: newNoteData,
    })
    .then((response) => {
      // Add to notes container
      notes.value.push(newNoteData);

      // Clear textarea
      newNote.value = null;

      // Success message
      $message({
        message: 'Note added!',
        type: 'success',
      });
    }).catch((err) => {
      // Error message
      let errMessage = `Error occurred while adding note: ${err}`;
      $message({
        message: errMessage,
        type: 'error',
      });
    });
  };
  
  function getData() {
    getNotes();
    getLicenseData();
  };
  
  function getLicenseData() {
    // Clear license data
    licenses.value = null;

    // Check if all required info is available to get license info
    if (props.domainObj && props.domainObj.domain) {
      loading.value = true;
      axios({
        url: `/api/license/details/${props.domainObj.domain}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
      })
      .then((response) => {
        let licenses = response.data.map((data) => data.plan);
        licenses.value = new Set(licenses);
        quickbooksId.value = response && response.data && response.data[0] ? response.data[0].id_quickbooks : null;
        // Don't include userId if belongs to admin
        userId.value = response && response.data && response.data[0] ? response.data[0].id_user : null;
        if (userId.value === 2) userId.value = null;
        loading.value = false;
      }).catch((err) => {
        // Error message
        let errMessage = `Error occurred while retrieving license: ${err}`;
        $message({
          message: errMessage,
          type: 'error',
        });
      });
    } else if (props.domainObj) {
      // Error message
      let errMessage = `Missing either cdn domain or hostname: ${err}`;
      $message({
        message: errMessage,
        type: 'error',
      });
    };
  };
  
  function getNotes() {
    // Clear notes
    notes.value = [];

    if (props.domainObj) {
      axios({
        url: `/api/cdn_note/${props.domainObj.domain_id}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
      })
      .then((response) => {
        notes.value = response.data;
      }).catch((err) => {
        // Error message
        let errMessage = `Error occurred while retrieving notes: ${err}`;
        $message({
          message: errMessage,
          type: 'error',
        });
      });
    };
  };
  
  function openUserModal(id, quickbooksId) {
    emit('openUserModal', {id, quickbooksId});
  };
  
  function triggerClose() {
    emit('close');
  };
</script>

<style scoped>
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  .domainNote {
    border: var(--zingnet-border);
    border-radius: 10px;
    overflow: hidden;
  }
  .domainNote + .domainNote {
    margin-top: 1.125rem;
  }
  .domainNote--body {
    font-size: 0.9375rem;
    padding: 1rem 1.125rem;
  }
  .domainNote--container {
    max-height: 20rem;
    overflow: scroll;
  }
  .domainNote--header {
    background-color: var(--zingnet-color-gray);
    border-bottom: var(--zingnet-border);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    font-style: italic;
    padding: 1rem 1.125rem;
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>

<style>
  :root {
    --domain-modal-padding: 1.75rem 1.875rem;
  }

  [domain-modal] .el-dialog {
    border-radius: 10px;
    padding-right: 0 !important;
  }

  [domain-modal] .el-dialog__header {
    border-bottom: var(--zingnet-border);
    padding: var(--domain-modal-padding) !important;
  }
  [domain-modal] .title {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }

  [domain-modal] .el-dialog__body {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    padding: 0;
  }

  [domain-modal] .zingnetCard--header {
    display: block;
  }
</style>