<template>
  <zingnet-card>
    <template v-slot:header>ZingChart License Generator</template>
    <template v-slot:subtitle>
      <div>
        <p>Please choose the proper license form to fill out when generating a license for a client.</p>
        <collapse-section title="License Generator Notes" :open="false" button-text="Notes">
          <template v-slot:body>
            <div>
              <p>Example scenarios of domains and subdomains that would get covered by license key.</p>
              <p>Note that when subdomain is omitted or asterisk (*) is used, this makes the license key cover more subdomains.</p>
              <p>It is best if keys are generated to be more strict if license key is for subdomain.</p>
              <br/>
              <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                  prop="domain"
                  label="Domain / Subdomain"
                  width="300">
                </el-table-column>
                <el-table-column
                  prop="generated"
                  label="Covered by keys generated for">
                </el-table-column>
              </el-table>
            </div>
          </template>
        </collapse-section>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <section class="zingnetCard--row">
          <section class="zingnetCard--column">
            <section class="section">
              <h3 class="zingnetCard--header">
                Output Filename
                <bubble-count :count="1" :label="label" :after="true"></bubble-count>
              </h3>
              <p>Providing the output filename is optional</p>
              <el-input type="text" v-model="fileNameModel"></el-input>
            </section>

            <section class="section">
              <h3 class="zingnetCard--header">
                License Generation
                <bubble-count :count="2" :label="label" :after="true"></bubble-count>
              </h3>
              <p>Separate with commas</p>
              <el-input type="textarea" v-model="licenseVal" placeholder="*.zingchart.com,api.zingchart.com"></el-input>
              <el-button @click="_handleLicense" span>
                Generate License
                <svg-icon icon="generate" fill="var(--color-primary-gray)"></svg-icon>
              </el-button>
            </section>
          </section>

          <section class="zingnetCard--column">
            <h3 class="zingnetCard--header">Additional Generation Options</h3>
            <collapse-section title="Enterprise License Generation" :open="true">
              <template v-slot:body>
                <div>
                  <h3 class="zingnetCard--header">
                    License Generation
                    <bubble-count :count="2" :label="label" :after="true"></bubble-count>
                  </h3>
                  <p>Separate with commas</p>
                  <el-input type="textarea"  v-model="enterpriseLicenseVal" placeholder="Company Name"></el-input>
                  <el-button @click="_handleEnterpriseLicense" span>
                    Generate License
                    <svg-icon icon="generate" fill="var(--color-primary-gray)"></svg-icon>
                  </el-button>
                </div>
              </template>
            </collapse-section>

            <collapse-section title="PlantomJS License Generation">
              <template v-slot:body>
                <div>
                  <h3 class="zingnetCard--header">
                    License Generation
                    <bubble-count :count="2" :label="label" :after="true"></bubble-count>
                  </h3>
                  <p>Separate with commas</p>
                  <el-input type="textarea"  v-model="phantomLicenseVal" placeholder="Company Name"></el-input>
                  <el-button @click="_handlePhantomLicense" span>
                    Generate License
                    <svg-icon icon="generate" fill="var(--color-primary-gray)"></svg-icon>
                  </el-button>
                </div>
              </template>
            </collapse-section>

            <!-- hidden anchor tag for download -->
            <a ref="downloadAnchorTag" :href="fileData" :download="fileName" hidden>Hidden Anchor Tag</a>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { computed, onBeforeUnmount, ref } from 'vue';
  import { useStore } from 'vuex';
  import Axios from 'axios';
  import BubbleCount from '../BubbleCount.vue';
  import CollapseSection from '../CollapseSection.vue';
  import SvgIcon from '../../../../components/SvgIcon.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  
  const $store = useStore();
  const licenseVal = ref('');
  const enterpriseLicenseVal = ref('');
  const phantomLicenseVal = ref('');
  const fileData = ref('');
  const fileNameModel = ref('license');
  const label = ref('Step');
  const tableData = ref([{
    domain: 'zingsoft.com',
    generated: 'zingsoft.com, www.zingsoft.com, *.zingsoft.com'
  }, {
    domain: 'www.zingsoft.com',
    generated: 'www.zingsoft.com, zingsoft.com, *.zingsoft.com'
  }, {
    domain: 'demos.zingsoft.com',
    generated: 'demos.zingsoft.com, *.demo.zingsoft.com, *.zingsoft.com'
  }, {
    domain: 'demos.memo.zingsoft.com',
    generated: 'demos.memo.zingsoft.com, *.demo.memo.zingsoft.com, *.memo.zingsoft.com, *.zingsoft.com'
  }]);
  const downloadAnchorTag = ref(null);
  const anchorTimetoutId = ref(null);

  const fileName = computed(() => {
    return `${fileNameModel.value || 'license'}.js`;
  });

  // cleanup before leaving component
  onBeforeUnmount(() => {
    clearTimeout(anchorTimetoutId.value);
  });

  /**
   * @description trim the input fields and modify the value
   * based on comma separated value
   * @param {String} sLicense - license input field values
   */
  function _pruneLicenseInput(sLicense) {
    sLicense = sLicense.replace('\n', '');
    sLicense = sLicense.replace('\t', '');
    sLicense = sLicense.replace('\r', '');

    return sLicense.trim();
  };

  /**
   * @description format data for clientside download of file.
   * We create a blob and then a "fake" url string for the 
   * anchor tag to reach which will in return download content.
   * Kind of acts like image data in a way.
   * Anchor tag Solution might only work in chrome, but thats fine for us (in general)
   */
  function _generateBlobContentUrl(sLicense) {
    // blob takes array of parts, so pass single array with string
    const tempBlob = new Blob([sLicense], { type: 'application/javascript', charset: 'UTF-8' });

    // return the url string for anchor tag download
    return window.URL.createObjectURL(tempBlob);
  };

  /**
   * @description Mimic the anchor click to download the file.
   * We need a delay for this clientside download
   */
  function _mimicAnchorClick() {
    anchorTimetoutId.value = setTimeout(() => {
      // click to download content
      downloadAnchorTag.value.click();
    }, 250);
  };

  /**
   * @description generate, return and save default
   * license file
   */
  function _handleLicense() {
    // split license by comma
    let aLicenses = licenseVal.value.split(',');
    // trim license values input
    let sLicense = aLicenses.map((license) => _pruneLicenseInput(license));

    // generate and download license
    let input = sLicense;
    generateLicense('general', input);
  };

  /**
   * @description generate, return and save enterprise
   * license file
   */
  function _handleEnterpriseLicense() {
    // generate and download license
    let input = _pruneLicenseInput(enterpriseLicenseVal.value);
    generateLicense('enterprise', input);
  };

  /**
   * @description generate, return and save phantom
   * license file
   */
  function _handlePhantomLicense() {      
    // generate and download license
    let input = _pruneLicenseInput(phantomLicenseVal.value);
    generateLicense('phantom', input);
  };

  function generateLicense(type, input){
    Axios({
      url: '/api/license/zc',
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
      data: {
        type: type,
        input: input,
      },
    })
    .then((sLicense) => {
      // assign download content
      fileData.value = _generateBlobContentUrl(sLicense.data);

      // download file
      _mimicAnchorClick();
    });
  };
</script>

<style scoped>
  [icon="generate"] {
    position: relative;
    top: 1px;
  }
  p {
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    margin: 1rem 0;
  }
  .section + .section {
    margin-top: 2.3125rem;
  }
</style>