<template>
  <default-layout v-if="!isRedirecting">
    <section class="content--narrow">
      <header class="content__header content__header--expanded">
        <h2>My Most Recent Demos</h2>
        <div class="content__header-more"><a href="/demos">All Demos <font-awesome-icon :icon="['fas', 'arrow-right']"/></a></div>
      </header>
        <demo-viewer :add="true"></demo-viewer>
    </section>
    <!-- UPDATES -->
    <section class="content--narrow">
      <header class="content__header content__header--expanded">
        <h2>Updates</h2>
        <div class="content__header-more"><a href="https://blog.zingchart.com/">View more updates <font-awesome-icon :icon="['fas', 'arrow-right']"/></a></div>
      </header>
      <section class="blog__feed">
        <article class="blog__article" v-for="(post, index) in blogPosts" :key="index">
          <a :href="post.url"><div class="blog__image" :style="{'background-image':'url(' + post.image+ ')'}"></div></a>
          <div class="blog__content">
            <header class="blog__header">
              <h3 class="blog__title"><a :href="post.url">{{post.title}}</a></h3>
              <section class="blog__summary">
                {{post.summary}}
              </section>
              <div class="blog__subheading">
                <div class="blog__date">{{post.date}}</div>
              </div>
            </header>
          </div>
        </article>
      </section>
    </section>
  </default-layout>
</template>


<script setup>
  import { getCurrentInstance, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import DefaultLayout from './layouts/Default.vue';
  import DemoViewer from '../components/DemoViewer.vue'
  import axios from 'axios';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $route = useRoute();
  const $router = useRouter();
  const demos = ref([]);
  const blogPosts = ref([
    {
      title: '',
      url: '',
      date: '',
      image: '',
      summary: '',
      author: '',
    },
    {
      title: '',
      url: '',
      date: '',
      image: '',
      summary: '',
      author: '',
    },
    {
      title: '',
      url: '',
      date: '',
      image: '',
      summary: '',
      author: '',
    },

  ]);
  const isRedirecting = ref(true);

  onMounted(() => {
    checkSession();

    $api('demo/list', {}, $global)
    .then((response) => {
      let demos = response.data.results.splice(0, 5);
      demos = demos.map(({title, image, last_updated, uid}) => {
        let date = new Date(last_updated).toLocaleString('en-us', { month: "long", year: 'numeric', day: 'numeric' } );
        title = title || 'Untitled Demo';
        return {
          title,
          uid,
          image: `https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${image}`,
          date,
        }
      });
      demos.value = demos;
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Could not fetch demos',
        showClose: true,
        type: 'error',
      });
    });

    $api('blog/list', {}, $global)
    .then((response) => {
      // Format dates in blog posts
      blogPosts.value = response.data.map((post) => {
        post.date = new Date(post.date).toLocaleDateString();
        return post;
      });
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Could not load blog posts!',
        showClose: true,
        type: 'error',
      });
    });
  });

  function checkSession() {
    // Redirect to last seen for expired session
    let lastSeen = localStorage.getItem('lastSeen');
    if (lastSeen) {
      localStorage.removeItem('lastSeen');
      if ($route.path !== lastSeen) $router.push(lastSeen);
    } else isRedirecting.value = false;
  };

  function formatDemoLink(uid) {
    return '/demos/create/' + uid;
  };

  function formatDate(date) {
    date = new Date(date);
    return `${date.getMonth()}/${date.getDay()}/${date.getFullYear()}`;
  };
</script>

<style>
  /* Blog */
  .blog__feed {
  }
  .blog__article {
    display: flex;
    margin-bottom: 1rem;
    border: 1px solid #e6e6e6;
  }
  .blog__image {
    width: 150px;
    height: 100px;
    background-size: cover;
  }
  .blog__title {
    margin: 0;
  }
  .blog__content {
    padding: 0.5rem 1rem;
  }
  .blog__summary {
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blog__title > a:hover {
    color: var(--link-hover);
  }
  .blog__image {
    background-position: center center;
  }
</style>
