<template>
  <div class="footer__wrap" :footer="footer">
    <div style="flex: 1;"></div>
    <footer class="content__footer">

      <div class="flex-item  flex-item--left">
        <div>
          <a href="https://www.zinggrid.com/about" target="_">About Us</a>
        </div>
        <div class="content__footer__links">
          <span>ZingSoft Sites:</span>
          <a href="https://www.zingchart.com/" target="_"><svg-icon :icon="`${footer == 'secondary' ? 'zingchart-logomark' : 'zingchart-logomark-light'}`"></svg-icon>ZingChart</a> 
          <a href="https://www.zinggrid.com/" target="_"><svg-icon :icon="`${footer == 'secondary' ? 'zinggrid-logomark' : 'zinggrid-logomark-light'}`"></svg-icon>ZingGrid</a> 
        </div>
      </div>

      <div class="content__footer--right">
        <div>© Copyright 2009-{{new Date().getFullYear()}} ZingSoft, Inc. </div>
        <div>
          <a href="https://www.zinggrid.com/privacy" target="_">Privacy</a>
          <span class="content__footer__links__separator">|</span>
          <a href="#" target="_">Legal</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
  import { defineProps } from 'vue';
  import SvgIcon from '../../components/SvgIcon.vue';

  const props = defineProps({
    footer: {
      type: String,
      required: false,
    },
  });
</script>

<style>
:root {
  --footer-margin: 0.9rem;
}

.footer__wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
  height: var(--footer-height);
}

.content__footer {
  display: flex;
  justify-content: space-between;
  background: var(--color-primary-1);
  color: white;
  padding: 1rem;
  width: 100%;
  min-height: var(--footer-height);
  font-size: 0.8rem;
  z-index: 1500;
}

.content__footer div > div {
  max-height: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
}

.content__footer--right > div {
  justify-content: flex-end;
}

.content__footer a {
  color: var(--nav-icon-color);
  text-decoration: none;
}

.content__footer a:hover,
[footer="secondary"] .content__footer a:hover {
  color: var(--color-tertiary-1);
}

.content__footer__links a {
  margin: 0 0 0 var(--footer-margin);
}

.content__footer__links svg {
  margin-right: 0.05rem;
  height: 20px;
  width: 20px;
  filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);;
}

.content__footer__links a:hover svg {
  filter: grayscale(0%);
}

.content__footer__links [icon="zinggrid-logomark-light"] {
  margin-right: 0.35rem;
}

.content__footer__links__separator {
  margin: 0 var(--footer-margin);
}

/* Footer on Entry Pages */
[footer="secondary"] .content__footer {
  background: var(--color-reset);
  color: var(--color-greyscale-8);
}
[footer="secondary"] .content__footer a {
  color: var(--color-primary-1);
}
[footer="secondary"] .content__footer__links svg {
  filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);;
}
</style>
