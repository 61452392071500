const tenant = {
  namespaced: true,
  mutations: {
    add(state, obj) {
      state.name = obj.name;
      state.logo = obj.logo;
    },
  },
  getters: {
  },
  actions: {
  },
  state: {
    name: '',
    logo: '',
  },
};

export default tenant;
