<template>
  <el-dialog
    @open="getData"
    @close="triggerClose"
    domain-modal
    v-bind="visible"
    width="1200px">

    <template v-slot:header>
      <div>
        <p class="title">User info for {{email}}</p>
      </div>
    </template>

    <div class="flex" v-loading="loading">
      <div>
        <section>
          <p class="zingnetCard--header">General</p>

          <p v-if="name">{{name}}</p>
          <p v-if="email || dataEmail">{{email || dataEmail}}</p>
          <p v-if="company || dataCompany">{{company || dataCompany}}</p>
          <p v-if="ip || dataIp">{{ip || dataIp}}</p>

          <p v-if="noData">No data on user</p>
        </section>

        <section v-if="quickbooks">
          <p class="zingnetCard--header">Quickbooks</p>
          <a :href="`https://c11.qbo.intuit.com/app/customerdetail?nameId=${quickbooks}`" target="_blank">Customer</a>
        </section>

        <section v-if="email">
          <p class="zingnetCard--header">TrackJS</p>
          <a :href="trackJsUrl" target="_blank">Errors by User</a>
        </section>
      </div>

      <div>
        <section>
          <p class="zingnetCard--header">Licensing</p>
          <template v-if="licenses && licenses.length > 0">
            <ul>
              <template v-for="license in licenses" :key="license.id">
                <a :href="`https://c11.qbo.intuit.com/app/invoice?txnId=${license.id}`" target="_blank"><li>{{license.name}}</li></a>
              </template>
            </ul>
          </template>
          <template v-else>
            <callout type="danger" icon="info">
              <template v-slot:content>
                <p>This domain is not associated with a ZingChart or ZingGrid license</p>
              </template>
            </callout>
          </template>
        </section>

        <section>
          <p class="zingnetCard--header">Total Spendings</p>
          <p>${{totalSpending || 0}}</p>
        </section>

        <section>
          <p class="zingnetCard--header">Balance</p>
          <p>${{balance || 0}}</p>
        </section>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import Callout from './Callout.vue';

  const emit = defineEmits(['close']);

  const props = defineProps({
    company: String,
    email: String,
    id: Number,
    ip: String,
    quickbooksId: Number,
    visible: Boolean,
  });

  const $store = useStore();
  const balance = ref(0);
  const dataCompany = ref('');
  const dataEmail = ref('');
  const dataIp = ref('');
  const name = ref('');
  const licenses = ref([]);
  const loading = ref(false);
  const quickbooks = ref('');
  const totalSpending = ref(0);
  
  const noData = computed(() => {
    return !name.value && !props.company && !dataCompany.value && !props.email && !dataEmail.value
      && !props.ip && !dataIp.value;
  });

  function getData() {
    loading.value = true;

    let query = '?';
    let addQuery = (q) => {
      if (props[q]) {
        if (query.length > 1) query += '&';
        query += `${q}=${props[q]}`;
      };
    };

    if (props.company) query += `company=${props.company}`;
    addQuery('email');
    addQuery('id');
    addQuery('quickbooksId');

    axios({
      url: `/api/admin/user${query}`,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    })
    .then((response) => {
      let data = response.data;

      balance.value = _addCommas(data.balance);
      dataCompany.value = data.company;
      dataEmail.value = data.email;
      dataIp.value = data.ip;
      name.value = data.name;
      if (data.licenses) {
        licenses.value = data.licenses.map((license) => {
          let interval = license.plan.interval === 'year' ? 'Annual' : 'Perpetual';
          let type = license.plan.type === 'ZC' ? 'ZingChart' : 'ZingGrid';
          return {
            id: license.id,
            name: [interval, type, license.plan.product].join(' '),
          };
        });
      };
      quickbooks.value = data.quickbooksId;
      totalSpending.value = _addCommas(data.totalSpending);

      loading.value = false;
    });
  };

  function trackJsUrl() {
    return props.email ? `https://my.trackjs.com/users/filtered?userid=${encodeURIComponent(props.email)}` : null;
  };

  function triggerClose() {
    balance.value = 0,
    dataCompany.value = '';
    dataEmail.value = '';
    dataIp.value = '';
    name.value = '';
    licenses.value = [];
    quickbooks.value = '';
    totalSpending.value = 0;

    emit('close');
  };

  function _addCommas(num) {
    if (num) {
      let sNum = typeof num === 'string' ? num : num.toString();
      return sNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return null;
    };
  };
</script>

<style scoped>
  .flex {
    display: flex;
  }
  .flex > div {
    padding: var(--domain-modal-padding);
    width: 50%;
  }
  .flex > div + div {
    border-left: var(--zingnet-border);
  }
  section + section {
    margin-top: 1.875rem;
  }
</style>
