<template>
  <default-layout>
    <section ref="support" support class="content--narrow">
      <header class="content__header">
        <h2>Support</h2>
      </header>

      <el-form :label-position="isMobile ? 'top' : 'left'" label-width="75px" :model="form">
        <el-form-item label="Email">
          {{email}}
        </el-form-item>
        <el-form-item label="Subject">
          <el-select placeholder="Support Subject" v-model="form.type">
            <el-option label="Trouble Ticket" value="troubleTicket"></el-option>
            <el-option label="Sales Inquiry" value="salesInquiry"></el-option>
            <el-option label="Licensing" value="licensing"></el-option>
            <el-option label="Installation" value="installation"></el-option>
            <el-option label="Troubleshooting" value="troubleshooting"></el-option>
            <el-option label="Other" value="other"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Product">
          <el-radio-group v-model="form.product" @change="handleRadioChange">
            <el-radio-button value="zinggrid">ZingGrid</el-radio-button>
            <el-radio-button value="zingchart">ZingChart</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Title">
          <el-input :maxlength="MAX_TITLE" placeholder="Your Title Here" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="Comment">
          <el-input :maxlength="MAX_BODY" placeholder="Your Comment Here" :rows="10" type="textarea" v-model="form.comment"></el-input>
        </el-form-item>
        <el-form-item>
          <form support="form">
            <input ref="createFile" support="createFile" :accept="acceptedFiletypes.toString()" @change="filesChange($event.target.files)" multiple type="file">
          </form>
          <p support="uploadText">The maximum you can upload is 10 files at 5MB. You have uploaded <span style="font-weight:600">{{attachmentAmount}}/10</span> files at <span style="font-weight:600">{{attachmentSize}}MB</span>.</p>
        </el-form-item>
        <el-form-item button-align="right" size="large">
          <el-button @click="onSubmit()" :disabled="!createValidated || attachmentAmount > 10 || attachmentSize > 5" type="primary">Create</el-button>
        </el-form-item>
      </el-form>

      <my-dialog
        @close="submitVisible = false"
        title="Message Sent"
        type="success"
        :visible="submitVisible">
        <template v-slot:content>
          <div>
            <p>Your message has been sent to support. We usually respond within 24 hours.</p>
          </div>
        </template>
        <template v-slot:options>
          <div>
            <el-button @click="submitVisible = false" type="primary">Close</el-button>
          </div>
        </template>
      </my-dialog>

    </section>

  </default-layout>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import DefaultLayout from '../layouts/Default.vue';
import MyDialog from '../../components/MyDialog.vue';

const instance = getCurrentInstance();
const $global = instance.appContext.config.globalProperties;
const $api = $global.$api;
const $message = $global.$message;
const $store = useStore();

const support = ref(null);
const createFile = ref(null);
const MAX_BODY = 65536;
const MAX_TITLE = 100;
const acceptedFiletypes = ['.gif', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.pdf', '.txt', '.css', '.html', '.json', '.js', '.zip'];
const attachmentAmount = ref(0);
const attachmentSize = ref(0);
const email = ref(null);
const form = ref ({
  type: '',
  title: '',
  comment: '',
  product: $store.state.user['referrer'].toLowerCase(),
});
const submitVisible = ref(false);

const createValidated = computed(() => {
  const type = form.value.type.length > 0;
  const title = form.value.title.length > 0;
  const body = form.value.comment.length > 0;
  return type && title && body;
});
const isMobile = computed(() => {
  return $store.getters['ui/isMobile'];
});

onBeforeMount(() => {
  axios({
    url: '/api/user',
    method: 'GET',
    headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
  }).then((data) => {
    let userData = data.data;
    email.value = userData.email;
  });
})

function clearFileInput(fileInput) {
  let inputs = [fileInput];
  if (fileInput === 'all') inputs = ['create', 'update', 'close'];
  inputs.forEach(input => {
    let $fileInput = support.value.querySelector(`[support="${input}File"]`);
    if ($fileInput) $fileInput.value = '';
  });

  attachmentAmount.value = 0;
  attachmentSize.value = 0;
};

/**
 * @description Triggered when choosing attachments to track amount and size
 */
function filesChange(files) {
  attachmentAmount.value = files.length;
  if (attachmentAmount.value > 10) {
    $message({
      duration: 0,
      message: `Please select only 10 attachments max. Currently attempting to attach ${attachmentAmount.value} attachments.`,
      showClose: true,
      type: 'error',
    });
  }

  attachmentSize.value = 0;
  for (let file in files) {
    if(files[file].size) attachmentSize.value += files[file].size;
  }
  attachmentSize.value = parseFloat(attachmentSize.value/1000000).toFixed(2);
  if (attachmentSize.value > 5) {
    $message({
      duration: 0,
      message: `Max limit is 5MB. Currently attempting to attach ${attachmentSize.value}MB.`,
      showClose: true,
      type: 'error',
    });
  }
};

/**
 * @description When radio button change, update the state 'referrer'
 */
function handleRadioChange(label) {
  $store.state.user['referrer'] = label.toLowerCase();
};

/**
 * @description On submision of form, check type of support ticket. If type is:
 *  - Bug: Create support ticket
 *  - Other: Send email
 * TODO: add group id and check for support bool
 */
function onSubmit() {
  if(!form.value.type || form.value.comment === '' || form.value.title === '' || form.value.title === '') {
    $message({
      duration: 0,
      message: 'Please fill out all the fields.',
      showClose: true,
      type: 'error',
    });
    return;
  }
  let files = createFile.value.files;
  if (files.length === 0) {
    sendIssue();
  } else {
    let attachmentArr = [];
    Array.prototype.forEach.call(files, (file) => {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        let encoded = reader.result
        attachmentArr.push({
          content: encoded,
          filename: file.name
        });
        if (attachmentArr.length === files.length) {
          sendIssue(attachmentArr);
        };
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    });
  }
};

/**
 * @description Sends support ticket with to devs or support
 * @param { Object } attachmentArr - array of attachments to include to suport ticket
 */
function sendIssue(attachmentArr) {
  let body = {
    attachments: attachmentArr,
    description: form.value.comment,
    product: form.value.product,
    referer: 'ZingSoft',
    title: form.value.title,
    type: form.value.type,
  }

  $api('email/support', {
    json: true,
    data: body,
  }, $global)
  .then(() => {
    submitVisible.value = true;
    clearFileInput('create');
    form.value.name = form.value.email = form.value.type = form.value.title = form.value.comment = '';
  }).catch((err) => {
    console.log(err)
    $message({
      duration: 0,
      message: 'Error sending message: ' + err,
      showClose: true,
      type: 'error',
    });
  });
};
</script>

<style>
  [support="uploadText"] {
    font-size: 0.8rem;
    margin: 0.25rem;
  }

  [support].el-button {
    bottom: 3px;
    margin: 0 0.5rem 0 0;
    position:relative;
  }

  [support="form"] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }

  @media screen and (max-width: 400px) {
    [support] .el-form {
      margin-top: 1.5rem;
    }

    [support] .el-form-item {
      margin-bottom: 10px;
    }

    [support] .el-form--label-top .el-form-item__label {
      padding: 0;
    }
  }
</style>
