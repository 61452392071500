import { computed, ref } from 'vue';

export default function subscriptionComposable({$global, activePlan, activePlanIndex, dialogNewDomains, dialogNewSubdomains, pricingInterval, 
  updateDomainDialogVisible, updateDomainSubscription}) {
  
  const showExpiredState = ref(false);
  const transactions = ref([]);

  const zingchartEnterpriseLicense = computed(() => {
    if (activePlan.value) {
      return activePlan.value.Sku === 'ZC-S-ENT';
    }
    return false;
  });
  const flatTransactions = computed(() => {
    if (transactions.value && transactions.value) {
      return transactions.value.map((transaction) => {
        let date = new Date(transaction.created * 1000);
        return {
          name: transaction.name,
          amount: '$' + transaction.amount,
          date: date.getMonth() + '/' + date.getDay() + '/' + date.getFullYear(),
        };
      });
    } else {
      return [];
    }
  });

  /**
   * @description Get count of domains, which is displayed in the subscription card.
   * Should only count domain objects and not domain strings. Strings are domains that are
   * about to be registered.
   * @param {Array} domains - Array of objects and strings representing domains. Objects are registered
   * domains and strings are domains to be registered
   * @param {Boolean} subdomain - Get count of subdomain instead of domains
   */
  function domainCount(domains, subdomain) {
    let prop = subdomain ? 'subdomains' : 'domains';
    if (domains && domains[prop]) {
      let toCount = domains[prop].filter((domain) => typeof domain === 'object');
      return toCount.length;
    } else return 0;
  };
   
  function isEnterprise(plan) {
    return plan.type === 'ZC' && plan.product === 'Enterprise';
  };

  function isZingGridOem(plan) {
    return plan.type === 'ZG' && plan.product === 'OEM';
  };

  function isPlanActive(index) {
    return (activePlanIndex.value === index) ? 'plan-tab' : 'plan-tab--inactive';
  };

  function showCompany(plan) {
    return (plan.type === 'ZC' && plan.product === 'Enterprise') || (plan.type === 'ZG' && plan.product === 'OEM');
  };

  /**
   * @description Given the license key, returns the company associated to the
   * ZingChart Enterprise license
   */
  function getLicenseCompany(license) {
    if (license) {
      let arr = license.split('\'');
      return arr[3];
    }
  };

  function getPlanFeatures(product) {
    let currentInterval = pricingInterval.value ? 'perpetual' : 'year';
    let currentProduct = product.plans.filter(plan => plan.interval === currentInterval)[0];
    return currentProduct && currentProduct.features ? currentProduct.features : [];
  };

  function getSubscriptionTransactions() {
    $global.$api('subscription/transaction', {}, $global)
      .then((result) => {
        transactions.value = result.data;
      })
      .catch(() => {
        $global.$message({
          duration: 0,
          message: 'Could not get subscription transactions',
          showClose: true,
          type: 'error',
        });
      });
  };

  function openUpdateDomainDialog(subscription) {
    updateDomainDialogVisible.value = true;
    dialogNewDomains.value = subscription.domains.filter((domain) => typeof domain === 'object');
    dialogNewSubdomains.value = subscription.subdomains.filter((subdomain) => typeof subdomain === 'object');
    updateDomainSubscription.value = subscription;
  };

  function showExpired(subscription){
    return !(subscriptionStatus(subscription) == 'Expired' || subscriptionStatus(subscription) == 'Unsubscribed') || showExpiredState.value;
  };

  function subscriptionStatus(subscription, color) {
    let status = null;
    let statusColor = null;

    // Determine in subscription is still processing payment
    if (subscription.status === 'Unsubscribed') {
      status = 'Unsubscribed';
      statusColor = 'red';
    } else if (subscription.status) {
      status = 'Pending';
      statusColor = 'orange';
    } else {
      status = 'Active';
      statusColor = 'green';
    }

    // Determine if subscription is expired
    let now = new Date().getTime()/1000;
    if (subscription.plan.interval === 'year' && now > subscription.current_period_end) {
      status = 'Expired';
      statusColor = 'red';
    };
    return color ? statusColor : status;
  };
  
  function toggleShowExpired() {
    showExpiredState.value = !showExpiredState.value;
    return;
  };

  return {
    showExpiredState,
    transactions,
    zingchartEnterpriseLicense,
    flatTransactions,
    domainCount,
    isEnterprise,
    isZingGridOem,
    isPlanActive,
    showCompany,
    getLicenseCompany,
    getPlanFeatures,
    getSubscriptionTransactions,
    openUpdateDomainDialog,
    showExpired,
    subscriptionStatus,
    toggleShowExpired
  };
};