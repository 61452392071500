<template>
  <div class="app">
    <app-nav></app-nav>
    <section class="app-content" :style="fixedHeightStyling">
      <router-view></router-view>
    </section>
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import AppNav from '../components/AppNav.vue';

  const $route = useRoute();
  const $store = useStore();

  function fixedHeightStyling() {
    if($route && $route.path.includes('/demos/create')) {
      return {
        overflow: 'hidden',
      }
    } else {
      return null;
    }
  };

  function socialAccount() {
    return $store.getters['auth/is_social'];
  };

  function isMenuOpen() {
    return $store.state.ui.isMenuOpen;
  };
</script>
