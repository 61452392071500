import { ref } from 'vue';

export default function deleteSubscriptionComposable({$global, getUserSubscriptions}) {
  const unsubscribeDialogVisible = ref(false);
  const subscriptionToDelete = ref(false);

  function cancelSubscription() {
    $global.$api('subscription/delete', {
      slug: subscriptionToDelete.value.id,
    }, $global)
      .then(() => {
        $global.$message({
          message: 'Unsubscribed!',
          showClose: true,
          type: 'success',
        });
        getUserSubscriptions();
        unsubscribeDialogVisible.value = false;
      })
      .catch(() => {
        $global.$message({
          duration: 0,
          message: 'Cannot unsubscribe',
          showClose: true,
          type: 'error',
        });
      });
  };

  function handleCancelSubscription(subscription) {
    subscriptionToDelete.value = subscription;
    unsubscribeDialogVisible.value = true;
  };

  return {
    unsubscribeDialogVisible,
    subscriptionToDelete,
    cancelSubscription,
    handleCancelSubscription
  };
};