<template>
  <div class="filter_wrapper">
    <el-select :show="authenticated" v-model="selectedValue" @change="updateSelected">
      <el-option v-for="option in defaultOptions" :key="option.id" :label="option.name" :value="option.id">
        <div class="filter__option">
          <div class="filter__name">{{option.name}}</div>
        </div>
      </el-option>
      <el-option-group v-if="options.length > 0" label="Groups">
        <el-option
          v-for="group in options"
          :key="group.id"
          :label="group.name"
          :value="group.id">
          <div class="filter__option">
            <div class="filter__name">{{group.name}}</div>
            <div class="filter__count">{{getCount(group)}}</div>
          </div>
        </el-option>
      </el-option-group>
    </el-select>
    <svg-icon icon="close--circle" height="1rem" width="1rem"></svg-icon>
    <svg-icon class="el-input-icon" icon="angle"></svg-icon>
  </div>
</template>

<script setup>
  import { computed, defineEmits, ref } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from './SvgIcon.vue';

  const emit = defineEmits(['group']);

  const props = defineProps(['defaultOptions', 'getCount', 'groupBy', 'options', 'selected']);
  
  const authenticated = computed(() => {
    if (!authSetup.value) {
      authSetup.value = true;
      return $store.state.user['user_id'];
    }
  });

  const $store = useStore();
  const authSetup = ref(false);
  const selectedValue = ref(props.selected);

  function clearSelect() {
    selectedValue.value = null;
  };

  /**
   * @description Triggered on change to notify `DemoViewer.vue` that `group` value should
   * update to new value
   * @param {String} value - value to update to
   */
  function updateSelected(value) {
    // Update group results
    emit('group', value);
  };
</script>

<style src="../assets/styles/filterSelector.css" scoped></style>

<style>
  .filter_wrapper [icon="close--circle"] svg {
    position: relative;
    top: -6px;
  }
</style>
