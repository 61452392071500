<template>
  <zingnet-card>
    <template v-slot:header>
      <span>Library Releases</span>
    </template>
    <template v-slot:body>
      <div>
        <section class="zingnetCard--row">
          <div class="zingnetCard--column column-45 flex no-padding">
            <div class="zingnetCard--column">
              <h3 class="zingnetCard--header">Latest ZingChart Release</h3>
              <score-card :value="zingchartVersion" icon="zingchart-logomark" icon-size="29" :title="`Released on ${formatDate(zingchartReleaseDate, null, 'MMMM D, YYYY')}`"></score-card>
            
              <h3 class="zingnetCard--header"># of Changelog Releases</h3>
              <score-card :value="zingchartChangelog" title="since 2015"></score-card>

              <el-button @click="openChangelog('zingchart')">View Changelog</el-button>
            </div>
            <div class="zingnetCard--column column-50">
              <h3 class="zingnetCard--header">Latest ZingGrid Release</h3>
              <score-card :value="zinggridVersion" icon="zinggrid-logomark" icon-size="29" :title="`Released on ${formatDate(zinggridReleaseDate, null, 'MMMM D, YYYY')}`"></score-card>
              
              <h3 class="zingnetCard--header"># of Changelog Releases</h3>
              <score-card :value="zinggridChangelog" title="since 2018"></score-card>
              
              <el-button @click="openChangelog('zinggrid')">View Changelog</el-button>
            </div>
          </div>

          <div id="chart-versionBuilds" class="zingnetCard--column column-55"></div>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import { Octokit } from '@octokit/core';
  import ScoreCard from '../../components/ScoreCard.vue';
  import ZingnetCard from '../ZingnetCard.vue';

  import dateComposable from '../../../../mixins/date.js';

  const { formatDate } = dateComposable();
  const octokit = new Octokit({ auth: VUE_APP_GITHUB_API_TOKEN });
  const randomatic = require('randomatic');
  const $store = useStore();

  // Library Releases
  const chart_versionBuilds = ref({
    type: 'bar',
    title: {
      text: 'Build Size Comparison',
      fontColor: 'var(--color-tertiary-blue)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '15px',
      fontWeight: 600,
      textAlign: 'left',
      x: '-5px',
      y: '-5px',
    },
    legend: {
      borderWidth: 0,
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '12px',
        fontWeight: 400,
        offsetX: '-5px',
      },
      layout: 'horizontal',
      offsetX: '-5px',
      offsetY: '-16px',
      width: '270px',
    },
    scaleX: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
      },
      fontSize: '12px',
      label: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '13px',
        fontWeight: 600,
        offsetY: '5px',
        text: 'Builds'
      },
      labels: ['ZingChart', 'ZingChart (gzip)', 'ZingGrid', 'ZingGrid (gzip)'],
      lineColor: 'var(--zingnet-chart-color)',
      tick: {
        visible: false,
      }
    },
    scaleY: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
      },
      fontColor: 'var(--color-primary-gray)',
      fontFamily: 'Nunito Sans, sans-serif',
      fontSize: '13px',
      guide: {
        lineColor: 'var(--zingnet-chart-color)',
      },
      label: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '13px',
        fontWeight: 600,
        text: 'Build Size (kB)',
      },
      lineColor: 'var(--zingnet-chart-color)',
      step: 200,
      tick: {
        fontSize: '12px',
        fontFamily: 'Nunito Sans, sans-serif',
        lineColor: 'var(--zingnet-chart-color)',
      },
    },
    plot: {
      animation: {
        effect: 'ANIMATION_EXPAND_BOTTOM',
        method: 'ANIMATION_STRONG_EASE_OUT',
        sequence: 'ANIMATION_BY_NODE',
        speed: 275,
      }
    },
    plotarea: {
      margin: '45px 0 42px 56px',
    },
    series: [{
        values: [],
        text: 'Full Build',
        backgroundColor: 'var(--color-primary-blue)'
      },
      {
        values: [],
        text: 'Core Build',
        backgroundColor: 'var(--color-secondary-blue)'
      },
      {
        values: [],
        text: 'ES5/ES6 Build',
        backgroundColor: 'var(--color-secondary-blue-visited)'
      }
    ]
  });
  
  const zingchartBuilds = ref(['zingchart.min.js', 'zingchart-core.min.js', 'zingchart-es6.min.js']);
  const zingchartChangelog = ref(null);
  const zingchartReleaseDate = ref(null);
  const zingchartVersion = ref(null);

  const zinggridBuilds = ref(['zinggrid.min.js', 'zinggrid.es6.min.js', 'zinggrid.es5.min.js']);
  const zinggridChangelog = ref(null);
  const zinggridReleaseDate = ref(null);
  const zinggridVersion = ref(null);

  onMounted(() => {
    getData();
  });

  function getData() {
    // Get library versions
    _getLibraryData('zingchart');
    _getLibraryData('zinggrid');

    // Get library changelog
    _getLibraryChangelog('zingchart');
    _getLibraryChangelog('zinggrid');

    // Get library build sizes
    let compressed = [_getBuildSize('zingchart'), _getBuildSize('zinggrid')];
    Promise.all(compressed)
      .then((response) => {
        // Add to chart data
        _addChartData(response);
        instantiateChart('chart-versionBuilds', chart_versionBuilds.value, '334px', '537.5px');

        // Add to sidebar
        response.forEach((library, i) => {
          let lib = i === 0 ? 'zingchart' : 'zinggrid';
          
          library.data.builds.forEach((build) => {
            let buildName = _getBuildName(build.name, lib);
            let id = `zingNet_github_${lib}_library_${buildName}Build`;
            setSidebar(id, _bToKb(build.size, true));
            setSidebar(`${id}_gzip`, _bToKb(build.size_gzip, true));
          });
        });
      });
  };
  
  function instantiateChart(id, data, height, width) {
    zingchart.render({id, data, height, width});
  };
  
  function openChangelog(lib) {
    window.open(`https://github.com/${lib}/${lib}/releases`);
  };
  
  function setSidebar(id, val) {
    let ref = document.getElementById(id);
    if (ref) ref.textContent = val;
  };
  
  function _addChartData(data) {
    // Format chart data
    let chartData = [[], [], []];
    data.forEach((lib, i) => {
      let libName = i === 0 ? 'zingchart' : 'zinggrid';

      lib.data.builds.forEach((build) => {
        let index = (libName === 'zingchart' ? zingchartBuilds : zinggridBuilds).value.indexOf(build.name);
        chartData[index].push(_bToKb(build.size));
        chartData[index].push(_bToKb(build.size_gzip));
      });
    });

    // Add chart data
    chartData.forEach((data, i) => {
      chart_versionBuilds.value.series[i].values = data;
    });
  };
  
  function _bToKb(num, addUnit) {
    let conversion = Math.floor(num/1024);
    return addUnit ? `${conversion} kB` : conversion;
  };
  
  function _getLibraryChangelog(lib) {
    axios({
      url: `https://api.github.com/repos/${lib}/${lib}/releases`,
      method: 'GET',
    }).then((response) => {
      if (lib === 'zingchart') {
        zingchartChangelog.value = response.data.length;
        zingchartReleaseDate.value = response.data[0].published_at;
      } else {
        zinggridChangelog.value = response.data.length;
        zinggridReleaseDate.value = response.data[0].published_at;
      };
    });
  };
  
  function _getLibraryData(lib) {
    octokit.request('GET https://api.github.com/repos/{owner}/{repo}/releases/latest', {
      owner: "zingsoftinc",
      repo: `${lib}-lib`,
    })
    .then((response) => {
      let version = response.data.name;
      if (lib === 'zingchart') zingchartVersion.value = version;
      else zinggridVersion.value = version;
      setSidebar(`zingNet_github_${lib}_library_version`, version);
    })
    .catch((error) => {
      console.log(error);
    });
  };
  
  function _getBuildName(name, lib) {
    let buildTypes = ['core', 'es5', 'es6'];
    let type = null;
    for (let i = 0; i < buildTypes.length; i++) {
      if (name.indexOf(buildTypes[i]) > -1) {
        type = buildTypes[i];
        break;
      };
    };
    // Map zinggrid es6 build to core
    if (type === 'es6' && lib === 'zinggrid') type = 'core';
    return type || 'full';
  };
  
  function _getBuildSize(lib) {
    return new Promise((resolve, reject) => {
      // Determine to use conditional request
      let headers = {
        Authorization: `token ${VUE_APP_GITHUB_API_TOKEN}`,
        Accept: 'application/vnd.github.v3+json',
        'If-None-Match': randomatic('a0', 40)
      };

      // Make request for build files
      axios({
        url: `https://api.github.com/repos/zingsoftinc/${lib}-cdn/contents/public`,
        headers,
        method: 'GET',
      }).then((response) => {
        // Filter for current library builds
        let builds = response.data.filter((file) => {
          if ((lib === 'zingchart' ? zingchartBuilds : zinggridBuilds).value.indexOf(file.name) > -1) {
            return true;
          }
        });

        // Get gzip compressed size
        _getGzipBuildSize(builds, resolve);
      }).catch((error) => {
        console.log(error);
      });
    });
  };
  
  function _getGzipBuildSize(arg, resolve) {
    axios({
      url: '/api/admin/buildsize/gzip',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${$store.state.auth.idToken}`,
        'X-CSRF-TOKEN': axios.defaults.headers.delete['X-CSRF-Token'] ,
      },
      data: {builds: arg},
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.log(error);
    });
  };
</script>

<style scoped>
  .el-button {
    margin-top: 0;
  }
  .zingnetCard--column {
    height: 380px;
  }
</style>