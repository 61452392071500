<template>
  <div resetPassword="container" :modal="!!embed" :visible="!!visible">

    <entry-container slim=true resetPassword="center" :showLogo="!!!embed">
      <template v-slot:entryHeader>
        <h3>
          {{headerText}}
          <span resetPassword="close-modal" @click="closeModal"><svg-icon icon="close"></svg-icon></span>
        </h3>
      </template>

      <template v-slot:entryBody>
        <div resetPassword="container__body">
          <p resetPassword="p" v-html="bodyText"></p>

          <div v-if="!displayHomeButton" resetPassword="form" ref="resetFormWrapper">
            <el-form @submit.prevent :model="form" ref="resetForm">
              <input-email
                form="form"
                :model="form.email"
                @keyup.enter="resetPassword"
                @change="checkFilled"
                @validate="validateForm">
              </input-email>
              <el-form-item>
                <el-button @click="resetPassword" type="primary" class="button button--bottom" :disabled="disableButton">Recover Password</el-button>
              </el-form-item>
            </el-form>
          </div>

          <!-- Redirect Home -->
          <div v-if="displayHomeButton" @click="goHome" class="button  button--bottom">
            <p resetPassword="p">
              <span>Home</span>
            </p>
          </div>
        </div>
      </template>
    </entry-container>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits, getCurrentInstance, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import EntryContainer from './EntryContainer.vue';
  import InputEmail from './InputEmail.vue';
  import SvgIcon from '../../../components/SvgIcon.vue';

  const emit = defineEmits(['keyup', 'close', 'submit']);

  const props = defineProps({
    embed: true,            // Embed reset form on page (else use modal)
    visible: true,          // For modal use - visibility of modal
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $store = useStore();
  const $route = useRoute();
  const $router = useRouter();
  const headerText = ref('Recover your password');
  const bodyText = ref('Enter your email address to recover your password.');
  const displayHomeButton = ref(false);
  const disableButton = ref(true);
  const form = ref({
    email: '',
  });
  const resetForm = ref(null);
  const resetFormWrapper = ref(null);

  function checkFilled(value) {
    if (typeof value === 'string') {
      form.value.email = value;
      disableButton.value = form.value.email.length === 0;
    };
  };

  /**
   * @description Available when `[modal]` is enabled. On 'close' button click, component emits 'close' event.
   * Parent component should then set the variable responsible for the visibility of this modal.
   */
  function closeModal() {
    emit('close');
  };

  /**
   * @description Available after user submits email for password recovery. Redirects back to homepage.
   */
  function goHome() {
    if ($route.path !== '/') $router.push('/');
  };

  /**
   * @description Sends email for password reset
   */
  function resetPassword(e) {
    e.preventDefault();

    // Validate
    let hasError = resetFormWrapper.value.querySelectorAll('.el-form-item.is-error');
    if (hasError.length > 0) {
      $message({
        duration: 0,
        message: 'Please fill in all fields correctly.',
        showClose: true,
        type: 'error',
      });
      return;
    };

    // Change Password
    $store.dispatch('auth/changePassword', {
      email: form.value.email,
    }, $global).then((result) => {
      // Update text
      headerText.value = 'Email Sent!';
      bodyText.value = `We sent you an email with further instructions.
        Please <a href="https://www.zinggrid.com/contact">contact support</a> for any questions.`;
      displayHomeButton.value = true;

      // Add timestamp to invalidate sessions before password reset
      $api('user/update_session', {email: form.value.email, session_expiration: new Date().getTime()}, $global);
    })
  };

  function validateForm() {
    resetForm.value.validate((valid) => {
      disableButton.value = !valid;
    });
  };

  function update(type, val) {
    form.value[type] = val;
  };
</script>

<style>
  [entryContainer] .button--bottom { background: var(--color-tertiary-1); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem; }
  [entryContainer] .button--bottom p { font-weight: 600; margin: auto; }
  [entryContainer] .button--bottom:hover { background: var(--color-tertiary-4); }
  [entryContainer] [disabled].button--bottom { background: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed; }
  
  [modal="true"] {
    background: #0000008c;
    left: 0;
    height: 100vh;
    opacity: 0;
    padding-top: 5rem;
    pointer-events: none;
    position: absolute;
    width: 100vw;
    top: 0;
    z-index: 1000;
  }
  [modal="true"][visisble] { opacity: 1; pointer-events: all; }
  [modal="true"] [slim][entryContainer] { top: calc(50% - 15rem); }
  [resetPassword] .button--bottom { margin: 0.75rem auto 0; width: 11.25rem; }
  [resetPassword] .button--bottom p { color: var(--color-reset); font-size: 0.8125rem; margin: auto; }
  [resetPassword="p"] { color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; }
  [resetpassword="close-modal"] { cursor: pointer; float: right; opacity: 0; pointer-events: none; position: relative; right: 1rem; top: 0.2rem; }
  [modal="true"] [resetpassword="close-modal"] { opacity: 1; pointer-events: all; }
  [resetPassword="center"] { align-self: center; }
  [resetPassword="center"].center--content-inline { display: flex; margin: auto; }
  [resetPassword="container"] { text-align:center; }
  [resetPassword="container__body"] { flex: 1; padding: 0.5rem 0; }
  [resetPassword="form"] { margin: 1.5rem 0.5rem 0; }

  [resetPassword="close-modal"]:hover svg { fill: var(--color-tertiary-7); }
  [resetPassword] [entryContainer="entry__content"] { padding: 0; }
  [resetPassword] [entryContainer="entry__actions"] { margin: 0.5rem 0 2rem; }
  [resetPassword] .el-form-item__error { top: 75%; }
</style>