const Domain = {
  // Domain error messages
  messageDomain: 'Expecting valid url with format "domain.com"',
  messageSubdomain: 'Expecting valid url with format "stage.domain.com"',
  /**
   * Returns error message when subdomain is not a subdomain of listed domains. Message
   * specifies an example of a subdomain with given domain. Otherwise a default example is used.
   * @param {Array} domains - Array of strings or objects representing registered
   * or will be registered domains.
   * Expected domain object is {domain, id, id_user, plan, quickbooks_invoice_id, sub, update}
   */
  messageSubdomainCheck: function(domains) {
    if (domains && domains[0]) {
      let domain = domains[0] ? domains[0].domain || domains[0] : 'domain.com';
      return `Expecting url to be a subdomain of provided domain(s). `
        + `Example: stage.${domain}`;
    }
  },

  // Domain regex for validation
  regexDomain: /^[a-zA-Z0-9\-\_\~]+(\.[a-z]+)+$/,
  regexSubdomain: /^([a-zA-Z0-9\-\_\~\*]+\.){1,3}[a-zA-Z0-9\-\_\~]+\.[a-z]+$/,
  /**
   * Creates regex pattern for subdomain validation by checking if subdomain is a subdomain of
   * at least one domain from `domains`.
   * @param {Array} domains - Domain string or object representing domains registered or to be
   * registered to license. If object, requires property domain to contain domain.
   */
  regexSubdomainCheck: function(domains) {
    if (domains) {
      // Create regex for subdomain
      let str = '^[a-zA-Z0-9\-\_\~\*\.]*\\.(';
      // From subscription view, which handles multiple domain/subdomain registration
      domains.forEach((domain, i) => {
        if (domain) {
          if (i > 0) str += '|';
          str += domain.domain || domain;
        }
      });
      str += ')$';
      return new RegExp(str);
    };
  },
};

// export default Domain;
module.exports = Domain;
