<template>
  <span
    bubble-count
    :class="classname"
    :style="after ? 'margin-right: auto;' : ''">
    {{label ? ` ${label}` : label}}{{displayValue}}{{labelRight ? ` ${labelRight}` : labelRight}}
  </span>
</template>

<script setup>
  import { computed, defineProps, ref } from 'vue';

  const props = defineProps({
    count: Number,
    comparisonCur: Number,
    comparisonPrev: Number,
    label: String,
    labelRight: String,
    after: Boolean,
  });

  const classname = ref('sidebar-item__count');

  const displayValue = computed(() => {
    if (typeof props.count !== 'undefined') {
      return addCommas(props.count);
    } else if (typeof props.comparisonCur !== 'undefined' && typeof props.comparisonPrev !== 'undefined') {
      // Calculate Percentage
      let percentage = 0;
      if (props.comparisonPrev === 0 && props.comparisonCur > 0) percentage = 1 
      else if (props.comparisonPrev > 0 && props.comparisonPrev > 0) percentage = (props.comparisonCur - props.comparisonPrev) / props.comparisonPrev;
      percentage = parseInt(percentage*100);
      let positve = percentage > 0 ? '+' : '';

      // Add class
      classname.value = `sidebar-item__count ${positve ? 'pos' : 'neg'}`;
      return `${positve}${percentage}%`;
    };
  });

  function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
</script>

<style>
  [bubble-count] .sidebar-item__count {
    border-color: #F5F9FC;
    background-color: #F5F9FC;
    color: var(--color-primary-gray);
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0 0.3125rem;
    padding: 3px 10px;
  }

  [bubble-count] .sidebar-item__count.pos {
    border-color: #DAFFE0;
    background-color: #DAFFE0;
    color: #1E6F2B;
  }
  [bubble-count] .sidebar-item__count.neg {
    border-color: #FFCDDA;
    background-color: #FFCDDA;
    color: var(--color-primary-danger);
  }
  
  .zingnetCard--header [bubble-count] .sidebar-item__count {
    margin: 0 auto 0 2.25rem;
  }
</style>