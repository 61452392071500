<template>
  <el-popover
    editorSetting
    placement="bottom"
    @after-leave="updateEditorSetting"
    title="View"
    trigger="click"
    width="inherit">
    <!-- LAYOUT -->
    <template v-if="!isMobile">
      <div editorSetting="setting">
        <p editorSetting="setting__title">Layout:</p>
        <el-radio-group v-model="currentState.layout" @change="handleUpdate" :size="size">
          <el-radio-button
            v-for="option in layoutOptions" 
            :key="option"
            :value="option">
            {{renderLabel(option)}}
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-divider></el-divider>
    </template>
    <!-- FONT -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Font Family:</p>
      <el-select v-model="currentState.fontFamily" @change="handleUpdate" placeholder="Select">
        <el-option
          v-for="option in fontOptions" 
          :key="option"
          :label="renderLabel(option)"
          :value="option">
        </el-option>
      </el-select>
      <p>Font Size:</p>
      <el-input-number v-model="currentState.fontSize" @change="handleUpdate" :min="1" :size="size"></el-input-number>
    </div>
    <el-divider></el-divider>
    <!-- THEME -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Theme:</p>
      <el-select v-model="currentState.theme" @change="handleUpdate" placeholder="Select">
        <el-option
          v-for="option in themeOptions" 
          :key="option"
          :label="renderLabel(option)"
          :value="option">
        </el-option>
      </el-select>
    </div>
    <el-divider></el-divider>
    <!-- Previews -->
    <div editorSetting="setting">
      <p editorSetting="setting__title">Previews:</p>
      <template v-for="option in tabOptions" :key="option">
        <el-button
          @click="handleUpdate(option)"
          :size="size">
          {{renderLabel(option)}}<font-awesome-icon :icon="['fas', 'external-link-alt']" layout-dropdown="externalLink" size="sm"></font-awesome-icon>
        </el-button>
      </template>
    </div>
    <!-- TRIGGER -->
    <template v-slot:reference>
      <el-button :type="type" :size="size">View<i class="el-icon-arrow-down el-icon--right"></i></el-button>
    </template>
  </el-popover>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';

  const emit = defineEmits(['update']);

  const props = defineProps({
    uid: {
      type: String,
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: ''
    },
  });

  const $store = useStore();
  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;

  // Setup dropdown options
  const defaultOptions = ref(['bottom', 'default']);  // Default editor options
  const layoutOptions = ref(['left', 'bottom', 'right', 'top']); //, 'grid', 'columns'], // Editor layout options
  const fontOptions = ref(['cursive', 'fantasy', 'monospace', 'sans-serif', 'serif']);
  const tabOptions = ref(['view', 'embed']);  // Dropdown options that open to new tab
  const themeOptions = ref(['default', 'dark', 'cobalt', 'github', 'monokai']); // Theme options
  const viewOptions = ref(['view', 'embed']);  // View layout options

  // Current state
  const currentState = ref({
    fontFamily: 'monospace',
    fontSize: 13,
    layout: 'bottom',
    theme: 'default',
  });

  // Settings
  const editorSettings = ref(['FontFamily', 'FontSize', 'Layout', 'Theme']);

  const isMobile = computed(() => {
    return $store.getters['ui/isMobile'];
  });
  // Current state
  const stateFontFamily = computed(() => {
    return $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']).fontFamily : currentState.value.fontFamily;
  });
  const stateFontSize = computed(() => {
    return $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']).fontSize : currentState.value.fontSize;
  });
  const stateLayout = computed(() => {
    return $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']).layout : currentState.value.layout;
  });
  const stateTheme = computed(() => {
    return $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']).theme : currentState.value.theme;
  });
  
  onMounted(() => {
    // Load in user's editor settings
    loadEditorSettings();
  });

  /**
   * @description Load in user's editor settings
   */
  function loadEditorSettings() {
    editorSettings.value.forEach(setting => {
      let stateSetting = null;
      switch(setting) {
        case 'FontFamily': stateSetting = stateFontFamily; break;
        case 'FontSize': stateSetting = stateFontSize; break;
        case 'Layout': stateSetting = stateLayout; break;
        case 'Theme': stateSetting = stateTheme; break;
      };

      currentState.value[setting] = stateSetting.value;
      handleUpdate(stateSetting.value, false);
    });
  };

  /**
   * @description Renders labels for dropdown items. When dropdown command contains '_', the words after
   * are wrapped in parenthesis.
   * @param {String} text - text to render
   * Ex. tabs => Tabs
   * Ex. tabs_row => Tabs (row)
   * Ex. tabs_row_mode => Tabs (row mode)
   */
  function renderLabel(text) {
    // Capitalize first word
    let label = text[0].toUpperCase() + text.slice(1);
    let words = label.split('_');
    // Place word(s) in parenthesis
    if (words.length > 1) {
      label = '';
      words.forEach((word, index) => {
        // Add parenthesis to second word
        if (index === 1) label += `(`;
        // If last word, add closing parenthesis
        if (index === words.length-1) label += `${word})`;
        else label += `${word} `;
      });
    }
    return label;
  };

  /**
   * @description When dropdown item selected:
   * 1. Open tab option: open view in new tab
   * 2. Theme option: update theme by emitting event to parent to handle
   * 3. Editor layout option: update view by emitting event to parent to handle
   * After update both the state and send settings to database
   * @param {String} view - Selected dropdown item's `[command]` attribute value
   * @param {Boolean} update - Update state and database
   */
  function handleUpdate(option, update=true) {
    if (option) {
      // Font-Family
      if (fontOptions.value.indexOf(option) > -1) emit('update', option, 'fontFamily');
      // Layout
      else if (layoutOptions.value.indexOf(option) > -1) emit('update', option, 'layout');
      // Tabs
      else if (tabOptions.value.indexOf(option) > -1) window.open(`/demos/${option}/${props.uid}`);
      // Theme
      else if (themeOptions.value.indexOf(option) > -1) emit('update', option, 'theme');
      // Font-Size
      else emit('update', option.toString(), 'fontSize');
    }
  };

  /**
   * @description Update editor settings when user makes changes to settings
   * @param {Boolean} val - new value of switch
   */
  function updateEditorSetting() {
    // Update state
    let state = $store.state.user['settings_editor'] ? JSON.parse($store.state.user['settings_editor']) : {};
    state.fontFamily = currentState.value.fontFamily,
    state.fontSize = currentState.value.fontSize,
    state.layout = currentState.value.layout,
    state.theme = currentState.value.theme,
    $store.state.user['settings_editor'] = JSON.stringify(state);

    // Null check user and settings
    if ($store.state.user.user_id && $store.state.user['settings_editor']) {
      // Update database
      $api('user/update', {
        'settings_editor': state,
      }, $global)
      .then((response) => {
        $store.dispatch('user/refresh', $global);
      })
      .catch((result) => {
        $message({
          duration: 0,
          message: 'Unable to update autoupdate settings',
          showClose: true,
          type: 'error',
        });
      })
    };
  };
</script>


<style>
  .editor__resizer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #989898;
    z-index: 1;
  }

  [editorLayout] {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }

  [editorSetting="setting"] {
    margin-top: 20px;
  }

  [editorSetting="setting__title"] {
    font-weight: 600;
  }

  /* COL / ROW */
  [editorLayout|="col"] {
    flex-direction: row;
  }

  [editorLayout|="col"] .editor__code-container{
    flex-direction: column;
  }

  [editorLayout|="col"] .editor__resizer--code {
    cursor: row-resize;
    height: 10px;
    width: 100%;
  }
  [editorLayout|="col"] .editor__resizer--preview {
    cursor: col-resize;
    height: 100%;
    width: 10px;
  }

 [editorLayout|="row"] .editor__resizer--code {
    cursor: col-resize;
    height: 100%;
    width: 10px;
  }
  [editorLayout|="row"] .editor__resizer--preview {
    cursor: row-resize;
    height: 10px;
    width: 100%;
  }

  [editorLayout|="col"] .editor__resizer--code .fa-ellipsis-vertical,
  [editorLayout|="col"] .editor__resizer--preview .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--code .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--preview .fa-ellipsis-vertical {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  /* LEFT */
  [editorLayout$="left"] {
    flex-direction: row-reverse;
  }

  /* RIGHT */
  [editorLayout$="right"] {
    flex-direction: row;
  }

  /* TOP */
  [editorlayout$="top"] {
    flex-direction: column-reverse;
  }

  /* LAYOUT DROPDOWN */
  [layout-dropdown="externalLink"] {
    margin-left: 0.75rem;
    position: relative;
    bottom: 1px;
  }

  /* THEMES: DEFAULT */
  [editorTheme="default"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: #fff;
    --editorTheme-editorTertiary: var(--color-primary-4);
    --editorTheme-primary: #1EBAED;
    --editorTheme-secondary: #78d6f4;
    --editorTheme-tertiary: #e9f8fd;
  }

  /* THEMES: DARK */
  [editorTheme="dark"] {
    --editorTheme-editorPrimary: #383838;
    --editorTheme-editorSecondary: #1e1e1e;
    --editorTheme-editorTertiary: #6d7698;
    --editorTheme-primary: #94aedf;
    --editorTheme-secondary: #6d7c99;
    --editorTheme-tertiary: #e5edfd;
  }

  /* THEMES: COBALT */
  [editorTheme="cobalt"] {
    --editorTheme-editorPrimary: #1b364e;
    --editorTheme-editorSecondary: #002240;
    --editorTheme-editorTertiary: #7b937f;
    --editorTheme-primary: #da8b0a;
    --editorTheme-secondary: #b67a13;
    --editorTheme-tertiary: #ffe8c5;
  }

  /* THEMES: GITHUB */
  [editorTheme="github"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: ghostwhite;
    --editorTheme-editorTertiary: #09885a;
    --editorTheme-primary: #0000ff;
    --editorTheme-secondary: #67a7f0;
    --editorTheme-tertiary: #f2f7ff;
  }

  /* THEMES: MONOKAI */
  [editorTheme="monokai"] {
    --editorTheme-editorPrimary: #4c4b3e;
    --editorTheme-editorSecondary: #272822;
    --editorTheme-editorTertiary: #ab5772;
    --editorTheme-primary: #cdc369;
    --editorTheme-secondary: #928c50;
    --editorTheme-tertiary: #f7f3d1;
  }

  /* THEME */
  [editorTheme] .editor {
    background: var(--editorTheme-editorSecondary);
  }
  [editorTheme] .editor__code-header {
    background: var(--editorTheme-editorTertiary);
  }
  [editorTheme] .editor__controls,
  [editorTheme] .editor__resizer {
    background: var(--editorTheme-editorPrimary);
  }

  [editorTheme] .editor__controls .el-input__wrapper,
  [editorTheme] .editor__controls .el-input__inner {
    background-color: var(--editorTheme-editorSecondary);
    box-shadow: 0 0 0 1px var(--editorTheme-editorSecondary) inset;
  }
  [editorTheme] .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: var(--editorTheme-primary);
    border-color: var(--editorTheme-primary);
  }
  [editorTheme] > .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    box-shadow: -1px 0 0 0 var(--editorTheme-secondary);
  }
  [editorTheme] .el-button:hover,
  [editorTheme] .el-checkbox-button__inner:hover {
    background-color: var(--editorTheme-tertiary);;
    border-color: var(--editorTheme-primary);
    color: var(--editorTheme-secondary);
  }
  [editorTheme] .el-button-group .el-button--primary:first-child {
    border-right-color: var(--editorTheme-secondary);
  }
  [editorTheme] .el-button-group .el-button--primary:last-child {
    border-left-color: var(--editorTheme-secondary);
  }
  [editorTheme] .is-link {
    color: var(--editorTheme-editorTertiary)
  }
  [editorTheme] .editor__controls .el-input__wrapper,
  [editorTheme] .el-input input {
    border-color: var(--editorTheme-editorPrimary);
  }

  .wrapper[editorTheme] {
    height: calc(100% - var(--footer-height));
  }

  /* MEDIA QUERY: ABOVE MOBILE */
  @media (max-width: 420px) {
    .editor__resizer {
      display: none;
    }
  }
</style>