<template>
  <div ref="$el" class="sidebar-container" secondary-sidebar="open">
    <!-- PRIMARY BAR -->
    <nav class="sidebar sidebar--primary"></nav>
    <nav class="sidebar sidebar--primary real">
      <template v-for="tab in sidebars" :key="tab.id">
        <div :primary-id="tab.id" class="sidebar-item sidebar-item--primary trigger" @click="openSecondary(tab.id, true)" :class="isActivePrimary(tab.id)">
          <svg-icon :icon="tab.id" class="sidebar-item__icon zoom"></svg-icon>
          <svg-icon icon="angle" width="7" class="sidebar-item__icon--open"></svg-icon>
        </div>
      </template>
    </nav>

    <!-- SECONDARY BAR -->
    <nav class="sidebar sidebar--secondary"></nav>
    <nav class="sidebar sidebar--secondary real">
      <template v-for="(item, index) in secondary">
        <!-- top nav item -->
        <template v-if="index == 0">
          <div :key="`${item.id}-top`" class="sidebar-item sidebar-item--secondary sidebar-item--top" @click="closeSecondary(true)">
            <div class="sidebar-item__label sidebar-item__label--top">{{item.name}}</div>
            <div class="sidebar-item__angle active">
              <svg-icon icon="angle" fill="var(--color-primary-1)" width="8"></svg-icon>
            </div>
          </div>

          <!-- top nav items -->
          <nav :key="`${item.id}-nav`" class="sidebar sidebar--tertiary sidebar--top">
            <router-link v-for="navItem in item.nav" :key="navItem.id" :to="linkType('fork', navItem.id)" class="sidebar__link">
              <div class="sidebar-item--tertiary">{{navItem.name}}</div>
            </router-link>
          </nav>
          <hr :key="item.id" class="sidebar__hr"/>
        </template>

        <!-- default nav item -->
        <nav v-else :key="item.id" 
          @click="handleSecondaryClick(item.id, true, true, item)" 
          :sidebar-id="item.id" 
          class="sidebar-item sidebar-item--secondary"
          :class="addSecondaryClass(item.id, item.nav)">
          <div class="sidebar-item--flex">
            <div>
            <!-- top level -->
              <div class="sidebar-item--flex" :class="`${isInnerNav ? 'sidebar-item__inner__top' : ''}${item.nonInteractive ? ' no-hover' : ''}`">
                <svg-icon v-show="item.nav && item.nav.length > 0" icon="angle" fill="var(--color-tertiary-7)" width="8" class="sidebar-item__angle--secondary"></svg-icon>
                <div class="sidebar-item__label">{{item.name}}</div>
                <transition name="fade">
                  <div v-show="item.count || item.count == 0" class="sidebar-item__count">{{item.count}}</div>
                </transition>
              </div>
              <!-- second level -->
              <nav class="sidebar sidebar--tertiary">
                <router-link v-for="navItem in item.nav" :key="navItem.id" :to="linkType('fork', navItem.id)" class="sidebar__link">
                  <div class="sidebar-item--tertiary">{{navItem.name}}</div>
                </router-link>
              </nav>
            </div>
            <div class="sidebar-item__bar"></div>
          </div>
        </nav>
      </template>
    </nav>

  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from './SvgIcon.vue';

  const emit = defineEmits(['update-primary', 'update-secondary']);
  
  const props = defineProps({
    activePrimary: String,
    activeSecondary: String,
    open: Boolean,
    sidebars: Object, // Expecting {sidebarName: {id: 'sidebarName', sidebar: []}}
  });
  
  const $store = useStore();
  const $el = ref(null);
  const aPrimary = ref(null);
  const sidebarRef = ref(null);
  const secondarySidebarRef = ref(null);
  const updateSecondary = ref(null);

  const referrer = computed(() => {
    return $store.state.user['referrer'].toLowerCase();
  });
  const secondary = computed(() => {
    if (aPrimary.value || props.activePrimary) return props.sidebars[aPrimary.value || props.activePrimary].sidebar;
  });

  watch(() => props.activePrimary, () => {
    aPrimary.value = props.activePrimary;
    handlePrimaryClick(props.aPrimary);
  });
  watch(() => props.activeSecondary, () => {
    handleSecondaryClick(props.activeSecondary);
  });
  watch(updateSecondary, () => {
    handleSecondaryClick(updateSecondary.value);
  });

  onMounted(() => {
    // Save references
    sidebarRef.value = $el.value;
    secondarySidebarRef.value = $el.value.querySelectorAll('.sidebar--secondary');

    $store.commit('ui/updateAlignment');
    addListeners();
    handlePrimaryClick(props.activePrimary, true);

    // Handle sidebar state
    if (props.open) openSecondary(aPrimary.value || props.activePrimary);
    else closeSecondary();
  });

  onUnmounted(() => {
    removeListeners();
  });

  /**
   * @description Returns 'active' class if current primary nav item is active
   * @param {String} id - Id of secondary nav item to check if active
   */
  function isActivePrimary(id) {
    if (id) return id === aPrimary.value || id === props.activePrimary ? 'active' : '';
  };

  /**
   * @description Adds classes to secondary nave item: 'active', 'sidebar-item--secondaryNav'
   * @param {String} id - Id of secondary nav item to check if active
   * @param {Array} nav - Inner nav items
   */
  function addSecondaryClass(id, nav) {
    let isActive = id === props.activeSecondary ? 'active' : '';
    let isNav = nav && nav.length > 0 ? 'sidebar-item--secondaryNav' : '';
    return `${isActive} ${isNav}`;
  };

  /**
   * @description Add event listeners
   */
  function addListeners() {
    window.addEventListener('update-secondary', active => { handleSecondaryClick(secondaryItems, active) });
  };

  /**
   * @description Closes secondary nav. Updates top level attribute.
   */
  function closeSecondary(transition) {
    // Set transition
    if (transition) sidebarRef.value.classList.add('transition');
    else sidebarRef.value.classList.remove('transition');
    // Close sidebar
    sidebarRef.value.setAttribute('secondary-sidebar', 'close');
    $store.commit('ui/updateSidebar', {isSidebarOpen: false});
  };

  /**
   * @description On nav item click, determines which nav item is active
   * @param {String} id - Id of nav item to add 'active' class
   * @param {Boolean} emit - To emit to update tab
   */
  function handlePrimaryClick(id, toEmit) {
    let nav =  $el.value.querySelectorAll('.sidebar-item--primary');
    nav.forEach(item => { 
      item.classList.remove('active');
      let itemId = item.getAttribute('primary-id');;
      if (itemId === id) {
        // Update nav item
        item.classList.add('active');
        aPrimary.value = id;
        // Update tab
        if (toEmit) emit('update-primary', id);
        nextTick(() => {
          // Update active secondary item. Use stored state, otherwise default to referrer or first nav item
          let active = null;
          if (props.activeSecondary && secondary.value.filter(item => (item.id).toString() === props.activeSecondary).length > 0 ) {
            active = props.activeSecondary.toString();
          } else {
            active = secondary.value[0].id === 'groups' ? (secondary.value[1].id).toString() : referrer.value;
          }
          handleSecondaryClick(active, true);
        });
      }
    });
  };

  /**
   * @description On nav item click, determines which nav item is active or opens url
   * @param {String} id - Id of nav item to add 'active' class
   * @param {Boolean} emit - To emit to update tab
   * @parma {Object} item - sidebar item
   */
  function handleSecondaryClick(id, toEmit, collapse, item) {
    if (item && item.url) {
      // Open url
      window.open(item.url);
    } else if ($el.value) {
      // Adds active class
      let sId = id.toString();
      let nav = $el.value.querySelectorAll('.sidebar-item--secondary');
      nav.forEach(item => { 
        let itemId = item.getAttribute('sidebar-id');
        if (itemId === sId) {
          // If nav item active, collapse submenu
          let classlist = item.classList;
          if (collapse && classlist.contains('active')) {
            if (classlist.contains('collapse')) item.classList.remove('collapse');
            else item.classList.add('collapse');
          }

          // Update state
          let referrer = sId === 'zinggrid' || sId === 'zingchart' ? sId : null;
          if (referrer) $store.state.user['referrer'] = referrer;
          // Update nav item
          item.classList.add('active');
          // Update tab
          if (toEmit) emit('update-secondary', sId);
        } else {
          item.classList.remove('active');
        }
      });
    }
  };

  /**
   * @description Based on nav length, determines if is an inner nav item
   * @param nav - Array of nav items
   */
  function isInnerNav(nav) {
    return nav && nav.length > 0;
  };

  /**
   * @description If current nav item is active, open secondary nav. Updates top level attribute.
   * If not active, updates the view and secondary sidebar.
   * @param id - Current nav item to check if active before opening secondary nav
   * @param transition - to add transition effect
   */
  function openSecondary(id, transition) {
    let primaryTabs = $el.value.querySelectorAll('[primary-id]');
    primaryTabs.forEach(tab => {
      if (tab.getAttribute('primary-id') === id && tab.classList.contains('active')) {
        // Set transition
        if (transition) sidebarRef.value.classList.add('transition');
        else sidebarRef.value.classList.remove('transition');
        // Open sidebar
        sidebarRef.value.setAttribute('secondary-sidebar', 'open');
      }
    })
    handlePrimaryClick(id, true);
    $store.commit('ui/updateSidebar', {isSidebarOpen: true});
  };

  /**
   * @description Remove event listeners
   */
  function removeListeners() {
    window.removeEventListener('update-secondary', active => { handleSecondaryClick(secondaryItems, active) });
  };

  /**
   * @description Constuct url based on link type
   * @param {String} linkType - 'fork', 'create'
   * @param {String} data - data for creating link
   */
  function linkType(linkType, data) {
    switch(linkType) {
      case 'fork': return `/demos/create/${data}?fork`; break;
    }
  };
</script>

<style>
  /* GLOBAL */
  :root {
    --nav-height: 3.125rem;
    --sidebar-primary-width: 3.25rem;
    --sidebar-secondary-width: 13.4375rem;
    --sidebar-item-height: 1.375rem;
    --sidebar-item-hover: #E2F4FA;
    --sidebar-item-container-height: 3.1875rem;
    --sidebar-top-item-color: #286D8B;
  }

  /* TRANSITIONS */
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  /* SIDEBAR */
  .real {
    height: 100vh;
    position: fixed;
    z-index: 1000;
  }
  .sidebar-container { 
    display: flex;
  }
  .sidebar-container[display="none"] { 
    display: none;
  }
  .sidebar--primary {
    background: var(--color-primary-6);
    width: var(--sidebar-primary-width);
  }
  .sidebar--secondary {
    background: #fff;
    overflow: hidden;
    width: 0;
  }
  .sidebar--secondary.real {
    box-shadow: 0 4px 20px #0000003d;
    overflow-y: auto;
  }
  .transition .sidebar--secondary {
    transition: width 0.25s ease-in;
  }
  .sidebar--secondary.real {
    margin-left: var(--sidebar-primary-width);
    padding-bottom: 8rem;
  }
  [secondary-sidebar="open"] .sidebar--secondary,
  .sidebar--secondary.open {
    width: var(--sidebar-secondary-width);
  }
  .sidebar--tertiary {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
  }
  .sidebar-item--secondary.active.collapse .sidebar--tertiary {
    max-height: 0;
  }
  .sidebar--top {
    height: auto;
  }
  .sidebar--top a { color: var(--color-primary-7); }
  .sidebar-item--secondary.active .sidebar--tertiary {
    max-height: 100rem;
  }
  .sidebar__link {
    text-decoration: none;
  }
  .sidebar__hr {
    border-style: solid;
    border-top: 0;
    color: var(--color-greyscale-11);
    margin: 0 1.65rem 0;
  }

  /* SIDEBAR ITEM */
  [sidebar-id^="na"] {
    pointer-events: none;
  }
  .sidebar-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    height: var(--sidebar-item-container-height);
    width: var(--sidebar-secondary-width);
  }
  .sidebar-item--primary {
    align-content: center;
    background: var(--color-primary-6);
    display: flex;
    justify-content: center;
    padding: 0;
    width: var(--sidebar-primary-width);
    transition: background 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
  }
  .sidebar-item--primary.active {
    background: var(--color-tertiary-7);
  }
  .sidebar-item--primary:not(.active):hover {
    background: #294D61;
  }
  .sidebar-item--secondaryNav {
    height: auto;
  }
  .sidebar-item--secondary:not(.active):hover {
    background: var(--sidebar-item-hover);
  }
  .sidebar-item--flex {
    display: flex;
    width: 100%;
  }
  .sidebar-item--tertiary {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.625rem;
    padding: 0px 1.625rem 0 3.3rem;
    text-indent: -1.8rem;
  }
  .sidebar-item--tertiary:hover {
    background: var(--sidebar-item-hover);
  }
  .active .sidebar-item--tertiary {
    color: var(--color-primary-7);
  }
  .sidebar-item__svg--tertiary {
    margin-right: 0.6875rem;
    position: relative;
    top: 2px;
  }
  .sidebar-item--top {
    font-weight: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }
  .sidebar-item__angle {
    bottom: 3px;
    margin: 0 0.625rem 0 auto;
    position: relative;
    transform: rotate(90deg);
  }
  .sidebar-item__angle--secondary,
  .sidebar-item--secondary.active.collapse .sidebar-item__angle--secondary {
    position: absolute;
    bottom: 0.90rem;
    left: 0.65rem;
    transform: rotate(-90deg);
    transition: transform 0.25s ease-in;
  }
  .sidebar-item--secondary.active .sidebar-item__angle--secondary {
    bottom: 0.90rem;
    transform: rotate(0deg);
  }
  .sidebar-item__count {
    background-color: #D6F2FF;
    border-radius: 20px;
    color: var(--color-tertiary-7);
    font-size: 0.75rem;
    line-height: var(--sidebar-item-height);
    padding: 0 0.75rem;
    margin: 0 0.625rem;
    height: var(--sidebar-item-height);
    text-align: center;
  }
  .sidebar-item__icon {
    height: 19px;
    margin: 0;
  }
  .sidebar-item__icon--open {
    fill: #fff;
    left: 2.35rem;
    position: absolute;
    opacity: 0;
    transform: rotate(270deg);
  }
  .sidebar-item__inner__top {
    align-items: center;
    display: flex;
    height: var(--sidebar-item-container-height);
    padding-left: 1.4375rem;
    position: relative;
  }
  .sidebar-item__bar {
    border-right: 5px solid transparent;
    height: auto;
    margin: 0.8rem 0 0.8rem auto;
  }
  [secondary-sidebar="close"] .active .sidebar-item__icon--open {
    opacity: 1;
  }
  .sidebar-item__label {
    color: var(--color-primary-6);
    font-size: 0.9375rem;
  }
  .sidebar-item__label--top {
    color: var(--sidebar-top-item-color);
    font-size: 0.8125rem;
    padding-left: 1.4375rem;
    position: relative;
    top: 2px;
  }
  
  /* Active states */
  .active .sidebar-item__bar {
    border-color: var(--color-tertiary-6);
  }
  .active .sidebar-item__label {
    color: var(--color-tertiary-6);
  }
</style>
