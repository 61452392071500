<template>
  <default-layout>
    <section class="content--narrow">
      <!-- Profile -->
      <header class="content__header">
        <h2 class="header__title">Profile</h2>
        <div class="header__items default__setting">
          <h4>{{isMobile ? 'Default' : 'Default Homepage'}}: </h4>
          <el-radio-group v-model="defaultHomepage" @change="updateReferrer" size="small">
            <el-radio-button value="zingchart">{{isMobile ? 'ZC' : 'ZingChart'}}</el-radio-button>
            <el-radio-button value="zinggrid">{{isMobile ? 'ZG' : 'ZingGrid'}}</el-radio-button>
          </el-radio-group>
        </div>
      </header>

      <section class="form__collection form__collection--reverse">
        <div class="form__col">
          <div class="form__row">
            <name-field @change="bufferProfile" :value="name"></name-field>
          </div>
          <div class="form__row">
            <company-field @change="bufferProfile" :value="company"></company-field>
          </div>
          <div class="form__row">
            <phone-field @change="bufferProfile" :value="phone"></phone-field>
          </div>
          <div class="form__row">
            <location-field @change="bufferProfile" :value="location"></location-field>
          </div>
          <div class="form__row">
            <billing-field @change="bufferProfile" :value="billing"></billing-field>
          </div>
          <div class="form__row">
            <el-button :disabled="isProfileDirty" @click="updateUserInfo" type="primary">Save Profile</el-button>
          </div>
        </div>
        <div class="form__col">
          <!-- <avatar></avatar> -->
        </div>
      </section>

      <!-- Authentication -->
      <header class="content__header">
        <h2>Authentication</h2>
      </header>
      <section class="form__collection form__collection--reverse">
        <div class="form__col">
          <div class="form__row">
            <!-- EMAIL ADDRESS -->
            <email-form
              @verify="emailVerification"
              @change="bufferEmail"
              :value="userEmail">
            </email-form>
          </div>
          <div class="form__row">
            <el-button :disabled="isUsernameOrEmail" @click="delegateAuthCalls" type="primary">Change E-mail</el-button>
          </div>
        </div>
        <div class="form__col">
        <!-- <social-form :value="identities"></social-form> -->
        </div>
      </section>
      <header class="content__header">
        <h2>Password</h2>
      </header>
      <!-- PASSWORD -->
      <password-form @change="passwordUpdate" :email="userEmail"></password-form>
      <!--  DELETE ACCOUNT -->
      <!-- <div class="delete-field">
        <a href="#" @click="$message('Please email support@zingsoft.com to delete your account!')">Delete Your Account</a>
      </div> -->

      <!-- BILLING -->
      <header class="content__header">
         <h2 class="header__title">Billing</h2>
      </header>
      <credit-card></credit-card>

      <!-- API KEY -->
      <api-key :adminAccess="adminAccess || false"></api-key>

      <account-settings :adminAccess="adminAccess || false"></account-settings>
    </section>

  </default-layout>
</template>

<script setup>
  import { computed, getCurrentInstance, onBeforeMount, ref } from 'vue';
  import { useStore } from 'vuex';
  import { computedAsync } from '@vueuse/core';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $store = useStore();

  import AccountSettings from './components/AccountSettings.vue';
  import Avatar from './components/Avatar.vue';
  import DeleteForm from './components/Delete.vue';
  import EmailForm from './components/Email.vue';
  import PasswordForm from '../../components/passwordField.vue'
  import SocialForm from './components/Social.vue';

  import NameField from './components/Name.vue';
  import LocationField from './components/Location.vue';
  import PhoneField from './components/PhoneNumber.vue';
  import CompanyField from './components/Company.vue';
  import BillingField from './components/AdditionalBilling.vue';

  import ApiKey from './components/ApiKey.vue';
  import CreditCard from './components/CreditCard.vue';

  import DefaultLayout from '../layouts/Default.vue';
  import { ElLoading } from 'element-plus';
  import axios from 'axios';
  import permissionsComposable from '../../mixins/permissions';
  const { checkPermission } = permissionsComposable();

  const defaultHomepage = ref($store.state.user['referrer'].toLowerCase());
  const email = ref({
    old: null,
    update: null,
  });
  const profile = ref(null);
  const password = ref(null);
  const name = ref(null);
  const company = ref(null);
  const phone = ref(null);
  const location = ref(null);
  const billing = ref(null);
  const identities = ref(null);
  const userEmail = ref(null);

  const adminAccess = computedAsync(
    async () => {
      return await checkPermission('admin_dashboard_view', null, null, $store);
    }, null
  );

  const user_id = computed(() => {
    return $store.state.user.user_id;
  });
  const isMobile = computed(() => {
    return $store.getters['ui/isMobile'];;
  });
  const isPassword = computed(() => {
    return (showPassword.value) ? 'text' : 'password';
  });
  const isProfileDirty = computed(() => {
    return !profile.value;
  });
  const isUsernameOrEmail = computed(() => {
    return (email.value.update === null) || (email.value.update === email.value.old) || email.value.update === '';
  });

  onBeforeMount(() => {
    // Fetch data to prefill fields
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data || '';
      name.value = userData.name || '';
      company.value = userData.company || '';
      phone.value = userData.phone_number || '';
      location.value = userData.location || '';
      billing.value  = userData.billing || '';
      identities.value = userData.identities || '';
      userEmail.value = userData.email || '';
    });
  });

  function bufferProfile(obj) {
    profile.value = profile.value || {};
    Object.assign(profile.value, obj);
  };

  function bufferEmail(value) {
    if (!email.value.old) email.value.old = value;
    email.value.update = value;
  };

  function bufferPassword(value) {
    password.value = value;
  };

  function delegateAuthCalls() {
    emailUpdate(email.value.update);
  };

  function updateUserInfo() {
    $api('user/update', profile.value, $global)
      .then(() => {
        $store.dispatch('refresh_state');
        $message({
          message: 'User updated!',
          showClose: true,
          type: 'success',
        });
      })
      .catch(() => {
        $message({
          duration: 0,
          message: 'Could not update the user',
          showClose: true,
          type: 'error',
        });
      });
  };

  // EMAIL
  function emailVerification() {
    $api('email/resend', {slug: user_id.value}, $global)
      .then(() => {
        $store.dispatch('Verification email sent!');
        $message({
          message: 'Email verifiication sent!!',
          showClose: true,
          type: 'success',
        });
      })
      .catch(() => {
        $message({
          duration: 0,
          message: 'Could not send the verification email',
          showClose: true,
          type: 'error',
        });
      });
  };

  function emailUpdate(newEmail) {
    ElLoading.service({
      background: 'rgba(255,255,255,0)',
    });
    $api('user/update', {
      email: newEmail,
    }, $global)
      .then(() => {
        $message({
          message: 'Email updated. Please check your email to verify.',
          showClose: true,
          type: 'success',
        });
        $store.dispatch('user/refresh', $global);
        email.value.old = newEmail;

        // Add timestamp to invalidate sessions before email change
        $api('user/update', {
          email: newEmail,
          session_expiration: new Date().getTime(),
        }, $global).then(() => {
          ElLoading.service({
            background: 'rgba(255,255,255,0)',
          });
          // Refresh token to update auth init
          $store.dispatch('refresh_tokens');
        });
      })
      .catch((error) => {
        $message({
          duration: 0,
          message: error.response.data.message ? error.response.data.message : 'Unable to update email',
          showClose: true,
          type: 'error',
        });
      });
  };

  /**
   * @description When user updates "Default Homepage", update field referrer in database. This field
   * is used to load default view (ZingChart / ZingGrid).
   * @param {String} val - update values of state 'referrer'
   */
  function updateReferrer(val) {
    $api('user/update', {
      signup_url: val,
    }, $global)
    .then(() => {
      $store.dispatch('user/refresh', $global);
    })
    .catch(() => {
      $message({
        duration: 0,
        message: 'Unable to update default homepage',
        showClose: true,
        type: 'error',
      });
    });
  };

  // Add timestamp to invalidate sessions before password change
  function passwordUpdate(newPassword) {
    $api('user/update', {
      password: newPassword,
      session_expiration: new Date().getTime(),
    }, $global)
      .then(() => {
        $message({
          message: 'Password updated.',
          showClose: true,
          type: 'success',
        });
        $store.dispatch('user/refresh', $global)
      })
      .catch((result) => {
        $message({
          duration: 0,
          message: 'Password could not be updated',
          showClose: true,
          type: 'error',
        });
      });
  };
</script>

<style>
  .content--narrow { margin-bottom: 5rem; }
  .default__setting { display: flex; align-items: center; }
  .default__setting h4 { margin: 1rem 0.5rem 0.5rem; }
  .flex__container { align-items: center; display: flex; justify-content: space-between; }
  .el-radio-group { margin: 1rem 0 0.5rem; }
</style>