const billing = {
  namespaced: true,
  mutations: {
    add(state, obj) {
      state.planId = obj.planId;
      state.planInterval = obj.planInterval;
    },
  },
  getters: {
  },
  actions: {
  },
  state: {
    planId: '',
    planInterval: '',
  },
};

export default billing;
