import { ref } from 'vue';

export default function licenseKeyComposable() {
  const licenseKeyInfo = ref(null);
  const licenseKeyVisible = ref(false);
  
  function displayLicenseKey(subscription) {
    licenseKeyVisible.value = true;
    licenseKeyInfo.value = { invoiceId: subscription.id, plan: subscription.plan };
  };

  return {
    licenseKeyInfo,
    licenseKeyVisible,
    displayLicenseKey,
  };
};