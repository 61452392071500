<template>
  <filter-selector
    ref="filterSelector"
    @authenticated="getTags"
    @filterBy="filter"
    :clearable="true"
    :options="possibleTags"
    placeholder="Filter By Tag..."
  ></filter-selector>
</template>

<script setup>
  import { defineEmits, defineProps, getCurrentInstance, ref, watch } from 'vue';
  import FilterSelector from './FilterSelector.vue';

  const emit = defineEmits(['update:filterBy']);

  const props = defineProps(['filterBy', 'group', 'template', 'type']);

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const possibleTags = ref([]);
  const filterSelector = ref(null);

  watch(() => props.group, () => {
    if (props.group) getTags();
  });
  watch(() => props.type, () => {
    if (props.type) getTags();
  });
  watch(() => props.template, () => {
    if (props.template) getTags();
  });

  function clearTagResults() {
    filterSelector.value.clearFilter();
  };

  function filter(value) {
    emit('update:filterBy', value)
  };

  function getTags() {
    let query = {type: props.type, group: props.group};
    
    if (props.template) {
      query.template = props.template;
      if (!props.type && props.group === 'all_demos' && props.template === 'personal') delete query.type;
    }

    $api('tag/list', query, $global)
      .then((result) => {
        possibleTags.value = [];
        possibleTags.value = Object.keys(result.data)
        .map((tag, index) => {
          return {
            name: tag,
            count: result.data[tag],
            id: index,
          }
        })
        .sort((tag, tag2) => {
          return tag2.count - tag.count;
        });
      })
      .catch(() => {
        $message({
          duration: 0,
          message: 'Could not fetch user tags',
          showClose: true,
          type: 'error',
        });
      });
  };
</script>