import { onBeforeMount, ref } from 'vue';
let axios = require('axios');

export default function chartTypesComposable() {

  const chartTypes = ref({});
  const chartTypesMap = ref({
    'area': ['timeseries'],
    'line': ['real-time-feed'],
    'maps': ['map'],
    'mixed': ['blank'],
    'tree-module': ['tree', 'org'],
    'venn-diagram': ['venn'],
  });
  const svg = ref(null);

  onBeforeMount(() => {
    fetchChartTypes();
  });

  function fetchChartTypes() {
    axios('/assets/images/chartTypes.svg')
      .then((response) => {
        svg.value = response.data;
        _removeSvgTag();
        _separateSvg();
        _setChartType();
      });
  };
  
  function _mapName(name) {
    return chartTypesMap.value[name] || false;
  };
  
  function _setChartType() {
    svg.value.forEach((svg) => {
      svg = svg.trim();
      let i = svg.indexOf('id="') + 4;
      let i2 = svg.indexOf('" viewBox');
      let chartType = svg.slice(i, i2);
      if (chartType) {
        svg = ('<svg chartTypeThumbnail '.concat(svg)).replace('</symbol>', '</svg>');
        chartTypes.value[chartType] = svg;
        
        // Add again mapping to another name
        let alias = _mapName(chartType);
        if (alias) {
          alias.forEach((a) => {
            chartTypes.value[a] = svg;
          });
        };
      }
    });
  };
  
  function _removeSvgTag() {
    let svgTagRegex = /<(\/)*svg(.)*\>/igm;
    svg.value = svg.value.replace(svgTagRegex, '');
  };
  
  function _separateSvg() {
    svg.value = svg.value.split(/<symbol /gm);
  };

  return {
    chartTypes,
  };
};