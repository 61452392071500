<template>
  <div class="group_wrapper">
    <group-filter-selector
      ref="filterSelector"
      @group="filter"
      :defaultOptions="defaultOptions"
      :getCount="getCount"
      :options="myGroups"
      :selected="selected"
    ></group-filter-selector>
  </div>
</template>

<script setup>
  import { defineEmits, defineProps, ref } from 'vue';
  import { useStore } from 'vuex';
  import GroupFilterSelector from './GroupFilterSelector.vue';
  import groupsComposable from '../mixins/groups';

  const $store = useStore();
  const { myGroups } = groupsComposable({$store});

  const emit = defineEmits(['update:groupBy']);

  const props = defineProps(['groupBy', 'template', 'type']);

  const defaultOptions = ref([
    {
      id: 'all_demos',
      name: 'All Demos',
    }, {
      id: 'personal_demos',
      name: 'Personal Demos',
    }
  ]);
  const selected = ref('all_demos');

  /**
   * @description Determines which group count to display depending on current demotype.
   * @param {Object} group - Group object. Contains at least three different counts to choose from: 
   * zinggrid, zingchart, all
   */
  function getCount(group) {
    let groupCount = '';
    switch (props.type) {
      case 'zingchart': 
        groupCount = group.count_zingchart;
        break;
      case 'zinggrid':
        groupCount = group.count_zinggrid;
        break;
      default:
        groupCount = group.count;
        break;
    }
    return groupCount || 0;
  };

  /**
   * @description Triggered on change to notify `DemoViewer.vue` that `group` value should
   * update to new value
   * @param {String} value - value to update to
   */
   function filter(value) {
    // Update group results
    emit('update:groupBy', value);
    updateSelected(value);
  };

  /**
   * @description When `groupBy` is updated, set to either 'all_demos', 'personal', or grouping_id.
   * If attempting to set to neither of the above options, default to 'all_demos'
   */
  function updateSelected(value) {
    function checkSelection(groupBy) {
      let newVal = groupBy;
      if (!(newVal === 'all_demos' || newVal === 'personal_demos')) {
        if (!isNaN(groupBy)) newVal = parseInt(groupBy);
        else newVal = 'all_demos';
      }
      return newVal;
    }
    selected.value = checkSelection(value);
  };
</script>

<style>
  .group_wrapper {
    width: 100%;
  }
</style>