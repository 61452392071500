<template>
  <section class="demo-cards" demo-view-2 :mode="mode">
    <!-- Header / Demo Controls -->
    <header class="demo-controls" >
      <div class="demo-controls__left">
        <!-- Filter -->
        <div class="demo-control__item">
          <tag-selector :filterBy="tag" :group="group" :type="demoType"></tag-selector>
          <svg-icon class="el-input-icon" icon="angle"></svg-icon>
        </div>
        <!-- Sort -->
        <div class="demo-control__item">
          <el-select v-model="sortBy" placeholder="Select">
          <el-option
            v-for="item in sortOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <svg-icon class="el-input-icon" icon="angle"></svg-icon>
      </div>
      </div>
      <!-- Search -->
      <div class='demo-controls__right'>
        <div class="demo-control__item">
          <el-input search placeholder="Search By..." v-model="watchSearch" maxlength="75"></el-input>
          <svg-icon class="el-input-icon" icon="search"></svg-icon>
        </div>
      </div>
    </header>

    <section class="demos" :template="template ? template : undefinfed" v-show="authenticated && loaded">
      <template v-if="pagedDemos && pagedDemos.length > 0 && add">
        <template v-for="(demo, index) in pagedDemos" :key="demo.id">
          <demo-card v-show="index < 5" :demo="demo" @delete="refreshDemos" :mode="mode" :template="template || undefined"></demo-card>
        </template>
        <demo-card :demo-type="demoType" dummy="true" :template="template || undefined"></demo-card> <!-- TODO view if have group seat or is personal demo viewer -->
      </template>
      <template v-else-if="pagedDemos && pagedDemos.length > 0">
        <demo-card v-for="demo in pagedDemos" :demo="demo" :key="demo.id" @delete="refreshDemos" :mode="mode" :template="template || undefined"></demo-card>
        <demo-card :demo-type="demoType" v-if="pagedDemos && pagedDemos.length < 6 && group === false && template === false" dummy="true" :mode="mode" :template="template || undefined"></demo-card>
      </template>
      <template v-else-if="template && pagedDemos.length == 0">
        <div style="height: 400px;">
          <h3>You have not created any templates!</h3>
          <p>You can convert a demo in to a template in the demo's settings dialog.</p>
        </div>
      </template>
      <template v-else>
        <div class="demos no-demos" :template="template ? template : undefined">
          <demo-card @update-view="$emit('update-view', 'create', demoType || null)" :demo-type="demoType" dummy="true" :mode="mode" :template="template || undefined"></demo-card> <!-- TODO view if have group seat or is personal demo viewer -->
        </div>
      </template>
    </section>
    <section class="demo-pagination" v-show="pagination">
      <el-pagination
        layout="prev, pager, next"
        :total="totalDemos"
        :pageSize="pageSize"
        :current-page="currentPage"
        @current-change="handleChange"
        @next-click="handleNext"
        @prev-click="handlePrev"
        >
      </el-pagination>
    </section>
  </section>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import debounce from 'lodash.debounce';
  import DemoCard from './DemoCard.vue';
  import TagSelector from './TagSelector.vue';
  import axios from 'axios';
  import Autocomplete from 'vuejs-auto-complete';
  import SvgIcon from './SvgIcon.vue';
  import permissionComposable from '../mixins/permissions';

  const {
    checkPermission,
    listPermissions,
    setPermissions,
  } = permissionComposable();
    
  const emit = defineEmits(['fetch']);

  const props = defineProps({
    add: {
      default: false,
    },
    demoType: String,
    pagination: {
      default: false,
    },
    search: {
      default: false,
    },
    template: {
      default: false,
    },
    group: {
      default: false,
    },
    mode: String,
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const start = ref(0);
  const tag = ref(null);
  const sortBy = ref('last_updated');
  const searchQuery = ref('');
  const currentPage = ref(1);
  const pageSize = ref(6);
  const sortOptions = ref([
    {
      value: 'last_updated',
      label: 'Last Modified'
    },
    {
      value: 'title',
      label: 'Alphabetical'
    },
  ]);
  const demos = ref([]);
  const pagedDemos = ref([]);
  const demosCount = ref(0);
  const newQuery = ref(false);
  const loaded = ref(false);
  const watchSearch = ref('');

  const authenticated = computed(() => {
    const auth = $store.state.user['user_id'];
    if (auth) fetchNext();
    return $store.state.user['user_id'];
  });
  const totalDemos = computed(() => {
    return demosCount.value;
  });
  const sortDir = computed(() => {
    return (sortBy.value === 'last_updated') ? 'DESC' : 'ASC';
  });
    
  watch(searchQuery, () => {
    newQuery.value = true;
  });
  watch(sortBy, (value) => {
    newQuery.value = true;
    fetchNext();
  });
  watch(tag, () => {
    newQuery.value = true;
    fetchNext();
  });
  watch(watchSearch, debounce((newVal) => {
    checkSearchStr(newVal)
  }, 100));

  onMounted(() => {
    newQuery.value = false;
  });

  const checkSearchStr = debounce(function(string) {
    newQuery.value = true;
    searchQuery.value = string;
  }, 500);
  
  function refreshDemos() {
    fetchNext();
    newQuery.value = false;
  };

  function fetchNext() {
    if(newQuery.value) {
      demos.value = [];
      start.value = 0;
      newQuery.value = false;
      currentPage.value = 1;
      demosCount.value = 0;
    }
    let startVal = (currentPage.value - 1) * pageSize.value;
    let url = WP_API_URL + `/api/demo?start=${startVal}&limit=${pageSize.value}${props.group ? '&id_grouping='+props.group : ''}`;
    url += `&sort_by=${sortBy.value}&sort_direction=${sortDir.value}`;
    let filter = [];
    if(searchQuery.value) {
      filter.push({
        by: 'title',
        value: searchQuery.value,
        type: 'demo'
      });
    }
    if(tag.value) {
      filter.push({
        by: 'name',
        value: tag.value,
        type: 'tag'
      });
    }
    if(props.template) {
      filter.push({
        by: 'is_template',
        value: 1,
        type: 'demo',
      })
    }
    if (props.demoType) {
      filter.push({
        by: 'type',
        value: props.demoType,
        type: 'demo',
      })
    }
    if(filter && filter.length > 0) {
      url += `&filter=${JSON.stringify(filter)}`;
    }

    axios({
      url,
      json: true,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + $store.state.auth.idToken,
      },
    })
    .then((response) => {
      // TODO: PAGE SIZE IS UPDATED BEFORE FETCH NEXT. FIX THIS! POSSIBLY JUST PAGEDDEMOS AS A DATA VAL INSTEAD OF COMPUTED
      let demosTemp = response.data.results.splice(0, pageSize.value);
      demosTemp = demosTemp.map(({title, image, last_updated, uid, tags, template_type, description}) => {
        let date = new Date(last_updated).toLocaleString('en-us', { month: "long", year: 'numeric', day: 'numeric' } );
        title = title || 'Untitled Demo';
        return {
          title,
          uid,
          image: `https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${image}`,
          date,
          tags,
          template_type,
          description
        }
      });

      // demos.value = demos.value.concat(demos);
      demos.value[currentPage.value] = demosTemp;
      setPagedDemos(currentPage.value);
      demosCount.value = response.data.size;
      
      // Setup new account with default demos
      if (localStorage.getItem('setupDemos') === 'true' && (props.demoType === 'zingchart' || props.demoType === 'zinggrid')) addDefaultDemos();
      else {
        loaded.value = true;
      }

      // Check if preview given, give height of 100% just in case images displays default behind
      let images = document.querySelectorAll('[demo-viewer] .demo__image__wrapper');
      images.forEach(image => {
        if (image.querySelector('.demo__image').getBoundingClientRect().height > 25) image.style.height = '100%';
      })
    })
    .catch((e) => {
      if (e.response && e.response.status) {
        // Session expires, log user out and save lastSeen
        if (e.response.data.message === 'jwt expired') {
          localStorage.setItem('startup_status', JSON.stringify({
            message: 'Session expired. Please log back in',
            type: 'success',
          }));
          localStorage.setItem('lastSeen', window.location.pathname);
          $store.commit('auth/logout');
        }
      }
      return Promise.reject(e);
    });
  };

  function handlePrev(newPage) {
    currentPage.value--;
    setPagedDemos(newPage);
  };

  function handleNext(newPage) {
    if(!demos.value[newPage]) {
      // start.value += pageSize.value;
      currentPage.value++;
      fetchNext();
    } else {
      setPagedDemos(newPage);
    }
  };

  function handleChange(newPage) {
    if(!demos.value[newPage]) {
      currentPage.value = newPage;
      fetchNext();
    } else {
      setPagedDemos(newPage);
    }
  };

  function setPagedDemos(currentPage) {
    pagedDemos.value = demos.value[currentPage];
  };

  /**
   * @description Retieve default demo of demo to add to new account
   * @param { String } demoUID - unique id of default demo
   */
  function retrieveDemo(demoUID) {
    return new Promise( (resolve) => {
      axios({
        url: `/api/demo/${demoUID}`,
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + $store.state.auth.idToken },
      }).then((response) => {
        resolve({
          html: response.data.html,
          js: response.data.js,
          css: response.data.css,
          title: response.data.title,
          description: response.data.description,
          public: response.data.public,
          is_template: response.data.is_template,
          premium_template: response.data.premium_template,
          image: response.data.image,
          image_small: response.data.image_small,
          mobile_grid: response.data.mobile_grid,
          mobile_height: response.data.mobile_height,
          mobile_image: response.data.mobile_image,
          mobile_image_small: response.data.mobile_image_small,
          type: response.data.type,
        });
      }).catch((err) => {
        $message({
          duration: 0,
          message: 'Could not retieve default demos',
          showClose: true,
          type: 'error',
        });
      });
    })
  };

  /**
   * @description Create default demo to add to new account
   * @param { Object } demoData - data to create new demos from
   */
  function createDemo(demoData) {
    return new Promise( (resolve) => {
      axios({
        url: '/api/demo',
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + $store.state.auth.idToken },
        data: demoData,
      }).then((response) => {
        resolve(true);
      }).catch((err) => {
        $message({
        duration: 0,
        message: 'Could not create default demos',
        showClose: true,
        type: 'error',
      });
    });
  });
  };

  /**
   * @description For new accounts, create two default demos
   */
  async function addDefaultDemos() {
    // Remove signup trigger
    localStorage.removeItem('setupDemos');
    localStorage.removeItem('signupReferrer');

    // Default demos to add
    const defaultDemos = [VUE_APP_GRID_ONE, VUE_APP_GRID_TWO, VUE_APP_CHART_ONE, VUE_APP_CHART_TWO, VUE_APP_CHART_THREE]
    
    // After creating default demos, add to demolist
    defaultDemos.forEach(async(demo, index) => { 
      const data = await retrieveDemo(demo);
      await createDemo(data); 
      if (index === defaultDemos.length-1) { 
        emit('fetch');
        loaded.value = true;
      };
    });
  };
</script>

<style>
  [demo-view-2] .demos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: var(--row-padding) 0;
  }
  [demo-view-2] .demo-pagination{
    display: flex;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
  [demo-view-2] .el-pagination {
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
    flex: 1;
  }

  [demo-view-2][mode="list"] .demos {
    margin-top: 1.3rem;
  }

  [demo-view-2] .demo-controls__right {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }

  [demo-view-2] [demo-card] {
    width: 33%
  }

  [demo-view-2] [demo-card] .demo__action {
    display: none;
  }

  @media screen and (max-width:1200px) {
    [demo-view-2] [demo-card] {
      width: 50%
    }
  }

@media screen and (max-width:1000px) {
  [demo-view-2] [demo-card] {
    width: 90%
  }
}
</style>