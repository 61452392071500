<template>
  <div pricing="wrap">
    <el-button pricing="btn--closeform" @click="closeView">Back to Pricing</el-button>
    <section>
      <div class="inner">
        <div pricing="intro" loaded="true">
          <h1>{{title}}</h1>
        </div>

        <!-- Step 1: Subscription Form -->
        <!-- Mobile view of price box -->
        <price-box
          v-if="view === 0"
          @toggle="toggleInterval"
          class="desktop--hide"
          :plan="plan"
          :plan-interval="planInterval"
          :interval="interval"
          :isPerpetual="isPerpetual">
        </price-box>

        <div v-if="view === 0" pricing="container--box">
          <!-- Subscription Type -->
          <!-- Tablet/Desktop view of price box -->
          <div class="price-box-wrapper">
            <price-box
              v-if="view === 0"
              @toggle="toggleInterval"
              class="mobile--hide tablet--hide"
              :plan="plan"
              :plan-interval="planInterval"
              :interval="interval"
              :isPerpetual="isPerpetual">
            </price-box>
          </div>
          <hr pricing="divider--vertical">

          <!-- Subscription Details -->
          <div>
            <h2 pricing="h2">License &amp; Payment Details</h2>
            <hr pricing="divider--horizontal">

            <subscription-form
              :form-pricing="formPricing"
              :include-domain-fields="includeDomainFields"
              :plan="plan"
              :submitAction="nextStep"
            ></subscription-form>
          </div>
        </div>

        <!-- Step 2: Account Association -->
        <div v-if="view === 1" :pricing="loginOnly ? 'container--medium--box' : 'container--large'">
          <!-- Login -->
          <div pricing="container--flex-column">
            <h2 pricing="h2">{{loginOnly ? 'Login to complete account creation!' : 'Already have an account?'}}</h2>
            <hr pricing="divider--horizontal">

            <div @click="pushAccountDataLayer('existing', 'google')">
              <google-entry popup>Log in with Google</google-entry>
            </div>

            <p pricing="entry-text">or use you email to log in</p>

            <el-form @validate="formLoginValidation" pricing="formLogin" ref="formLoginRef" :model="formLogin" hide-required-asterisk label-position="top" :rules="formLoginValidationRules">
              <el-form-item label="Email" prop="email">
                <el-input v-model="formLogin.email" placeholder="Enter email address" type="email"></el-input>
              </el-form-item>
              <el-form-item label="Password" prop="password">
                <el-input v-model="formLogin.password" placeholder="Enter password" type="password"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="userEntry('login')" :disabled="formLoginIncomplete" type="primary">Log In</el-button>
              </el-form-item>
            </el-form>
            <el-button @click="resetModalVisible=true" link>Forgot your password</el-button>
          </div>

          <!-- Signup -->
          <div v-if="!loginOnly" pricing="container--box--flex-column">
            <h2 pricing="h2">Don't have an account?</h2>
            <hr pricing="divider--horizontal">

            <div @click="pushAccountDataLayer('new', 'google')">
              <google-entry popup signup>Sign Up with Google</google-entry>
            </div>

            <p pricing="entry-text">or use you email to log in</p>

            <el-form
              @validate="formSignupValidation"
              pricing="formSignup"
              ref="formSignupRef"
              :model="formSignup"
              hide-required-asterisk
              label-position="top"
              :rules="formSignupValidationRules">
              <el-form-item label="Email" prop="email">
                <el-input v-model="formSignup.email" placeholder="Enter email address" type="email"></el-input>
              </el-form-item>
              <el-form-item label="Choose a Password" prop="password">
                <el-input
                  v-model="formSignup.password"
                  placeholder="Enter password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="Confirm Password" prop="passwordConfirm">
                <el-input
                  v-model="formSignup.passwordConfirm"
                  placeholder="Confirm password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="userEntry('signup')" :disabled="formSignupIncomplete" type="primary">Sign Up</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <!-- Step 3: Purchase Confirmation -->
        <div v-if="view === 2" pricing="container--large--box">
          <!-- Subscription Type -->
          <div pricing="confirmation">
            <h2 pricing="h2">Account Successfully Created</h2>
            <hr pricing="divider--horizontal">
            <div pricing="text--light">
              <p pricing="text-box">
                <template v-if="entryState == 'signup'">An account associated with the email <span pricing="bold">{{formSignup.email || userEmail}}</span> has been created successfully</template>
                <template v-else-if="entryState == 'login'">You've successfully logged in to the account associated with the email <span pricing="bold">{{formLogin.email || userEmail}}</span></template>
                <template v-else>Your account has been successfully associated with this subscription.</template>
              </p>
              <p>When you complete this order, your subscription will appear in the Subscriptions section in the ZingSoft Studio.</p>
              <p>You can manage your licensed domain and subdomain in the Subscriptions section anytime.</p>
            </div>
          </div>

          <hr pricing="divider--vertical">

          <!-- Subscription Details -->
          <div pricing="confirmation--flex-1">
            <h2 pricing="h2">Confirm License {{renewalProcess ? 'Renewal' :''}} Details</h2>
            <hr pricing="divider--horizontal">
            <div pricing="text--light">
              <p><span pricing="bold--primary">Subscription: </span>{{plan && plan.name}} License</p>
              <p><span pricing="bold--primary">Name: </span>{{formPricing.nameFirst}} {{formPricing.nameLast}}</p>
              <p v-if="!renewalProcess"><span pricing="bold--primary">Licensed Domain:</span> {{formPricing.domainOptional}} (you can edit this later)</p>
              <p v-if="!renewalProcess"><span pricing="bold--primary">Licensed Subdomain:</span> {{formPricing.subdomainOptional}} (you can edit this later)</p>
              <p><span pricing="bold--primary">Company: </span>{{formPricing.company}}</p>
              <p><span pricing="bold--primary">Credit Card Number: </span>**** **** **** {{formPricing.cardNumber.substring(formPricing.cardNumber.length-4)}}</p>
              <p><span pricing="bold--primary">Cread Card CVC: </span>***</p>
              <p><span pricing="bold--primary">Credit Card Expiration: </span> {{formPricing.cardExpiration}}</p>
              <!-- TODO COUPON remove when enable <p><span pricing="bold--primary">Discount Code: </span>{{formPricing.discountOptional}}</p> -->
            </div>
            <div pricing="button-flex">
              <el-button @click="view = view-2;" plain type="danger">Edit Info</el-button>
              <el-button v-if="renewalProcess" @click="placeOrder('renew')" :disabled="!hasUser" :loading="placingOrder" type="primary">Renew</el-button>
              <el-button v-else @click="placeOrder" :disabled="!hasUser" :loading="placingOrder" type="primary">Place Order</el-button>
            </div>
          </div>
        </div>

        <!-- Step 4: Purchase Complete -->
        <div v-if="view === 3" pricing="container--small--box--center--flex-column--nowrap">
          <h2 pricing="h2">Your subscription is now {{renewalProcess ? 'renewed' : 'active'}}!</h2>
          <hr pricing="divider--horizontal">

          <!-- Logged in user -->
          <template v-if="entryState">
            <p pricing="entry-text--bold--highlight">Payment successfully processed.</p>
            <p pricing="entry-text">Thanks for your purchase.</p>
            <p pricing="entry-text">Visit the Studio to manage your subscription.</p>
          </template>
          <!-- Signup / Logged out user -->
          <template v-else>
            <p pricing="entry-text">Thanks for your purchase.</p>
            <p pricing="entry-text--bold--highlight">Your payment has been processed successfully.</p>
            <p pricing="entry-text"><a href="/account/subscriptions" target="_blank">Visit the Studio</a> to manage your subscription.</p>
          </template>
          
          <div pricing="button-flex">
            <el-button @click="openTab('account')" type="primary">View Account</el-button>
            <el-button @click="openTab('subscriptions')" pricing="button-secondary" type="primary">Manage License</el-button>
          </div>
        </div>

      </div>
    </section>

    <!-- Reset Modal -->
    <reset-modal @close="resetModalVisible=false" embed="false" v-model="resetModalVisible"></reset-modal>
  
    <!-- Privacy Settings Modal -->
    <my-dialog
      title="Please enable 3rd Party Cookies"
      type="danger"
      :visible="privacyModalVisible">
      <template v-slot:content>
        <div>
          <p>To continue checkout process, please change your browser settings to allow third-party cookies.</p>
        </div>
      </template>
      <template v-slot:options>
        <div>
          <el-button @click="checkPrivacySettings(true)" type="primary">Reload Page</el-button>
        </div>
      </template>
    </my-dialog>
  
    <!-- Support Modal -->
    <my-dialog
      title="Your Purchase Could Not Be Completed"
      type="danger"
      @close="supportModalVisible=false"
      :visible="supportModalVisible">
      <template v-slot:content>
        <div>
          <p>A sales representative will later contact and assist you.</p>
          <p>Or contact us at <a href="mailto:sales@zingsoft.com">sales@zingsoft.com</a>.</p>
        </div>
      </template>
      <template v-slot:options>
        <div>
          <el-button @click="supportModalVisible=false" type="primary">OK</el-button>
        </div>
      </template>
    </my-dialog>

    <!-- License Purchased Dialog -->
    <license-purchased-dialog
      @close="licensePurchasedDialogVisible=false;"
      @renew="renewalProcess=true;"
      @purchase="placeOrder('purchase')"
      v-model="licensePurchasedDialogVisible"
      :plan="planInterval"
      :domains="formPricing ? [formPricing.domainOptional] : []"
      :subdomains="formPricing ? [formPricing.domainOptional] : []"
      :userId="userId">
    </license-purchased-dialog>

    <!-- Hidden div to check user triggers signup/login -->
    <div style="display: none;">{{userPopupAuth}}{{userPopupAuthSignup}}{{setupEntry}}</div>
  </div>
</template>

<script setup>
  import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import GoogleEntry from './components/GoogleEntry.vue';
  import LicensePurchasedDialog from '../account/components/LicensePurchasedDialog.vue';
  import MyDialog from '../../components/MyDialog.vue';
  import PriceBox from './components/PriceBox.vue';
  import ResetModal from './components/ResetModal.vue';
  import SubscriptionForm from './components/SubscriptionForm.vue';
  import purchaseStepsComposable from '../account/subscriptionMixins/PurchaseSteps';

  // CUSTOM VALIDATION RULES (used in data)
  /**
   * @description Checks if password meets requirements (8 chars, 1 uppercase, 1 lowercase, 1 number)
   * @param {Object} rule - validation rule corresponding to field being evaluated
   * @param {String} value - value of field being validated
   * @param {Function} callback - callback function to invoke when validation completed
   */
  let validateSignupPassword = (rule, value, callback) => {
    if (value === '') {
      callback(new Error('Required Field'));
    } else {
      // Check if password meets requirement
      let atLeast8Char = value.length >= 8;
      let hasUppercase = /[A-Z]/.test(value);
      let hasLowercase = /[a-z]/.test(value);
      let hasNumber = /[0-9]/.test(value);
      if (!(atLeast8Char && hasUppercase && hasLowercase && hasNumber)) {
        // Error when password does not reach requirements
        callback(new Error(`Password expected to have at least 8 characters, 1 uppercase letter, 1 lowercase letter, and 1 number`));
      } else {
        // Validates password confirmation to check if it matches (checks only if non-empty)
        let confirmField = formSignup.value.passwordConfirm;
        if (confirmField && confirmField.length !== 0) formSignupRef.value.validateField('passwordConfirm');
      }
      callback();
    }
  };

  /**
   * @description Checks if password confirmation matches
   * @param {Object} rule - validation rule corresponding to field being evaluated
   * @param {String} value - value of field being validated
   * @param {Function} callback - callback function to invoke when validation completed
   */
  let validatePasswordConfirm = (rule, value, callback) => {
    if (value === '') {
      // Error for when field is empty
      callback(new Error('Please confirm password'));
    } else if (value !== formSignup.value.password) {
      // Error when passwords do not match
      callback(new Error('Passwords do not match'));
    } else {
      callback();
    }
  };

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $route = useRoute();
  const $store = useStore();

  const REQUIRED = ref('Field required');
  const cardBrand = ref(null);
  const currentInterval = ref(null);  // Interval of subscription: 'yearly, 'perpetual'
  const dataLayerObject = ref(null);
  const isPerpetual = ref(false);        // Is interval 'perpetual'
  const failedAttempts = ref(0);      // Track failed attempts and display form to contact sales
  const plan = ref({                 // Subscription plan details
    name: '',
    description: [],
    tags: '',
  });
  const planId = ref(null);
  const planInterval = ref({         // Currently subscription plan interval amount
    amount: '-',
  });
  const formLogin = ref({            // Login form
    email: '',
    password: '',
  });
  const formPricing = ref({          // Subscription details form
    nameFirst: '',
    nameLast: '',
    domainOptional: '',
    subdomainOptional: '',
    company: '',
    cardNumber: '',
    cardExpiration: '',
    cardCvc: '',
    chargeOptional: '',
    // discountOptional: '',  // TODO COUPON remove when enable
  });
  const formLoginValidated = ref(true); // Whether login form passes validation
  const formLoginValidationRules = ref({ // Rules to validate login
    email: [
      {required: true, trigger: 'blur', message: REQUIRED},
      {type: 'email', trigger: 'blur', message: 'Invalid email'},
    ],
    password: [{required: true, trigger: 'blur', message: REQUIRED}],
  });
  const formSignup = ref({ // Signup form
    email: '',
    password: '',
    passwordConfirm: '',
  });
  const formSignupRef = ref(null);
  const formSignupValidated = ref(true);  // Whether signup form passes validation
  const formSignupValidationRules = ref({  // Rules to validate signup
    email: [
      {required: true, trigger: 'blur', message: REQUIRED},
      {type: 'email', trigger: 'blur', message: 'Invalid email'},
    ],
    password: [{validator: validateSignupPassword, trigger: 'blur'}],
    passwordConfirm: [{validator: validatePasswordConfirm, trigger: 'blur'}],
  });
  const loadingMask = ref(false);
  const loginOnly = ref(false); // Flag to show login form only after Google auth signup
  const placingOrder = ref(false);  // Flag to display loading state on button when processing order
  const privacyModalVisible = ref(false); // Visibility of privacy modal
  const resetModalVisible = ref(false); // Flag to display reset moddal for password reset
  const supportModalVisible = ref(false); // Visibility of support modal
  const entryState = ref(null); // How the user enters the app - 'login', 'signup'
  const redirectPath = ref(null); // One of two flag to determine if need to manually setup after entry
  const transactionId = ref(null);
  const userEmail = ref(null);
  const view = ref(0);  // Which view/step of the subscription process user is on
  const renewalProcess = ref(false);

  // Determines if user if logged in
  const hasUser = computed(() => {
    return !!$store.state.user['user_id'];
  });
  // Subscription interval currently being displayed
  const interval = computed(() => {
    if (currentInterval.value) {
      if (currentInterval.value === 'annual') currentInterval.value = 'yearly';
      return currentInterval.value === 'yearly' ? 'year' : 'perpetual';
    }
  });
  // Determines if login form fields non-empty and validated
  const formLoginIncomplete = computed(() => {
    let disable = false;
    let keys = Object.keys(formLogin.value);
    // Check if field is nonempty
    keys.forEach(field => {
      if (!formLogin.value[field] || formLogin.value[field].length === 0) disable = true;
    });
    // Check if form validated
    if (!formLogin.valueValidated) disable = true;
    return disable;
  });
  // Determines if signup form fields non-empty and validated
  const formSignupIncomplete = computed(() => {
    let disable = false;
    let keys = Object.keys(formSignup.value);
    // Check if field is nonempty
    keys.forEach(field => {
      if (!formSignup.value[field] || formSignup.value[field].length === 0) disable = true;
    });
    // Check if form validated
    if (!formSignupValidated.value) disable = true;
    return disable;
  });
  // Determines if subscription form should includes fields for domain and subdomains
  const includeDomainFields = computed(() => {
    // Do not include for enterprise licenses
    return planId.value ? !planId.value.includes('-ENT') : true;
  });
  const productType = computed(() => {
    let planName = plan.value.Name;
    return planName.slice(0, 2) === 'ZC' ? 'zingchart' : 'zinggrid';
  });
  // Setup after login/signup and waiting to fetch session data
  const setupEntry = computed(() => {
    if (!!$store.state.user['user_id'] && redirectPath.value && redirectPath.value.includes('/subscribe')) {
      setupManualEntry();
    }
    return redirectPath.value && $store.state.user['user_id'];
  });
  // Text to display as title of current view
  const title = computed(() => {
    if (view.value === 0) return 'Enter Your Subscription Details';
    else if (view.value === 1) return 'Associate Your License with an Account';
    else if (view.value === 2) return `Confirm Your ${view.renewalProcess ? 'Renewal' : 'Purchase'}`;
    else return `${view.renewalProcess ? 'Renewal' : 'Purchase'} Complete`;
  });
  const userId = computed(() => {
    return parseInt($store.state.user['user_id']);
  });
  // After Google auth login, proceeed to next view
  const userPopupAuth = computed(() => {
    if ($store.state.auth.popupAuth && view.value === 1) view.value++;
    return $store.state.auth.popupAuth;
  });
  // After Google auth signup, prompt user to login
  const userPopupAuthSignup = computed(() => {
    if ($store.state.auth.popupAuthSignup) {
      loginOnly.value = true;
    }
    return $store.state.auth.popupAuthSignup;
  });

  watch(currentInterval, () => {
    renewalProcess.value = false;
  });

  const { licensePurchasedDialogVisible, renewalStep }
    = purchaseStepsComposable({$global, cardBrand, productType, userId}); 

  onBeforeMount(() => {
    // Fetch data to prefill fields
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      userEmail.value = userData.email;
    });
  });

  onMounted(() => {
    // Check if 3rd party cookies enabled
    checkPrivacySettings();

    // Grab subscription details based on query params that were appended when redirected from pricing page
    getSubscription();

    // Determine if redirected after login/signup
    if (!!localStorage.getItem('isAuthenticating')) {
      loadingMask.value = $global.$loading();
    }
    redirectPath.value = localStorage.getItem('redirectPath');
    if (!redirectPath.value && loadingMask.value) {
      loadingMask.value.close();
    }
  });
  
  /**
   * @description Add card to user account if card not retrieved. Retrieved cards
   * contains '*' to mask card number.
   */
  function addCard() {
    return new Promise((resolve) => {
      if (!formPricing.value.cardNumber.includes('*')) {
        let cardExpiration = formPricing.value.cardExpiration.split('/');
        let card = {
          number: formPricing.value.cardNumber,
          exp_month: parseInt(cardExpiration[0]),
          exp_year: parseInt(cardExpiration[1]),
          cvc: formPricing.value.cardCvc,
        };
        if (formPricing.value.chargeOptional) card.name = formPricing.value.chargeOptional;
        
        axios({
          url: '/api/card',
          method: 'POST',
          data: {'card': card},
          headers: {'Authorization': `Bearer ${$store.state.auth.idToken}`},
        }).then(result => {
          cardBrand.value = getCardBrand(result.data);
          resolve(true);
        }).catch((err) => {
          let message = err;
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.message) message = err.response.data.message;
              else message = err.response.data;
            };
          } else {
            message = err.response;
          };

          failedAttempts.value++;
          if (failedAttempts.value > 1) support(message);

          $message({
            duration: 0,
            message: `${message}`,
            showClose: true,
            type: 'error',
          });
          resolve(false);
        });
      } else {
        resolve(true);
      }
    });
  };

  /**
   * @description Associate user data to account
   */
  function associateData() {
    let userData = {
      company: formPricing.value.company,
      name: `${formPricing.value.nameFirst} ${formPricing.value.nameLast}`,
    };

    return new Promise((resolve) => {
      axios({
        url: '/api/user',
        method: 'POST',
        data: userData,
        headers: {'Authorization': `Bearer ${$store.state.auth.idToken}`},
      }).then(result => {
        $store.dispatch('refresh_state');
        resolve(true);
      });
    });
  };

  /**
   * @description Checks for access of localStorage to determine if 3rd party cookies allowed.
   * If not allowed, display modal to inform users to enable cookies. 
   * 3rd party cookies are usually disabled when access subscription process through an iframe on
   * ZingChart or ZingGrid site.
   * @param {Boolean} retry - Retry by refreshing page
   */
  function checkPrivacySettings(retry) {
    if (retry) {
      // Refresh page with new privacy settings
      window.top.postMessage('Reload Page', '*');
    } else {
      // Check for localStorage access
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
      } catch (error) {
        privacyModalVisible.value = true;
      }
    }
  };

  function closeView() {
    // Close iframe
    window.top.postMessage('Close Form', '*');
  };
  
  /**
   * @description Returns active plan from `plan.value`, array of objects
   * @param {Array} arr - array to filter through
   * @param {String} prop - property to filter on
   * @param {String} val - value to filter for
   */
  function filterPlan(arr, prop, val) {
    return arr.filter(el => {
      if (val.toLowerCase().includes(el[prop].toLowerCase())) return el;
    })[0];
  };

  /**
   * @description Helper method that `OR` the results of form items to determine if
   * form is validated and to enable submit button
   * @param validated - whether form item is validated
   * @param form - form to determine validation
   */
  function formValidation(validated, form) {
    function checkValidate(form) {
      // If form previously validated and form item is validated => form remains validated
      if (validated && form.value) form.value = true;
      // If form item is invalidated = > form becomes invalidated
      else if (!validated) form.value = false;
      else {
        // Check if any form item is invalidated
        setTimeout(() => {
          let invalidatedFormItem = document.querySelector(`[pricing="${form}"] .is-error`);
          form.value = !invalidatedFormItem;
        }, 500);
      }
    };
    
    checkValidate(form === 'formLogin' ? formLoginValidated : formSignupValidated);
  };

  /**
   * @description Triggered when a form item is validated to return if validation passes
   * @param prop - prop name of form item being validated
   * @param validated - whether form item is validated
   */
  function formLoginValidation(prop, validated) {
    formValidation(validated, 'formLogin')
  };

  /**
   * @description Triggered when a form item is validated to return if validation passes
   * @param prop - prop name of form item being validated
   * @param validated - whether form item is validated
   */
  function formSignupValidation(prop, validated) {
    formValidation(validated, 'formSignup')
  };

  function getCardBrand(card) {
    if (card && (card.brand || card.cardType)) {
      let cardBrand = (card.brand || card.cardType).toLowerCase();
      if (cardBrand === 'mc') cardBrand = 'mastercard';
      else if (cardBrand === 'disc') cardBrand = 'discover';
      return cardBrand;
    };
    return null;
  };

  /**
   * @description Grab all plan descriptions and update `plan.description` with details of
   * which features are included in plan
   */
  function getPlanDescriptions(plan) {
    let features = [];
    // Gather all unique features
    plan.forEach(p => {
      p.features = p.features.map(
        feature => feature.toLowerCase() || feature.text.toLowerCase()
      );

      p.features.forEach(f => {
        let feature = f.toLowerCase();
        if (
          features.filter(fea => {
            if (fea === feature) return feature;
          }).length === 0
        ) {
          features.push(feature);
        }
      });
    });

    // Mark which features are included in plan
    plan.forEach(p => {
      p.features = features.map(fea => {
        let formatted = `${fea.slice(0, 1).toUpperCase()}${fea
          .slice(1)
          .toLowerCase()}`;
        return {
          text: formatted,
          disabled: p.features.indexOf(fea) === -1
        };
      });
    });
  };

  /**
   * @description Get subscription plans based on query params `planInterval` and `planId`
   */
  function getSubscription() {
    let _planInterval = $route.query.planInterval;
    let _planId = $route.query.planId;
    planId.value = _planId || 'ZG-S-MUL';
    currentInterval.value = _planInterval || 'yearly';
    isPerpetual.value = currentInterval.value === 'perpetual' ? true : false;

    // Make sure product is purchasable via studio
    if (planId.value && !planId.value.includes('-S-')) planId.value = 'ZG-S-MUL';

    let displayPlan = (result) => {
      let plans = Object.keys(result.data).map((plan) => result.data[plan]);
      plan.value = filterPlan(plans, 'Sku', planId.value);

      // Retry getting plan using fallback
      if (!plan.value) plan.value = filterPlan(plans, 'Sku', 'ZG-S-MUL');
      
      // Get plan interval
      if (plan.value) {
        planInterval.value = filterPlan(
          plan.value.plans,
          'interval',
          currentInterval.value.includes('year') ? 'year' : 'perpetual',
        );
      }
    };

    axios({
      url: `/api/plan?plan=${planId.value}`,
      method: 'GET'
    }).then(result => {
      displayPlan(result);
    });
  };

  /**
   * @description Initialize datalayer object when empty.
   * Initialization occurs:
   * - After first step
   * - After second step when user logs in or signup through email
   */
  function initDataLayerObject() {
    if (!dataLayerObject.value) {
      dataLayerObject.value = {
        'event': `${productType.value}Checkout`,
        'product-name': productType.value,
        'license-interval': currentInterval.value,
        'license-id': planId.value.toUpperCase(),
        'license-type': plan.value.name.toLowerCase(),
        'license-price': planInterval.value.UnitPrice,
        'license-domains-count': formPricing.value.domainOptional.length > 0 ? 1 : 0,
        'license-domains-name': [formPricing.value.domainOptional],
        'license-subdomains-count': formPricing.value.subdomainOptional.length > 0 ? 1 : 0,
        'license-subdomains-name': [formPricing.value.subdomainOptional],
        'license-company': formPricing.value.company,
      };
    }
  };

  /**
   * @description Goes to the next step from the first step (Pricing)
   */
  function nextStep() {
    // Skip account association step if user already logged in
    if (hasUser.value) {
      // Skip account association step if user logged in
      view.value += 2;
    } else {
      // Go to account association step
      view.value++;
    };

    // Push data to dataLayer
    initDataLayerObject();
    if (userId.value) dataLayerObject.value['user-id'] = userId.value;
    window.dataLayer.push(Object.assign({
      'checkout-step': '1',
      'checkout-name': 'plan', 
    }, dataLayerObject.value));

    window.dataLayer.push(Object.assign({
      'checkout-step': '2',
      'checkout-name': 'info', 
    }, dataLayerObject.value));
  };

  /**
   * @description Open new tab to specified page
   * @param {String} page - page to open
   */
  function openTab(page) {
    let url = null;
    switch (page) {
      case 'account': url = '/account'; break;
      default: url = '/account/subscriptions'; break;
    }
    window.open(url,'_blank');
  };

  /**
   * @description Places order by adding card and subscribing to product. Also increments view.
   * @param {Enum} type - leave empty, initially to check if user owns license. If not, order will continue,
   * else user is prompted to enter 'renew' or 'purchase' flow. ('', 'renew', 'purchase')
   */
  async function placeOrder(type) {
    // Button state
    placingOrder.value = true;
    // Associate user information to account
    await associateData();
    // Add card
    let success = await addCard();
    
    // Subscribe to product
    if (success) {
      if (type === 'renew') {
        // Renew license
        view.renewalProcess = true;
        success = await renewalStep(planInterval.value.Sku, planInterval.value.Name, true);
        completeSubscription(success);
      } else if (type === 'purchase') {
        // Purchase without checking if customer owns license
        success = await subscribe();
        completeSubscription(success);
      } else {
        // Purchase license after checking if user owns license
        $api('subscription/list', {}, $global)
          .then(async (result) => {
            let subscriptions = (result.data || []).map((subscription) => subscription.planName.split(':').pop());
            let duplicate = (subscriptions.indexOf(planInterval.value.Name)) !== -1;
            let isAnnual = planInterval.value.Name.endsWith('-A');

            if (duplicate && isAnnual) {
              // Prompt user if they want to purchase or pay renewal
              licensePurchasedDialogVisible.value = true;

              // Button state
              placingOrder.value = false;
            } else {
              // Create new subscription and push data to datalayer
              success = await subscribe();
              completeSubscription(success);
            };
          });
      };
    } else {
      placingOrder.value = false;
    };
  };

  function completeSubscription(success) {
    // Go to next view
    if (success) {
      view.value++;
    } else {
      placingOrder.value = false;
    };

    // Push data to dataLayer 
    initDataLayerObject();
    window.dataLayer.push(Object.assign({
      'checkout-step': '4',
      'checkout-name': 'purchase',
      'card-type': cardBrand.value,
      'transaction-id': transactionId.value,
      'user-id': userId.value,
    }, dataLayerObject.value));
  };

  /**
   * @description Push data layer info when associating purchase to account
   * @param {Enum} accountAction - Indicates whether customer created a new account
   * or associating to an existing account ('existing' || 'new')
   * @param {Enum} accountType - type of registration ('google' || 'email')
   */
  function pushAccountDataLayer(accountAction, accountType) {
    // For google entry, set flag
    if (accountType === 'google') {
      entryState.value = accountAction === 'existing' ? 'login' : 'signup';
    };

    // Push data layer
    dataLayerObject.value['account-action'] = accountAction;
    dataLayerObject.value['account-type'] = accountType;
    window.dataLayer.push(Object.assign({
      'checkout-step': '3',
      'checkout-name': 'account',
    }, dataLayerObject.value));
  };

  /**
   * @description After manual user login / signup, redirects back to this page with user information which is used to login user.
   * Grab data placed in local storage to continue subscription process.
   */
  function setupManualEntry() {
    // For users logging in, grab hash to log user in
    let hash = $route.hash;
    if (hash) {
      // Get user token => used to log user in
      let idToken = hash.slice(hash.indexOf('=')+1, hash.indexOf('&'));
      localStorage.setItem('id_token', true);
      // Log user in by placing token into state and refresh state
      axios({
        url: '/api/user/serversession',
        method: 'POST',
        headers: { 'Authorization': `Bearer ${idToken}` },
      }).then(() => {
        $store.commit('auth/add_tokens', { idToken });
        if ($store.state.auth.parsedToken && $store.state.auth.parsedToken.sub.indexOf('auth0|') > -1) {
          $store.dispatch('refresh_state', { idToken });
        }
      });
    };

    // Check if user redirected back to this page. If yes, then go to next step
    // and load data from server session
    if (redirectPath.value && redirectPath.value.includes('/subscribe')) {
      // Go to next step in subscription process
      view.value = 2;
      if (loadingMask.value) loadingMask.value.close()

      // Grab subscription data and clear local storage
      axios({
        url: '/api/user/serversession',
        method: 'POST',
        headers: {'Authorization': `Bearer ${$store.state.auth.idToken}`},
      }).then((session) => {
        if (session.data.entryState) {
          let dataKeys = ['currentInterval', 'dataLayerObject', 'isPerpetual', 'plan', 'planInterval', 'formPricing', 'entryState'];
          dataKeys.forEach(data => { [data].value = session.data[data]; });

          // Clear local storage and session
          localStorage.removeItem('redirectPath');
        }
      });
    };
  };

  /**
   * @description Displays after 2 failed attempts (this.failAttempts). Creates form to submit to manually contact sales.
   */
  function support(err) {
    // Display support modal
    supportModalVisible.value = true;

    // Forward information to ZingSoft intercom in form of message
    axios({
      url: '/api/subscription/log',
      method: 'POST',
      headers: {'Authorization': `Bearer ${$store.state.auth.idToken}`},
      data: {
        type: 'log',
        email: formSignup.value.email || formLogin.value.email || userEmail.value,
        name: `${formPricing.value.nameFirst} ${formPricing.value.nameLast}`,
        company: formPricing.value.company,
        subscription: `${productType.value} ${plan.value && plan.value.name}`,
        domains: formPricing.value.domainOptional,
        subdomains: formPricing.value.subdomainOptional,
        errMessage: err
      },
    });
  };

  /**
   * @description Subscribe to product
   */
  function subscribe() {
    return new Promise((resolve) => {
      let subscription = {
        // coupon: formPricing.value.discountOptional, // TODO COUPON remove when enable
        domains: [formPricing.value.domainOptional],
        subdomains: [formPricing.value.subdomainOptional],
        planSku: planInterval.value.Sku,
      };

      axios({
        url: '/api/subscription',
        method: 'POST',
        data: subscription,
        headers: {'Authorization': `Bearer ${$store.state.auth.idToken}`},
      }).then(result => {
        if (result.status === 202) {
          $message({
            duration: 1000,
            message: 'Invalid domains/subdomains were found and not added. Please contact us or try register domains/subdomains again.',
            showClose: true,
            type: 'warning',
          })
        };
        transactionId.value = result.data.Id;
        resolve(true);
      }).catch((err) => {        
        failedAttempts.value++;
        if (failedAttempts.value > 1) support(err.response.data);

        $message({
          duration: 0,
          message: `Cannot subscribe. ${err.response.data}`,
          showClose: true,
          type: 'error',
        });
        resolve(false);
      });
    });
  };

  /**
   * @description Explicitly set to TRUE or FALSE or just toggle boolean state
   * @param {*} type - The target type to use
   */
  function toggleInterval() {
    // Toggle state
    isPerpetual.value = !isPerpetual.value;
    currentInterval.value = currentInterval.value === 'yearly' ? 'perpetual' : 'yearly';
    // Update Plan
    planInterval.value = filterPlan(
      plan.value.plans,
      'interval',
      interval.value
    );
  };

  /**
   * @description Non-auth user login / signup. Place data in localstorage that is later grabbed in `setupManualEntry` after redirecting.
   * @param {String} entry - determines entry type: 'login', 'signup'
   */
  function userEntry(entry) {
    // Push data to dataLayer
    pushAccountDataLayer(entry === 'login' ? 'existing' : 'new', 'email');

    // Save information for subscription process and account association
    let subscriptionData = {
      currentInterval: currentInterval.value,
      dataLayerObject: dataLayerObject.value,
      isPerpetual: isPerpetual.value,
      plan: plan.value,
      planInterval: planInterval.value,
      formPricing: formPricing.value,
      entryState: entry,
    }
    let { planInterval, planId } = $route.query;
    localStorage.setItem('redirectPath', `/subscribe?planId=${planId}&planInterval=${planInterval}` );

    axios({
      url: '/api/user/serversession',
      method: 'POST',
      data: { 'set': subscriptionData },
    }).then(() => {
      // For signup, update state to setup account
      if (entry === 'signup') setAuthIsNewSignupLocalStorage();
      
      // Toggle localStorage 'auth' state
      $store.state.auth.setAuthIsAuthenticatingLocalStorage();
      // Auth0 non-auth login
      let form = entry === 'login' ? formLogin.value : formSignup.value;
      $store.dispatch(`auth/${entry}`, {
        email: form['email'],
        password: form['password'],
        alert: $message
      });
    });
  };
</script>

<style src="./styles/subscription.css" scoped></style>

<style>
/* Element Ui Overwrites */
[pricing] .el-button {
  border-radius: 6px !important;
  font-size: 0.8125rem;
  font-weight: 600;
  transition: background-color .1s,border-color .1s,color .1s !important;
}
[pricing] .el-button.el-button--default {
  border-color: #b9bfc5 !important;
  color: #b9bfc5 !important;
}
[pricing] .el-button.el-button--primary {
  background: #04a3f5 !important;
  border-color: #04a3f5 !important;
  color: #fff !important;
}
[pricing] .el-button.el-button--primary:not(.is-disabled):hover {
  background-color: var(--color-tertiary-5) !important;
  border-color: transparent !important;
}
[pricing] .el-button.is-disabled {
  background: #ebebeb !important;
  border-color: #ebebeb !important;
  color: #fff !important;
}
[pricing] .is-link {
  color: var(--color-tertiary-7) !important;
  font-weight: 800;
  margin-top: 1rem;
  text-decoration: underline;
}
[pricing] .el-button.el-button--danger.is-plain {
  background-color: #fff !important;
  border: 1px solid #dd1659 !important;
  color: #dd1659 !important;
}
[pricing] .el-date-editor.el-input {
  width: 100% !important;
}
[pricing] .el-date-editor input,
[pricing] .el-input input {
  border-color: #dedede !important;
  color: #357083 !important;
  height: 2.5rem !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02rem;
  padding: 0 15px !important;
}
[pricing] .el-input input::placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-form-item {
  margin-bottom: 0.5rem;
}
[pricing] .el-form-item__content {
  line-height: inherit;
}
[pricing] .el-form-item__error {
  position: relative !important;
}
[pricing] .el-form-item__label {
  color: #808486;
  font-size: 0.6875rem;
  line-height: 1.5rem;
  padding: 0.15rem 0 0.25rem !important;
}
[pricing="formLogin"] .el-form-item__label,
[pricing="formSignup"] .el-form-item__label {
  color: var(--color-primary-1);
  font-weight: 500;
}
[pricing="button-secondary"].el-button.el-button--primary {
  background: #00394b !important;
  border-color: #00394b !important;
}
[pricing="button-secondary"].el-button.el-button--primary:hover {
  background-color: #778c92 !important;
  border-color: transparent !important;
}
[pricing="button-flex"],
[pricing*="form"] > .el-form-item:last-of-type .el-form-item__content {
  display: flex;
  margin-top: 1.25rem;
}
[pricing="button-flex"] .el-button,
[pricing*="form"] .el-button {
  font-size: 0.8125rem;
  font-weight: 600;
}
[pricing="button-flex"] .el-button + .el-button,
[pricing*="form"] .el-button + .el-button {
  margin-left: auto;
}
[pricing="button-flex"] .el-button,
[pricing="formPricing"] .el-button {
  width: 45.8333%;
}
[pricing="btn--closeform"].el-button.el-button--default {
  background-color: #fff;
  border-color: #003748 !important;
  color: #003748 !important;
  left: 1rem;
  position: fixed;
  top: 1rem;
  z-index: 999;
}
[pricing="formLogin"] .el-button,
[pricing="formSignup"] .el-button {
  width: 100%;
}
@media screen and (max-width: 400px) {
  [pricing="button-flex"] {
    flex-wrap: wrap;
  }
  [pricing="button-flex"] .el-button {
    width: 100%;
  }
  [pricing="button-flex"] .el-button + .el-button {
    margin-top: 1rem;
  }
}
</style>