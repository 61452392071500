<template>
  <div ref="$el" :noHide="!allowHide" :hidden="hideToggleGrid">

    <div toggle-grid="container" :heading="heading" :hideList="hideList" :hidden="hideGrid ">
      <!-- List Grid -->
      <!-- pTODO : remove all these v-show -->
      <zing-grid 
        v-show="(!hideList && !groupAdmin) || (groupAdmin && groupingAdminDashboardView)"
        height=625
        pager="true"
        page-size=10
        sorter="true"
        toggle-grid="list">
        <zg-caption>
          {{ caption ? caption : '' }}
          <el-button v-if="create" v-show="!hideCreate" toggle-grid="createButton" @click="createVisible=true" size="small" type="primary">Create</el-button>
        </zg-caption>
        <zg-colgroup>
          <zg-column v-if="listUsers" index="id" hidden="true"></zg-column>
          <zg-column v-if="setRole" index="id_user" hidden="true"></zg-column>
          <zg-column index="id_role" hidden="true"></zg-column>
          <zg-column index="name" :header="listHeader" renderer="renderName"></zg-column>
          <zg-column :hidden="!setRole || hideRoleSet" index="id_role, set"  header="Role" type="custom" renderer="renderSetRoles"></zg-column>
          <zg-column :hidden="!setRole || hideRoleView" index="id_role, view" header="Role" type="custom" renderer="renderViewRoles"></zg-column>
          <zg-column :hidden="!setRoleDefault" index="type" header="Type" sort-asc renderer=formatSnakeCase></zg-column>
          <zg-column :hidden="removeDetail || hidePermissionView" index="permissions_edit" header="Permissions" type="custom" width="175">
            <el-button size="small" type="primary">{{ buttonText }}</el-button>
          </zg-column>
          <zg-column v-if="requestDelete" :hidden="hideDelete" header=" " index="remove_button" sort=false type="custom" width="125">
            <el-button size="small" type="danger">{{removeText}}</el-button>
          </zg-column>
        </zg-colgroup>
        <zg-data>
          <zg-param v-if="!setRole && !hideList" name="src" :value="`${requestList}${groupId ? '/'+groupId : ''}`"></zg-param>
          <zg-param name="headers" :value="headerVal"></zg-param>
          <zg-param v-if="paging" name="countPath" value="total"></zg-param>
          <zg-param v-if="paging" name="loadByPage" value="true"></zg-param>
          <zg-param v-if="paging" name="nextPath" value="next"></zg-param>
          <zg-param v-if="paging" name="pageKey" value="page"></zg-param>
          <zg-param v-if="paging" name="prevPath" value="previous"></zg-param>
          <zg-param v-if="paging" name="recordPath" value="results"></zg-param>
        </zg-data>
      </zing-grid>

      <!-- Details Grid -->
      <zing-grid
        v-show="!groupAdmin || !hideDetailGrid"
        :class="hideList ? 'main' : 'hide'"
        height=680
        toggle-grid="details">
        <zg-caption>
          {{ caption ? caption : captionSecondary }}{{ detailCaption ? `: ${detailCaption}` : '' }}
          <el-button v-if="!hideList" @click="hidePermissions" size="small" type="primary">Back</el-button>
        </zg-caption>
        <zg-colgroup>
          <zg-column index="id" hidden="true"></zg-column>
          <zg-column v-if="admin && setRoleDefault" :hidden="hideDetailSet" index="id, name, active" header=" " renderer="renderCheckbox"></zg-column>
          <zg-column index="title, description" header="Permission" renderer="renderFeatureName" :width="admin ? '50%' : '75%'"></zg-column>
          <zg-column v-if="admin && setFeatureActive" :hidden="hideStatus" index="id, name, status" header="Active" renderer="renderActive" width="100"></zg-column>
          <!-- Set Feature Column -->
          <zg-column v-else-if="(admin || groupAdmin)" :hidden="hideDetailSet" index="id, name, enabled" header="Enabled" renderer="renderAdminEnabled" width="100"></zg-column>
          <zg-column v-else index="id, name, enabled, editable" header="Enabled" renderer="renderEnabled" width="100"></zg-column>
          <zg-column v-if="(admin || groupAdmin) && setAll" :hidden="hideDetailSet" index="id, name, editable" header="Editable" renderer="renderEditable" width="100"></zg-column>
          <zg-column v-if="(admin || groupAdmin) && setAll" :hidden="hideDetailSet" index="id, name, visible" header="Visisble" renderer="renderVisisble" width="100"></zg-column>
          <!-- View Feature Column -->
          <zg-column v-if="(admin || groupAdmin) && setAll" :hidden="!hideDetailSet" index="enabled" header="Enabled" width="100"></zg-column>
          <zg-column v-if="(admin || groupAdmin) && setAll" :hidden="!hideDetailSet" index="editable" header="Editable" width="100"></zg-column>
          <zg-column v-if="(admin || groupAdmin) && setAll" :hidden="!hideDetailSet" index="visible" header="Visisble" width="100"></zg-column>
        </zg-colgroup>
      </zing-grid>

      <!-- Create Form -->
      <el-dialog title="Create" v-model="createVisible">
        <el-form label-width="120px" :model="createForm">
          <el-form-item label="Name">
            <el-input autofocus :maxlength="MAX_LENGTH" v-model="createForm.name"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input :maxlength="MAX_LENGTH" v-model="createForm.description"></el-input>
          </el-form-item>
          <el-form-item label="Type" size="large">
            <el-select v-model="createForm.type" placeholder="Select">
            <el-option
              v-for="(option, index) in createTypeOptions"
              :key="index"
              :label="`${option.value[0].toUpperCase() + option.value.slice(1)}`"
              :value="option.value">
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item button-align="right" size="large">
            <el-button @click="submitCreate" type="primary">Create</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- Delete Form -->
      <el-dialog
        class="dialog--condense"
        v-model="deleteVisible">
        <h2 class="dialog__icon" style="color: #f56c6c"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="3x"/><br></h2>
        <h2>Are you sure you want to {{removeText.toLowerCase()}}?</h2>
        <p>You will not be able to revert your changes once deletion is confirmed.</p>

        <!-- Section for role deletion when role has existing users -->
        <div 
          v-if="setRoleFeature && requiresMigrate"
          class="dialog__delete">
          <h3 class="">Cannot delete this role!</h3>
          <p>There are users in this role. Please manually remove all users from the role or select a role to move all users to.</p>
          <el-select v-model="migrateTo" placeholder="Move current users to">
            <template v-for="(role, index) in roles">
              <el-option
                v-if="role"
                :key="role ? role.id : index"
                :label="role ? formatSnakeCase(role.name) : ''"
                :value="role ? role.id : ''">
              </el-option>
            </template>
          </el-select>
        </div>

        <div class="dialog__controls" button-align="right">
          <el-button @click="deleteVisible = false">Cancel</el-button>
          <el-button type="danger" @click="confirmDelete()">{{removeText}}</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- No Display Option -->
    <h1 v-show="type === 'features' || type === 'permissions_admin'">Sorry, you do not access to view</h1>
  </div>
</template>

<script setup>
  import { computed, defineProps, getCurrentInstance, onMounted, onUnmounted, ref } from 'vue';
  import { computedAsync } from '@vueuse/core';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import permissionsComposable from '../mixins/permissions';

  const { checkPermission } = permissionsComposable();

  const props = defineProps({
    /**
     * This component can consists of 1-2 grids.
     *   1 Grid: Starts off with Detail Grid
     *   2 Grids: Starts off with List Grid that opens up Detail Grid, where usually used to list users and make permission edits
     */
    admin: { type: Boolean, default: false, },            // Grid used by admin user usually to make changes 
    buttonText: String,                                   // List Grid: text for button that opens up Detail Grid
    caption: String,                                      // List Grid Caption
    captionSecondary: String,                             // Detail Grid Caption. If empty, uses List Grid Caption
    create: { type: Boolean, default: false, },           // Determines if grid used to create new record
    getRole: { type: Boolean, default: false, },          // Determines if grid will use roles information
    groupAdmin: { type: Boolean, default: false, },       // Grid used by group admin user
    groupId: { type: Number, default: null, },            // Id of group to request data for
    heading: { type: String, default: null, },            // Heading of caption
    allowHide: { type: Boolean, default: false, },        // Determines if allow ToggleGrid component to be hidden
    hideList: { type: Boolean, default: false, },         // Determines if component will only use Detail Grid
    listHeader: { type: String, default: 'User', },       // Header for first column for Detail Grid
    listUsers: { type: Boolean, default: false, },        // Determines if grid will use user information
    paging: { type: Boolean, default: false, },           // Determines if grid will use pagination (List Grid only)
    requestCreate: String,                                // Request call for creating new record
    requestDelete: { type: String, default: null, },      // Request call for deleting record
    requestDetail: String,                                // Request call for grid data to display in Detail Grid
    requestList: String,                                  // Request call for grid data to display in List Grid
    requestSet: String,                                   // Request call to make edits to a record
    removeDetail: { type: Boolean, default: false, },     // Adds button column in List Grid to redirect to Detail Grid
    removeText: { type: String, default: 'Delete', },     // Remove button text
    setAll: { type: Boolean, default: false, },           // Determines if editing features for editable, enabled, visible, active
    setFeatureActive: { type: Boolean, default: false, }, // Determines if Detail Grid used to enable/disable features
    setRole: { type: Boolean, default: false, },          // Determines if grid will be used to set roles
    setRoleFeature: { type: Boolean, default: false, },   // Determines if grid will be used to set features (enable only)
    setRoleDefault: { type: Boolean, default: false, },   // Determines if grid will be used to set default features for roles (turns on checkbox on Detail Grid)
    type: { type: String, default: null, },               // Type of ToggleGrid determines which permissions to check for actions
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $message = $global.$message;
  const $store = useStore();
  const $el = ref(null);
  const createTypeOptions = ref([{ value: 'grouping'}, {value: 'system'}]);                     // Create role type options
  const deleteVisible = ref(false);                                                             // Visibility of delete form
  const detailCaption = ref('');                                                                // Caption for Detail Grid
  const headerVal = ref(`{"Authorization": "Bearer ${$store.state.auth.idToken}"}`);       // Request header for actions
  const hideToggleGrid = ref(false);                                                            // Hide this ToggleGrid component
  const features = ref(null);                                                                   // List of features
  const createForm = ref({});                                                                   // Hold user inputs from from
  const createVisible = ref(false);                                                             // Visibility of create form
  const listGrid = ref(null);                                                                   // Reference to List Grid
  const MAX_LENGTH = ref(100);                                                                  // Max character length for inputs
  const migrateList = ref([]);                                                                  // List of users to migrate from role to delete
  const migrateTo = ref(null);                                                                  // Id of role to migrate users to
  const noData = ref(false);                                                                    // If grid contains no data, hide grid
  const toDelete = ref(null);                                                                   // Id of role to delete
  const requiresMigrate = ref(false);                                                           // Flag to determine if migration needed when deleting role (true when role has members)
  const roles = ref([]);                                                                        // List of roles used for select dropdown
  const roleId = ref(null);                                                                     // Id of role currently viewed in Detail Grid, used in request body on actions to this role
  const detailGrid = ref(null);                                                                 // Reference to Detail Grid
  const userId = ref(null);                                                                     // Id of user currently viewed in Detail Grid, used in request body on actions to this user

  const adminFeatureSetStatus = computedAsync(
    async () => {
      return await checkPermission('admin_feature_set_status', null, null, $store);
    }, null
  );
  const adminFeatureSetUser = computedAsync(
    async () => {
      return await checkPermission('admin_feature_set_user', null, null, $store);
    }, null
  );
  const adminFeatureView = computedAsync(
    async () => {
      return await checkPermission('admin_feature_view', null, null, $store);
    }, null
  );
  const adminFeatureViewusers = computedAsync(
    async () => {
      return await checkPermission('admin_feature_viewusers', null, null, $store);
    }, null
  );
  const adminRoleCreate = computedAsync(
    async () => {
      return await checkPermission('admin_role_create', null, null, $store);
    }, null
  );
  const adminRoleDelete = computedAsync(
    async () => {
      return await checkPermission('admin_role_delete', null, null, $store);
    }, null
  );
  const adminRoleUpdate = computedAsync(
    async () => {
      return await checkPermission('admin_role_update', null, null, $store);
    }, null
  );
  const adminRoleSet = computedAsync(
    async () => {
      return await checkPermission('admin_role_set', null, null, $store);
    }, null
  );
  const adminRoleView = computedAsync(
    async () => {
      return await checkPermission('admin_role_view', null, null, $store);
    }, null
  );
  const adminRoleViewusers = computedAsync(
    async () => {
      return await checkPermission('adminRoleViewusers', null, null, $store);
    }, null
  );
  const adminUserDelete = computedAsync(
    async () => {
      return await checkPermission('admin_user_delete', null, null, $store);
    }, null
  );
  const adminUserView = computedAsync(
    async () => {
      return await checkPermission('admin_user_view', null, null, $store);
    }, null
  );
  const groupingAdminDashboardView = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_dashboard_view', null, true, $store);
    }, null
  );
  const groupingAdminFeatureSetUser = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_feature_set_user', null, true, $store);
    }, null
  );
  const groupingAdminKick = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_kick', null, true, $store);
    }, null
  );
  const groupingAdminFeatureViewusers = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_feature_viewusers', null, true, $store);
    }, null
  );
  const groupingAdminRoleSet = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_role_set', null, true, $store);
    }, null
  );
  const groupingAdminRoleViewusers = computedAsync(
    async () => {
      return await checkPermission('grouping_admin_role_viewusers', null, true, $store);
    }, null
  );
  const groupingViewusers = computedAsync(
    async () => {
      return await checkPermission('grouping_viewusers', null, true, $store);
    }, null
  );

  const curUserId = computed(() => {
    return $store.state.user.user_id;
  });
  const hideCreate = computed(() => {
    switch(props.type) {
      case 'roles':
        return !adminRoleCreate.value;
        break;
    }
  });
  const hideDelete = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return !adminUserDelete.value;
        break;
      case 'permissions_group_admin':
        return !groupingAdminKick.value;
        break;
      case 'roles':
        return !adminRoleDelete.value;
        break;
    }
  });
  const hideGrid = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return !adminUserView.value;
        break;
      case 'permissions_group_admin':
        return !groupingViewusers.value;
        break;
      case 'features':
        return !adminFeatureView.value;
        break;
    }
  });
  const hideDetailGrid = computed(() => {
    switch(props.type) {
      case 'permissions_group_admin':
        return !groupingAdminDashboardView.value;
        break;
    }
  });
  const hideDetailSet = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return !adminFeatureSetUser.value;
        break;
      case 'permissions_group_admin':
        return !groupingAdminFeatureSetUser.value;
        break;
      case 'roles':
        return !adminRoleUpdate.value;
        break;
    }
  });
  const hidePermissionView = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return !adminFeatureViewusers.value;
        break;
      case 'permissions_group_admin':
        return !groupingAdminFeatureViewusers.value;
        break;
      case 'roles':
        return !adminRoleView.value;
        break;
    }
  });
  const hideRoleSet = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return !adminRoleSet.value;
        break;
      case 'permissions_group_admin':
        return !groupingAdminRoleSet.value;
        break;
    }
  });
  const hideRoleView = computed(() => {
    switch(props.type) {
      case 'permissions_admin':
        return adminRoleSet.value || !adminRoleViewusers.value;
        break;
      case 'permissions_group_admin':
        return groupingAdminRoleSet.value || !groupingAdminRoleViewusers.value;
        break;
    }
  });
  const hideStatus = computed(() => {
    switch(props.type) {
      case 'features':
        return !adminFeatureSetStatus.value;
        break;
    }
  });
  
  onMounted(() => {
    if (initToggleGrid) initToggleGrid();
  });

  onUnmounted(() => {
    // Remove event listeners for listGrid
    if (listGrid.value) {
      listGrid.value.removeEventListener('change', (e) => { selectRole(e) });
      listGrid.value.removeEventListener('record:click', (e) => { viewPermissions(e) });
      listGrid.value.removeEventListener('data:load', (e) => { checkData(e) });
      detailGrid.value.removeEventListener('data:load', (e) => { checkData(e) });
    }
    // Remove event listeners for detailGrid
    if (detailGrid.value) {
      detailGrid.value.removeEventListener('data:load', (e) => { checkData(e) });
      detailGrid.value.removeEventListener('record:click', (e) => { 
        toggleSwitch(e);
        toggleCheckbox(e);
      });
    }

    // Remove ZingGrid reference
    listGrid.value = null;
    detailGrid.value = null;
  });

  /**
   * @description Checks if grid displaying data. If no data, hide grid
   * @param { Object } e - ZingGrid event object
   * @param { Object } grid - target
   * @param { Object } gridData - target's data
   */
  function checkData(e, grid, gridData) {
    let zgRef = null;
    let data = null;
    if (e) {
      zgRef = e.target;
      data = zgRef.data;
    } else {
      zgRef = grid;
      data = gridData;
    }
    if (!data || Object.keys(data).length === 0) noData.value = true;
    else noData.value = false;
  };

  /**
   * @description Closes form while clearing out input fields
   */
  function closeForm(){
    createForm.value = {};
    createVisible.value = false;
  };

  /**
   * @description On button click, migrate (if neccessary) and delete current record
   * TODO need request for admin delete user
   * TODO sparate delete mthods depend of type of grid (role) -- need to include group id in request
   */
  function confirmDelete() {
    if (requiresMigrate.value) migrateAndDelete(); 
    else deleteRecord()
  };

  /**
   * @description Deletes record
   */
  function deleteRecord() {
    if (!hideDelete.value) {
      axios({
        url: `${props.requestDelete}/${toDelete.value}`,
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
      })
      .then((response) => {
        deleteVisible.value = false;
        reloadListGrid();
      }).catch((err) => {
        let errMessage = `Error occurred while deleting: ${err}`;
          if (requiresMigrate.value) errMessage = 'Error occurred while deleting: Make sure to select role to move current users';
          $message({
            message: errMessage,
            type: 'error',
          });
      });
    } else {
      $message({
        message: `You do not have permission to delete.`,
        type: 'error',
      });
    }
  };

  /**
   * @description Displays toggle grid
   */
  function displayPermissions() {
    listGrid.value.classList.add('hide');
    detailGrid.value.classList.remove('hide');
  };

  /**
   * @description Formats snake_case text
   * @param { String } text - text in snake_case to format
   */
  function formatSnakeCase(text) {
    if (!text) return;
    let retVal = text[0].toUpperCase() + text.slice(1);
    let underscoreIndex = text.indexOf('_');
    while (underscoreIndex > 0) {
      retVal = retVal.slice(0, underscoreIndex) 
        + ' ' 
        + retVal.slice(underscoreIndex+1, underscoreIndex+2).toUpperCase() 
        + retVal.slice(underscoreIndex+2);
      underscoreIndex = retVal.indexOf('_');
    }
    return retVal;
  };

  /**
   * @description Retrieve roles data and format data to find role data based on role id
   */
  function getRoles() {
    axios({
      url: `/api/role/list${props.groupId ? '/'+props.groupId : ''}`,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
    })
    .then((response) => {
      // Only display system-level roles if not dealing with group roles
      response.data.forEach(role => { if ((!props.groupId && role.type === 'system') || props.groupId) roles.value[role.id] = role; });
      localStorage.setItem('roles', JSON.stringify(roles.value));
      if (listGrid.value) listGrid.value.setSrc(`${props.requestList}${props.groupId ? '/'+props.groupId : ''}`);
    }).catch((err) => {
      $message({
        message: `Error creating role mappings: ${err}`,
        type: 'error',
      });
    });
  };

  /**
   * @description Get data to display in Detail Grid
   * @param { Object } data - The data of record to grab information to display
   */
  function getDetailData(data) {
    if (!detailGrid.value) {
      detailGrid.value = $el.value.querySelector('[toggle-grid="details"]');
      setupGrid(true);
    }
    if (!hidePermissionView.value) {
      axios({
        url: `${props.requestDetail}/${data.id ? data.id : data.id_user}${props.groupId ? '/'+props.groupId : ''}`,
        method: 'GET',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
      })
      .then((response) => {
        if (detailGrid.value) detailGrid.value.setData(response.data);
      }).catch((err) => {
        $message({
          message: `Error retrieving user's permissions: ${err}`,
          type: 'error',
        });
      });
    } else {
      $message({
        message: `You do not have permission to view user permissions.`,
        type: 'error',
      });
    }
  };

  /**
   * @description Hides the permissions grid
   */
  function hidePermissions() {
    listGrid.value.classList.remove('hide');
    detailGrid.value.classList.add('hide');
  };

  /**
   * @description Initializes detail grid by filtering data to display
   */
  function initDetailGrid() {
    axios({
      url: `${props.requestDetail}${props.groupId ? '/'+props.groupId : ''}`,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
    })
    .then((response) => {
      if (detailGrid.value) {
        if (props.setFeatureActive) detailGrid.value.setData(response.data);
        else {
          let permissions = [];
          for (let permission in response.data) if (response.data[permission].visible === 'true') permissions.push(response.data[permission]);
          detailGrid.value.setData(permissions);
          features.value = response.data;
          if (detailGrid.value && detailGrid.value.data && detailGrid.value.data.length === 0) {
            hideToggleGrid.value = true;
          }
        }
      }
    }).catch((err) => {
      $message({
        message: `Error retrieving user's permissions: ${err}`,
        type: 'error',
      });
    });
  };

  /** 
   * @description Initializes grid of user permissions by 
   *   - registering rendering methods
   *   - retrieving role data
   *   - setup grid by attaching event listeners
   *   - setup description grid if it is the main grid (usually displays list -> description)
   */
  function initToggleGrid() {
    // Save reference to grid
    if (!props.hideList) listGrid.value = $el.value.querySelector('[toggle-grid="list"]');
    detailGrid.value = $el.value.querySelector('[toggle-grid="details"]');

    registerMethods();
    if (props.getRole || props.setRole) getRoles();
    setupGrid();
    if (props.hideList) initDetailGrid();
  };

  /**
   * @description Migrates dependents of current record being deleted (toDelete) to another record (migrateTo)
   */
  function migrateAndDelete() {
    if (!hideRoleSet.value) {
      let requestBody = [];
      migrateList.value.forEach(user => {
        requestBody.push({
          userId: user.id, 
          roleId: migrateTo.value,
        });
      });
      axios({
        url: '/api/role/set',
        method: 'POST',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
        data: requestBody
      })
      .then((response) => {
        deleteRecord();
      }).catch((err) => {
        $message({
          message: `Error migrating users: ${err}`,
          type: 'error',
        });
      });
    } else {
      $message({
        message: `You do not have permission to migrate users to another role.`,
        type: 'error',
      });
    };
  };

  /**
   * @description Saves record id and prompts user to confirm record deletion
   */
  function promptDelete(e) {
    if (e.detail.ZGEvent.oDOMRealTarget.textContent === props.removeText) {
      toDelete.value = e.detail.ZGData.data.id ? e.detail.ZGData.data.id : e.detail.ZGData.data.id_user;

      if (!hideDelete.value) {
        axios({
          url: `/api/role/${toDelete.value}?limit=1000`,
          method: 'GET',
          headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
        })
        .then((response) => {
          requiresMigrate.value = response.data.total === 0 ? false : true;
          if (requiresMigrate.value) migrateList.value = response.data.results;
        }).catch((err) => {
          let errMessage = `Error occurred while deleting: ${err}`;
          if (requiresMigrate.value) errMessage = 'Error occurred while deleting: Make sure to select role to move current users';
          $message({
            message: errMessage,
            type: 'error',
          });
        });
      }
      deleteVisible.value = true;
    }
  };

  /**
   * @description Called after action to display new changes for list grid
   */
  function reloadListGrid() {
    axios({
      url: `${props.requestList}${props.groupId ? '/'+props.groupId : ''}`,
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` }
    })
    .then((response) => {
      if (listGrid.value) {
        listGrid.value.setData(response.data);
        listGrid.value.lastPage();
        closeForm();
      }
    }).catch((err) => {
      $message({
        message: `Error reloading data: ${err}`,
        type: 'error',
      });
    });
  };

  /**
   * @description Registers methods for rendering the grid data
   */
  function registerMethods() {
    window.formatSnakeCase = (text) => {
      if (!text) return;
      let retVal = text[0].toUpperCase() + text.slice(1);
      let underscoreIndex = text.indexOf('_');
      while (underscoreIndex > 0) {
        retVal = retVal.slice(0, underscoreIndex) 
          + ' ' 
          + retVal.slice(underscoreIndex+1, underscoreIndex+2).toUpperCase() 
          + retVal.slice(underscoreIndex+2);
        underscoreIndex = retVal.indexOf('_');
      }
      return retVal;
    }

    // Render Element UI checkbox
    window.renderCheckbox = (id, feature, value, el) => {
      let active = value && (value === true || value === 'true') ? true : false;
      // If feature disabled for role, add disable class to parent row
      if (!active) el.closest('zg-row').classList.add('disabled');
      return `<span aria-checked="mixed" class="el-checkbox__input ${ active ? 'is-checked' : '' }" feature-id="${id}" feature="${feature}"><span class="el-checkbox__inner"></span><input type="checkbox" aria-hidden="true" class="el-checkbox__original" value=></span>`;
    }

    // Renders name
    window.renderName = (name) => {
      let header = formatSnakeCase(name);
      return `${header ? header : 'N/A'}`;
    }

    // Renders tooltip on text hover
    window.renderFeatureName = (name, description) => {
      return `
      <div class="tooltip">
        <span class="tooltip__trigger">${name}</span>
        <span class="tooltip__message">${description}</span>
      </div>`;
    }
    

    // Render toggles for each type of permissions (delegates to renderPermissions)
    window.renderActive = (id, feature, status) => { return window.renderPermissions('status', id, feature, status, 'true') };
    window.renderAdminEnabled = (id, feature, permission) => { return window.renderPermissions('enabled', id, feature, permission, 'true') };
    window.renderEditable = (id, feature, permission) => { return window.renderPermissions('editable', id, feature, permission, 'true') };
    window.renderEnabled = (id, feature, permission, editable) => { return window.renderPermissions('enabled', id, feature, permission, editable) };
    window.renderVisisble = (id, feature, permission) => { return window.renderPermissions('visible', id, feature, permission, 'true') };
    
    // Renders toggle switches
    window.renderPermissions = (type, id, feature, permission, editable) => {
      function on(feature, editable) { return `<span toggle-grid="switch" feature="${feature}" role="switch" class="el-switch is-checked ${ editable === 'true' ? '' : 'is-disabled' }" aria-checked="true"><input type="checkbox" name="" true-value="true" class="el-switch__input"><span class="el-switch__core"></span></span>`; }
      function off(feature, editable) { return `<span toggle-grid="switch" feature="${feature}" role="switch" class="el-switch ${ editable === 'true' ? '' : 'is-disabled' }"><input type="checkbox" name="" true-value="true" class="el-switch__input"><span class="el-switch__core"></span></span>`; }
      let retVal = `<p feature-id="${id}" feature-name="${feature}">${permission === 'true' || permission ==='active' ? on(type, editable) : off(type, editable)}</p>`;
      return retVal;
    }

    // Renders select dropdown for roles (admin view)
    window.renderSetRoles = (curRole) => {
      let roles = JSON.parse(localStorage.getItem('roles'));
      let retVal = `<div class="default"><select>`;
      // Include default if not role set
      retVal += `<option value = "0" disabled ${curRole === 0 ? 'selected' : ''}>Select a role</option>`;
      roles.forEach(role => { if (role) retVal += `<option value="${role.id}" ${curRole === role.id ? 'selected' : ''}>${formatSnakeCase(role.name)}</option>`; }); 
      retVal += `</select></div><div class="expandable"></div>`;
      return retVal;
    }

    // Renders roles (member view)
    window.renderViewRoles = (curRole) => {
      let roles = JSON.parse(localStorage.getItem('roles'));
      let retVal = `<div class="default">`;
      retVal += `${curRole === 0 ? 'N/A' : ''}`;
      roles.forEach(role => { if (role && (curRole === role.id)) retVal += formatSnakeCase(role.name) }); 
      retVal += `</div>`;
      return retVal;
    }
  };

  /**
   * @description Setup functionalities of grid by adding event listeners
   * @param { Boolean } detailOnly - setup only Detail Grid
   */
  function setupGrid(detailOnly) {
    if (!props.hideList && listGrid.value) {
      listGrid.value.executeOnLoad(() => {
        listGrid.value.addEventListener('change', (e) => { selectRole(e) });
        listGrid.value.addEventListener('record:click', (e) => {
          if (props.requestDelete) promptDelete(e);
          viewPermissions(e);
        });
        listGrid.value.addEventListener('data:load', (e) => { checkData(e) });
      });
    }
    if (detailGrid.value || detailOnly) {
      detailGrid.value.executeOnLoad(() => {
        detailGrid.value.addEventListener('record:click', (e) => {
          toggleSwitch(e);
          toggleCheckbox(e);
        });
        detailGrid.value.addEventListener('data:load', (e) => { checkData(e) });
      });
    }
  };
  
  /**
   * @description Updates the role and sends a request to make change in database
   */
  function selectRole(e) {
    // Check if target is the select element
    if (e.target.tagName === 'SELECT') {
      // Form request body
      let rowRef =  e.target.closest('zg-row')
      let columnId = rowRef.querySelector('[data-field-index="id"]') ? rowRef.querySelector('[data-field-index="id"]') : rowRef.querySelector('[data-field-index="id_user"]');
      let userId = columnId.getAttribute('value');
      let requestBody = [{
        'userId': userId,
        'roleId': e.target.value,
      }];
      if (props.groupId) { requestBody[0]['groupingId'] = props.groupId; }

      if (!hideRoleSet.value) {
        axios({
          url: `/api/role/set`,
          method: 'POST',
          headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
          data: requestBody
        });
      } else {
        $message({
          message: `You do not have permission to set user roles.`,
          type: 'error',
        });
      }
    }
  };

  /**
   * @description Submits create form to insert a new row into grid
   */
  function submitCreate() {
    // Check if fields nonempty
    if (!createForm.value.name || createForm.value.name.length === 0
      || !createForm.value.description || createForm.value.description.length === 0 
      || !createForm.value.type || createForm.value.type.length === 0) {
      $message({
        message: `All fields are required to be filled`,
        type: 'error',
      });
      return;
    }

    if (!hideCreate.value) {
      let requestBody = {
        description: createForm.value.description,
        name: createForm.value.name.toLowerCase().replace(' ', '_'),
        title: createForm.value.name,
        type: createForm.value.type
      };

      axios({
        url: props.requestCreate,
        method: 'POST',
        headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
        data: requestBody
      })
      .then((response) => {
        reloadListGrid();
      }).catch((err) => {
        $message({
          message: `Error during creation process: ${err}`,
          type: 'error',
        });
      });
    } else {
      $message({
        message: `You do not have permission to create new role.`,
        type: 'error',
      });
    }
  };

  /**
   * @description Toggles checkbox and sends a request to make update on checkboxed feature 
   */
  function toggleCheckbox(e) {
    // On click event, check if target is checkbox before toggling
    let target = e.detail.ZGEvent.oDOMTarget;
    if (target.classList.contains('el-checkbox__inner')) {
      if (!hideStatus.value) {
        let checkboxRef = target.closest('.el-checkbox__input');
        let feature = checkboxRef.getAttribute('feature');
        checkboxRef.classList.toggle('is-checked');
        target.closest('zg-row').classList.toggle('disabled');
        let enabled = checkboxRef.classList.contains('is-checked');

        let requestBody = [{ 
          'name': feature,
        }];
        requestBody[0]['active'] = enabled;
        if (props.admin) requestBody[0]['roleId'] = roleId.value;
        axios({
          url: props.requestSet,
          method: 'PUT',
          headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
          data: requestBody
        });
      } else {
        $message({
          message: `You do not have permission to toggle feature for role.`,
          type: 'error',
        });
      }
    }
  };

  /**
   * @description Toggles switch and sends a request to make update on toggled feature 
   */
  function toggleSwitch(e) {
    // On click event, check if target is switch before toggling
    let target = e.detail.ZGEvent.oDOMTarget;
    if (target.classList.contains('el-switch__core')) {
      let switchRef = target.closest('[role="switch"]');
      // Check if disabled from editing
      if (switchRef.classList.contains('is-disabled')) {
        $message({
          message: `Permission is disabled from editing`,
          type: 'error',
        });
        return;
      }

      // Grabs closest element containing data to form request body
      let switchContent = target.closest('p').textContent.toLowerCase();
      let switchType = switchContent.slice(0, switchContent.length-2);
      let featureName = target.closest('p').getAttribute('feature-name');
      let featureId = target.closest('p').getAttribute('feature-id');
      let feature = switchRef.getAttribute('feature');
      if (!hideDetailSet.value  // Admin set
        || (!props.admin && features.value && features.value[featureName].editable === 'true')) { // User set
        switchRef.classList.toggle('is-checked');

        let enabled;
        // Check if enabling or disabling
        if (switchRef.getAttribute('aria-checked') === 'true') {
          switchRef.removeAttribute('aria-checked');
          enabled = false;
        } else {
          switchRef.setAttribute('aria-checked', 'true');
          enabled = true;
        }

        let requestBody = [{ 
          'id': featureId,
        }];
        requestBody[0][feature] = props.setFeatureActive ? ( enabled ? 'active' : 'disable') : enabled;
        if ((props.admin && props.setAll && !props.setRoleFeature) || (props.groupId && userId.value)) requestBody[0]['userId'] = userId.value;
        if (props.admin && props.setRoleFeature) requestBody[0]['roleId'] = roleId.value;
        // TODO check for group admin
        if (props.groupId) requestBody[0]['groupingId'] = props.groupId

        axios({
          url: props.requestSet,
          method: 'PUT',
          headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
          data: requestBody
        });
      }
    }
  };

  /**
   * @description On button click, display grid of user permissions
   */
  function viewPermissions(e) {
    if (e.detail.ZGEvent.oDOMRealTarget.textContent === "Edit / View") {
      let data = e.detail.ZGData.data;
      if (props.setAll) userId.value = data.id ? e.detail.ZGData.data.id :data.id_user;
      if (props.setRoleFeature) roleId.value = data.id;
      detailCaption.value = data.title ?  formatSnakeCase(data.title) : (data.name ? formatSnakeCase(data.name) : 'N/A');
      getDetailData(data);
      displayPermissions();
    }
  };
</script>

<style>
  /* Dialog Overwrites */
  [toggle-grid] .dialog--condense { display: flex; justify-content: center; }
  [toggle-grid] .dialog--condense .el-dialog { padding-right: 0 !important; }
  [toggle-grid] .dialog--condense .el-dialog__body { padding: 0 30px 2rem !important; }
  [toggle-grid] .dialog--condense .el-dialog__body > *, .dialog__icon { text-align: center; }
  [toggle-grid] .dialog--condense  .el-dialog__header { padding: 1rem 0rem !important; }
  [toggle-grid] .dialog__controls { display: flex; justify-content: center; flex-wrap: wrap; margin-top: 2rem; width: 100%; }
  [toggle-grid] .dialog__delete { background: #f3dede; border-radius: 8px; margin: 2rem; padding: 1rem; }

  /* Grid */
  [toggle-grid][heading="2"] zg-caption { font-size: 1.5rem; }
  [toggle-grid] zg-caption .el-button { float: right; margin: 0 0.5rem 0 0; }
  [toggle-grid] + h1,
  [toggle-grid][hidden], [noHide="false"][hidden] { position: absolute; opacity: 0; pointer-events: none; }
  [toggle-grid][hidden] + h1, [noHide="true"][hidden]  { position: relative; opacity: 1; pointer-events: all; }
  [noHide="true"][hidden] { display: block; }

  /* List ZingGrid */
  [toggle-grid].hide { opacity: 0; pointer-events: none; }
  [toggle-grid="container"] { position: relative; }

  /* Details ZingGrid */
  [hideList=true] [toggle-grid="list"] + [toggle-grid="details"] { margin-top: 7rem; }
  [toggle-grid="details"].hide { display: none; }
  [toggle-grid="details"].main { display: block; position: relative; }
  [toggle-grid="details"] zg-row.disabled zg-cell:not([data-field-index="id, name, active"]) { opacity: 0.5; pointer-events: none; }
  [toggle-grid="details"] zg-cell[value="false"] { color: rgb(255, 73, 73); }
  [toggle-grid="details"] zg-cell[value="true"] { color: rgb(19, 206, 102); }
  [toggle-grid="list"] + [toggle-grid="details"] { margin: 0; }

  /* Toggle Switches */
  [toggle-grid="switch"] { bottom: 2px; margin-left: 1rem; }
  [toggle-grid] .el-switch__core { background-color: rgb(255, 73, 73);  border-color: rgb(255, 73, 73); width: 40px; }
  [toggle-grid] .el-switch.is-checked .el-switch__core { background-color: rgb(19, 206, 102); border-color: rgb(19, 206, 102); }
  [toggle-grid] .is-disabled .el-switch__core, [toggle-grid] .el-switch.is-checked.is-disabled .el-switch__core { background-color: rgb(132, 132, 132); border-color: rgb(132, 132, 132); }
  /* Expandable Row Styles */
  [toggle-grid="list"] zg-row:hover { background-color:#f5f7fa; border-bottom:1px solid #ebebeb; transition:background-color 0.25s ease-in; }

  /* Tooltips */
  .tooltip { position: relative; }
  .tooltip__trigger:hover + .tooltip__message { opacity: 1; pointer-events: all; }
  .tooltip__message { background-color: #303133; border-radius: 5px; color: #fff; font-weight: 500; opacity: 0; padding: 0.5rem 1rem; pointer-events: none; position: absolute; right: 0; text-align: center; width: 15rem; top:-1rem; z-index: 100; }
  .tooltip__message:after {  border-top: solid 8px transparent; border-right: solid 10px #303133; border-bottom: solid 8px transparent; content: ''; position: absolute; left: -0.5rem; top: 1rem; }

  /* Theme */
  [toggle-grid] zg-caption {
    background: transparent;
    border-bottom: 1px solid #DDD;
    color: #6A848F;
    font-family: var(--font-family);
    font-size: 1.5625rem;
    font-weight: lighter;
    height: 3.25rem;
    letter-spacing: 0.05rem;
    margin: 2rem 0 1rem;
    padding: 0;
  }
  [toggle-grid="details"] {
    position: absolute;
    top: 0;
  }
  zing-grid[toggle-grid]{
    background: transparent;
    border: 1px solid #dcdfe6;
    box-shadow: var(--box-shadow-card);
    margin-top: 7rem;
  }
  [toggle-grid] zg-head-cell {
    background-color: #f5f7fa;
  }
  [toggle-grid] zg-header {
    position: absolute;
    top: -7rem;
    width: 100%;
  }
</style>