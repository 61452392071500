<template>
  <div class="avatar-upload">
    <div class="avatar-upload__image" :style="avatar"></div>
    <el-tooltip
      class="item"
      effect="dark"
      content="Coming Soon! Currently available when Google accounts are linked"
      placement="bottom">
      <el-button class="avatar-upload__action" type="primary" size="small" plain>Edit Avatar</el-button>
    </el-tooltip>
  </div>
</template>

<script setup>
  import { computed, onBeforeMount, ref } from 'vue';
  import { useStore } from 'vuex';

  const $store = useStore();
  const picture = ref(null);

  const avatar = computed(() => {
    let url = $store.state.user && $store.state.user.picture
      ? picture.value : null;
      return `background-image: url('${url}')`;
  });

  onBeforeMount(() => {
    axios({
      url: '/api/user',
      method: 'GET',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
    }).then((data) => {
      let userData = data.data;
      picture.value = userData.picture;
    });
  });
</script>

<style>
.avatar-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.avatar-upload__image {
  height: 150px;
  width: 150px;
  margin: 0 4rem 1rem 4rem;
  border-radius: 75px;
  background: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
