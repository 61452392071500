<template>
  <div class="form__item">
    <label class="form__label" id="location">Billing Address</label>
    <el-input
      v-model="location_"
      class="form__field"
      for="location"
      maxlength="200"
      placeholder="Your Billing Address"
      :rows="2"
      :show-word-limit="true"
      type="textarea"></el-input>
  </div>
</template>

<script setup>
  import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
  
  const emit = defineEmits(['change']);

  const props = defineProps({
    value: {
      type: String
    }
  });
  
  const location_ = ref(null);
  const init = ref(true);
  const dirty = ref(false);

  const location = computed(() => {
    return props.value;
  });

  watch(location, (value) => {
    location_.value = value;
    dirty.value = false;
  });
  watch(location_, () => {
    if (init.value) {
      // Handles an init call
      init.value = false;
    } else if (!dirty.value)  {
    // Handles changes by the store mutating the event
      dirty.value = true;
      emit('change', {location: location_.value});
    } else if (dirty.value) {
      // If the field was modified by user entry
      emit('change', {location: location_.value});
    } 
  });

  onMounted(() => {
    location_.value = props.value;
  });
</script>
