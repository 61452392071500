<template>
  <div v-show="hasUser" ref="headerBar" header-bar="bar">
    <div header-bar="inner">
      <p><strong>Reminder:</strong> Complete your <router-link to="/account">profile</router-link> to help us better serve you!</p>
    </div>
    <span @click="toggleBar()" class="trigger">
      <svg-icon icon="close--circle" class="spin" height="1rem" width="1rem"></svg-icon>
    </span>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import SvgIcon from '../../components/SvgIcon.vue';

  const $store = useStore();

  const headerBar = ref(null);

  const allowDisplay = computed(() => {
    // Determine if user previously closed
    // Do not display until state refreshed
    return $store.state.ui.headerBar;
  });
  const displayReminder = computed(() => {
    // Check if all required fields filled
    return (!$store.state.user.name
      || !$store.state.user.company
      || !$store.state.user.location
      || !$store.state.user.phone_number)
      && $store.state.user.quickbooks;
  });
  const hasUser = computed(() => {
    return !!$store.state.user['user_id'];
  });

  watch(displayReminder, () => {
    toggleBar(displayReminder.value);
  });

  onMounted(() => {
    if (displayReminder.value) {
      toggleBar(true);
    }
  });
   
  function toggleBar(display) {
    // Allow animation to apply
    if (headerBar.value && allowDisplay.value) {
      setTimeout(() => {
        // Determine to display or hide
        if (display) {
          // Display
          headerBar.value.classList.add('show');
        } else {
          // Hide
          headerBar.value.classList.remove('show');
          // Prevent from displaying in this state
          $store.state.ui.headerBar = false;
        }
      }, 500);
    }
  };
</script>

<style>
  [header-bar="bar"] {
    background: var(--color-secondary-3);
    left: 0;
    max-height: var(--nav-height);
    position: absolute;
    right: 0;
    top: 0;
    transition: top .4s cubic-bezier(.175,.885,.32,1.085);
    z-index: 100;
  }

  [header-bar].show {
    top: var(--nav-height);
  }

  [header-bar="inner"] {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: auto;
  }

  [header-bar] [icon="close--circle"] {
    cursor: pointer;
    fill: #fff;
    margin: 9px 1rem;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 8px 15px;
  }


  [header-bar] a {
    color: #fff;
  }
</style>
