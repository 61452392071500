<template>
  <section
    v-if="adminAccess"
    class="content--wide"
    account-settings>

    <toggle-grid
      allow-hide
      caption="Your Permissions"
      heading="2"
      hide-list
      paging
      set-all
      request-detail="/api/feature/get/list"
      request-set="/api/feature/set"
      type="permissions_user"
    ></toggle-grid>

  </section>
</template>

<script setup>
  import { defineProps, onMounted } from 'vue';

  import toggleGrid from './../../../components/ToggleGrid.vue';

  const props = defineProps({
    adminAccess: {
      type: Boolean,
      required: true,
    },
  });

  onMounted(() => {
    if (props.adminAccess) {
      registerMethods();
    }
  });

  /**
   * @description Register renderer functions
   */
  function registerMethods() {
    window.renderToggle = (curVal) => {
      const ON = '<div role="switch" class="el-switch is-checked" aria-checked="true"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(19, 206, 102); background-color: rgb(19, 206, 102);"></span><!----></div>';
      const OFF = '<div role="switch" class="el-switch"><input type="checkbox" name="" true-value="true" class="el-switch__input"><!----><span class="el-switch__core" style="width: 40px; border-color: rgb(255, 73, 73); background-color: rgb(255, 73, 73);"></span><!----></div>';
      if (curVal) return ON;
      else return OFF;
    }
  };
</script>