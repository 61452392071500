<template>
  <section>
    <template v-if="!card">
      <el-button type="danger" size="small" @click="cardDialogVisible=true;" plain>Add Card</el-button>
    </template>
    <template v-else>
      <template v-if="mini">
        <div class="ccard__mini">
          <div class="ccard__number">**** **** **** {{card.last4}}</div>
          <div class="ccard__brand"><font-awesome-icon :icon="cardIcon" size="2x"/></div>
          <el-button type="danger" size="small" @click="removeCard" plain>Remove Card</el-button>
        </div>

      </template>
      <template v-else>
        <div class="ccard__frame">
          <header class="ccard__header">
            <h3>My Card</h3>
          </header>
          <div class="ccard__number">
            **** **** **** {{card.last4}}
          </div>
          <div class="ccard__bottom">
            <div class="ccard__exp">
              Expires:<br>
              {{card.expMonth}} / {{card.expYear}}
            </div>
            <div class="ccard__brand">
            <font-awesome-icon :icon="cardIcon" size="4x"/>
            </div>
          </div>
        </div>
        <el-button type="danger" @click="removeCard" plain>Remove Card</el-button>
      </template>
    </template>

      <!-- Add Card -->
      <el-dialog
        subscription="cardDialog"
        title="Add Card"
        v-model="cardDialogVisible">
        <subscription-form
          :form-pricing="formCard"
          :submitAction="addCard"
          submit-text="Add Card"
          @close-dialog="cardDialogVisible = false;">
        </subscription-form>
      </el-dialog>
  </section>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, onMounted, ref } from 'vue';
  import SubscriptionForm from '../../entry/components/SubscriptionForm.vue';

  const emit = defineEmits(['remove', 'update']);

  const props = defineProps({
    mini: {
      type: Boolean,
      default: false
    }
  }); 
  
  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;

  const card = ref(null);
  const cardDialogVisible = ref(false);
  const formCard = ref({
    cardNumber: '',
    cardExpiration: '',
    cardCvc: '',
    chargeOptional: '',
  });

  const cardIcon = computed(() => {
    return ['fab', 'cc-' + card.value.brand.toLowerCase()];
  });

  onMounted(() => {
    // Check to see if the user has a billing card.
    retrieveCard();
  });

  function removeCard() {
    $api('card/delete', {}, $global)
      .then(() => {
        card.value = null;
        $message({
          message: 'Card Deleted!',
          showClose: true,
          type: 'success',
        });
        emit('remove');
      })
      .catch(() => {
        $message({
          duration: 0,
          message: 'Card could not be deleted!',
          showClose: true,
          type: 'error',
        });
      })
  };

  function retrieveCard() {
    $api('card/retrieve', {}, $global).then((result) => {
      card.value = result.data;
      updateBrand();
    });
  };

  function addCard() {
    let cardExpiration = formCard.value.cardExpiration.split('/');
    let card = {
      number: formCard.value.cardNumber,
      exp_month: parseInt(cardExpiration[0]),
      exp_year: parseInt(cardExpiration[1]),
      cvc: formCard.value.cardCvc,
    };
    if (formCard.value.chargeOptional) card.name = formCard.value.chargeOptional;
    
    $api('card/add', {
      data: {card},
    }, $global).then((response) => {
      if (response.data.cardType) {
        cardDialogVisible.value = false;
        retrieveCard();
      } else {
        $message({
          duration: 0,
          message: `Could not add card. ${response.data}`,
          showClose: true,
          type: 'error',
        });
      }
    }).catch((err) => {
      $message({
        duration: 0,
        message: `Could not add card. ${err.response.data}`,
        showClose: true,
        type: 'error',
      });
    });
  };

  function updateBrand() {
    if (card.value && card.value.brand) {
      let cardBrand = card.value.brand.toLowerCase();
      if (cardBrand === 'mc') cardBrand = 'mastercard';
      else if (cardBrand === 'disc') cardBrand = 'discover';
      emit('update', cardBrand);
    }
  };
</script>

<style>
  .ccard__mini {
    display: flex;
    align-items: center;
  }

  .ccard__mini .ccard__number {
    font-size: 0.9rem;
  }
  .ccard__mini .ccard__brand {
    margin-right: 1rem;
  }

  .ccard__header {
    padding: 0 1rem;
  }

  .ccard__frame {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 215px;
    border: 1px solid #333;
    border-radius: 15px;
    margin: 1rem 0;
    background: linear-gradient(to top, #818284, #2c3e50);
    color: #f3f3f3;
    box-shadow: var(--box-shadow-card);
  }

  .ccard__frame:hover {
    box-shadow: var(--box-shadow-card-hover);
  }

  .ccard__frame--blank {
    justify-content: center;
    align-items: center;
    background: none;
    cursor: pointer;
    border: 1px solid #EFEFEF;
    color: var(--color-primary-1);
  }

  .ccard__number {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 40%;
    padding: 0 1rem 1rem;
    font-size: 1.2rem;
    letter-spacing: 4px;
    font-family:Courier, Times, serif;
  }
  .ccard__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 1rem;
    font-family:Courier, Times, serif;
  }

  @media screen and (min-width: 400px) {
    .ccard__frame {
      width: 335px;
    }
  }
</style>
