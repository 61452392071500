import { nextTick, ref } from 'vue';
import axios from 'axios';

export default function invoiceComposable({$global, invoiceFormRef}) {
  const disableInvoiceButton = ref(false);
  const subscriptionInvoiceDialogVisible = ref(false);
  const subscriptionInvoiceSend = ref(null);
  const subscriptionInvoiceSent = ref(null);
  const formInvoice = ref({
    email: '',
    invoiceId: '',
  });
  
  function checkInvoiceFormFilled(value) {
    formInvoice.value.email = value;
    disableInvoiceButton.value = formInvoice.value.email.length === 0;
  };

  function openInvoiceDialog(subID) {
    // Open dialog
    subscriptionInvoiceDialogVisible.value = true;

    nextTick(() => {
      // Get references
      if (!subscriptionInvoiceSend.value) {
        subscriptionInvoiceSend.value = document.querySelector('#sendInvoiceButtonSend');
        subscriptionInvoiceSent.value = document.querySelector('#sendInvoiceButtonSent');
      };

      // Update buttons
      subscriptionInvoiceSend.value.classList.remove('hide');
      subscriptionInvoiceSent.value.classList.add('hide');
      disableInvoiceButton.value = false;

      // Set invoice id to form
      formInvoice.value.invoiceId = subID;
    });
  };

  function sendInvoice() {
    // Disable button to prevent double submission
    disableInvoiceButton.value = true;

    // Send invoice
    axios({
      url: '/api/subscription/invoice',
      method: 'POST',
      headers: { 'Authorization': `Bearer ${$global.$store.state.auth.idToken}` },
      data: {
        email: formInvoice.value.email,
        invoiceId: formInvoice.value.invoiceId,
      },
    }).then(() => {
      // Update buttons
      disableInvoiceButton.value = false;
      nextTick(() => {
        subscriptionInvoiceSend.value.classList.add('hide');
        subscriptionInvoiceSent.value.classList.remove('hide');
      });
    }).catch((error) => {
      console.log(error)
    });
  };
  
  function validateInvoiceForm() {
    invoiceFormRef.value.validate((valid) => {
      disableInvoiceButton.value = !valid;
    });
  };

  return {
    disableInvoiceButton,
    subscriptionInvoiceDialogVisible,
    subscriptionInvoiceSend,
    subscriptionInvoiceSent,
    formInvoice,

    checkInvoiceFormFilled,
    openInvoiceDialog,
    sendInvoice,
    validateInvoiceForm,
  };
};