<template>
  <zingnet-card>
    <template v-slot:header>
      <span>
        Sales Summary
        <date-range @updateDateRange="updateDateRange"></date-range>
      </span>
    </template>
    <template v-slot:header__right>
      <span>
        <el-button @click="openQuickbooks">View in Quickbooks</el-button>
      </span>
    </template>
    <template v-slot:body>
      <div v-loading="loading">
        <section class="zingnetCard--row">
          <section
            v-for="item in counts"
            :key="item.title.replace(/\s/g, '')"
            class="zingnetCard--column">
            <h3 class="zingnetCard--header">{{item.title}} <bubble-count :comparison-cur="item.after.count" :comparison-prev="item.before.count"></bubble-count></h3>
            <div class="zingnetCard--row">
              <score-card-group>
                <template v-slot:card_one>
                  <score-card :currency="item.currency" :label="label" :value="item.after.count" :title="`in last ${daysDiff} days`"></score-card>
                </template>
                <template v-slot:card_two>
                  <score-card :currency="item.currency" :label="label"  :value="item.before.count" :title="`in previous ${daysDiff} days`" :secondary="true"></score-card>
                </template>
              </score-card-group>
            </div>
            <div class="zingnetCard--row no-border">
              <div :id="`chart-${item.title.replace(/\s/g, '')}`"></div>
            </div>
          </section>
        </section>
      </div>
    </template>
  </zingnet-card>
</template>

<script setup>
  import { defineEmits, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import BubbleCount from '../BubbleCount.vue';
  import DateRange from '../DateRange.vue';
  import ScoreCard from '../ScoreCard.vue';
  import ScoreCardGroup from '../ScoreCardGroup.vue';
  import ZingnetCard from '../ZingnetCard.vue';
  import dateComposable from '../../../../mixins/date.js';
  import dateRangeComposable from '../../mixins/dateRange.js';

  const { dateDiff, dateList, dateRange } = dateComposable();
  const { start, end, updateDateRange } = dateRangeComposable({ getReportData});
  
  const emit = defineEmits(['openQuickbooks']);

  const $store = useStore();
  const chartConfig = ref({
    type: 'line',
    plot: {
      animation: {
        effect: 'ANIMATION_EXPAND_LEFT',
        method: 'ANIMATION_LINEAR',
        sequence: 'ANIMATION_NO_SEQUENCE',
        speed: 1000,
      },
      lineWidth: '1.5px',
      marker: {
        visible: false,
      }
    },
    plotarea: {
      margin: '5px 0 25px 0',
    },
    scaleX: {
      item: {
        fontColor: 'var(--color-primary-gray)',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '12px',
        rules: [{
          rule: '%v == 0',
          offsetX: 20,
        }, {
          rule: '%v == 7',
          offsetX: -20,
        }]
      },
      guide: {
        visible: false,
      },
      labels: [],
      lineColor: 'var(--zingnet-border-color)',
      showLabels: [],
      tick: {
        visible: false,
      },
    },
    scaleY: {
      guide: {
        visible: false,
      },
      visible: false,
    },
    series: [
      {
        values: [],
        lineColor: 'var(--color-secondary-blue)',
      },
      {
        values: [],
        lineColor: 'var(--color-primary-gray-visited)',
      }
    ]
  });
  const counts = ref({
    paymentsReceived: {
      title: 'Total payments received',
      currency: true,
      before: {
        count: 0,
        series: [],
      },
      after: {
        count: 0,
        series: [],
      },
    },
    paymentsNotReceived: {
      title: 'Total payments not received',
      currency: true,
      before: {
        count: 0,
        series: [],
      },
      after: {
        count: 0,
        series: [],
      },
    },
    customers: {
      title: 'New Customers',
      currency: false,
      before: {
        count: 0,
        series: [],
      },
      after: {
        count: 0,
        series: [],
      },
    },
  });
  const loading = ref(false);
  const daysDiff = ref(7);
  const label = ref('$');

  function calcDiff() {
    daysDiff.value = parseInt(dateDiff(start.value, end.value, 'YYYY-MM-DD', 'days'));
  };

  function getReportData() {
    calcDiff();
    getSummary();
  };

  function getSummary() {
    loading.value = true;

    axios({
      url: `/api/admin/quickbooks/sales/summary?startDate=${start.value}&endDate=${end.value}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${$store.state.auth.idToken}`,
      },
    }).then((response) => {
      counts.value.paymentsReceived.before.count = response.data.received.total;
      counts.value.paymentsReceived.before.series = response.data.received.series;
      counts.value.paymentsReceived.after.count = response.data.receivedPrev.total;
      counts.value.paymentsReceived.after.series = response.data.receivedPrev.series;

      counts.value.paymentsNotReceived.before.count = response.data.notReceived.total;
      counts.value.paymentsNotReceived.before.series = response.data.notReceived.series;
      counts.value.paymentsNotReceived.after.count = response.data.notReceivedPrev.total;
      counts.value.paymentsNotReceived.after.series = response.data.notReceivedPrev.series;

      counts.value.customers.before.count = response.data.customers.total;
      counts.value.customers.before.series = response.data.customers.series;
      counts.value.customers.after.count = response.data.customersPrev.total;
      counts.value.customers.after.series = response.data.customersPrev.series;

      initChart();
    });
  };

  function initChart() {
    // Determine scale labels
    let labels = dateList({
      end: end.value,
      step: 'days',
      stepNum: 1, 
      add: daysDiff.value, 
      inputFormat: 'YYYY-MM-DD', 
      outputFormat: 'MMM D'
    });
    chartConfig.value.scaleX.labels = labels;
    chartConfig.value.scaleX.showLabels = [labels[0], labels[labels.length-1]];

    // Determine rules
    chartConfig.value.scaleX.item.rules[1].rule = `%v == ${daysDiff.value}`;

    // Render ZingChart
    for (let count in counts.value) {
      let config = Object.assign({}, chartConfig.value);

      // Add series (data)
      config.series[0].values = counts.value[count].after.series;
      config.series[1].values = counts.value[count].before.series;

      // Adjust speed for year's worth of data
      if (daysDiff.value > 300) config.plot.animation.speed = 100;

      // Render ZingChart
      zingchart.render({
        id: `chart-${counts.value[count].title.replace(/\s/g, '')}`,
        data: config,
        height: '80px',
        width: '294px'
      });

      loading.value = false;
    };
  };

  function openQuickbooks() {
    emit('openQuickbooks');
  };
</script>