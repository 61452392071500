 <template>
  <section>
    <template v-for="connection in connections" :key="connection.provider">
      <div class="social-option">
        <section class="social-option--icon">
          <font-awesome-icon v-if="accounts[connection.provider]" style="color:#f44336;" :icon="['fab', connection.icon]" size="3x"/>
          <font-awesome-icon v-else style="color:#DDD;" :icon="['fab', connection.icon]" size="3x"/>
        </section>
        <section>
          <div class="social-option--name">{{connection.name}}</div>
          <div v-if="accounts[connection.provider]">{{accounts[connection.provider].email}}
            <a href="#" @click="unlink(connection.provider)">Unlink</a>
          </div>
          <div v-else>
            <a href="#" @click="link(connection.provider)">Link</a>
          </div>
        </section>
      </div>
    </template>
  </section>
</template>

<script setup>
  import { computed, defineProps, getCurrentInstance, ref } from 'vue';
  import { useStore } from 'vuex';

  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $store = useStore();

  const props = defineProps({
    value: {
      type: String
    }
  });

  const connections = ref([{
    name: 'Google Plus',
    icon: 'google-plus-square',
    provider: 'google-oauth2',
    style: 'color:#f44336;',
  }]);

  const accounts = computed(() => {
    const socials = {};
    // Loop through the identities and find only social accounts
    const identities = props.value;
    if(identities) {
      identities.forEach(connection => {
        if(connection.provider !== 'auth0') {
          socials[connection.provider] = {
            email: connection.profileData.email,
          };
        }
      });
    }
    return socials;
  });

  function link(social) {
    $store.state.auth.webAuth.authorize({
      scope: 'openid email',
      connection: social,
      responseType: 'token',
      redirectUri: window.location.origin + '/?linking',
    }, function(err, authResult) {
      console.log(authResult);
    });

  };

  function unlink(social) {
    const primaryid = $store.state.user.user_id;
    const socialid = $store.getters['user/retrieveSocialID'](social);
    if (socialid) {
      $api('oauth/unlink', {
        data: {
          primaryid,
          social,
          socialid,
        },
      }, $global)
      .then(() => {
        $store.dispatch('refresh_tokens');
        $message({
          message: 'Account unlinked!',
          showClose: true,
          type: 'success',
        });
      })
      .catch((error) => {
        console.log(error);
        console.log('Error unlinking to server');
      })
    }
  };
</script>

<style>
.social-option {
  display: flex;
  /* border: 1px solid rgb(201, 201, 201); */
  border-radius: 5px;
  align-items: center;
  margin: 1rem;
}

.social-option--icon {
  margin: 0 1rem;
}
.social-option--name {
  font-size: 1.4rem;
}
</style>
