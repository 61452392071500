<template>
  <section>
    <pre>{{licenseKey}}</pre>
    <textarea ref="textarea"></textarea>
    <footer>
      <el-button @click="copyCode" type="primary" size="small">{{buttonText}}</el-button>
    </footer>
  </section>
</template>

<script setup>
  import { defineProps, onMounted, watch, ref } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';

  const props = defineProps({
    subscription: Object,
  }); 

  const $store = useStore();
  const fetchText = ref('Fetching license key...');
  const initialText = ref('Copy');
  const copyText = ref('Copied!');
  const buttonText = ref(null);
  const licenseKey = ref(null);
  const textarea = ref(null);
      
  watch(props.subscription, () => {
    getKey();
  });

  onMounted(() => {
    // Set text
    buttonText.value = initialText.value;
    licenseKey.value = fetchText.value;

    // Fetch license key
    getKey();
  });

  /**
   * @description Copies license key to clipboard
   */
  function copyCode() {
    // Copy to clipboard
    textarea.value.textContent = licenseKey.value;
    textarea.value.select();
    document.execCommand('copy');

    // Update text for 1s
    buttonText.value = copyText.value;
    setTimeout(() => {
      buttonText.value = initialText.value;
    }, 1000);
  };

  /**
   * @description Fetches license key
   */
  function getKey() {
    if (props.subscription) {
      let interval = props.subscription.plan.interval;
      let plan = `${interval === 'year' ? 'New/Annual' : 'Perpetual'}:${props.subscription.plan.type}-${props.subscription.plan.product}-${interval === 'year' ? 'A' : 'P'}`;
      axios({
        url: '/api/license/key',
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + $store.state.auth.idToken },
        data: {
          plan: plan,
          invoiceId: props.subscription.invoiceId,
          type: props.subscription.plan.type,
        },
      }).then((response) => {
        licenseKey.value = response.data;
      }).catch((err) => {
        $message({
          duration: 0,
          message: `Could not fetch license key. Please contact us regarding your license key.`,
          showClose: true,
          type: 'error',
        });
      });
    } else {
      licenseKey.value = fetchText.value;
    }
  };
</script>

<style scoped>
  footer {
    display: flex;
    justify-content: flex-end;
  }

  pre {
    background-color: #f4f9fb;
    border: 1px solid #d6e1e6;
    border-radius: 4px;
    margin-bottom: 1.25rem;
    padding: 18px 24px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  section {
    padding: 2rem;
  }

  textarea {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }

  @media screen and (min-width: 472px) {
    section {
      padding: 2rem 3rem;
    }
  }
</style>