import { ref } from 'vue';
import cachingComposable from './caching';
const { cacheFetch, cacheSaveData } = cachingComposable();

export default function metadataComposable({$global}) {
  const galleryMetadata = ref({
    chartType: {
      options: {},
      type: 'select',
      selectMultiple: true,
      addOption: false,
    }, 
    features: {
      options: {},
      type: 'select',
      selectMultiple: true,
      addOption: true,
    },
    isDashboard: {
      type: 'boolean',
    },
    isExample: {
      type: 'boolean',
    },
    isPopular: {
      type: 'boolean',
    },
    loadInIframe: {
      type: 'boolean',
    },
    relatedDocs: {
      options: {},
      type: 'select-url',
      selectMultiple: true,
      addOption: true,
    },
    theme: {
      options: {},
      type: 'select',
      selectMultiple: false,
      addOption: true,
    },
    useCase: {
      options: {},
      type: 'select',
      selectMultiple: true,
      addOption: true,
    },
    vanityUrl: {
      type: 'url',
      selectMultiple: false,
      addOption: true,
    }
  });
  const galleryMetadataOptions = ref([
    {
      name: 'Chart Type',
      value: 'chartType',
    }, {
      name: 'Features',
      value: 'features',
    }, {
      name: 'Example',
      value: 'isExample',
    }, {
      name: 'Dashboard',
      value: 'isDashboard',
    }, {
      name: 'Popular',
      value: 'isPopular',
    }, {
      name: 'Load in Iframe',
      value: 'loadInIframe',
    }, {
      name: 'Related Docs',
      value: 'relatedDocs',
    }, {
      name: 'Theme',
      value: 'theme',
    }, {
      name: 'Business Use Case',
      value: 'useCase',
    }, {
      name: 'Gallery Vanity URL',
      value: 'vanityUrl',
    }
  ]);

  /**
   * @description Fetches options for all gallery metadata types
   */
  function fetchAllOptions() {
    let options = galleryMetadataOptions.value.map((option) => option.value);
    fetchOption(options);
  };

  /**
   * @description Fetches options for specified gallery metadata type. Fetches from localstorage first,
   * before attempting to fetch from api endpoint
   * @description {String[]} type - gallery metadata type
   */
  function fetchOption(type) {
    // Get options is cached
    if (typeof type !== 'object') type = [type];
    type.forEach((t) => {
      let data = cacheFetch(`metaoptions_${t}`);
      if (data) {
        data = JSON.parse(data);
        if (galleryMetadata.value[t].type === 'boolean' && data && data[''] && data[''[0]]) data = data[''][0];
        // Parse data and set options right away
        galleryMetadata.value[t].options = data;
      } 
    });

    // Fetch data to check if localstorage updated
    $global.$api('metaoptions/list_and_group', {
      slug: 'type',
    }, $global)
    .then((result) => {
      if (result && result.data) {
        for (let type in result.data) {
          let options = {};
          result.data[type].forEach((item) => {
            // Option object
            let obj = {
              name: item.value,
              value: item.value,
              content: item.content || '',
              demo_type: item.demo_type,
              metadata_uid: item.id,
            }
            // Create array (if not exist) and push
            if (!options[item.demo_type]) options[item.demo_type] = [];
            options[item.demo_type].push(obj);
          });

          if (galleryMetadata.value[type]) {
            if (galleryMetadata.value[type].type === 'boolean') options = options[''][0];
            galleryMetadata.value[type].options = options;
            cacheSaveData(`metaoptions_${type}`, options);
          };
        };
      }
    });
  };

  return {
    galleryMetadata,
    galleryMetadataOptions,
    fetchAllOptions,
    fetchOption,
  };
};
