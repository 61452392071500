<template>
  <section class="zingnetCardSection">
    <!-- User Summary -->
    <user-summary></user-summary>

    <!-- Users -->
    <latest-users></latest-users>
  </section>
</template>

<script setup>
  import LatestUsers from './LatestUsers.vue';
  import UserSummary from './UserSummary.vue';
</script>